import { constants } from '../../constants';

export const requestPasswordReset = (email) => ({
  type: constants.components.resetPasswordRequest.START,
  payload: {
    email,
  },
});

export const passwordResetSentTo = (email) => ({
  type: constants.components.resetPasswordRequest.SENT_TO,
  payload: {
    email,
  },
});

export const setNewPassword = (hash, password) => ({
  type: constants.components.resetPasswordRequest.SET_NEW_PASSWORD,
  payload: {
    hash,
    plainPassword: password,
  },
});

export const setNewPasswordUser = (user) => ({
  type: constants.components.resetPasswordRequest.SET_NEW_PASSWORD_USER,
  payload: {
    user,
  },
});
