import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import TransferRoute from '../TransferRoute/TransferRoute';
import RoutePrice from '../RoutePrice/RoutePrice';
import './TransfersDropdown.scss';

const TransfersDropdown = (props) => {
  const { t, route } = props;
  const { steps, sellable } = route;

  return (
    <div className="search-results-route-dropdown">
      {!!steps.length && (
        <>
          <div className="search-results-route-dropdown-content">
            {steps.map((step, i) => (
              <React.Fragment key={`transfer-route-step-${i}`}>
                <TransferRoute
                  route={step}
                  isFirst={i === 0}
                  isLast={steps.length - 1 === i}
                  isConnecting={steps.length >= 3}
                />
              </React.Fragment>
            ))}
          </div>

          {sellable && (
            <div className="search-results-route-dropdown-footer">
              <div className="search-results-route-dropdown-footer__title">
                {t('route_ticket_price')}:
              </div>

              <RoutePrice sellable={sellable} route={route} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

TransfersDropdown.propTypes = {
  t: PropTypes.func.isRequired,
  route: PropTypes.shape(),
};

TransfersDropdown.defaultProps = {
  route: null,
};

export default withTranslation()(TransfersDropdown);
