/* eslint-disable react/no-danger */

import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';
import { formatMoney } from '../../../../../core/utilities/money';
import { tripDirections, ticketStatusFulfilled, ticketTypes } from '../../../../../core/config/constants/tickets';
import Button from '../../../../../components/presentational/Button/Button';
import './Ticket.scss';
import { getLink, routes } from '../../../../../core/config/routes';
import Icon from '../../../../../components/presentational/Icon/Icon';

const Ticket = ({
  orderHash,
  ticket,
  t,
}) => {
  const isMultiDirection = tripDirections
    .map((direction) => !!ticket[direction])
    .filter(Boolean)
    .length > 1;
  const allStates = tripDirections
    .map((direction) => ticket[direction] && ticket[direction].status)
    .filter(Boolean);
  const allStatesEqual = allStates.every((state) => state === allStates[0]);
  const multipleUniqueStates = isMultiDirection && !allStatesEqual;
  const {
    downloadUrl,
    clientName,
    total,
    number,
    canChange,
    canReturn,
    ticketId,
    ticketType,
    discountName,
    gaClientId,
  } = ticket;
  const onDownloadClick = () => {
    window.location.href = downloadUrl;
  };

  if (allStates.includes(ticketStatusFulfilled)) {
    React.useEffect(() => {
      const forward = ticket.forward.parts.map((value) => ({
        name: value.trip,
        id: value.identifer,
        price: value.price / 100,
        quantity: 1,
      }));

      const products = isMultiDirection ? forward.concat(ticket.backward.parts.map((value) => ({
        name: value.trip,
        id: value.identifer,
        price: value.price / 100,
        quantity: 1,
      })))
        : forward;

      TagManager.dataLayer({
        dataLayer: {
          event: 'purchase',
          ecommerce: {
            purchase: {
              actionField: {
                id: ticket.ticketId,
                revenue: ticket.total / 100,
              },
            },
            products,
            customerId: gaClientId,
          },
        },
      });
    }, [gaClientId, ticket]);
  }

  return (
    <div className="ticket">
      <div className="ticket-info">
        <div className="ticket-header">
          <div className="ticket-header__name">
            {clientName}

            {ticketType === 3 && (
              <div className="ticket-header__discount-name">
                ({discountName})
              </div>
            )}
          </div>

          <div className="ticket-header__total">
            <p>{t('checkout_price')}</p>{formatMoney(total)}
          </div>
        </div>

        {tripDirections.map((direction) => {
          const trip = ticket[direction];

          if (!trip) return null;

          const {
            name,
            parts,
            departureDate,
            departureTime,
            arrivalDate,
            arrivalTime,
            duration,
            withBike,
          } = trip;

          return (
            <div key={direction} className="ticket-trip">
              <div className="ticket-trip-stops">
                <div className="ticket-trip-stops__header">{isMultiDirection ? t(`checkout_trip_${direction}`) : t('ticket_return_trip')}</div>

                <div className="ticket-trip-stops__name">{name}</div>

                <div className="ticket-trip-stops-parts">
                  {parts.map((part, partIndex) => (
                    <div key={part.partId} className="ticket-trip-stops-part">
                      {!!partIndex && <div className="circle" />}

                      <Icon icon="bus" className="ticket-trip-stops-part__bus" />

                      <b>{part.departureTimeString}</b>

                      {' '}

                      {part.fromTripStop.name}

                      <Icon icon="arrow-long-right" className="ticket-trip-stops-part__arrow" />

                      <b>{part.arrivalTimeString}</b>

                      {part.toTripStop.name}

                      {' '}
                    </div>
                  ))}
                </div>
              </div>

              {withBike && (
                <div className="ticket-trip-property">
                  <div className="ticket-trip-property-name">{t('ticket_bike_spot')}</div>

                  <div className="ticket-trip-property-value">
                    {t('ticket_reserved')}
                  </div>
                </div>
              )}

              <div className="ticket-trip-property">
                <div className="ticket-trip-property-name">{t('checkout_order_departure')}</div>

                <div className="ticket-trip-property-value">
                  {departureDate}

                  <Icon icon="clock" />

                  {departureTime}
                </div>
              </div>

              <div className="ticket-trip-property">
                <div className="ticket-trip-property-name">{t('checkout_order_return')}</div>

                <div className="ticket-trip-property-value">
                  {arrivalDate}

                  <Icon icon="clock" />

                  {arrivalTime}
                </div>
              </div>

              <div className="ticket-trip-property ticket-trip-duration">
                <div className="ticket-trip-property-name">{t('route_ticket_duration')}</div>

                <div className="ticket-trip-property-value">{duration}</div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="ticket-actions">
        <div className="ticket-number">
          <span className="ticket-number__label">{t('ticket_no')}</span>
          {' '}
          <span className="ticket-number__value">{number}</span>
        </div>

        {(
          multipleUniqueStates
            ? tripDirections
            : [tripDirections[0]]
        ).map((direction, directionIndex) => {
          const trip = ticket[direction];

          if (!trip) return null;

          const { status } = trip;

          return (
            <React.Fragment key={direction}>
              {multipleUniqueStates && (
                <div className="ticket-status-direction">
                  {t(`ticket_direction_title_${direction}`)}
                </div>
              )}

              <div className={`ticket-status ${!multipleUniqueStates || directionIndex === tripDirections.length - 1 ? 'is-last' : ''}`}>
                <div dangerouslySetInnerHTML={{
                  __html: t('ticket_status', {
                    status: `<span class="ticket-status__value">${t(`ticket_status_${status}`)}</span>`,
                  }),
                }}
                />

                {ticketType === ticketTypes.promo && (
                  <div className="promo-ticket-title">
                    {t('route_purchase_promo')}
                  </div>
                )}
              </div>
            </React.Fragment>
          );
        })}

        <div className="ticket-action-buttons">
          {canChange && (
            <Button
              variant="inline-link"
              className="ticket-action-button"
              route={
                getLink(t(routes.ticketChange.ticket), { orderHash, activeTicketId: ticketId })
              }
            >
              <Icon icon="clock" />

              <span>
                {t('ticket_date_change')}
              </span>
            </Button>
          )}

          {canReturn && (
            <Button
              variant="inline-link"
              className="ticket-action-button"
              route={
                getLink(t(routes.ticketReturn.ticket), { orderHash, activeTicketId: ticketId })
              }
            >
              <Icon icon="clock" />

              <span>
                {t('ticket_return')}
              </span>
            </Button>
          )}
        </div>

        {allStates.includes(ticketStatusFulfilled)
          && (
            <Button
              className="ticket-download"
              variant="brand"
              onClick={onDownloadClick}
            >
              {t('ticket_download')}
            </Button>
          )}
      </div>
    </div>
  );
};

Ticket.propTypes = {
  t: PropTypes.func.isRequired,
  ticket: PropTypes.shape().isRequired,
  orderHash: PropTypes.string.isRequired,
};

export default withTranslation()(Ticket);
