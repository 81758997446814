import { routes } from '../../../core/config/routes';

export default ({
  t,
  onOpenContactModal,
  onRouteClick,
  defaultLocale,
  locale,
}) => ([
  {
    children: process.env.CONTACT_EMAIL,
    icon: 'email',
    onClick: onOpenContactModal,
    id: 'info',
    position: 'left',
  },
  {
    children: t('common_help'),
    icon: 'circle-question',
    to: t('url_cms_help'),
    onClick: onRouteClick,
    id: 'help',
    position: 'left',
  },
  {
    children: t('events_events'),
    to: t(routes.events.index),
    onClick: onRouteClick,
    id: 'event_with_bus',
    position: 'right',
  },
  {
    children: t('navigation_route_search'),
    to: t(routes.homepage),
    onClick: onRouteClick,
    id: 'search',
    position: 'right',
  },
  locale === defaultLocale && {
    children: t('e_ticket'),
    to: t(routes.eticket.index),
    onClick: onRouteClick,
    id: 'e_ticket',
    position: 'right',
  },
]);
