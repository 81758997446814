/* eslint-disable react/no-danger */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { getObjectNestedData } from '../../../core/utilities/object';
import RouteMap from './Partials/RouteMap/RouteMap';
import './RouteSchedule.scss';
import Button from '../../../components/presentational/Button/Button';
import Icon from '../../../components/presentational/Icon/Icon';

class RouteSchedule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMap: false,
      activeMarker: null,
    };
  }

  getStopsFromSteps = () => {
    const { route } = this.props;
    const { steps } = route;
    const arrayToMap = steps || [...route];
    const stepsStops = [];

    arrayToMap.forEach((step) => {
      const { transitDetails } = step;
      const {
        stops, departureStop, arrivalStop, departureTime, arrivalTime,
      } = transitDetails;
      const newStepsArray = [];

      newStepsArray.push(...stops);

      newStepsArray.unshift({
        ...departureStop,
        lat: departureStop.location.lat,
        lng: departureStop.location.lng,
        departureTime: { ...departureTime },
        arrivalTime: { ...arrivalTime },
      });

      newStepsArray.push({
        ...arrivalStop,
        lat: arrivalStop.location.lat,
        lng: arrivalStop.location.lng,
        arrivalTime: { ...arrivalTime },
      });

      stepsStops.push(newStepsArray);
    });

    if (steps && steps.length > 1) this.addWaitingTime(steps, stepsStops);

    return stepsStops;
  };

  addWaitingTime = (steps, stepsStops) => {
    stepsStops.forEach((stops, stepsArrayIndex) => {
      const { t } = this.props;
      const nextIndex = stepsArrayIndex + 1;

      if (steps.length - 1 >= nextIndex) {
        const { transitDetails, travelMode } = steps[nextIndex];
        const { departureTime: nextStopDepartureTime } = transitDetails;
        const lastStopArrivalTime = stops[stops.length - 1].arrivalTime.value;
        const timeDifferenceSeconds = nextStopDepartureTime.value - lastStopArrivalTime;
        const timeDifferenceMinutes = timeDifferenceSeconds / 60;

        stepsStops[nextIndex].unshift({
          type: 'wait',
          message: travelMode === 'WALKING'
            ? t('route_will_have_to_walk')
            : (
              <div
                className="has-flex"
                dangerouslySetInnerHTML={{
                  __html: t('route_will_have_to_wait', {
                    mins: `<div class="is-bold">${timeDifferenceMinutes}${t('route_will_have_to_wait_min')}</div>`,
                  }),
                }}
              />
            ),
        });
      }
    });
  };

  onMarkerClick = (marker) => {
    const { showMap } = this.state;

    if (showMap) {
      this.setState({
        activeMarker: marker,
      });
    }
  };

  render() {
    const { t, route } = this.props;
    const { showMap, activeMarker } = this.state;
    const {
      title, steps, transitDetails, travelMode, duration,
    } = route;
    const stepsStops = this.getStopsFromSteps();
    const shortName = steps
      ? getObjectNestedData(steps[0].transitDetails, 'line.shortName')
      : getObjectNestedData(transitDetails, 'line.shortName');
    const isFirstStepWalking = steps && steps[0].travelMode === 'WALKING';
    const stepsCount = steps && steps.length;
    const transferCount = isFirstStepWalking ? stepsCount - 2 : stepsCount - 1;

    return (
      <div className="route-schedule-modal-wrap">
        <div className="route-schedule-modal">
          <div className="route-schedule-modal-stops">
            <div className="route-schedule-modal-stops-header">
              {shortName && (
                <div className="route-schedule-modal-stops-header__code">{t('route_title')}&nbsp;{shortName}:</div>
              )}

              <h2 className="route-schedule-modal-stops-header__title">
                {title || transitDetails.headsign}

                {travelMode === 'WALKING' && (
                  <div className="route-schedule-modal-stops-header__title is-walking">
                    <Icon icon="walking" />

                    <span>{t('route_ticket_walking_duration')}: <span>{duration.text}.</span></span>
                  </div>
                )}
              </h2>

              {steps && steps.length > 1 && (
                <div className="route-schedule-modal-stops-header__transfers">
                  <div className="bubble" />

                  {transferCount}&nbsp;

                  {t(`route_${transferCount >= 2 ? 'transfer_plural' : 'transfer'}`)}
                </div>
              )}
            </div>

            <div className="route-schedule-modal-stops-content">
              <div className="route-schedule-modal-stops-list">
                {stepsStops.map((stops, stopsArrayIndex) => (
                  <React.Fragment key={`stop-${stopsArrayIndex}`}>
                    {stops.map((stop, i) => {
                      const {
                        type, arrivalTime, departureTime, name, message,
                      } = stop;
                      const isFirst = stopsArrayIndex === 0 && i === 0;
                      const isLast = stopsArrayIndex === stepsStops.length - 1
                        && stops.length - 1 === i;

                      return (
                        <div
                          key={`${stop.id}_${(arrivalTime || {}).value}`}
                          role="presentation"
                          className={`
                            route-schedule-modal-stops-list-item
                            ${(activeMarker && activeMarker.id === stop.id) ? 'is-active' : ''}
                            ${type === 'wait' ? 'has-transfer' : ''}
                          `}
                          onClick={() => this.onMarkerClick(stop)}
                        >
                          <div className={`
                            route-schedule-modal-stops-list-item__time
                            ${isFirst ? 'is-first' : ''}
                            ${isLast ? 'is-last' : ''}
                          `}
                          >
                            {type !== 'wait' && (stops.length - 1 === i ? arrivalTime.text : departureTime.text)}
                          </div>

                          <div className="route-schedule-modal-stops-list-item__name">
                            {type === 'wait' ? message : name}
                          </div>
                        </div>
                      );
                    })}
                  </React.Fragment>
                ))}
              </div>
            </div>

            <div className="route-schedule-modal-stops-content__shadow" />
          </div>

          <div className={`route-schedule-modal-map ${!showMap ? 'is-disabled' : ''}`}>
            {showMap ? (
              <RouteMap
                route={route}
                stepsStops={stepsStops}
                onMarkerClick={this.onMarkerClick}
                activeMarker={activeMarker}
                loadingElement={<div style={{ height: '100%' }} />}
                containerElement={<div style={{ height: '100%' }} />}
                mapElement={<div style={{ height: '100%' }} />}
                googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.GOOGLE_MAPS_KEY}`}
              />
            ) : (
              <Button
                variant="brand"
                className="route-schedule-modal-map__btn"
                onClick={() => this.setState({ showMap: true })}
              >
                {t('common_button_view')}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

RouteSchedule.propTypes = {
  t: PropTypes.func.isRequired,
  route: PropTypes.shape(),
};

RouteSchedule.defaultProps = {
  route: null,
};

export default withTranslation()(RouteSchedule);
