import {
  put,
  call,
  all,
  takeEvery,
} from 'redux-saga/effects';
import { getObjectNestedData } from '../../../utilities/object';
import { setSubscribtionStatus } from './NewsletterActions';
import { constants } from '../../constants';
import { startRequest } from '../../Request/RequestSaga';
import { endpoints } from '../../../config/endpoints';

export function* onsubscribeNewsletter(action) {
  const { response } = yield call(
    startRequest,
    endpoints.SUBSCRIBE_NEWSLETTER.name,
    endpoints.SUBSCRIBE_NEWSLETTER.url.path(),
    action.payload,
  );

  if (response) {
    const state = getObjectNestedData(response, 'data.data');

    yield put(setSubscribtionStatus(state.success));
  }
}

export default function* NewsletterSaga() {
  yield all([
    takeEvery(constants.components.newsletter.SUBSCRIBE, onsubscribeNewsletter),
  ]);
}
