import React from 'react';
// eslint-disable-next-line import/extensions
import FamilyDiscount from '../FamilyDiscount/FamilyDiscount-VARIANT.js';

const DiscountAb = () => (
  <div className="discounts-container">
    <FamilyDiscount />
  </div>
);

export default DiscountAb;
