import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import './Modal.scss';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createFocusTrap } from 'focus-trap';
import ButtonClose from '../ButtonClose/ButtonClose';

const Modal = ({
  opened,
  content,
  size,
  onClose,
  disableUiClosing,
  t,
}) => {
  const handleClose = disableUiClosing ? () => null : onClose;
  const handleEsc = ({ keyCode }) => {
    if (opened && keyCode === 27) handleClose();
  };

  const modalContentRef = useRef(null);

  React.useEffect(() => {
    document.addEventListener('keydown', handleEsc, false);

    return () => {
      document.removeEventListener('keydown', handleEsc, false);
    };
  }, [disableUiClosing, opened]);

  React.useEffect(() => {
    let focusTrap;
    if (opened) {
      document.body.classList.add('modal-open');
      focusTrap = createFocusTrap(modalContentRef.current, {
        onDeactivate: handleClose,
        clickOutsideDeactivates: !disableUiClosing,
        escapeDeactivates: true,
      });
      focusTrap.activate();
    } else {
      document.body.classList.remove('modal-open');
      if (focusTrap) {
        focusTrap.deactivate();
      }
    }
    return () => {
      if (focusTrap) {
        focusTrap.deactivate();
      }
    };
  }, [opened, disableUiClosing, handleClose]);

  const isOpenedClass = opened ? 'is-opened' : '';
  const sizeClass = (size === 'normal' ? ' is-normal' : '')
    || (size === 'large' ? ' is-large' : '')
    || (size === 'big' ? ' is-big' : '')
    || '';

  return (
    <div className={`modal-container ${isOpenedClass}`}>
      <div
        className={`modal-content ${isOpenedClass} ${sizeClass}`}
        role="dialog"
        tabIndex={-1}
        ref={modalContentRef}
      >
        <ButtonClose
          onClick={handleClose}
          className="modal-close-button"
          ariaLabel={t('exit_button')}
        />
        {content}
      </div>

      <div className="modal-overlay" onClick={handleClose} role="presentation" />
    </div>
  );
};

Modal.propTypes = {
  opened: PropTypes.bool,
  disableUiClosing: PropTypes.bool,
  content: PropTypes.node,
  size: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

Modal.defaultProps = {
  opened: false,
  size: 'normal',
  disableUiClosing: false,
  content: null,
};

export default withTranslation()(Modal);
