import { cityTypes } from '../config/constants/cityTypes';
import druskininkaiImageSource from '../../../assets/images/druskininkai.jpg';
import druskininkaiResidentImageSource from '../../../assets/images/druskininkai_resident.jpg';
import alytusImageSource from '../../../assets/images/alytus.jpg';
import marijampoleImageSource from '../../../assets/images/marijampole.jpg';

export const eTicketLoginData = [
  {
    id: cityTypes.DRUSKININKAI, imageSrc: druskininkaiImageSource, imageAlt: 'Druskininkų eticket logo', title: 'Druskininkų e.bilietas', pinType: 0,
  },
  {
    id: cityTypes.DRUSKININKAI, imageSrc: druskininkaiResidentImageSource, imageAlt: 'Druskininkų card logo', title: 'Druskininkiečio kortelė', pinType: 1,
  },
  {
    id: cityTypes.ALYTUS, imageSrc: alytusImageSource, imageAlt: 'Alytaus eticket logo', title: 'Alytaus e.bilietas', pinType: 0,
  },
  {
    id: cityTypes.MARIJAMPOLE, imageSrc: marijampoleImageSource, imageAlt: 'Marijampolės eticket logo', title: 'Marijampolės e.bilietas', pinType: 0,
  },
];
