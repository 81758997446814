import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Datepicker from '../../../containers/Datepicker/Datepicker';
import { formatDate } from '../../../../core/utilities/date';
import Timepicker from '../../../containers/Timepicker/Timepicker';
import Checkbox from '../../FormElements/Checkbox/Checkbox';
import Button from '../../Button/Button';
import RouteAutocomplete from '../../../containers/FormElements/RouteAutocomplete/RouteAutocomplete';
import { getObjectNestedData } from '../../../../core/utilities/object';
import ButtonClose from '../../ButtonClose/ButtonClose';
import variantConst from '../../../../core/config/constants/variantConst/variantConst';

const EventSearchForm = (props) => {
  const {
    formProps,
    stopsList,
    tablet,
    submitting,
    t,
    onGetStops,
    stopsLoading,
    onKeyPress,
    activeEvent,
  } = props;
  const {
    values, errors, setFieldValue, setErrors,
  } = formProps;
  const {
    departureDate, departureTime, returnDate, returnTime,
  } = values;
  const { city: cityTo, cityFrom } = activeEvent;

  React.useEffect(() => {
    if (cityFrom && cityFrom.originId && cityFrom.title) {
      setFieldValue('from', { number: cityFrom.originId, name: cityFrom.title });
    }

    if (cityTo && cityTo.originId && cityTo.title) {
      setFieldValue('to', { number: cityTo.originId, name: cityTo.title });
    }
  }, []);

  return (
    <>
      <div className="search-results-form">
        <div className="search-form-field">
          <div className="search-form-field__label">
            {t('ticket_search_from')}:
          </div>

          <RouteAutocomplete
            placeholder={t(variantConst.ticketSearchPlaceholderFrom)}
            name="from.number"
            id="route-from"
            error={t(getObjectNestedData(errors, 'from.number'))}
            suggestions={Array.from(stopsList)}
            suggestionValue={(suggestion) => suggestion.name}
            onChange={({ number, name }) => {
              setErrors({
                from: null,
                to: null,
              });

              setFieldValue('from', { number, name });
            }}
            onInput={(value) => onGetStops(value, values.to.number)}
            loading={stopsLoading}
            value={values.from.name}
            onKeyPress={onKeyPress}
          />
        </div>

        <div className="search-form-field">
          <div className="search-form-field__label">
            {t('ticket_search_to')}:
          </div>

          <RouteAutocomplete
            placeholder={t(variantConst.ticketSearchPlaceholderTo)}
            name="to.number"
            id="route-to"
            error={errors.from ? null : t(getObjectNestedData(errors, 'to.number'))}
            suggestions={Array.from(stopsList)}
            suggestionValue={(suggestion) => suggestion.name}
            onChange={({ number, name }) => {
              setErrors({
                from: null,
                to: null,
              });

              setFieldValue('to', { number, name });
            }}
            onInput={(value) => onGetStops(value, values.from.number)}
            loading={stopsLoading}
            value={values.to.name}
            onKeyPress={onKeyPress}
          />
        </div>

        <div className="search-form-field">
          <Datepicker
            id="depart-date"
            name="departureDate"
            onKeyPress={onKeyPress}
            onChange={(date) => {
              setFieldValue('departureDateObj', date);
              setFieldValue('departureDate', formatDate(date));
            }}
            value={departureDate}
            label={t('ticket_search_departure_date_time')}
            minDate={new Date()}
            timepicker={(
              <Timepicker
                id="depart-time"
                name="departureTime"
                value={departureTime}
                onChange={(time) => setFieldValue('departureTime', time)}
              />
            )}
          />
        </div>

        <div className="search-form-field">
          {!values.isForward ? (
            <>
              <ButtonClose
                className="search-form-field__button"
                onClick={() => {
                  setFieldValue('isForward', true);
                  setFieldValue('returnDateObj', null);
                  setFieldValue('returnDate', null);
                  setFieldValue('returnTime', null);
                }}
              />

              <Datepicker
                id="return-date"
                label={t('ticket_search_return_date_time')}
                onChange={(date) => {
                  setFieldValue('returnDateObj', date);
                  setFieldValue('returnDate', formatDate(date));
                }}
                value={returnDate || departureDate}
                minDate={new Date(departureDate)}
                timepicker={(
                  <Timepicker
                    id="return-time"
                    name="returnTime"
                    value={returnTime || departureTime}
                    onChange={(time) => setFieldValue('returnTime', time)}
                  />
                )}
              />
            </>
          )
            : (
              <Checkbox
                id="ticket-backward-checkbox"
                name={t('ticket_search_would_buy_round_trip')}
                label={t('ticket_search_would_buy_round_trip')}
                onChange={() => {
                  setFieldValue('isForward', false);
                  setFieldValue('returnDateObj', values.departureDateObj);
                  setFieldValue('returnDate', values.departureDate);
                  setFieldValue('returnTime', values.departureTime);
                }}
                value={!values.isForward}
              />
            )}
        </div>

        <div className={`search-form-button ${tablet ? 'is-mobile' : ''}`}>
          <Button
            aria-label={t('ticket_search_search_trip')}
            big
            type="submit"
            variant="danger"
            loading={submitting}
          >
            {t('ticket_search_search_trip')}
          </Button>
        </div>
      </div>
    </>
  );
};

EventSearchForm.propTypes = {
  formProps: PropTypes.shape().isRequired,
  stopsList: PropTypes.shape(),
  activeEvent: PropTypes.shape(),
  tablet: PropTypes.bool,
  submitting: PropTypes.bool,
  t: PropTypes.func.isRequired,
  onGetStops: PropTypes.func.isRequired,
  stopsLoading: PropTypes.bool,
  onKeyPress: PropTypes.func.isRequired,
};

EventSearchForm.defaultProps = {
  stopsList: null,
  tablet: false,
  submitting: false,
  stopsLoading: false,
  activeEvent: {},
};

export default withTranslation()(EventSearchForm);
