import { constants } from '../constants';

export const setRefundInfo = (refundInfo) => ({
  type: constants.ticketReturn.SET_REFUND_INFO,
  payload: {
    refundInfo,
  },
});

export const cancelFetchRefundInfo = () => ({
  type: constants.ticketReturn.CANCEL_FETCH_REFUND_INFO,
  payload: {},
});

export const fetchRefundInfo = (orderHash, ticketId) => ({
  type: constants.ticketReturn.FETCH_REFUND_INFO,
  payload: {
    orderHash,
    ticketId,
  },
});

export const refundTicket = (orderHash, ticketId, refundType) => ({
  type: constants.ticketReturn.REFUND_TICKET,
  payload: {
    orderHash,
    ticketId,
    refundType,
  },
});

export const setTicketRefunded = () => ({
  type: constants.ticketReturn.SET_REFUNDED,
  payload: {},
});
