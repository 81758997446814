import { constants } from '../constants';

const initialState = [];

const TicketSearchReducer = (state = initialState, action) => {
  switch (action.type) {
  case constants.ticketSearch.SET_TICKETS:
    return action.payload.data;

  default:
    return state;
  }
};

export default TicketSearchReducer;
