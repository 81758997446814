import {
  all,
  put,
  call,
  takeEvery,
} from 'redux-saga/effects';
import { constants } from '../constants';
import { startRequest } from '../Request/RequestSaga';
import { endpoints } from '../../config/endpoints';
import {
  setCurrentUser, getCurrentUser, setUserOrders, deleteUser,
} from './UserActions';
import { getObjectNestedData } from '../../utilities/object';
import { closeModal } from '../Components/Modal/ModalActions';
import { clearTokens, setRefreshToken, setToken } from '../Token/TokenActions';
import { redirect } from '../../utilities/routing';
import { history } from '../../historyAndStore';
import { routes } from '../../config/routes';
import {
  setOrderRegistered, setOrderData, setOrderStatus,
} from '../Order/OrderActions';
import i18n from '../../../../i18next';

export function* loginUser(action) {
  const { response } = yield call(
    startRequest,
    endpoints.LOGIN_USER.name,
    endpoints.LOGIN_USER.url.path(),
    action.payload,
  );

  if (response) {
    yield all([
      put(setToken(constants.token.type.USER, response.data.token)),
      put(setRefreshToken(constants.token.type.USER, response.data.refresh_token)),
      put(getCurrentUser()),
      put(closeModal()),
    ]);
    redirect(history, i18n.t(routes.user.index));
  }
}

export function* loginSocial(action) {
  const { provider, code } = action.payload;
  const { response } = yield call(
    startRequest,
    endpoints.LOGIN_USER.name,
    endpoints.LOGIN_USER_SOCIAL.url.path(provider, code),
  );

  if (response) {
    yield all([
      put(setToken(constants.token.type.USER, response.data.token)),
      put(setRefreshToken(constants.token.type.USER, response.data.refresh_token)),
      put(getCurrentUser()),
      put(closeModal()),
    ]);
    redirect(history, i18n.t(routes.user.index));
  }
}

export function* registerUser(action) {
  const { response } = yield call(
    startRequest,
    endpoints.REGISTER_USER.name,
    endpoints.REGISTER_USER.url.path(),
    action.payload,
  );

  if (response) {
    yield all([
      put(setToken(constants.token.type.USER, response.data.data.token)),
      put(setRefreshToken(constants.token.type.USER, response.data.data.refresh_token)),
      put(getCurrentUser()),
      put(setOrderRegistered(true)),
    ]);
  }
}

export function* getCurrentUserSaga() {
  const { response } = yield call(
    startRequest,
    endpoints.GET_CURRENT_USER.name,
    endpoints.GET_CURRENT_USER.url.path(),
  );

  if (response) {
    yield put(setCurrentUser(response.data.data));
  }
}

export function* updateCurrentUserSaga(action) {
  const { response } = yield call(
    startRequest,
    endpoints.UPDATE_CURRENT_USER.name,
    endpoints.UPDATE_CURRENT_USER.url.path(),
    action.payload,
  );

  if (response) {
    const userData = getObjectNestedData(response, 'data.data.user');
    const token = getObjectNestedData(response, 'data.data.token');
    const refreshToken = getObjectNestedData(response, 'data.data.refresh_token');

    yield all([
      put(setCurrentUser(userData)),
      put(setToken(constants.token.type.USER, token)),
      put(setRefreshToken(constants.token.type.USER, refreshToken)),
    ]);
  }
}

export function* updateCurrentUserPassword(action) {
  const { response } = yield call(
    startRequest,
    endpoints.UPDATE_USER_PASSWORD.name,
    endpoints.UPDATE_USER_PASSWORD.url.path(),
    action.payload,
  );

  if (response) {
    yield put(setCurrentUser(response.data.data));
  }
}

export function* getUserOrders(action) {
  const { response } = yield call(
    startRequest,
    endpoints.GET_USER_ORDERS.name,
    endpoints.GET_USER_ORDERS.url.path(),
    action.payload,
  );

  if (response) {
    const data = getObjectNestedData(response, 'data.data');
    yield put(setUserOrders(data));
  }
}

export function* getUserOrder(action) {
  yield put(setOrderStatus({ loading: true }));

  const { response, error } = yield call(
    startRequest,
    endpoints.GET_USER_ORDER.name,
    endpoints.GET_USER_ORDER.url.path(action.payload.id),
  );

  if (error) {
    const globalError = getObjectNestedData(error, 'response.data.errors.global');
    const node = globalError && globalError.length > 0 && document.getElementsByClassName('app-container')[0];

    if (node) {
      node.scrollTop = 0;
    }
    yield put(setOrderStatus());

    return;
  }

  yield put(setOrderData(response.data.data));
  yield put(setOrderStatus());
}

export function* logoutUser() {
  yield all([
    put(clearTokens(constants.token.type.USER)),
    put(setCurrentUser()),
    put(setUserOrders()),
  ]);
  redirect(history, i18n.t(routes.homepage));
}

export function* removeUser() {
  const { response } = yield call(
    startRequest,
    endpoints.DELETE_USER.name,
    endpoints.DELETE_USER.url.path(),
  );

  if (response) {
    yield put(deleteUser(response.data.data));
  }
  yield all([
    put(clearTokens(constants.token.type.USER)),
    put(setCurrentUser()),
    put(setUserOrders()),
  ]);
  redirect(history, i18n.t(routes.homepage));
}

export default function* UserSaga() {
  yield all([
    takeEvery(constants.user.LOGIN, loginUser),
    takeEvery(constants.user.LOGIN_SOCIAL, loginSocial),
    takeEvery(constants.user.UPDATE_CURRENT, updateCurrentUserSaga),
    takeEvery(constants.user.GET_CURRENT, getCurrentUserSaga),
    takeEvery(constants.user.UPDATE_PASSWORD, updateCurrentUserPassword),
    takeEvery(constants.user.GET_ORDERS, getUserOrders),
    takeEvery(constants.user.GET_ORDER, getUserOrder),
    takeEvery(constants.user.REGISTER, registerUser),
    takeEvery(constants.user.LOGOUT, logoutUser),
    takeEvery(constants.user.DELETE, removeUser),
  ]);
}
