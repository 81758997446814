import { constants } from '../constants';

export const searchByPart = (orderHash, partId, date) => ({
  type: constants.ticketChange.SEARCH_ROUTES_BY_PART,
  payload: {
    orderHash, partId, date,
  },
});

export const setPartRoutes = (routes) => ({
  type: constants.ticketChange.SET_PART_ROUTES,
  payload: {
    routes,
  },
});

export const submitOrderChange = (orderHash, exchangeAll, parts) => ({
  type: constants.ticketChange.SUBMIT_ORDER_CHANGE,
  payload: {
    orderHash, exchangeAll, parts,
  },
});
