import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { List } from 'immutable';
import './TopRoutesStations-VARIANT.scss';
import { Link } from 'react-router-dom';
import {
  getTopRoutes,
  getTopStations,
} from '../../../core/modules/TopRoutesStations/TopRoutesStationsActions';
import Button from '../../../components/presentational/Button/Button';
import { getLink, routes } from '../../../core/config/routes';
import Icon from '../../../components/presentational/Icon/Icon';

const TopRoutesStations = ({
  dispatchGetTopRoutes,
  dispatchGetTopStations,
  topRoutes,
  topStations,
  locale,
  t,
}) => {
  React.useEffect(() => {
    dispatchGetTopRoutes(locale);
    dispatchGetTopStations();
  }, [locale]);

  const haveDataForDisplay = !!topRoutes.size || !!topStations.size;

  if (!haveDataForDisplay) return null;

  return (
    <div className="top-routes-stations-container">
      <div className="top-routes-stations">
        <div className="top-routes-stations-list-container top-routes-stations__promo" />

        {!topRoutes.size
          ? null
          : (
            <div className="top-routes-stations-list-container">
              <h3 className="top-routes-stations__title">{t('common_popular_routes')}</h3>

              <ul className="top-routes-stations-list">
                {topRoutes.take(10).map((topRoute) => (
                  <li
                    className="top-routes-stations-list-item"
                    key={topRoute.fromStopName + topRoute.toStopName}
                  >
                    <Link
                      to={getLink(t(routes.topRoutes.route), { slug: topRoute.slug })}
                    >
                      {topRoute.fromStopName} - {topRoute.toStopName}
                    </Link>

                    <Icon icon="arrow-right" />
                  </li>
                ))}
              </ul>

              <Button
                variant="white"
                route={t(routes.topRoutes.index)}
                small
              >
                {t('common_all_popular_routes')}
              </Button>
            </div>
          )}

        {!topStations.size
          ? null
          : (
            <div className="top-routes-stations-list-container">
              <h3 className="top-routes-stations__title">{t('common_bus_stations')}</h3>

              <ul className="top-routes-stations-list">
                {topStations.filter((station, i) => i < 10).map((topStation) => (
                  <li className="top-routes-stations-list-item" key={topStation.title}>
                    <Link
                      to={getLink(
                        t(routes.topStations.local.station),
                        { station: topStation.urlTitle },
                      )}
                    >
                      {topStation.title}
                    </Link>

                    <Icon icon="arrow-right" />
                  </li>
                ))}
              </ul>

              <Button variant="white" route={t(routes.topStations.local.index)} small>
                {t('common_all_bus_stations')}
              </Button>
            </div>
          )}
      </div>
    </div>
  );
};

TopRoutesStations.propTypes = {
  dispatchGetTopRoutes: PropTypes.func.isRequired,
  dispatchGetTopStations: PropTypes.func.isRequired,
  topRoutes: PropTypes.shape(),
  topStations: PropTypes.shape(),
  t: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
};

TopRoutesStations.defaultProps = {
  topRoutes: List([]),
  topStations: List([]),
};

const mapStateToProps = (state) => ({
  locale: state.locale,
  topRoutes: state.data.collections.topRoutesAndStations.getIn(['routes', state.locale]),
  topStations: state.data.collections.topRoutesAndStations.getIn(['stations', state.locale, 'popularity_']),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchGetTopRoutes: (locale) => dispatch(getTopRoutes(locale)),
  dispatchGetTopStations: () => dispatch(getTopStations('popularity')),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(TopRoutesStations));
