import { Map } from 'immutable';
import { constants } from '../constants';

const initialState = Map({});

const TokenReducer = (state = initialState, action) => {
  switch (action.type) {
  case constants.token.SET_TOKEN:
    return state.setIn([action.payload.type, 'token'], action.payload.token);

  case constants.token.SET_REFRESH_TOKEN:
    return state.setIn([action.payload.type, 'refreshToken'], action.payload.refreshToken);

  case constants.token.CLEAR_TOKENS:
    return state.set(action.payload.type, {});

  default:
    return state;
  }
};

export default TokenReducer;
