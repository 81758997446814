import { constants } from '../../constants';

export const openModal = (content, size, disableUiClosing) => ({
  type: constants.components.modal.OPEN,
  payload: {
    content,
    size,
    disableUiClosing,
  },
});

export const closeModal = () => ({
  type: constants.components.modal.CLOSE,
  payload: {},
});

export const resetModal = () => ({
  type: constants.components.modal.RESET,
  payload: {},
});

export const setDisableUiClosing = (disableUiClosing) => ({
  type: constants.components.modal.SET_DISABLE_UI_CLOSING,
  payload: {
    disableUiClosing,
  },
});
