import React from 'react';
import PropTypes from 'prop-types';
import './Radio.scss';
import { isImageUrl } from '../../../../../core/utilities/url';

const Radio = ({
  name,
  id,
  value,
  error,
  label,
  disabled,
  required,
  onChange,
  onBlur,
  altText,
}) => (
  <label className={`radio ${disabled ? 'is-disabled' : ''}`} htmlFor={id}>
    <input
      type="radio"
      className="radio__input"
      value={id}
      checked={value === id}
      name={name}
      id={id}
      required={required}
      disabled={disabled}
      onChange={onChange}
      onBlur={onBlur}
    />
    
    <div className="radio-wrap" aria-label={altText}>
      <div className={`radio-wrap-checkmark ${value === id ? 'is-active' : ''} ${error ? 'has-error' : ''}`}>
        <div className="radio-wrap-checkmark__circle" />
      </div>
      
      {!!label && (
        <div className={`radio-wrap__label ${disabled ? 'is-disabled' : ''}`}>
          {isImageUrl(label) ? <img src={label} alt={altText} /> : label}
          <p className="payment-disability-text">{altText}</p>
        </div>
      )}
    </div>
  </label>
);

Radio.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
    PropTypes.bool,
  ]),
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  altText: PropTypes.string,
};

Radio.defaultProps = {
  value: '',
  disabled: false,
  required: false,
  onBlur: () => null,
  error: null,
  label: null,
  id: null,
  altText: '',
};

export default React.memo(Radio);
