import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import './Checkbox.scss';
import Icon from '../../Icon/Icon';

const Checkbox = ({
  name,
  id,
  value,
  error,
  label,
  disabled,
  required,
  onChange,
  onClick,
  onBlur,
}) => {
  const checkboxRef = useRef(null);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        checkboxRef.current.click();
      }
    };

    checkboxRef.current.addEventListener('keypress', handleKeyPress);

    return () => {
      checkboxRef.current.removeEventListener('keypress', handleKeyPress);
    };
  }, []);

  return (
    <div 
      className={`checkbox ${disabled ? 'is-disabled' : ''}`} 
    >
      <input
        ref={checkboxRef}
        name={name}
        id={id}
        checked={value}
        type="checkbox"
        className="checkbox__input"
        required={required}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        title={name}
        onClick={onClick}
      />

      <div className="checkbox-wrap">
        <div className={`checkbox-wrap__checkmark ${value ? 'is-active' : ''} ${error ? 'has-error' : ''}`}>
          <Icon icon="check" />
        </div>

        {!!label && (
          <label htmlFor={id} className={`checkbox-wrap__label ${disabled ? 'is-disabled' : ''}`}>
            {label}

            {required && (<span className="checkbox-wrap__label-required"> *</span>)}
          </label>
        )}
      </div>

      {!!error && (
        <div className="checkbox__error" role="alert">
          {error}
        </div>
      )}
    </div>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.node,
  value: PropTypes.bool,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
};

Checkbox.defaultProps = {
  required: false,
  disabled: false,
  value: false,
  error: null,
  label: null,
  onBlur: () => null,
  onClick: () => null,
};

export default Checkbox;
