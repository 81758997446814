import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { store } from './app/core/historyAndStore';
import { setActiveLocale } from './app/core/modules/Locales/LocalesActions';
import { getCookie } from './app/core/utilities/cookies';

// disable for now. Debug mode will be needed on staging
// TODO: const debugMode = process.env.ENV === 'stg';
const debugMode = getCookie('showTranslations') === '1';
export const possibleLocales = ['ab', 'aa', 'af', 'ak', 'sq', 'am', 'ar', 'an', 'hy', 'as', 'av', 'ae', 'ay', 'az', 'bm', 'ba', 'eu', 'be', 'bn', 'bh', 'bi', 'bs', 'br', 'bg', 'my', 'ca', 'km', 'ch', 'ce', 'ny', 'zh', 'cu', 'cv', 'kw', 'co', 'cr', 'hr', 'cs', 'da', 'dv', 'nl', 'dz', 'en', 'eo', 'et', 'ee', 'fo', 'fj', 'fi', 'fr', 'ff', 'gd', 'gl', 'lg', 'ka', 'de', 'ki', 'el', 'kl', 'gn', 'gu', 'ht', 'ha', 'he', 'hz', 'hi', 'ho', 'hu', 'is', 'io', 'ig', 'id', 'ia', 'ie', 'iu', 'ik', 'ga', 'it', 'ja', 'jv', 'kn', 'kr', 'ks', 'kk', 'rw', 'kv', 'kg', 'ko', 'kj', 'ku', 'ky', 'lo', 'la', 'lv', 'lb', 'li', 'ln', 'lt', 'lu', 'mk', 'mg', 'ms', 'ml', 'mt', 'gv', 'mi', 'mr', 'mh', 'ro', 'mn', 'na', 'nv', 'nd', 'ng', 'ne', 'se', 'no', 'nb', 'nn', 'ii', 'oc', 'oj', 'or', 'om', 'os', 'pi', 'pa', 'ps', 'fa', 'pl', 'pt', 'qu', 'rm', 'rn', 'ru', 'sm', 'sg', 'sa', 'sc', 'sr', 'sn', 'sd', 'si', 'sk', 'sl', 'so', 'st', 'nr', 'es', 'su', 'sw', 'ss', 'sv', 'tl', 'ty', 'tg', 'ta', 'tt', 'te', 'th', 'bo', 'ti', 'to', 'ts', 'tn', 'tr', 'tk', 'tw', 'ug', 'ua', 'ur', 'uz', 've', 'vi', 'vo', 'wa', 'cy', 'fy', 'wo', 'xh', 'yi', 'yo', 'za', 'zu'];
const customLocaleDetector = new LanguageDetector();

customLocaleDetector.addDetector({
  name: 'customLocaleDetector',

  cacheUserLanguage(lng) {
    store.dispatch(setActiveLocale(lng));
  },

  lookup() {
    const { pathname } = window.location;
    const pathnameRoot = pathname.split('/')[1];

    if (possibleLocales.includes(pathnameRoot)) {
      return pathnameRoot;
    }

    const prevLocale = store.getState().locale;
    const defaultLocale = store.getState().locales.get('default');
    return prevLocale || defaultLocale;
  },
});

i18n
  .use(Backend)
  .use(customLocaleDetector)
  .use(initReactI18next)
  .init({
    react: {
      bindI18n: 'languageChanging',
    },
    detection: {
      order: ['customLocaleDetector'],
      caches: ['customLocaleDetector'],
    },
    backend: {
      loadPath: `${process.env.BASE_URL}/translations?locale=%lng%`,
      crossDomain: true,
      parse(response) {
        const translations = JSON.parse(response);
        return translations.data;
      },
    },
    debug: debugMode,
    interpolation: {
      escapeValue: false,
      prefix: '%',
      suffix: '%',
    },
    fallbackLng: false,
    keySeparator: null,
    parseMissingKeyHandler(key) {
      return debugMode ? key : '';
    },
  });

export default i18n;
