import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import './Features.scss';
import galleryBusImg from '../../../../../../assets/images/galerija_ant_ratu.png';

const SearchResultsRouteFeatures = (props) => {
  const {
    t, route, features, renderFeature, openFeatureModal,
  } = props;
  const { sellData } = route;

  const buttonName = (name) => {
    switch (true) {
    case name === 'isWifi':
      return t('is_wifi');
    case name === 'isFamily':
      return t('is_family');
    case name === 'isHandicapped':
      return t('is_handicapped');
    case name === 'isBusinessVip':
      return t('is_business_vip');
    case name === 'isBusiness':
      return t('is_business');
    case name === 'isBike':
      return t('is_bike');
    default:
      return '';
    }
  };

  const cmsByBus = (bus) => {
    switch (true) {
    case bus.isZalgirio === 1:
      return 'ABZALGIRISINFO';
    case bus.isNationalTeam === 1:
      return 'ABNATIONALTEAMINFO';
    case bus.isGallery === 1:
      return 'ABGALLERYINFO';
    default:
      return '';
    }
  };

  if (sellData) {
    const { carrierLogoSmall, carrierId, carrierPublicName } = sellData;
    const { isNationalTeam, isZalgirio, isGallery } = sellData.bus;
    const teamBus = isNationalTeam === 1 || isZalgirio === 1;
    const cmsIdent = cmsByBus(sellData.bus);

    return (
      <div className={`
        search-results-route-features-wrap
        ${(teamBus || isGallery) ? 'is-team-bus' : ''}
        `}
      >
        {carrierLogoSmall
          ? (
            <div className={
              `search-results-route-features-carrier
            ${(carrierId === 97 || isGallery) ? 'is-wide' : ''}`
            }
            >
              <span className="search-results-route-features-carrier-text">
                {carrierPublicName}
              </span>
              {teamBus || isGallery ? (
                <div
                  role="presentation"
                  className="search-results-route-features-carrier__btn"
                  onClick={() => {
                    openFeatureModal(cmsIdent);
                  }}
                >
                  <img src={isGallery ? galleryBusImg : carrierLogoSmall} alt={carrierPublicName} />
                </div>
              ) : (
                <img src={carrierLogoSmall} alt={carrierPublicName} />
              )}
            </div>
          )
          : (
            <div className="search-results-route-features-carrier">
              {carrierPublicName}
            </div>
          )}

        {!!features.length && (!teamBus && !isGallery) && (
          <div className="search-results-route-features">
            {features.map((feature, i) => (
              <button
                aria-label={buttonName(feature.name)}
                onClick={() => openFeatureModal(feature.id)}
                key={`feature-${feature + i}`}
                className="search-results-route-features-item"
              >
                <Fragment key={feature + i}>
                  {renderFeature(feature)}
                </Fragment>
              </button>
            ))}
          </div>
        )}

        {teamBus && (
          <div className="search-results-route-features-carrier__title">
            {t(`route_carries_${isZalgirio ? 'zalgiris' : 'national_team'}`)}
          </div>
        )}

        {isGallery === 1 && (
          <div className="search-results-route-features-carrier__title">
            <span>{t('route_carries_gallery')}</span>
          </div>
        )}
      </div>
    );
  }

  return null;
};

SearchResultsRouteFeatures.propTypes = {
  t: PropTypes.func.isRequired,
  features: PropTypes.arrayOf(PropTypes.shape()),
  renderFeature: PropTypes.func.isRequired,
  route: PropTypes.shape(),
  openFeatureModal: PropTypes.func.isRequired,
};

SearchResultsRouteFeatures.defaultProps = {
  features: [],
  route: null,
};

export default withTranslation()(SearchResultsRouteFeatures);
