export const constants = {
  request: {
    STARTED: 'request.STARTED',
    ENDED: 'request.ENDED',
    SET_ERROR: 'request.SET_ERROR',
    SET_ERRORS: 'request.SET_ERRORS',
  },
  search: {
    SUBMIT_SEARCH: 'search.SUBMIT_SEARCH',
    SUBMIT_SEARCH_BY_SLUG: 'search.SUBMIT_SEARCH_BY_SLUG',
    SET_SHOW_DATES: 'search.SET_SHOW_DATES',
    SET_SLUG_SEARCH_VALUES: 'search.SET_SLUG_SEARCH_VALUES',
    SET_SEARCH_VALUES: 'search.SET_SEARCH_VALUES',
    CLEAR_SEARCH_VALUES: 'search.CLEAR_SEARCH_VALUES',
  },
  data: {
    collections: {
      stops: {
        GET_STOPS_LIST: 'stops.GET_STOPS_LIST',
        SET_STOPS_LIST: 'stops.SET_STOPS_LIST',
      },
      routes: {
        SET_ROUTES_LIST: 'routes.SET_ROUTES_LIST',
      },
      topRoutes: {
        GET_TOP_ROUTES_LIST: 'topRoutes.GET_TOP_ROUTES_LIST',
        SET_TOP_ROUTES_LIST: 'topRoutes.SET_TOP_ROUTES_LIST',
        SET_ALL_TOP_ROUTES: 'topRoutes.SET_ALL_TOP_ROUTES',
      },
      topStations: {
        GET_TOP_STATIONS_LIST: 'topStations.GET_TOP_STATIONS_LIST',
        SET_TOP_STATIONS_LIST: 'topStations.SET_TOP_STATIONS_LIST',
      },
      stations: {
        GET_STATION: 'stations.GET_STATION',
        SET_STATION: 'stations.SET_STATION',
      },
      cms: {
        GET_PAGE: 'cms.GET_PAGE',
        SET_PAGE: 'cms.SET_PAGE',
        GET_MENU: 'cms.GET_MENU',
        SET_MENU: 'cms.SET_MENU',
      },
      banners: {
        GET_BANNER_BY_ZONE: 'banners.GET_BANNER_BY_ZONE',
        ADD_BANNER: 'banners.ADD_BANNER',
      },
      payment: {
        GET_PAYMENT_METHODS: 'payment.GET_PAYMENT_METHODS',
        SET_PAYMENT_METHODS: 'payment.SET_PAYMENT_METHODS',
      },
    },
  },
  components: {
    homepage: {
      SET_ERRORS: 'components.homepage.SET_ERRORS',
    },
    footer: {
      GET_CUSTOM_FOOTER: 'components.GET_CUSTOM_FOOTER',
      SET_CUSTOM_FOOTER: 'components.footer.SET_CUSTOM_FOOTER',
      ADD_FOOTER_COLUMNS: 'components.footer.ADD_FOOTER_COLUMNS',
      RESET: 'components.footer.RESET',
    },
    modal: {
      OPEN: 'components.modal.OPEN',
      CLOSE: 'components.modal.CLOSE',
      RESET: 'components.modal.RESET',
      SET_DISABLE_UI_CLOSING: 'components.modal.SET_DISABLE_UI_CLOSING',
    },
    resetPasswordRequest: {
      START: 'components.resetPasswordRequest.START',
      SENT_TO: 'components.resetPasswordRequest.SENT_TO',
      SET_NEW_PASSWORD_USER: 'components.resetPasswordRequest.SET_NEW_PASSWORD_USER',
      SET_NEW_PASSWORD: 'components.resetPasswordRequest.SET_NEW_PASSWORD',
    },
    newsletter: {
      SUBSCRIBE: 'components.newsletter.SUBSCRIBE',
      SET_STATUS: 'components.newsletter.SET_STATUS',
    },
    routeMap: {
      GET_POLYLINES: 'components.routeMap.GET_POLYLINES',
      SET_POLYLINES: 'components.routeMap.SET_POLYLINES',
    },
    lightBox: {
      OPEN: 'components.lightBox.OPEN',
      CLOSE: 'components.lightBox.CLOSE',
    },
    menuOverlay: {
      OPEN: 'components.menuOverlay.OPEN',
      CLOSE: 'components.menuOverlay.CLOSE',
    },
  },
  user: {
    LOGIN: 'user.LOGIN',
    LOGIN_SOCIAL: 'user.LOGIN_SOCIAL',
    LOGOUT: 'user.LOGOUT',
    REGISTER: 'user.REGISTER',
    GET_CURRENT: 'user.GET_CURRENT',
    SET_CURRENT: 'user.SET_CURRENT',
    UPDATE_CURRENT: 'user.UPDATE_CURRENT',
    UPDATE_PASSWORD: 'user.UPDATE_PASSWORD',
    GET_ORDERS: 'user.GET_ORDERS',
    SET_ORDERS: 'user.SET_ORDERS',
    GET_ORDER: 'user.GET_ORDER',
    DELETE: 'user.DELETE',
  },
  eTicket: {
    LOGIN: 'eTicket.LOGIN',
    LOGOUT: 'eTicket.LOGOUT',
    GET_CURRENT: 'eTicket.GET_CURRENT',
    SET_CURRENT: 'eTicket.SET_CURRENT',
    VALIDATE_SERVICE: 'eTicket.VALIDATE_SERVICE',
    order: {
      INITIALIZE: 'eTicket.order.INITIALIZE',
      SET_STATUS: 'eTicket.order.SET_STATUS',
      SET_DATA: 'eTicket.order.SET_DATA',
      GET: 'eTicket.order.GET',
      SELECT_ORDER_PAYMENT_METHOD: 'eTicket.order.SELECT_PAYMENT_METHOD',
      SUBMIT: 'eTicket.order.SUBMIT',
      DESTROY: 'eTicket.order.DESTROY',
    },
  },
  token: {
    type: {
      USER: 'token.type.USER',
      E_TICKET: 'token.type.E_TICKET',
    },
    SET_TOKEN: 'token.SET_TOKEN',
    SET_REFRESH_TOKEN: 'token.SET_REFRESH_TOKEN',
    CLEAR_TOKENS: 'token.CLEAR_TOKENS',
  },
  contact: {
    SUBMIT: 'contact.SUBMIT',
    SET_STATUS: 'contact.SET_STATUS',
  },
  order: {
    INITIALIZE: 'order.INITIALIZE',
    SET_DATA: 'order.SET_DATA',
    SET_STATUS: 'order.SET_STATUS',
    SET_ERROR: 'order.SET_ERROR',
    PASSENGER_ADD: 'order.PASSENGER_ADD',
    PASSENGER_REMOVE: 'order.PASSENGER_REMOVE',
    PASSENGER_UPDATE_NAME: 'order.PASSENGER_UPDATE_NAME',
    PASSENGER_UPDATE_PART_DISCOUNT: 'order.PASSENGER_UPDATE_PART_DISCOUNT',
    ADD_COUPON: 'order.ADD_COUPON',
    REMOVE_COUPON: 'order.REMOVE_COUPON',
    SET_PAYMENT_METHODS: 'order.SET_PAYMENT_METHODS',
    SELECT_ORDER_PAYMENT_METHOD: 'order.SELECT_PAYMENT_METHOD',
    SELECT_TICKET_TYPE: 'order.SELECT_TICKET_TYPE',
    ADD_BIKE: 'order.ADD_BIKE',
    REMOVE_BIKE: 'order.REMOVE_BIKE',
    DESTROY: 'order.DESTROY',
    SUBMIT: 'order.SUBMIT',
    GET: 'order.GET',
    SET_REGISTERED: 'order.SET_REGISTERED',
    ADD_LOYALTY_DISCOUNT: 'order.ADD_LOYALTY_DISCOUNT',
    REMOVE_LOYALTY_DISCOUNT: 'order.REMOVE_LOYALTY_DISCOUNT',
  },
  roundTripTickets: {
    SET_ROUND_TRIP_TICKET: 'ticket.SET_ROUND_TRIP_TICKET',
    REMOVE_ROUND_TRIP_TICKET: 'ticket.REMOVE_ROUND_TRIP_TICKET',
  },
  ticketSearch: {
    FIND_BY_TICKET: 'ticketSearch.FIND_BY_TICKET',
    FIND_BY_TRAVEL: 'ticketSearch.FIND_BY_TRAVEL',
    SET_TICKETS: 'ticketSearch.SET_TICKETS',
  },
  ticketReturn: {
    CANCEL_FETCH_REFUND_INFO: 'ticketReturn.CANCEL_FETCH_REFUND_INFO',
    FETCH_REFUND_INFO: 'ticketReturn.FETCH_REFUND_INFO',
    SET_REFUND_INFO: 'ticketReturn.SET_REFUND_INFO',
    SET_REFUNDED: 'ticketReturn.SET_REFUNDED',
    REFUND_TICKET: 'ticketReturn.REFUND_TICKET',
  },
  ticketChange: {
    SEARCH_ROUTES_BY_PART: 'ticketChange.SEARCH_ROUTES_BY_PART',
    SET_PART_ROUTES: 'ticketChange.SET_PART_ROUTES',
    SUBMIT_ORDER_CHANGE: 'ticketChange.SUBMIT_ORDER_CHANGE',
  },
  locales: {
    GET_LOCALES: 'locales.GET_LOCALES',
    SET_LOCALES: 'locales.SET_LOCALES',
    SET_ACTIVE_LOCALE: 'locales.SET_ACTIVE_LOCALE',
  },
  events: {
    GET_EVENT: 'events.GET_EVENT',
    GET_EVENTS_BY_CATEGORY_EVENT: 'events.GET_EVENTS_BY_CATEGORY_EVENT',
    SET_EVENTS: 'events.SET_EVENTS',
    SET_EVENT: 'events.SET_EVENT',
    GET_EVENTS_CATEGORIES: 'events.GET_EVENTS_CATEGORIES',
    SET_EVENTS_CATEGORIES: 'events.SET_EVENTS_CATEGORIES',
    REMOVE_EVENT: 'events.REMOVE_EVENTS',
  },
  eurolinesRoutes: {
    GET_ROUTES: 'eurolinesRoutes.GET_ROUTES',
    SET_ROUTES: 'eurolinesRoutes.SET_ROUTES',
    GET_ROUTE: 'eurolinesRoutes.GET_ROUTE',
    SET_ROUTE: 'eurolinesRoutes.SET_ROUTE',
  },
  blogs: {
    GET_BLOGS: 'blogs.GET_BLOGS',
    SET_BLOGS: 'blogs.SET_BLOGS',
    GET_BLOG: 'blogs.GET_BLOG',
    SET_BLOG: 'blogs.SET_BLOG',
    REMOVE_BLOGS_LIST: 'blogs.REMOVE_BLOGS_LIST',
  },
  social: {
    facebook: {
      name: 'facebook',
      oauth_url: 'https://www.facebook.com/v11.0/dialog/oauth?client_id=%client_id%&redirect_uri=%redirect_uri%&response_type=code&scope=email',
    },
    google: {
      name: 'google',
      oauth_url: 'https://accounts.google.com/o/oauth2/v2/auth?client_id=%client_id%&redirect_uri=%redirect_uri%&response_type=code&scope=email%20profile',
    },
  },
};
