import React from 'react';
import PropTypes from 'prop-types';
import CMSContent from '../../pages/CMS/CMSContent';
import './RouteFeaturesModal.scss';

const RouteFeaturesModal = ({ id }) => (
  <div className="route-features-modal-wrap">
    <div className="route-features-modal">
      <CMSContent page={id} />
    </div>
  </div>
);

RouteFeaturesModal.propTypes = {
  id: PropTypes.string,
};

RouteFeaturesModal.defaultProps = {
  id: '',
};

export default RouteFeaturesModal;
