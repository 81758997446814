export const methods = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};

export const responseStatus = {
  HTTP_OK: 200,
  HTTP_UNAUTHORIZED: 401,
  HTTP_NOT_FOUND: 404,
  HTTP_UNPROCESSABLE_ENTITY: 422,
  HTTP_LOCKED: 423,
  HTTP_INTERNAL_SERVER_ERROR: 500,
};
