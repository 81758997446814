export const eventsEndpoints = {
  GET_EVENTS_BY_CATEGORY_EVENT: {
    name: 'GET_EVENTS_BY_CATEGORY_EVENT',
    url: {
      method: 'GET',
      path: (category = '', event = '') => `/events/${category}/${event}`,
    },
  },
  GET_EVENTS_CATEGORIES: {
    name: 'GET_EVENTS_CATEGORIES',
    url: {
      method: 'GET',
      path: () => '/event_categories',
    },
  },
};
