import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { withTranslation } from 'react-i18next';
import {
  ticketStatusFulfilled,
  tripDirections,
} from '../../../../../core/config/constants/tickets';
import ExchangeTrip from '../ExchangeTrip/ExchangeTrip';
import Alert from '../../../../../components/presentational/Alert/Alert';
import Button from '../../../../../components/presentational/Button/Button';
import { getLink, routes } from '../../../../../core/config/routes';
import RadioGroup
from '../../../../../components/presentational/FormElements/Radio/RadioGroup/RadioGroup';
import { endpoints } from '../../../../../core/config/endpoints';
import {
  setPartRoutes,
  submitOrderChange,
} from '../../../../../core/modules/TicketChange/TicketChangeActions';
import './Exchange.scss';
import { setRequestsErrors } from '../../../../../core/modules/Request/RequestActions';

const Exchange = ({
  t,
  orderHash,
  activeTicket,
  tickets,
  ticketType,
  dispatchClearRoutes,
  dispatchSubmitOrderChange,
  dispatchClearTicketChangeErrors,
  submitting,
  submitErrors,
  searchErrors,
}) => {
  const [exchangeAll, setExchangeAll] = React.useState(ticketType === 3 ? '1' : '0');
  const [activeSearchDirection, setActiveSearchDirection] = React.useState('');
  const [newTrips, setNewTrips] = React.useState(Map({
    forward: null,
    backward: null,
  }));
  const setNewTrip = (direction, trip = null) => setNewTrips(newTrips.set(direction, trip));
  const selectedDirectionsCount = tripDirections
    .filter((direction) => !!newTrips.get(direction))
    .length;

  const forwardTripParts = newTrips.get('forward') || activeTicket.forward.parts;
  const forwardTripLastPart = forwardTripParts && forwardTripParts.slice(-1)[0];
  const forwardTripArrivalDate = forwardTripLastPart && `${forwardTripLastPart.arrivalDateString} ${forwardTripLastPart.arrivalTimeString}`;

  const backwardTripParts = newTrips.get('backward') || (activeTicket.backward && activeTicket.backward.parts);
  const backwardTripLastPart = backwardTripParts && backwardTripParts.slice(-1)[0];
  const backwardTripDepartureDate = backwardTripLastPart && `${backwardTripLastPart.departureDateString} ${backwardTripLastPart.departureTimeString}`;

  const datesOverlap = (forwardTripArrivalDate && backwardTripDepartureDate)
    && forwardTripArrivalDate > backwardTripDepartureDate;

  const newTripsReducer = (accumulator, direction) => {
    const newDirectionTrips = newTrips.get(direction);

    if (!newDirectionTrips) return accumulator;

    return accumulator
      .concat(newDirectionTrips.map((newDirectionTrip) => newDirectionTrip.sellData));
  };

  const onSubmit = () => {
    const joinedNewTrips = tripDirections.reduce(newTripsReducer, []);

    dispatchSubmitOrderChange(orderHash, exchangeAll, joinedNewTrips);
  };

  React.useEffect(() => function cleanup() {
    dispatchClearRoutes();
    dispatchClearTicketChangeErrors();
  }, []);

  return (
    <>
      {submitErrors.concat(searchErrors).map((error) => (
        <Alert
          key={error}
          variant="danger"
          heading={t('common_error_exclamation')}
        >
          {error}
        </Alert>
      ))}

      {tripDirections.map((direction) => {
        const trip = activeTicket[direction];

        return trip && trip.status === ticketStatusFulfilled && (
          <ExchangeTrip
            key={direction}
            trip={trip}
            orderHash={orderHash}
            direction={direction}
            activeSearchDirection={activeSearchDirection}
            setActiveSearchDirection={setActiveSearchDirection}
            setNewTrip={setNewTrip}
            newTrip={newTrips.get(direction)}
          />
        );
      })}

      <div className="ticket-exchange-confirmation">
        {ticketType === 1 && tickets.length > 1 && (
          <div className="ticket-exchange-confirmation-radio">
            <RadioGroup
              onChange={(e) => setExchangeAll(e.target.value)}
              name="ticket-exchange-all"
              value={exchangeAll}
              disabled={submitting}
              render={(radio) => {
                const { clientName } = activeTicket;

                return (
                  <>
                    <div className="ticket-exchange-confirmation-radio__header">{t('order_which_passengers')}</div>

                    {radio('0', clientName)}

                    {radio('1', (
                      <>
                        {t('order_all_tickets')}:

                        <ul>
                          {tickets.map((ticket) => (
                            <li key={ticket.ticketId}>
                              {ticket.clientName} {!ticket.canChange && `(${t('order_change_for_ticket_not_allowed')})`}
                            </li>
                          ))}
                        </ul>
                      </>
                    ))}
                  </>
                );
              }}
            />
          </div>
        )}

        {ticketType === 3 && (
          <Alert
            variant="warning"
            heading={`${t('common_unfortunately')},`}
          >
            {t('order_change_all_tickets_affected')}
          </Alert>
        )}

        {datesOverlap && (
          <Alert
            variant="warning"
            heading={`${t('common_unfortunately')},`}
          >
            {t('order_change_trip_back_begins_before_forward_ends')}
          </Alert>
        )}

        <div className="ticket-exchange-confirmation-buttons">
          <Button
            variant="brand"
            disabled={!selectedDirectionsCount || datesOverlap || submitting}
            onClick={onSubmit}
          >
            {t('order_confirm_and_change')}
          </Button>

          <Button
            disabled={submitting}
            variant="inverted"
            route={getLink(t(routes.tickets.index), { orderHash })}
          >
            {t('order_change_discard')}
          </Button>
        </div>
      </div>
    </>
  );
};

Exchange.propTypes = {
  t: PropTypes.func.isRequired,
  dispatchClearRoutes: PropTypes.func.isRequired,
  dispatchSubmitOrderChange: PropTypes.func.isRequired,
  dispatchClearTicketChangeErrors: PropTypes.func.isRequired,
  orderHash: PropTypes.string.isRequired,
  activeTicket: PropTypes.shape().isRequired,
  tickets: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  ticketType: PropTypes.number.isRequired,
  submitting: PropTypes.bool,
  submitErrors: PropTypes.arrayOf(PropTypes.string),
  searchErrors: PropTypes.arrayOf(PropTypes.string),
};

Exchange.defaultProps = {
  submitting: false,
  submitErrors: [],
  searchErrors: [],
};

const mapStateToProps = (state) => ({
  submitting: state.request.getIn([endpoints.SUBMIT_ORDER_CHANGE.name, 'loading']),
  submitErrors: state.request.getIn([endpoints.SUBMIT_ORDER_CHANGE.name, 'error', 'global']),
  searchErrors: state.request.getIn([endpoints.SEARCH_ROUTES_BY_PART.name, 'error', 'global']),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchClearRoutes: () => dispatch(setPartRoutes([])),
  dispatchSubmitOrderChange: (orderHash, exchangeAll, parts) => dispatch(submitOrderChange(
    orderHash, exchangeAll, parts,
  )),
  dispatchClearTicketChangeErrors: () => dispatch(setRequestsErrors([
    { name: endpoints.SUBMIT_ORDER_CHANGE.name },
    { name: endpoints.SEARCH_ROUTES_BY_PART.name },
  ])),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Exchange));
