import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import spinner from '../../../../assets/images/spinner.gif';
import './Button-VARIANT.scss';
import Icon from '../Icon/Icon';

const buttonTypeClass = {
  brand: ' is-brand',
  white: ' is-white',
  danger: ' is-danger',
  inverted: ' is-inverted',
  transparent: ' is-transparent',
  'inline-link': ' is-inline-link',
  'inline-link-dotted': ' is-inline-link dotted',
};

// TODO: change Link component import
const Button = (props) => {
  const {
    children,
    className,
    onClick,
    disabled,
    loading,
    route,
    href,
    icon,
    type,
    variant,
    big,
    small,
    t,
    ariaLabel,
    ariaExpanded,
    isFullWidth,
  } = props;
  let Container = 'button';
  if (route) {
    Container = Link;
  }
  if (href) {
    Container = 'a';
  }
  const buttonClass = !children ? ' only-icon' : '';
  const disabledClass = disabled ? ' is-disabled' : '';
  const loadingClass = loading ? ' is-loading' : '';
  const colorClass = variant ? buttonTypeClass[variant] : '';
  const sizeClass = (big ? ' is-big' : '')
    || (small ? ' is-small' : '');
  const fullWidthClass = isFullWidth ? ' is-full-width' : '';
  const modifierClasses = buttonClass + colorClass
      + disabledClass + sizeClass + loadingClass + fullWidthClass;
  const hrefAttribute = href ? { href } : undefined;

  return (
    <Container
      to={route}
      type={type}
      tabIndex={0}
      {...hrefAttribute}
      className={`button ${modifierClasses} ${className}`}
      onClick={onClick}
      disabled={disabled || loading}
      aria-label={ariaLabel}
      aria-expanded={ariaExpanded}
    >
      {icon && loading && (
        <img className="button-preloader" alt="" src={spinner} />
      )}

      {children && (
        <>
          <span>{!loading ? children : <span>{t('common_searching')}</span>}</span>

          {loading && (<img className="button-preloader" alt="" src={spinner} />)}
        </>
      )}

      {icon && !loading && (
        <Icon icon={icon} className={children ? 'has-children' : ''} />
      )}
    </Container>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  route: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.string,
  ]),
  href: PropTypes.string,
  icon: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  big: PropTypes.bool,
  small: PropTypes.bool,
  variant: PropTypes.string,
  t: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string,
  ariaExpanded: PropTypes.bool,
  isFullWidth: PropTypes.bool,
};

Button.defaultProps = {
  children: null,
  onClick: () => null,
  disabled: false,
  loading: false,
  route: '',
  href: '',
  icon: '',
  type: 'button',
  className: '',
  big: false,
  small: false,
  variant: '',
  ariaLabel: '',
  ariaExpanded: null,
  isFullWidth: false,
};

export default withTranslation()(Button);
