import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { formatMoney } from '../../../../../core/utilities/money';
import './RoundTripTicket.scss';
import { ticketTypes } from '../../../../../core/config/constants/tickets';
import Icon from '../../../../../components/presentational/Icon/Icon';

const RoundTripTicket = ({
  t, type, ticket, removeTicket,
}) => {
  const {
    promoPriceDiscounted, promoPrice, ticketType, price, departureTime, arrivalTime,
  } = ticket || {};
  const ticketDirection = (type === 'forward' && 'is-forward')
    || (type === 'backward' && 'is-backward');

  return (
    <div className={`round-trip-ticket-wrap ${ticketDirection} ${ticket ? 'has-ticket' : ''}`}>
      <div className={`round-trip-ticket ${ticket ? 'has-ticket' : ''}`}>
        {ticket ? (
          <>
            <div className="round-trip-ticket-header">
              <div className="round-trip-ticket-header__title">
                {t(`ticket_${type === 'forward' ? 'forward' : 'backward'}`)}
              </div>

              <div
                role="button"
                tabIndex={0}
                onClick={removeTicket}
                className="round-trip-ticket-header__action"
              >
                <span>{t('ticket_change_ticket')}</span>

                <Icon icon="arrow-right" />
              </div>
            </div>

            <div className="round-trip-ticket-content">
              <div className="round-trip-ticket__route">
                <div><b>{ticket.title}</b></div>

                <div>{departureTime.date}</div>
              </div>

              <div className="round-trip-ticket-route-time">
                <span>
                  <b>{departureTime.text}</b> - {arrivalTime.text}
                </span>

                <div>
                  <b>
                    {formatMoney(
                      ticketType === ticketTypes.promo
                        ? promoPriceDiscounted || promoPrice : price,
                    )}
                  </b>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={`round-trip-ticket-icon is-${type !== 'forward' && 'backward'}`}>
              <Icon icon="arrow-right" />
            </div>

            <span>
              <b>{t(`ticket_search_ticket_${type === 'forward' ? 'to' : 'from'}`)}</b>
            </span>
          </>
        )}
      </div>
    </div>
  );
};

RoundTripTicket.propTypes = {
  t: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  ticket: PropTypes.shape(),
  removeTicket: PropTypes.func.isRequired,
};

RoundTripTicket.defaultProps = {
  ticket: null,
};

export default withTranslation()(RoundTripTicket);
