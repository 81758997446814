import { Map } from 'immutable';
import { constants } from '../constants';

const initialState = Map({});

const SearchReducer = (state = initialState, action) => {
  switch (action.type) {
  case constants.search.SET_SHOW_DATES:
    return state.set('showDates', action.payload.showDates);

  case constants.search.SET_SLUG_SEARCH_VALUES:
    return state.setIn(['slugs', action.payload.slug], action.payload.values);

  case constants.search.SET_SEARCH_VALUES:
    return state.set('values', action.payload.values);

  case constants.search.CLEAR_SEARCH_VALUES:
    return state.remove('values');

  default:
    return state;
  }
};

export default SearchReducer;
