import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { getObjectNestedData } from '../../../../../core/utilities/object';
import SearchResultsRoute
from '../../../../../components/containers/SearchResultsRoute/SearchResultsRoute';
import './SearchResultsList.scss';
import Preloader from '../../../../../components/presentational/Preloader/Preloader';

class SearchResultsList extends Component {
  getSearchResultsSections = () => {
    const { routes } = this.props;
    const searchResultSections = [];
    let section = {
      routes: [],
    };

    if (routes && routes.length) {
      routes.forEach((route) => {
        const firstLeg = route.legs[0];
        const departureTime = getObjectNestedData(firstLeg, 'departureTime.text');
        const departureDate = getObjectNestedData(firstLeg, 'departureTime.date');

        if (!departureTime || !departureDate) return;

        const departureTimeHour = departureTime.split(':')[0];
        const newHour = section.hour !== departureTimeHour;
        const newDate = section.date !== departureDate;

        if (newHour || newDate) {
          section = { routes: [] };
          section.title = `${departureTimeHour}:00`;
          section.hour = departureTimeHour;
          section.date = departureDate;

          searchResultSections.push(section);
        }

        section.routes.push(route);
      });

      return searchResultSections;
    }

    return [];
  };

  render() {
    const {
      searchValues,
      loading,
      exchangeButtonProps,
      showDates,
      t,
    } = this.props;
    const searchResultsSections = this.getSearchResultsSections();

    if (loading) return <Preloader fullWidth />;

    return (
      <div className="search-results-list" aria-live="polite">
        {searchResultsSections.map((section, index) => {
          const { routes, title, date } = section;
          const dateArray = date.split('-');

          return (
            <div className="search-results-list-section" key={index}>
              <div className="search-results-list-section__title">
                <h2 className="search-results-list-section__time">
                  {title}
                  {showDates && (
                    <>
                      {', '}
                      <span className="search-results-list-section__date">
                        {t(`of_month_${dateArray[1]}`)}
                        {` ${dateArray[2]}`}
                        {t('day_short')}
                      </span>
                    </>
                  )}
                </h2>

                <div className="divider" />
              </div>

              {routes.map((route, i) => (
                <SearchResultsRoute
                  key={route.legs[0].title + i}
                  route={route.legs[0]}
                  searchValues={searchValues}
                  exchangeButtonProps={exchangeButtonProps}
                />
              ))}
            </div>
          );
        })}
      </div>
    );
  }
}

SearchResultsList.propTypes = {
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  showDates: PropTypes.bool,
  searchValues: PropTypes.shape(),
  routes: PropTypes.arrayOf(PropTypes.shape()),
  exchangeButtonProps: PropTypes.shape(),
};

SearchResultsList.defaultProps = {
  loading: false,
  searchValues: null,
  routes: [],
  showDates: false,
  exchangeButtonProps: null,
};

export default withTranslation()(SearchResultsList);
