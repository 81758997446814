import { Map } from 'immutable';
import { constants } from '../constants';

const initialState = Map({});

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
  case constants.user.SET_CURRENT:
    return state.set('data', action.payload);

  case constants.user.SET_ORDERS:
    return state.set('orders', action.payload);

  default:
    return state;
  }
};

export default UserReducer;
