import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Datepicker from '../../../containers/Datepicker/Datepicker';
import { validateDateFormat, formatDateToYYYYMMDD } from '../../../../core/utilities/date'; 
import Timepicker from '../../../containers/Timepicker/Timepicker';
import Checkbox from '../../FormElements/Checkbox/Checkbox';
import Button from '../../Button/Button';
import RouteAutocomplete from '../../../containers/FormElements/RouteAutocomplete/RouteAutocomplete';
import './SearchResultsForm.scss';
import { getObjectNestedData } from '../../../../core/utilities/object';
import { tripDirections } from '../../../../core/config/constants/tickets';
import Icon from '../../Icon/Icon';
import ButtonClose from '../../ButtonClose/ButtonClose';
import variantConst from '../../../../core/config/constants/variantConst/variantConst';

const SearchResultsFormView = (props) => {
  const {
    formProps,
    stopsList,
    tablet,
    mobile,
    submitting,
    t,
    onGetStops,
    stopsLoading,
    onKeyPress,
    onRemoveTickets,
    disabled,
    handleClick,
  } = props;
  const {
    values, errors, setFieldValue, setErrors, submitForm,
  } = formProps;
  const {
    departureDate, departureTime, returnDate, returnTime,
  } = values;

  return (
    <>
      <div className="search-results-form">
        <div className="search-form-field">
          <label htmlFor="route-from" className="search-form-field__label">
            {t(mobile ? 'ticket_search_from' : 'ticket_search_travelling_from')}
          </label>

          <RouteAutocomplete
            placeholder={t(variantConst.ticketSearchPlaceholderFrom)}
            name="from.number"
            id="route-from"
            error={t(getObjectNestedData(errors, 'from.number'))}
            suggestions={Array.from(stopsList)}
            suggestionValue={(suggestion) => suggestion.name}
            onChange={({ number, name }) => {
              setErrors({
                from: null,
                to: null,
              });

              setFieldValue('from', { number, name });
            }}
            onInput={(value) => onGetStops(value, values.to.number)}
            loading={stopsLoading}
            value={values.from.name}
            onKeyPress={onKeyPress}
            handleClick={handleClick}
          />
        </div>

        <button
          type="button"
          tabIndex={0}
          className="search-results-form__switch"
          aria-label={t('switch_destination')}
          onClick={() => {
            setFieldValue('from', values.to);
            setFieldValue('to', values.from);
          }}
        >
          <Icon icon="switch" />
        </button>

        <div className="search-form-field">
          <label htmlFor="route-to" className="search-form-field__label">
            {t(mobile ? 'ticket_search_to' : 'ticket_search_travelling_to')}
          </label>

          <RouteAutocomplete
            placeholder={t(variantConst.ticketSearchPlaceholderTo)}
            name="to.number"
            id="route-to"
            error={errors.from ? null : t(getObjectNestedData(errors, 'to.number'))}
            suggestions={Array.from(stopsList)}
            suggestionValue={(suggestion) => suggestion.name}
            onChange={({ number, name }) => {
              setErrors({
                from: null,
                to: null,
              });

              setFieldValue('to', { number, name });
            }}
            onInput={(value) => onGetStops(value, values.from.number)}
            loading={stopsLoading}
            value={values.to.name}
            onKeyPress={onKeyPress}
            handleClick={handleClick}
          />
        </div>

        <div className="search-form-field is-smaller">
          <Datepicker
            id="depart-date"
            name="departureDate"
            onKeyPress={onKeyPress}
            onChange={(date) => {
              const formattedDate = formatDateToYYYYMMDD(date);  

              setFieldValue('departureDateObj', date);
              setFieldValue('departureDate', formattedDate);
              setFieldValue('departureTime', '00:00');
            }}
            value={departureDate}
            label={t('ticket_search_departure_date_time')}
            minDate={new Date()}
            timepicker={(
              <Timepicker
                id="depart-time"
                name="departureTime"
                value={departureTime}
                onChange={(time) => setFieldValue('departureTime', time)}
              />
            )}
            error={validateDateFormat(departureDate) ? null : t('invalid_date')}
          />
        </div>

        <div className="search-form-field is-smaller">
          {!values.isForward ? (
            <>
              <ButtonClose
                className="search-form-field__button"
                onClick={() => {
                  setFieldValue('isForward', true);
                  setFieldValue('returnDateObj', null);
                  setFieldValue('returnDate', null);
                  setFieldValue('returnTime', null);

                  tripDirections.forEach((type) => onRemoveTickets(type));

                  submitForm();
                }}
              />

              <Datepicker
                id="return-date"
                label={t('ticket_search_return_date_time')}
                onChange={(date) => {
                  const formattedDate = formatDateToYYYYMMDD(date);  

                  setFieldValue('returnDateObj', date);
                  setFieldValue('returnDate', formattedDate);
                  setFieldValue('returnTime', '00:00');
                }}
                value={returnDate || departureDate}
                minDate={new Date(departureDate)}
                timepicker={(
                  <Timepicker
                    id="return-time"
                    name="returnTime"
                    value={returnTime || departureTime}
                    onChange={(time) => setFieldValue('returnTime', time)}
                  />
                )}
                error={validateDateFormat(departureDate) ? null : t('invalid_date')}
              />
            </>
          )
            : (
              <Checkbox
                id="ticket-backward-checkbox"
                name={t('ticket_search_would_buy_round_trip')}
                label={t('ticket_search_would_buy_round_trip')}
                onChange={() => {
                  setFieldValue('isForward', false);
                  setFieldValue('returnDateObj', values.departureDateObj);
                  setFieldValue('returnDate', values.departureDate);
                  setFieldValue('returnTime', values.departureTime);
                }}
                value={!values.isForward}
              />
            )}
        </div>

        <div className={`search-form-button ${tablet ? 'is-mobile' : ''}`}>
          <Button
            big
            type="submit"
            variant="danger"
            loading={submitting}
            icon={tablet ? '' : 'search'}
            disabled={disabled}
            ariaLabel={t('ticket_search_search_trip')}
          >
            {tablet ? t('ticket_search_search_trip') : ''}
          </Button>
        </div>
      </div>
    </>
  );
};

SearchResultsFormView.propTypes = {
  formProps: PropTypes.shape().isRequired,
  stopsList: PropTypes.shape(),
  tablet: PropTypes.bool,
  mobile: PropTypes.bool,
  submitting: PropTypes.bool,
  t: PropTypes.func.isRequired,
  onGetStops: PropTypes.func.isRequired,
  stopsLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  onKeyPress: PropTypes.func.isRequired,
  onRemoveTickets: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
};

SearchResultsFormView.defaultProps = {
  stopsList: null,
  tablet: false,
  mobile: false,
  disabled: false,
  submitting: false,
  stopsLoading: false,
};

export default withTranslation()(SearchResultsFormView);
