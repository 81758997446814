import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import {
  getTopStations,
} from '../../../core/modules/TopRoutesStations/TopRoutesStationsActions';
import Sidebar from './Partials/Sidebar/Sidebar';
import { routes } from '../../../core/config/routes';
import Stations from './Partials/Stations/Stations';
import { topRoutesStationsEndpoints } from '../../../core/config/endpoints/topRoutesStations';
import Station from './Partials/Station/Station';
import { withTracker } from '../../common/Tracker/withTracker';
import LocalizedSwitch from '../../common/LocalizedSwitch/LocalizedSwitch';

class TopStations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuOpen: true,
    };
  }

  componentDidMount() {
    const { topStations, onGetTopStations } = this.props;

    if (!topStations.size) onGetTopStations();
  }

  sortStations = (type) => {
    const { topStations } = this.props;
    const typeI = type === 'i';

    return Array.from(topStations)
      .filter((station) => station.type === type)
      .sort((a, b) => {
        const titleA = typeI ? a.country.title : a.title;
        const titleB = typeI ? b.country.title : b.title;

        return titleA.localeCompare(titleB);
      });
  };

  chunkStations = (type) => {
    const sortedStations = this.sortStations(type);
    const chunkedStationsByTitle = {};
    const sortedTitles = [];

    sortedStations.forEach((station) => {
      const { title } = type === 'i' ? station.country : station;
      const groupTitle = type === 'l' ? title[0] : title;

      if (!sortedTitles.includes(groupTitle)) sortedTitles.push(groupTitle);
      if (!chunkedStationsByTitle[groupTitle]) chunkedStationsByTitle[groupTitle] = [];

      chunkedStationsByTitle[groupTitle].push(station);
    });

    return sortedTitles.map((title) => chunkedStationsByTitle[title]);
  };

  // TODO: change inner pages render
  render() {
    const { loading } = this.props;
    const { menuOpen } = this.state;

    return (
      <div className="top-routes">
        <Sidebar
          onOpenMenu={() => this.setState({ menuOpen: !menuOpen })}
          menuOpen={menuOpen}
        />

        <div className="top-routes-content">
          <LocalizedSwitch>
            <Route
              exact
              path={routes.topStations.local.index}
              render={() => <Stations loading={loading} stations={this.chunkStations('l')} />}
            />
            <Route
              exact
              path={routes.topStations.abroad.index}
              render={() => (
                <Stations loading={loading} abroad stations={this.chunkStations('i')} />
              )}
            />
            <Route
              path={[routes.topStations.local.station, routes.topStations.abroad.station]}
              component={Station}
            />
          </LocalizedSwitch>
        </div>
      </div>
    );
  }
}

TopStations.propTypes = {
  onGetTopStations: PropTypes.func.isRequired,
  topStations: PropTypes.shape(),
  loading: PropTypes.bool,
};

TopStations.defaultProps = {
  topStations: List([]),
  loading: false,
};

const mapStateToProps = (state) => ({
  topStations: state.data.collections.topRoutesAndStations.getIn(['stations', state.locale, '_']),
  loading: state.request.getIn([topRoutesStationsEndpoints.GET_TOP_STATIONS.name, 'loading']),
});

const mapDispatchToProps = (dispatch) => ({
  onGetTopStations: () => dispatch(getTopStations()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(withTracker(TopStations)));
