import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { openModal } from '../../../../../core/modules/Components/Modal/ModalActions';
import { constants } from '../../../../../core/modules/constants';
import Login from '../../Login';
import { setRequestError } from '../../../../../core/modules/Request/RequestActions';
import { userEndpoints } from '../../../../../core/config/endpoints/user';
import { loginSocial } from '../../../../../core/modules/User/UserActions';
import { routes } from '../../../../../core/config/routes';
import { parseQuery } from '../../../../../core/utilities/query';

const resolveOAuthProvider = (pathname) => {
  switch (pathname) {
  case routes.oauthGoogleReturn:
    return constants.social.google.name;
  case routes.oauthFacebookReturn:
    return constants.social.facebook.name;
  default:
    return '';
  }
};

const SocialLoginCallback = ({
  openLoginModal,
  dispatchSocialLogin,
  isAuthenticated,
  setLoginError,
  t,
}) => {
  const params = parseQuery(useLocation().search);
  const provider = resolveOAuthProvider(useLocation().pathname);
  const { code } = params;
  const hasError = params.error_message || params.error || params.error_code;

  React.useEffect(() => {
    if (isAuthenticated) {
      return;
    }

    openLoginModal();

    if (hasError) {
      setLoginError(t('login_social_error'));
    }

    if (code) {
      dispatchSocialLogin(provider, code);
    }
  }, [code, hasError, isAuthenticated]);

  return null;
};

SocialLoginCallback.propTypes = {
  openLoginModal: PropTypes.func.isRequired,
  dispatchSocialLogin: PropTypes.func.isRequired,
  setLoginError: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  loginUserErrors: PropTypes.shape(),
  t: PropTypes.func.isRequired,
};

SocialLoginCallback.defaultProps = {
  loginUserErrors: {},
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.token.hasIn([constants.token.type.USER, 'token']),
  loginUserErrors: state.request.getIn([userEndpoints.LOGIN_USER.name, 'error']),
});

const mapDispatchToProps = (dispatch) => ({
  openLoginModal: () => dispatch(openModal(<Login />)),
  dispatchSocialLogin: (provider, code) => dispatch(loginSocial(provider, code)),
  setLoginError: (error) => dispatch(
    setRequestError(userEndpoints.LOGIN_USER.name, { global: [error] }),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SocialLoginCallback));
