import { constants } from '../../constants';

export const getPolylines = (trackIds) => ({
  type: constants.components.routeMap.GET_POLYLINES,
  payload: {
    trackIds,
  },
});

export const setPolylines = (trackId, direction, polylines) => ({
  type: constants.components.routeMap.SET_POLYLINES,
  payload: {
    trackId, direction, polylines,
  },
});
