import { addQueryToLink } from '../routes';

export const topRoutesStationsEndpoints = {
  GET_TOP_ROUTES: {
    name: 'GET_TOP_ROUTES',
    url: {
      method: 'GET',
      path: (limit = 10) => `/route/popular?limit=${limit}`,
    },
  },
  GET_TOP_STATIONS: {
    name: 'GET_TOP_STATIONS',
    url: {
      method: 'GET',
      path: (orderBy = '', type = '') => addQueryToLink('/stations', { orderBy, type }),
    },
  },
};
