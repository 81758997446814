import { Map } from 'immutable';
import { constants } from '../constants';

const initialState = Map({});

const TicketChangeReducer = (state = initialState, action) => {
  switch (action.type) {
  case constants.ticketChange.SET_PART_ROUTES:
    return state.set('routes', action.payload.routes);

  default:
    return state;
  }
};

export default TicketChangeReducer;
