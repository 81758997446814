import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './History.scss';
import {
  getUserOrders,
} from '../../../../../core/modules/User/UserActions';
import { getHistoryRecordsGroupedByYear } from '../../../../../core/utilities/groupByYear';
import { endpoints } from '../../../../../core/config/endpoints';
import Preloader from '../../../../../components/presentational/Preloader/Preloader';
import HistoryListResult from './Partials/HistoryListResult';
import withDocumentHead from '../../../../common/DocumentHead/withDocumentHead';
import variantConst from '../../../../../core/config/constants/variantConst/variantConst';

const History = ({
  t,
  orders,
  dispatchGetUserOrders,
  loading,
  setDocumentTitle,
  setDocumentDescription,
}) => {
  useEffect(() => {
    dispatchGetUserOrders();

    if (t) {
      setDocumentTitle(`${t(variantConst.documentTitle)} ${t(variantConst.documentTitleUserOrdersHistory)}`);
      setDocumentDescription(t(variantConst.documentDescriptionUserOrdersHistory));
    }
  }, [t]);

  const userHistoryGroupedByYear = getHistoryRecordsGroupedByYear(orders);

  return (
    <div className="user-history">
      <h1 className="user-purchase-page-header">{t('user_purchase_history')}</h1>

      {loading && <Preloader fullHeight fullWidth />}

      {!loading
      && userHistoryGroupedByYear && Object.keys(userHistoryGroupedByYear).reverse().map((year) => (
        <div className="has-padding" key={year}>
          <div className="history-divider">
            {year}
            <div className="has-border" />
          </div>

          <ul className="user-purchase-histories-list">
            {userHistoryGroupedByYear[year].map((order) => (
              <HistoryListResult
                key={order.hash}
                order={order}
              />
            ))}
          </ul>
        </div>
      ))}

      {!userHistoryGroupedByYear && !loading && <div className="user-purchase-page-header-wrapper">{t('user_empty_order_list')}</div>}
    </div>
  );
};

History.propTypes = {
  t: PropTypes.func.isRequired,
  setDocumentTitle: PropTypes.func.isRequired,
  setDocumentDescription: PropTypes.func.isRequired,
  dispatchGetUserOrders: PropTypes.func.isRequired,
  orders: PropTypes.arrayOf(PropTypes.shape()),
  loading: PropTypes.bool,
};

History.defaultProps = {
  orders: [],
  loading: false,
};

const mapStateToProps = (state) => ({
  orders: state.user.get('orders'),
  loading: state.request.getIn([endpoints.GET_USER_ORDERS.name, 'loading']),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchGetUserOrders: () => dispatch(getUserOrders()),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(withDocumentHead(History)),
);
