export const resetPasswordEndpoints = {
  REQUEST_PASSWORD_RESET: {
    name: 'REQUEST_PASSWORD_RESET',
    url: {
      method: 'PUT',
      path: () => '/user/password/reset',
    },
  },
  SET_NEW_PASSWORD: {
    name: 'SET_NEW_PASSWORD',
    url: {
      method: 'POST',
      path: (hash) => `/user/password/reset/${hash}`,
    },
  },
};
