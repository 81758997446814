import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { destroyOrder, getOrder } from '../../../core/modules/Order/OrderActions';
import Preloader from '../../../components/presentational/Preloader/Preloader';
import NotFound from '../NotFound/NotFound';
import Ticket from './Partials/Ticket/Ticket';
import Sidebar from './Partials/Sidebar/Sidebar';
import { endpoints } from '../../../core/config/endpoints';
import Alert from '../../../components/presentational/Alert/Alert';
import './Tickets.scss';
import withDocumentHead from '../../common/DocumentHead/withDocumentHead';
import { routes } from '../../../core/config/routes';
import { withTracker } from '../../common/Tracker/withTracker';
import BusStopLoader from '../BusStopLoader/BusStopLoader';
import variantConst from '../../../core/config/constants/variantConst/variantConst';

const Tickets = ({
  t,
  registrationErrors,
  match,
  order,
  loading,
  dispatchGetOrder,
  dispatchDestroyOrder,
  nameError,
  emailError,
  setDocumentTitle,
}) => {
  const { path, params } = match;
  const { orderHash } = params;

  React.useEffect(() => {
    dispatchGetOrder(orderHash);

    return function cleanup() {
      dispatchDestroyOrder();
    };
  }, [orderHash]);

  React.useEffect(() => {
    if (t) {
      setDocumentTitle(t(variantConst.documentTitle, { title: t('tickets') }));
    }
  }, [t]);

  if (loading) return <Preloader fullHeight fullWidth />;

  if (!order) return <NotFound />;

  const {
    email, tickets, userId, lastPayment,
  } = order;
  const { state } = lastPayment || {};

  if (state === 'processing') {
    return (
      <BusStopLoader className="tickets-payment-processing">
        <h1 className="tickets-processing-header">
          {t('tickets_waiting_for_bank_confirmation')}
        </h1>

        <div className="tickets-processing-message">
          {t('tickets_waiting_for_bank_confirmation_details')}
          {' '}
          <b>{email}</b>
        </div>
      </BusStopLoader>
    );
  }

  return (
    <div className="tickets">
      <div className="tickets-list">
        {path === t(routes.tickets.changed) && (
          <Alert
            className="tickets-registration-alert"
            variant="success"
            heading={`${t('common_congratulations')},`}
          >
            {t('order_change_success_reminder')}
          </Alert>
        )}

        {[
          ...registrationErrors,
          nameError,
          emailError,
        ].filter(Boolean).map((error) => (
          <Alert
            key={error}
            className="tickets-registration-alert"
            variant="danger"
            heading={t('common_error_exclamation')}
          >
            {error}
          </Alert>
        ))}

        {tickets.map((ticket) => (
          <Ticket key={ticket.ticketId} ticket={ticket} orderHash={orderHash} />
        ))}
      </div>

      <div className="tickets-sidebar-container">
        {!userId && (
          <Sidebar
            email={email}
            name={tickets.length ? tickets[0].clientName : ''}
          />
        )}
      </div>
    </div>
  );
};

Tickets.propTypes = {
  t: PropTypes.func.isRequired,
  dispatchGetOrder: PropTypes.func.isRequired,
  dispatchDestroyOrder: PropTypes.func.isRequired,
  match: PropTypes.shape().isRequired,
  order: PropTypes.shape(),
  loading: PropTypes.bool,
  registrationErrors: PropTypes.arrayOf(PropTypes.shape()),
  nameError: PropTypes.string,
  emailError: PropTypes.string,
  setDocumentTitle: PropTypes.func.isRequired,
};

Tickets.defaultProps = {
  loading: false,
  order: null,
  registrationErrors: [],
  nameError: null,
  emailError: null,
};

const mapStateToProps = (state) => ({
  loading: state.order.getIn(['status', 'loading']),
  order: state.order.get('data'),
  registrationErrors: state.request.getIn([endpoints.REGISTER_USER.name, 'error', 'global']),
  nameError: state.request.getIn([endpoints.REGISTER_USER.name, 'error', 'fields', 'name']),
  emailError: state.request.getIn([endpoints.REGISTER_USER.name, 'error', 'fields', 'email']),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchGetOrder: (orderHash) => dispatch(getOrder(orderHash)),
  dispatchDestroyOrder: () => dispatch(destroyOrder()),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(withDocumentHead(withTracker(Tickets))),
);
