import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import NotFound from '../../pages/NotFound/NotFound';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(err) {
    if (process.env.ENV !== 'prod') {
      this.setState({ hasError: true });
      Sentry.captureException(err);
    }
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    return hasError ? <NotFound /> : children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};
