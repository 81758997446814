import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { withTranslation } from 'react-i18next';
import { getErrors } from '../../../core/utilities/formValidator';
import { validations } from '../../../core/config/form/validations';
import Input from '../../../components/presentational/FormElements/Input/Input';
import Button from '../../../components/presentational/Button/Button';
import variantConst from '../../../core/config/constants/variantConst/variantConst';
import './NewsletterForm.scss';

class NewsletterForm extends React.Component {
  constructor(props) {
    super(props);

    this.form = React.createRef();
  }

  render() {
    const { t, onSubmit } = this.props;

    return (
      <Formik
        onSubmit={onSubmit}
        validateOnChange={false}
        initialValues={{
          email: '',
        }}
        validate={(values) => getErrors(values, {
          email: [validations.email],
        }, this.form)}
        render={(formProps) => {
          const {
            values,
            errors,
            handleSubmit,
            setFieldValue,
          } = formProps;

          return (
            <form onSubmit={handleSubmit} ref={this.form} className="newsletterForm">
              <div className="newsletter-spacer" />

              <div className="newsletterForm-input-row">
                <Input
                  name="email"
                  placeholder={t('newsletter_default_email')}
                  type="text"
                  value={values.email}
                  error={t(errors.email)}
                  onChange={(e) => setFieldValue('email', e.target.value)}
                  ariaLabel={t('newsletter_header')}
                />
              </div>

              <div className="newsletterForm-action">
                <Button type="submit" variant={variantConst.newsletterButtonVariant}>
                  {t('newsletter_subscribe')}
                </Button>
              </div>
            </form>
          );
        }}
      />
    );
  }
}

NewsletterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  NewsletterStatus: state.components.newsletter.get('state'),
});

export default connect(mapStateToProps, null)(withTranslation()(NewsletterForm));
