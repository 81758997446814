import {
  put,
  call,
  select,
} from 'redux-saga/effects';
import axios from 'axios';
import { baseURL, defaultHeaders, endpoints } from '../../config/endpoints';
import { requestEnded, requestStarted } from './RequestActions';
import { refreshTokenSaga } from '../Token/TokenSaga';
import { responseStatus } from '../../config/constants/api';
import { redirect } from '../../utilities/routing';
import { history } from '../../historyAndStore';
import i18n from '../../../../i18next';
import { routes } from '../../config/routes';
import { setHomepageErrors } from '../Components/Homepage/HomepageActions';
import { getObjectNestedData } from '../../utilities/object';

export const coreRequest = (
  method,
  url,
  data,
  params,
  customHeaders,
) => {
  const headers = customHeaders || defaultHeaders;
  const options = {
    method,
    baseURL,
    url,
    headers,
    responseType: 'json',
  };

  if (method !== 'GET') {
    options.data = { ...data };
  }

  options.params = { ...params };

  return axios(options)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
};

export function* startRequest(
  name,
  url,
  data,
  params,
  customHeaders,
) {
  const method = endpoints[name] ? endpoints[name].url.method : 'GET';
  const tokenType = endpoints[name] ? endpoints[name].token : null;

  // TODO: do not go through with request if token type doesnt yield any token?
  function* executeRequest() {
    const token = tokenType
      ? yield select((state) => state.token.getIn([tokenType, 'token']))
      : null;
    const locale = yield select((state) => state.locale);
    const headers = {
      ...defaultHeaders,
      ...customHeaders,
      'Accept-Language': locale,
      ...(token
        ? { Authorization: `Bearer ${token}` }
        : {}
      ),
    };

    yield put(requestStarted(name));

    return yield call(
      coreRequest,
      method,
      url,
      data,
      params,
      headers,
    );
  }

  try {
    let { response, error } = yield executeRequest();

    const refreshToken = yield select((state) => state.token.getIn([tokenType, 'refreshToken']));
    const errorResponseStatus = getObjectNestedData(error, 'response.status');

    if (errorResponseStatus === responseStatus.HTTP_LOCKED) {
      const errors = getObjectNestedData(error, 'response.data.errors.global');

      yield put(setHomepageErrors(errors));
      redirect(history, i18n.t(routes.homepage));
    }

    if (
      errorResponseStatus === responseStatus.HTTP_UNAUTHORIZED
      && tokenType
      && refreshToken
    ) {
      yield call(refreshTokenSaga, tokenType);

      const newResponse = yield executeRequest();
      response = newResponse.response;
      error = newResponse.error;
    }

    const errorData = error ? error.response.data.errors : undefined;

    yield put(requestEnded(name, errorData));

    return { response, error };
  } catch (error) {
    yield put(requestEnded(name, error));

    return { response: null, error };
  }
}
