/* eslint-disable react/no-danger */

import React from 'react';
import PropTypes from 'prop-types';
import './OnlyText.scss';

const OnlyText = ({ parts }) => (
  parts.map((part) => (
    <div
      className="content-block only-text"
      dangerouslySetInnerHTML={{ __html: part.content }}
      key={part.id}
    />
  ))
);

OnlyText.propTypes = {
  parts: PropTypes.arrayOf(PropTypes.shape()),
};

OnlyText.defaultProps = {
  parts: [],
};

export default OnlyText;
