import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Dropdown from '../../../../../../components/presentational/Dropdown/Dropdown';
import { routes } from '../../../../../../core/config/routes';
import { logoutUser } from '../../../../../../core/modules/User/UserActions';
import './UserDropdown.scss';
import Icon from '../../../../../../components/presentational/Icon/Icon';

const UserDropdown = ({
  t,
  name,
  dispatchLogoutUser,
}) => {
  const [renderIndex, setRenderIndex] = React.useState(0);
  const handleLinkClick = () => setRenderIndex((prevIndex) => prevIndex + 1);

  return (
    <Dropdown
      key={renderIndex}
      className="header-user-dropdown"
      trigger={{
        'aria-label': t('user_dropdown'),
        className: 'header-button',
        children: (
          <>
            {name}
            <Icon icon="arrow-down" className="header-menu-item-dropdown-icon" />
          </>
        ),
      }}
      content={{
        className: 'header-user-dropdown-content',
        children: (
          <>
            <Link
              className="header-user-dropdown-item"
              to={t(routes.user.index)}
              onClick={handleLinkClick}
            >
              {t('profile_edit')}
            </Link>

            <button
              type="button"
              className="header-user-dropdown-item user-disconnect-button"
              onClick={dispatchLogoutUser}
            >
              {t('user_account_logout')}
            </button>
          </>
        ),
      }}
    />
  );
};

UserDropdown.propTypes = {
  t: PropTypes.func.isRequired,
  dispatchLogoutUser: PropTypes.func.isRequired,
  name: PropTypes.string,
};

UserDropdown.defaultProps = {
  name: '',
};

const mapStateToProps = (state) => ({
  name: state.user.getIn(['data', 'name']),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchLogoutUser: () => dispatch(logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserDropdown));
