import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import Input from '../../../../../components/presentational/FormElements/Input/Input';
import Button from '../../../../../components/presentational/Button/Button';
import { getErrors } from '../../../../../core/utilities/formValidator';
import { validations } from '../../../../../core/config/form/validations';
import { addCoupon } from '../../../../../core/modules/Order/OrderActions';
import './Coupon.scss';
import { endpoints } from '../../../../../core/config/endpoints';
import Alert from '../../../../../components/presentational/Alert/Alert';

const Coupon = ({
  t,
  dispatchAddCoupon,
  couponError,
  orderHash,
  loading,
}) => {
  const formRef = React.useRef(null);
  const onSubmit = (values, { setFieldValue }) => {
    const { couponNumber } = values;
    dispatchAddCoupon(orderHash, couponNumber);
    setFieldValue('couponNumber', '');
  };

  return (
    <Formik
      validateOnChange={false}
      onSubmit={onSubmit}
      validate={(values) => getErrors(values, {
        couponNumber: [validations.requiredCoupon],
      }, formRef)}
      initialValues={{
        couponNumber: '',
      }}
      render={(formProps) => {
        const {
          values,
          handleSubmit,
          setFieldValue,
          errors,
        } = formProps;
        const { couponNumber } = values;

        return (
          <form
            ref={formRef}
            onSubmit={handleSubmit}
            className="checkout-coupon"
          >
            <div className="checkout-coupon-input">
              <Input
                label={t('checkout_coupon')}
                ariaLabel={t('checkout_coupon')}
                name="checkout-coupon"
                value={couponNumber}
                onChange={(e) => setFieldValue('couponNumber', e.target.value)}
                disabled={loading}
              />

              {((errors.couponNumber && t(errors.couponNumber)) || couponError)
                  && (
                    <Alert variant="danger">
                      {(errors.couponNumber && t(errors.couponNumber)) || couponError}
                    </Alert>
                  )}
            </div>

            <Button
              className="checkout-coupon-submit"
              disabled={loading}
              onClick={handleSubmit}
            >
              {t('checkout_coupon_confirm')}
            </Button>
          </form>
        );
      }}
    />
  );
};

Coupon.propTypes = {
  t: PropTypes.func.isRequired,
  dispatchAddCoupon: PropTypes.func.isRequired,
  orderHash: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  couponError: PropTypes.string,
};

Coupon.defaultProps = {
  loading: false,
  couponError: null,
};

const mapStateToProps = (state, props) => ({
  sidebar: state.order.getIn(['data', 'items', props.index]),
  loading: state.order.getIn(['status', 'loading']),
  orderHash: state.order.getIn(['data', 'hash']),
  couponError: state.request.getIn([endpoints.ORDER_ADD_COUPON.name, 'error', 'fields', 'coupon']),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchAddCoupon: (orderHash, couponNumber) => (
    dispatch(addCoupon(orderHash, couponNumber))
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Coupon));
