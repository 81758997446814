import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Button from '../../../../../components/presentational/Button/Button';
import { tripDirections } from '../../../../../core/config/constants/tickets';
import Datepicker from '../../../../../components/containers/Datepicker/Datepicker';
import { formatDateToYYYYMMDD, validateDateFormat } from '../../../../../core/utilities/date';
import Timepicker from '../../../../../components/containers/Timepicker/Timepicker';
import SearchResultsList from '../../../SearchResults/Partials/SearchResultsList/SearchResultsList';
import { endpoints } from '../../../../../core/config/endpoints';
import Alert from '../../../../../components/presentational/Alert/Alert';
import {
  searchByPart,
  setPartRoutes,
} from '../../../../../core/modules/TicketChange/TicketChangeActions';
import './ExchangeTrip.scss';
import Icon from '../../../../../components/presentational/Icon/Icon';

const ExchangeTrip = ({
  t,
  dispatchInitiateSearch,
  dispatchClearRoutes,
  orderHash,
  searching,
  routes,
  trip,
  direction,
  activeSearchDirection,
  setActiveSearchDirection,
  setNewTrip,
  newTrip,
  submitting,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [departureDate, setDepartureDate] = useState(formatDateToYYYYMMDD(new Date()));
  const [departureTime, setDepartureTime] = useState('00:00');
  const [selectedTripParts, setSelectedTripParts] = useState(null);
  const [searchPartIndex, setSearchPartIndex] = useState(null);
  const [searchRetryCount, setSearchRetryCount] = useState(0);
  const [lastSelectedIndex, setLastSelectedIndex] = useState(null);
  const { name, duration, parts } = trip;
  const currentSearchDirectionIsActive = direction === activeSearchDirection;
  const searchingLastPart = !parts[searchPartIndex + 1];
  const lastPartIsSelected = !parts[lastSelectedIndex + 1];
  const searchIsActive = searchPartIndex !== null;
  const partsForDisplay = newTrip || (!searchIsActive && parts);
  const toggleIsOpen = () => setIsOpen(!isOpen);
  const sellableRoutes = routes.filter((route) => route.legs[0] && route.legs[0].sellable);

  const confirmSelection = (selectedTrip) => {
    setNewTrip(direction, selectedTrip);
    dispatchClearRoutes();
    setSelectedTripParts(null);
    setSearchPartIndex(null);
    setLastSelectedIndex(null);
  };

  const onSearchClick = () => {
    const indexAlreadySearching = searchPartIndex === 0;

    confirmSelection();
    setSearchPartIndex(0);

    if (indexAlreadySearching) setSearchRetryCount(searchRetryCount + 1);
  };

  const handleSelectedTripParts = ({ sellableSteps }) => {
    const newParts = sellableSteps.map((sellableStep) => {
      const { transitDetails, sellData } = sellableStep;
      const {
        departureStop,
        arrivalStop,
        departureTime: stepDepartureTime,
        arrivalTime: stepArrivalTime,
      } = transitDetails;

      const {
        actualTripId,
        fromTripStopId,
        toTripStopId,
      } = sellData;

      return {
        key: `${departureStop.id}${arrivalStop.id}`,
        departureTimeString: stepDepartureTime.text,
        departureDateString: stepDepartureTime.date,
        arrivalTimeString: stepArrivalTime.text,
        arrivalDateString: stepArrivalTime.date,
        fromTripStop: departureStop,
        toTripStop: arrivalStop,
        sellData: {
          partId: parts[searchPartIndex].partId,
          actualTripId,
          fromTripStopId,
          toTripStopId,
        },
      };
    });

    setSelectedTripParts((prevSelectedTripParts) => (prevSelectedTripParts || []).concat(newParts));

    if (!searchingLastPart) {
      setLastSelectedIndex(searchPartIndex);
      setSearchPartIndex(searchPartIndex + 1);
    } else {
      setLastSelectedIndex(searchPartIndex);
    }
  };

  useEffect(() => {
    if (searchPartIndex === null) return;

    const lastSelectedPart = selectedTripParts && selectedTripParts[selectedTripParts.length - 1];
    const date = searchPartIndex === 0
      ? `${departureDate} ${departureTime}`
      : `${lastSelectedPart.arrivalDateString} ${lastSelectedPart.arrivalTimeString}`;
    const part = parts[searchPartIndex];
    const { partId } = part;

    dispatchInitiateSearch(orderHash, partId, date);
    setActiveSearchDirection(direction);
  }, [searchPartIndex, searchRetryCount]);

  useEffect(() => {
    if (selectedTripParts && lastPartIsSelected) {
      confirmSelection(selectedTripParts);
    }
  }, [selectedTripParts, lastPartIsSelected]);

  useEffect(() => {
    if (direction !== activeSearchDirection) confirmSelection(newTrip);
  }, [activeSearchDirection]);

  return (
    <>
      <div className="ticket-exchange-trip-container">
        <div className="ticket-exchange-trip">
          <div className="ticket-exchange-trip-preview-container">
            <div className="ticket-exchange-trip-preview">
              <div className="ticket-exchange-trip__heading">{t(`checkout_trip_${direction}`)}</div>

              <div className="ticket-exchange-trip__route">{name}</div>

              <div className="ticket-exchange-trip__duration">{t('route_ticket_duration')} {duration}</div>
            </div>

            <Button
              className="ticket-exchange-trip-preview__button"
              variant="inverted"
              onClick={toggleIsOpen}
            >
              {t(isOpen ? 'ticket_return_cancel' : 'order_change')}
            </Button>
          </div>

          {isOpen && (
            <div className="ticket-exchange-trip-search-container">
              <div className="ticket-exchange-trip-search">
                <Datepicker
                  id="depart-date"
                  name="departureDate"
                  onChange={(date) => {
                    setDepartureDate(formatDateToYYYYMMDD(date));
                    setDepartureTime('00:00');
                  }}
                  value={departureDate}
                  label={`${t('order_change_select_new_departure_time')}:`}
                  minDate={new Date()}
                  timepicker={(
                    <Timepicker
                      id="depart-time"
                      name="departureTime"
                      value={departureTime}
                      onChange={setDepartureTime}
                    />
                  )}
                  error={validateDateFormat(departureDate) ? null : t('invalid_date')}
                />

                <Button
                  className="ticket-exchange-trip-search-button"
                  onClick={onSearchClick}
                  disabled={!departureDate || !departureTime || searching || submitting}
                >
                  {t('order_change_search_for_ticket')}
                </Button>

                {(searchIsActive || newTrip) && (
                  <Button
                    variant="inline-link"
                    onClick={() => confirmSelection()}
                    disabled={searching || submitting}
                  >
                    <span>{t('ticket_return_cancel')}</span>
                  </Button>
                )}
              </div>

              {partsForDisplay && (
                <div className="ticket-exchange-trip-details">
                  <div className="ticket-exchange-trip-current-route">
                    {t('order_change_current_route')}:
                  </div>

                  <div className="ticket-exchange-trip-current-route-stops-parts">
                    {partsForDisplay.map((part, partIndex) => {
                      const {
                        key,
                        partId,
                        departureDateString,
                        departureTimeString,
                        arrivalDateString,
                        arrivalTimeString,
                        fromTripStop,
                        toTripStop,
                      } = part;

                      return (
                        <div key={partId || key} className="ticket-exchange-trip-current-route-stops-part">
                          {!!partIndex && <div className="circle" />}

                          <Icon icon="bus" className="ticket-exchange-trip-current-route-stops-part__bus" />

                          <b>{departureDateString} {departureTimeString}</b>

                          {' '}

                          {fromTripStop.name}

                          <Icon icon="arrow-long-right" className="ticket-exchange-trip-current-route-stops-part__arrow" />

                          <b>{arrivalDateString} {arrivalTimeString}</b>

                          {toTripStop.name}

                          {' '}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {isOpen && currentSearchDirectionIsActive && searchIsActive && (
        <>
          {!searching && (
            <Alert className="ticket-exchange-trip__alert" variant="warning">
              {t('order_change_results_for_part')}
              <b>
                {parts[searchPartIndex].fromTripStop.name}
                {' - '}
                {parts[searchPartIndex].toTripStop.name}
              </b>
            </Alert>
          )}

          {(!!sellableRoutes.length || searching) && (
            <SearchResultsList
              loading={searching}
              routes={sellableRoutes}
              searchValues={{}}
              showDates
              exchangeButtonProps={{
                children: t('common_button_choose'),
                onClick: handleSelectedTripParts,
              }}
            />
          )}

          {!sellableRoutes.length && !searching && (
            <div className="ticket-exchange-trip-search-results-empty">
              <Icon icon="sad-bus" />

              <div>{t('order_change_no_tickets_available')}</div>
              <div>{t('order_change_try_changing_departure_time')}</div>
            </div>
          )}
        </>
      )}
    </>
  );
};

ExchangeTrip.propTypes = {
  t: PropTypes.func.isRequired,
  dispatchInitiateSearch: PropTypes.func.isRequired,
  dispatchClearRoutes: PropTypes.func.isRequired,
  setActiveSearchDirection: PropTypes.func.isRequired,
  setNewTrip: PropTypes.func.isRequired,
  activeSearchDirection: PropTypes.string.isRequired,
  orderHash: PropTypes.string.isRequired,
  trip: PropTypes.shape().isRequired,
  direction: PropTypes.oneOf(tripDirections).isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape()),
  newTrip: PropTypes.arrayOf(PropTypes.shape()),
  searching: PropTypes.bool,
  submitting: PropTypes.bool,
};

ExchangeTrip.defaultProps = {
  submitting: false,
  routes: [],
  searching: false,
  newTrip: null,
};

const mapStateToProps = (state) => ({
  submitting: state.request.getIn([endpoints.SEARCH_ROUTES_BY_PART.name, 'loading']),
  searching: state.request.getIn([endpoints.SEARCH_ROUTES_BY_PART.name, 'loading']),
  routes: state.ticketChange.get('routes'),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchInitiateSearch: (orderHash, partId, date) => dispatch(
    searchByPart(orderHash, partId, date),
  ),
  dispatchClearRoutes: () => dispatch(setPartRoutes([])),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ExchangeTrip));
