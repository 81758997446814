import { Map, List } from 'immutable';
import { constants } from '../constants';

const initialState = Map({});

const TopRoutesStationsReducer = (state = initialState, action) => {
  switch (action.type) {
  case constants.data.collections.topRoutes.SET_TOP_ROUTES_LIST:
    return state.setIn(['routes', action.payload.locale], List(action.payload.list));

  case constants.data.collections.topStations.SET_TOP_STATIONS_LIST:
    return state.setIn(['stations', action.payload.locale, action.payload.key], List(action.payload.list));

  default:
    return state;
  }
};

export default TopRoutesStationsReducer;
