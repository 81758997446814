export const searchEndpoints = {
  INITIATE_SEARCH: {
    name: 'INITIATE_SEARCH',
    url: {
      method: 'POST',
      path: () => '/route/search',
    },
  },
  INITIATE_SEARCH_BY_SLUG: {
    name: 'INITIATE_SEARCH_BY_SLUG',
    url: {
      method: 'GET',
      path: (slug) => `/route/search/by_slug/${slug}`,
    },
  },
};
