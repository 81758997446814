import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

const ScrollToTop = ({ children, location: { pathname }, containerRef }) => {
  useEffect(() => {
    if (containerRef) {
      const node = containerRef.current;
      node.scrollTop = 0;
    }
  }, [pathname]);

  return children || null;
};

ScrollToTop.propTypes = {
  containerRef: PropTypes.shape(), 
  location: PropTypes.shape(),
  children: PropTypes.arrayOf(PropTypes.shape()),
};

ScrollToTop.defaultProps = {
  containerRef: null,
  location: null,
  children: [],
};

export default withRouter(ScrollToTop);
