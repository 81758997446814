/* eslint-disable react/no-danger */

import React from 'react';
import PropTypes from 'prop-types';
import './TextAndImage.scss';
// eslint-disable-next-line import/extensions
import Image from './Partials/Image-VARIANT';

const classNameByImagePosition = {
  1: 'text-image-left',
  2: 'text-image-right',
  3: 'text-image-top',
  4: 'text-image-bottom',
};
const spaceBetweenParts = 5;
const TextAndImage = ({ parts }) => (
  <div className="content-block text-image-container">
    {parts.map((part) => {
      const multipleImagesPerBlock = parts.length > 1 && part.width < 100;
      const width = multipleImagesPerBlock ? `calc(${part.width === 33 ? 33.33 : part.width}% - ${spaceBetweenParts}px)` : `${part.width}%`;

      return (
        <div
          className={`text-image ${classNameByImagePosition[part.imagePosition] || ''}`}
          key={part.id}
          style={{
            width,
          }}
        >
          {part.displayContent && (
            <div
              className="text-image__item text-image__content"
              dangerouslySetInnerHTML={{ __html: part.content }}
              style={{
                width: `${part.contentWidthInPercent}%`,
              }}
            />
          )}

          {part.displayImage && (
            <Image part={part} />
          )}
        </div>
      );
    })}
  </div>
);

TextAndImage.propTypes = {
  parts: PropTypes.arrayOf(PropTypes.shape()),
};

TextAndImage.defaultProps = {
  parts: [],
};

export default TextAndImage;
