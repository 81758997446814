import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { getLink, routes } from '../../../../../core/config/routes';
import SidebarView from '../../../../../components/presentational/Sidebar/Sidebar';
import './Sidebar.scss';

const Sidebar = ({
  t,
  tickets,
  orderHash,
  ticketType,
}) => (
  <SidebarView
    menu={{
      title: t('ticket'),
      icon: 'ticket',
      items: tickets.map((ticket) => {
        const { clientName, ticketId } = ticket;

        return {
          id: ticketId,
          icon: 'exit',
          title: clientName,
          to: getLink(t(routes.ticketReturn.ticket), {
            orderHash,
            activeTicketId: ticketId,
          }),
          disabled: ticketType === 3,
        };
      }),
    }}
  />
);

Sidebar.propTypes = {
  t: PropTypes.func.isRequired,
  tickets: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  orderHash: PropTypes.string.isRequired,
  ticketType: PropTypes.number.isRequired,
};

export default withTranslation()(Sidebar);
