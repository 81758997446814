import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Formik } from 'formik';
import Input from '../../../../../components/presentational/FormElements/Input/Input';
import Button from '../../../../../components/presentational/Button/Button';
import { setRequestError } from '../../../../../core/modules/Request/RequestActions';
import {
  passwordResetSentTo,
  requestPasswordReset,
} from '../../../../../core/modules/Components/ResetPassword/ResetPasswordActions';
import { resetPasswordEndpoints } from '../../../../../core/config/endpoints/resetPassword';
import successImage from '../../../../../../assets/images/success.png';
import './ResetPasswordRequestForm.scss';
import { getErrors } from '../../../../../core/utilities/formValidator';
import { validations } from '../../../../../core/config/form/validations';
import Alert from '../../../../../components/presentational/Alert/Alert';
import Icon from '../../../../../components/presentational/Icon/Icon';

class ResetPasswordRequestForm extends React.Component {
  form = React.createRef();

  componentWillUnmount() {
    const { unsetResetPasswordError, unsetResetPasswordSentTo } = this.props;
    unsetResetPasswordError();
    unsetResetPasswordSentTo();
  }

  onSubmit = ({ email }) => {
    const { dispatchResetPassword } = this.props;
    dispatchResetPassword(email);
  };

  render() {
    const {
      toggleLoginModalContent,
      resetPasswordLoading,
      resetPasswordErrors,
      resetPasswordSentTo,
      t,
    } = this.props;

    return (
      !resetPasswordSentTo
        ? (
          <Formik
            validateOnChange={false}
            onSubmit={this.onSubmit}
            validate={(values) => getErrors(values, {
              email: [validations.email],
            }, this.form)}
            initialValues={{
              email: '',
            }}
            render={(formProps) => {
              const {
                values,
                handleSubmit,
                errors,
                setFieldValue,
              } = formProps;
              const {
                email,
              } = values;

              return (
                <form onSubmit={handleSubmit} ref={this.form}>
                  {resetPasswordErrors.length
                    ? resetPasswordErrors.map((error) => (
                      <Alert
                        key={error}
                        variant="danger"
                        heading={t('common_error_exclamation')}
                      >
                        {error}
                      </Alert>
                    ))
                    : null}

                  <div className="login-modal-input-row">
                    <Input
                      name="email"
                      id="email"
                      label={t('login_username')}
                      type="text"
                      value={email}
                      autocapitalize="none"
                      onChange={(e) => setFieldValue('email', e.target.value)}
                      disabled={resetPasswordLoading}
                      error={t(errors.email)}
                    />
                  </div>

                  <div className="login-modal-reset-password-text">
                    {t('reset_password_explanation')}
                  </div>

                  <Button
                    className="login-modal-text-button login-modal-login-form-button"
                    variant="inline-link"
                    onClick={toggleLoginModalContent}
                  >
                    <Icon icon="arrow-long-left" className="login-modal-login-form-icon" />

                    <span>{t('login_go_back')}</span>
                  </Button>

                  <Button
                    variant="brand"
                    type="submit"
                    className="login-modal-reset-password-request-button"
                    disabled={resetPasswordLoading}
                  >
                    {t('login_remind_password')}
                  </Button>
                </form>
              );
            }}
          />
        )
        : (
          <>
            <div className="reset-password-request-success">
              <img src={successImage} className="reset-password-request-success-img" alt="reset password request success" />
              <span className="reset-password-request-success-text">{t('login_password_sent_to')}</span>
              <span className="reset-password-request-success-email">{resetPasswordSentTo}</span>
            </div>

            <Button
              variant="brand"
              disabled={resetPasswordLoading}
              onClick={toggleLoginModalContent}
              className="reset-password-request-go-back"
            >
              {t('login_connect')}
            </Button>
          </>
        )
    );
  }
}

ResetPasswordRequestForm.propTypes = {
  toggleLoginModalContent: PropTypes.func.isRequired,
  dispatchResetPassword: PropTypes.func.isRequired,
  unsetResetPasswordError: PropTypes.func.isRequired,
  unsetResetPasswordSentTo: PropTypes.func.isRequired,
  resetPasswordLoading: PropTypes.bool,
  resetPasswordErrors: PropTypes.arrayOf(PropTypes.string),
  resetPasswordSentTo: PropTypes.string,
  t: PropTypes.func.isRequired,
};

ResetPasswordRequestForm.defaultProps = {
  resetPasswordLoading: false,
  resetPasswordErrors: [],
  resetPasswordSentTo: null,
};

const resetPasswordEndpointName = resetPasswordEndpoints.REQUEST_PASSWORD_RESET.name;

const mapStateToProps = (state) => ({
  resetPasswordSentTo: state.components.resetPassword.get('resetSentTo'),
  resetPasswordLoading: state.request.getIn([resetPasswordEndpointName, 'loading']),
  resetPasswordErrors: state.request.getIn([resetPasswordEndpointName, 'error', 'global']),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchResetPassword: (email) => dispatch(requestPasswordReset(email)),
  unsetResetPasswordError: () => dispatch(setRequestError(resetPasswordEndpointName, {})),
  unsetResetPasswordSentTo: () => dispatch(passwordResetSentTo(undefined)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(ResetPasswordRequestForm),
);
