import { getYear } from './date';

export const getHistoryRecordsGroupedByYear = (rawHistoryRecords) => rawHistoryRecords
  && rawHistoryRecords.length > 0
  && rawHistoryRecords.reduce((accumulator, rawHistoryRecord) => {
    if (rawHistoryRecord.createdAt < 0) {
      return accumulator;
    }

    const newAccumulator = { ...accumulator };
    const year = getYear(rawHistoryRecord.createdAt);

    if (newAccumulator[year]) {
      newAccumulator[year].push(rawHistoryRecord);
    } else {
      newAccumulator[year] = [rawHistoryRecord];
    }

    return newAccumulator;
  }, {});
