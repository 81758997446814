import React from 'react';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Button from '../../../components/presentational/Button/Button';
import { deleteUser } from '../../../core/modules/User/UserActions';
import { userEndpoints } from '../../../core/config/endpoints/user';
import './DeleteUserForm.scss';

class DeleteUserForm extends React.Component {
  constructor(props) {
    super(props);

    this.form = React.createRef();
  }

  onSubmit = () => {
    const { t, dispatchDeleteUser } = this.props;

    confirmAlert({
      title: t('setting_delete_user'),
      message: t('setting_delete_user_message'),
      buttons: [
        {
          label: t('cancel'),
        },
        {
          label: t('delete'),
          onClick: () => dispatchDeleteUser(),
        },
      ],
      closeOnClickOutside: true,
      closeOnEscape: true,
    });
  };

  render() {
    const { t } = this.props;

    return (
      <div>
        <div className="personal-data-form-header">{t('setting_delete_user_title')}</div>
        <Button
          className="personal-data-form-delete-request-button"
          onClick={this.onSubmit}
        >
          {t('delete')}
        </Button>
      </div>
    );
  }
}

DeleteUserForm.propTypes = {
  t: PropTypes.func.isRequired,
  dispatchDeleteUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  changePasswordErrors: state.request.getIn([userEndpoints.DELETE_USER.name, 'error']),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchDeleteUser: () => dispatch(deleteUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DeleteUserForm));
