import { constants } from '../constants';

export const getEvents = (category, event) => ({
  type: constants.events.GET_EVENTS_BY_CATEGORY_EVENT,
  payload: { category, event },
});

export const getEventsCategories = () => ({
  type: constants.events.GET_EVENTS_CATEGORIES,
  payload: {},
});

export const setEvents = (events) => ({
  type: constants.events.SET_EVENTS,
  payload: {
    events,
  },
});

export const setEventsCategories = (categories) => ({
  type: constants.events.SET_EVENTS_CATEGORIES,
  payload: {
    categories,
  },
});

export const removeEvent = () => ({
  type: constants.events.REMOVE_EVENT,
  payload: {},
});
