import React from 'react';
import PropTypes from 'prop-types';
import NumberPickerView from '../../../presentational/FormElements/NumberPicker/NumberPicker';

const NumberPicker = ({
  id,
  value,
  name,
  required,
  onIncrement,
  onDecrement,
  onBlur,
  disabled,
  ariaLabel,
  addPassenger,
  removePassenger,
}) => (
  <NumberPickerView
    id={id}
    ariaLabel={ariaLabel}
    value={value}
    name={name}
    required={required}
    onIncrement={onIncrement}
    onDecrement={onDecrement}
    onBlur={onBlur}
    disabled={disabled}
    addPassenger={addPassenger}
    removePassenger={removePassenger}
  />
);

NumberPicker.propTypes = {
  id: PropTypes.string,
  value: PropTypes.number,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onIncrement: PropTypes.func.isRequired,
  onDecrement: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  ariaLabel: PropTypes.string,
  addPassenger: PropTypes.string,
  removePassenger: PropTypes.string,
};

NumberPicker.defaultProps = {
  id: null,
  value: null,
  disabled: false,
  required: false,
  onBlur: () => null,
  ariaLabel: '',
  addPassenger: '',
  removePassenger: '',
};

export default NumberPicker;
