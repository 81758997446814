import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { ReCaptcha } from 'react-recaptcha-google';
import { getErrors } from '../../../../core/utilities/formValidator';
import Input from '../../FormElements/Input/Input';
import Button from '../../Button/Button';
import Checkbox from '../../FormElements/Checkbox/Checkbox';
import { validations } from '../../../../core/config/form/validations';
import './ContactForm.scss';
import Alert from '../../Alert/Alert';
import { contactEndpoints } from '../../../../core/config/endpoints/contact';
import { setRequestError } from '../../../../core/modules/Request/RequestActions';
import { recaptchaSiteKey } from '../../../../core/config/endpoints';

class ContactForm extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.form = React.createRef();
    this.captcha = React.createRef();
  }

  componentDidMount() {
    if (this.captcha.current) {
      this.captcha.current.reset();
      this.captcha.current.execute();
    }
  }

  componentWillUnmount() {
    const { unsetContactError } = this.props;
    unsetContactError();
  }

  onSubmitWrapper = (values) => {
    const { onSubmit } = this.props;
    onSubmit(values);

    if (this.captcha.current) {
      this.captcha.current.reset();
      this.captcha.current.execute();
    }
  };

  render() {
    const {
      t, submitContactErrors, submitContactLoading,
    } = this.props;

    const { global, fields } = submitContactErrors;
    const submitFieldsErrors = fields || {};

    return (
      <Formik
        validateOnChange={false}
        onSubmit={this.onSubmitWrapper}
        initialValues={{
          email: '',
          message: '',
          sendCopy: false,
        }}
        validate={(values) => getErrors(values, {
          email: [validations.email],
          message: [validations.required],
          token: [validations.required],
        }, this.form)}
        render={(formProps) => {
          const {
            values,
            handleSubmit,
            errors,
            setFieldValue,
          } = formProps;

          const {
            email, message, sendCopy, token,
          } = values;

          return (
            <form onSubmit={handleSubmit} ref={this.form} className="contact-form">
              <ReCaptcha
                ref={this.captcha}
                size="invisible"
                render="explicit"
                sitekey={recaptchaSiteKey}
                verifyCallback={(reCaptchaToken) => setFieldValue('token', reCaptchaToken)}
              />

              {global && global.map((error) => (
                <Alert
                  key={error}
                  variant="danger"
                  heading={t('common_error_exclamation')}
                >
                  {error}
                </Alert>
              ))}

              <h2 className="contact-form-header-row">
                {t('contact_header_message')}
              </h2>

              <div className="contact-form-subheader-row">
                {`* - ${t('form_marked_fields_required')}`}
              </div>

              <div className="contact-form-divider" />

              <div className="contact-form-input-row">
                <Input
                  name="email"
                  id="email-newsletter"
                  label={`${t('contact_email')} *`}
                  value={email}
                  error={t(errors.email) || submitFieldsErrors.email}
                  onChange={(e) => setFieldValue('email', e.target.value)}
                  disabled={submitContactLoading}
                />
              </div>

              <div className="contact-form-input-row is-large">
                <Input
                  container="textarea"
                  rows={4}
                  name="message"
                  id="message-newsletter"
                  label={`${t('contact_message')} *`}
                  value={message}
                  error={t(errors.message) || submitFieldsErrors.message}
                  onChange={(e) => setFieldValue('message', e.target.value)}
                  disabled={submitContactLoading}
                />
              </div>

              <div className="contact-form-checkbox-row">
                <Checkbox
                  className="checkbox__message"
                  id="ticket-backward-checkbox"
                  name="sendCopy"
                  label={t('contact_checkbox_message')}
                  onChange={(e) => {
                    setFieldValue(e.target.name, !sendCopy);
                  }}
                  value={sendCopy}
                  disabled={submitContactLoading}
                />
              </div>

              <div className="contact-form-actions">
                <Button
                  type="submit"
                  disabled={submitContactLoading || !token}
                >
                  {t('contact_submit')}
                </Button>
              </div>
            </form>
          );
        }}
      />
    );
  }
}

ContactForm.propTypes = {
  t: PropTypes.func.isRequired,
  submitContactErrors: PropTypes.shape(),
  submitContactLoading: PropTypes.bool,
  unsetContactError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

ContactForm.defaultProps = {
  submitContactLoading: false,
  submitContactErrors: {},
};

const mapStateToProps = (state) => ({
  submitContactLoading: state.request.getIn([contactEndpoints.CONTACT.name, 'loading']),
  submitContactErrors: state.request.getIn([contactEndpoints.CONTACT.name, 'error']),
});

const mapDispatchToProps = (dispatch) => ({
  unsetContactError: () => dispatch(setRequestError(contactEndpoints.CONTACT.name, {})),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ContactForm));
