import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { routes } from '../../../../../core/config/routes';
import { logoutUser } from '../../../../../core/modules/User/UserActions';
import Sidebar from '../../../../../components/presentational/Sidebar/Sidebar';
import './UserSidebar.scss';

const UserSidebar = ({
  t, name, dispatchUserLogout,
}) => {
  const menu = {
    icon: 'user',
    title: name,
    items: [
      {
        title: t('user_purchase_history'),
        to: t(routes.user.index),
        icon: 'wallet',
        id: 'account-history',
        activeRoutes: [
          t(routes.user.index),
          t(routes.user.orderHistory),
        ],
      },
      {
        title: t('user_data'),
        to: t(routes.user.details),
        icon: 'add',
        id: 'account-data',
      },
      {
        title: t('user_account_logout'),
        onClick: dispatchUserLogout,
        icon: 'exit',
        id: 'account-logout',
      },
    ],
  };

  return (
    <Sidebar menu={menu}>
      <div className="user-sidebar-header">{t('user_sidebar_header')}</div>
      <div className="user-sidebar-username">{name}</div>
    </Sidebar>
  );
};

UserSidebar.propTypes = {
  t: PropTypes.func.isRequired,
  name: PropTypes.string,
  dispatchUserLogout: PropTypes.func.isRequired,
};

UserSidebar.defaultProps = {
  name: '',
};

const mapStateToProps = (state) => ({
  name: state.user.getIn(['data', 'name']),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchUserLogout: () => dispatch(logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserSidebar));
