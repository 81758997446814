import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { destroyOrder, getOrder } from '../../../core/modules/Order/OrderActions';
import Preloader from '../../../components/presentational/Preloader/Preloader';
import NotFound from '../NotFound/NotFound';
import Sidebar from './Partials/Sidebar/Sidebar';
import './TicketReturn.scss';
import ReturnBox from './Partials/ReturnBox/ReturnBox';
import { withTracker } from '../../common/Tracker/withTracker';
import withDocumentHead from '../../common/DocumentHead/withDocumentHead';
import variantConst from '../../../core/config/constants/variantConst/variantConst';

const TicketReturn = ({
  t,
  match,
  order,
  loading,
  dispatchGetOrder,
  dispatchDestroyOrder,
  setDocumentTitle,
}) => {
  const { orderHash, activeTicketId } = match.params;

  React.useEffect(() => {
    dispatchGetOrder(orderHash);

    setDocumentTitle(t(variantConst.documentTitle, { title: t('ticket_return') }));

    return function cleanup() {
      dispatchDestroyOrder();
    };
  }, [orderHash]);

  if (loading) return <Preloader fullHeight fullWidth />;

  if (!order) return <NotFound />;

  const { hash } = order;
  const { tickets, ticketType } = order;
  const activeTicket = !activeTicketId
    ? tickets[0]
    : tickets.filter((ticket) => ticket.ticketId.toString() === activeTicketId)[0];

  if (!activeTicket) return <NotFound />;

  return (
    <div className="ticket-return">
      <Sidebar
        tickets={tickets}
        orderHash={hash}
        ticketType={ticketType}
      />

      <div className="ticket-return-content">
        <div className="ticket-return-content__header">
          {t('ticket_return')}
        </div>

        <ReturnBox
          orderHash={hash}
          ticketId={activeTicket.ticketId}
        />
      </div>
    </div>
  );
};

TicketReturn.propTypes = {
  t: PropTypes.func.isRequired,
  setDocumentTitle: PropTypes.func.isRequired,
  dispatchGetOrder: PropTypes.func.isRequired,
  dispatchDestroyOrder: PropTypes.func.isRequired,
  match: PropTypes.shape().isRequired,
  order: PropTypes.shape(),
  loading: PropTypes.bool,
};

TicketReturn.defaultProps = {
  loading: false,
  order: null,
};

const mapStateToProps = (state) => ({
  loading: state.order.getIn(['status', 'loading']),
  order: state.order.get('data'),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchGetOrder: (orderHash) => dispatch(getOrder(orderHash)),
  dispatchDestroyOrder: () => dispatch(destroyOrder()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(withDocumentHead(withTracker(TicketReturn))));
