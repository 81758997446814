import { constants } from '../../../constants';

export const getCustomFooter = (endpointFooter) => ({
  type: constants.components.footer.GET_CUSTOM_FOOTER,
  payload: {
    endpointFooter,
  },
});

export const addFooterColumns = (footerColumns) => ({
  type: constants.components.footer.ADD_FOOTER_COLUMNS,
  payload: {
    footerColumns,
  },
});
