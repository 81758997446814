import { constants } from '../constants';

export const getStation = (slug) => ({
  type: constants.data.collections.stations.GET_STATION,
  payload: { slug },
});

export const setStation = (slug, station) => ({
  type: constants.data.collections.stations.SET_STATION,
  payload: { slug, station },
});
