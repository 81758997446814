import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './Footer-ab.scss';
import { Link, Route, useLocation } from 'react-router-dom';
import { getCustomFooter } from '../../../core/modules/CMS/Components/Footer/FooterActions';
import Newsletter from '../../../components/containers/Newsletter/Newsletter';
import { footerlessRoutesList, routes } from '../../../core/config/routes';
import LocalizedSwitch from '../LocalizedSwitch/LocalizedSwitch';
import Copyrights from './Partials/Copyrights';
import { footerEndpoints } from '../../../core/config/constants/footer';
import AppStores from '../../../components/containers/AppStores/AppStores';

const FooterAb = ({
  getFooterColumns,
  footerColumns,
}) => {
  React.useEffect(() => {
    getFooterColumns(footerEndpoints.ab);
  }, []);

  const location = useLocation();

  const focusHeader = () => {
    const header = document.querySelector('#cms-content');

    if (header) {
      header.focus();
    }
  };

  useEffect(() => {
    focusHeader();
  }, [location]);

  const handleNavLinkKeyPress = (event) => {
    if (event.key === 'Enter') {
      focusHeader();
    }
  };

  return (
    <footer className="footer-ab">
      <LocalizedSwitch>
        <Route path={footerlessRoutesList} />

        <Route
          render={() => (
            <div className="footer-row">
              <div className="footer-lists-container">
                {footerColumns.map((footerColumn, i) => (
                  <ul
                    key={footerColumn.name}
                    className={`footer-list ${i === footerColumns.length - 1 ? 'is-last' : ''}`}
                  >
                    {footerColumn.data.map((listItem) => (
                      <Link
                        className="footer-list__item"
                        to={`/${listItem.slug}`}
                        key={listItem.key}
                        onKeyPress={handleNavLinkKeyPress}
                      >
                        {listItem.title}
                      </Link>
                    ))}
                  </ul>
                ))}

                <LocalizedSwitch>
                  <Route
                    exact
                    path={[
                      routes.homepage,
                      routes.searchResults,
                      routes.topRoutes.route,
                    ]}
                    component={AppStores}
                  />

                  <Route component={Newsletter} />
                </LocalizedSwitch>
              </div>
            </div>
          )}
        />
      </LocalizedSwitch>

      <Copyrights />
    </footer>
  );
};

FooterAb.propTypes = {
  getFooterColumns: PropTypes.func.isRequired,
  footerColumns: PropTypes.arrayOf(PropTypes.object),
};

FooterAb.defaultProps = {
  footerColumns: [],
};

const mapStateToProps = (state) => ({
  footerColumns: state.components.footer.get('footerColumns'),
});

const mapDispatchToProps = (dispatch) => ({
  getFooterColumns: (endpointsFooter) => dispatch(getCustomFooter(endpointsFooter)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FooterAb);
