export const bannersEndpoints = {
  GET_BANNER_BY_ZONE: {
    name: 'GET_BANNER_BY_ZONE',
    url: {
      method: 'GET',
      path: (zone) => `/banners/zone/${zone}`,
    },
  },
  GET_DIRECT_BANNER_URL_BY_ZONE: {
    name: 'GET_DIRECT_BANNER_URL_BY_ZONE',
    url: {
      method: 'GET',
      path: (zone, size, locale = '') => `/banners/zone/${zone}/size/${size}${locale ? `?locale=${locale}` : ''}`,
    },
  },
};
