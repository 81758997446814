import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { getLink, routes } from '../../../../../core/config/routes';
import SidebarView from '../../../../../components/presentational/Sidebar/Sidebar';
import Alert from '../../../../../components/presentational/Alert/Alert';
import './Sidebar.scss';

const Sidebar = ({
  t,
  tickets,
  orderHash,
  ticketType,
}) => {
  const reprintReminder = t('order_change_reprint_reminder');
  const chooseTicket = t('order_change_choose_ticket');

  return (
    <SidebarView
      menu={{
        title: t('ticket'),
        icon: 'ticket',
        items: tickets.map((ticket) => {
          const { clientName, ticketId } = ticket;

          return {
            id: ticketId,
            icon: 'exit',
            title: clientName,
            to: getLink(t(routes.ticketChange.ticket), {
              orderHash,
              activeTicketId: ticketId,
            }),
            disabled: ticketType === 3 || !ticket.canChange,
          };
        }),
      }}
    >
      {(reprintReminder || chooseTicket) && (
        <div className="ticket-change-sidebar-content">
          {chooseTicket && <div className="ticket-change-sidebar-message">{chooseTicket}</div>}

          {reprintReminder && (
            <Alert variant="warning" arrow="top">
              {reprintReminder}
            </Alert>
          )}
        </div>
      )}
    </SidebarView>
  );
};

Sidebar.propTypes = {
  t: PropTypes.func.isRequired,
  tickets: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  orderHash: PropTypes.string.isRequired,
  ticketType: PropTypes.number.isRequired,
};

export default withTranslation()(Sidebar);
