import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { getTimeFromSeconds } from '../../../../../core/utilities/date';
import SearchResultsRouteFeatures
from '../../../../containers/SearchResultsRoute/Partials/Features/Features';
import RoutePrice from '../RoutePrice/RoutePrice';
import TransferRouteMobile from './TransferRouteMobile/TransferRouteMobile';
import RouteLine from '../RouteLine/RouteLine';
import './TransferRoute.scss';
import { openModal } from '../../../../../core/modules/Components/Modal/ModalActions';
import RouteSchedule from '../../../../../containers/modals/RouteSchedule/RouteSchedule';
import Icon from '../../../Icon/Icon';
import Button from '../../../Button/Button';

class TransferRoute extends Component {
  getTripDurationTimes = (route) => {
    const { departureTime, arrivalTime } = route;

    return { departureTime: departureTime.text, arrivalTime: arrivalTime.text };
  };

  render() {
    const {
      t, route, isFirst, isLast, onOpenRouteSchedule, isConnecting,
    } = this.props;
    const {
      sellable, duration, transitDetails, travelMode,
    } = route;
    const { departureStop, arrivalStop } = transitDetails;
    const { hours, mins } = getTimeFromSeconds(duration.value);
    const { departureTime, arrivalTime } = this.getTripDurationTimes(transitDetails);

    return (
      <>
        <div className={`transfer-route is-desktop ${isLast ? 'is-last' : ''}`}>
          <RouteLine
            isFirst={isFirst}
            isLast={isLast}
            travelMode={travelMode}
            isConnecting={isConnecting && !isFirst && !isLast}
          />

          <div className="transfer-route-content">
            <div className="transfer-route-info">
              <div className="transfer-route-info__departure">
                <span><b>{departureTime}</b> - {arrivalTime}</span>

                <Button variant="inline-link-dotted" onClick={() => onOpenRouteSchedule(route)} ariaExpanded="false">
                  <Icon icon="list" />

                  <span>
                    {travelMode === 'WALKING'
                      ? t('route_trip_map')
                      : t('route_schedule')}
                  </span>
                </Button>
              </div>

              <div className="transfer-route-info__stop">
                {departureStop.name}

                <Icon icon="long-arrow-right" />

                <b>{arrivalTime}</b>&nbsp;{arrivalStop.name}
              </div>

              <div className="transfer-route-info__duration">
                {t('route_ticket_duration')}: &nbsp;

                {!!hours && (
                  <span>{t('route_duration_hours', { hours })}&nbsp;</span>
                )}

                {t('route_duration_minutes', { mins })}
              </div>
            </div>

            <div className="transfer-route-features">
              <SearchResultsRouteFeatures route={route} />
            </div>

            {travelMode !== 'WALKING' && (
              <div className="transfer-route-price-wrap promo-price-wrapper">
                <RoutePrice isTransfer sellable={sellable} route={route.sellData} />
              </div>
            )}
          </div>
        </div>

        <div className={`transfer-route is-mobile ${isLast ? 'is-last' : ''}`}>
          <TransferRouteMobile
            isFirst={isFirst}
            isLast={isLast}
            route={route}
            tripDuration={this.getTripDurationTimes(transitDetails)}
          />
        </div>
      </>
    );
  }
}

TransferRoute.propTypes = {
  t: PropTypes.func.isRequired,
  onOpenRouteSchedule: PropTypes.func.isRequired,
  route: PropTypes.shape(),
  isLast: PropTypes.bool,
  isFirst: PropTypes.bool,
  isConnecting: PropTypes.bool,
};

TransferRoute.defaultProps = {
  route: null,
  isFirst: false,
  isLast: false,
  isConnecting: false,
};

const mapDispatchToProps = (dispatch) => ({
  onOpenRouteSchedule: (route) => dispatch(openModal(<RouteSchedule route={route} />)),
});

export default connect(
  null,
  mapDispatchToProps,
)(withTranslation()(TransferRoute));
