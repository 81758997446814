import React, { useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import 'cookieconsent';
import 'cookieconsent/build/cookieconsent.min.css';
import './withCookiesConsent-VARIANT.scss';
import { deleteCookie } from '../../../core/utilities/cookies';
import { routes } from '../../../core/config/routes';
import Icon from '../../../components/presentational/Icon/Icon';

const withCookiesConsent = (Component) => (props) => {
  const { t, locale } = props;

  useEffect(() => {
    window.cookieconsent.initialise({
      layout: 'basic-close',
      type: 'opt-in',
      revokable: false,
      onStatusChange: (status) => {
        if (status === 'dismiss') deleteCookie('cookieconsent_status');
      },
      content: {
        close: ReactDOMServer.renderToStaticMarkup(<Icon icon="cancel" className="cc-close" />),
        message: `<span>${t('cc_cookies_text')} <a href=${t(routes.cms.privacyPolicy)} target="_blank" rel="noopener noreferrer">${t('cc_cookies_text_1')}</a> ${t('cc_cookies_text_2')}</span>`,
        allow: t('cc_cookies_agree'),
        deny: '',
        link: '',
      },
    });

    const allowBtn = document.querySelector('.cc-btn.cc-allow');
    const denyBtn = document.querySelector('.cc-btn.cc-deny');
    const exitBtn = document.querySelector('.cc-close');

    if (allowBtn) {
      allowBtn.setAttribute('aria-label', `${t('accept_cookies')}`);
      allowBtn.addEventListener('keydown', (event) => {
        if (event.keyCode === 13) {
          event.preventDefault();
          allowBtn.click();
        }
      });
    }

    if (denyBtn) {
      denyBtn.setAttribute('aria-label', `${t('exit_button')}`);
      denyBtn.addEventListener('keydown', (event) => {
        if (event.keyCode === 13) {
          event.preventDefault();
          denyBtn.click();
        }
      });
    }

    if (exitBtn) {
      exitBtn.setAttribute('aria-label', `${t('exit_button')}`);
      exitBtn.addEventListener('keydown', (event) => {
        if (event.keyCode === 13) {
          event.preventDefault();
          exitBtn.click();
        }
      });
    }

    return function cleanup() {
      const ccElement = document.querySelector('.cc-window');

      if (ccElement) {
        document.body.removeChild(ccElement);
      }
    };
  }, [locale]);

  return <Component {...props} />;
};

withCookiesConsent.propTypes = {
  Component: PropTypes.node.isRequired,
  locale: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default withCookiesConsent;
