import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../Icon/Icon';

const RouteLine = ({
  isFirst, isLast, travelMode, isConnecting,
}) => {
  if (!(isFirst && isLast)) {
    return (
      <div className={`transfer-route-line ${isConnecting ? 'is-dashed' : ''}`}>
        <div className={`transfer-route-line__half-bubble ${isFirst ? 'is-bottom' : 'is-top'}`} />

        <div className="transfer-route-line-icon">
          <Icon icon={travelMode === 'WALKING' ? 'walking' : 'bus'} />
        </div>

        {(isFirst || isLast) && (
          <div className={`transfer-route-line__bubble ${isLast ? 'is-bottom' : ''}`} />
        )}

        <div className={`transfer-route-line__half-bubble ${isLast ? 'is-top' : 'is-bottom'}`} />
      </div>
    );
  }

  return (
    <div className="transfer-route-line">
      <div className="transfer-route-line__bubble is-top" />

      <div className="transfer-route-line-icon">
        <Icon icon={travelMode === 'WALKING' ? 'walking' : 'bus'} />
      </div>

      <div className="transfer-route-line__bubble is-bottom" />
    </div>
  );
};

RouteLine.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  travelMode: PropTypes.string,
  isConnecting: PropTypes.bool,
};

RouteLine.defaultProps = {
  isFirst: false,
  isLast: false,
  travelMode: '',
  isConnecting: false,
};

export default RouteLine;
