import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import FieldContainer from '../FieldContainer/FieldContainer';
import './Input.scss';

const Input = ({
  id,
  value,
  name,
  label,
  type,
  disabled,
  loading,
  required,
  onChange,
  onBlur,
  error,
  placeholder,
  icon,
  container,
  rows,
  ariaLabel,
}) => {
  const Container = container || 'input';
  const inputRef = useRef(null);
  const onChangeWrapper = (e) => {
    if (!loading) onChange(e);
  };

  useEffect(() => {
    if (error && inputRef.current) {
      inputRef.current.focus();
    }
  }, [error]);

  return (
    <FieldContainer
      hidden={type === 'hidden'}
      error={error}
      disabled={disabled}
      id={id}
      label={label}
      required={required}
    >
      <Container
        ref={inputRef}
        className={`form-input ${icon ? 'has-icon' : ''}`}
        type={type}
        value={value}
        name={name}
        placeholder={placeholder}
        id={id}
        required={required}
        disabled={disabled}
        onChange={onChangeWrapper}
        onBlur={onBlur}
        rows={rows}
        aria-label={ariaLabel}
        aria-invalid={!!error}
      />

      {!!icon && (
        <div className="input-icon" aria-hidden="true">
          {icon}
        </div>
      )}
    </FieldContainer>
  );
};

Input.propTypes = {
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  rows: PropTypes.number,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  error: PropTypes.string,
  container: PropTypes.string,
  placeholder: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  ariaLabel: PropTypes.string,
};

Input.defaultProps = {
  value: '',
  label: null,
  placeholder: '',
  id: null,
  rows: 1,
  container: null,
  onBlur: () => null,
  type: 'text',
  disabled: false,
  loading: false,
  required: false,
  error: null,
  icon: null,
  ariaLabel: '',
};

export default Input;
