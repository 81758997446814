import { constants } from '../constants';

export const getEurolinesTopRoutes = () => ({
  type: constants.eurolinesRoutes.GET_ROUTES,
  payload: {},
});

export const setEurolinesTopRoutes = (eurolinesTopRoutes) => ({
  type: constants.eurolinesRoutes.SET_ROUTES,
  payload: { eurolinesTopRoutes },
});

export const getEurolinesTopRoute = (slug) => ({
  type: constants.eurolinesRoutes.GET_ROUTE,
  payload: { slug },
});

export const setEurolinesTopRoute = (slug, eurolinesTopRoute) => ({
  type: constants.eurolinesRoutes.SET_ROUTE,
  payload: { slug, eurolinesTopRoute },
});
