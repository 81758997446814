export const focusOnError = (form, errors, recursing = false) => {
  const errorsEntries = Object.entries(errors);
  if (errorsEntries.length) {
    let errorKey = Object.entries(errors)[0][0];
    let errorValue = Object.entries(errors)[0][1];

    if (typeof errorValue === 'object') {
      const [nextErrorKey, nextErrorValue] = focusOnError(form, errorValue, true);

      errorKey = `${errorKey}.${nextErrorKey}`;
      errorValue = nextErrorValue;
    }

    if (recursing) return [errorKey, errorValue];

    const firstField = form.current.querySelector(`input[name='${errorKey}'], input[real-name='${errorKey}']`);

    if (firstField) firstField.focus();
  }

  return [];
};
