import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchForm from '../../../../Forms/SearchForm/SearchForm';
import SearchResultsFormView
from '../../../../../components/presentational/Forms/SearchResultsForm/SearchResultsForm';
import './Form.scss';
import { baseURL, endpoints } from '../../../../../core/config/endpoints';

const Form = ({
  isFormVisible, tablet, mobile, toggleForm, searchValues, onSubmit, t, onRemoveTickets, submitting,
}) => {
  const background = `${baseURL}${endpoints.GET_DIRECT_BANNER_URL_BY_ZONE.url.path('main_front_page', 'desktop')}`;

  return (
    <div
      className={`
    search-results-form-wrap
    ${(isFormVisible && tablet) ? 'form-visible' : ''}
  `}
      style={{
        backgroundImage: `url(${background})`,
      }}
    >
      <div className="search-form-title-wrap">
        <div className="search-form-title">
          {t('ticket_search_find_bus_trip')}
        </div>

        <div className="search-form-subtitle">
          {t('ticket_search_routes_lt_and_eu')}
        </div>
      </div>

      <SearchForm
        initialValues={searchValues}
        onSubmit={onSubmit}
        render={(props) => (
          <SearchResultsFormView
            {...props}
            tablet={tablet}
            mobile={mobile}
            onRemoveTickets={onRemoveTickets}
            disabled={submitting}
          />
        )}
      />

      <div
        role="button"
        tabIndex={0}
        onClick={toggleForm}
        className="search-results-form-toggle"
      >
        {!isFormVisible && <span>{t('navigation_route_search')}</span>}

        <div className="search-results-form-toggle__button" />
      </div>
    </div>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  toggleForm: PropTypes.func.isRequired,
  searchValues: PropTypes.shape(),
  isFormVisible: PropTypes.bool,
  tablet: PropTypes.bool,
  mobile: PropTypes.bool,
  submitting: PropTypes.bool,
  t: PropTypes.func.isRequired,
  onRemoveTickets: PropTypes.func.isRequired,
};

Form.defaultProps = {
  searchValues: {},
  isFormVisible: false,
  tablet: false,
  mobile: false,
  submitting: false,
};

export default withTranslation()(Form);
