import { constants } from '../../../constants';

export const getBannerByZone = (zone, searchValues) => ({
  type: constants.data.collections.banners.GET_BANNER_BY_ZONE,
  payload: {
    zone, searchValues,
  },
});

export const addBanner = (banner) => ({
  type: constants.data.collections.banners.ADD_BANNER,
  payload: {
    ...banner,
  },
});
