/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../../containers/common/App/App';
import './SwichButtonTheme.scss';

function ToggleThemeSwitch() {
  const { t } = useTranslation();
  const { setOpenVisabilityControl, openDisabilityControl } = useContext(ThemeContext);
  const toggleButtonsContainer = () => {
    setOpenVisabilityControl((curr) => (curr === 'closeButtonConatainer' ? '' : 'closeButtonConatainer'));
  };

  return (
    <button aria-label={t('disability_control_button')} className="disability-button" onClick={toggleButtonsContainer} aria-expanded={openDisabilityControl === 'closeButtonConatainer'}>
      <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_235_139)">
          <path d="M17.1925 17.1925C15.5544 18.4412 13.5596 19.133 11.5 19.1667C4.79171 19.1667 0.958374 11.5 0.958374 11.5C2.15044 9.27849 3.8038 7.33759 5.80754 5.8075M9.48754 4.06334C10.1472 3.90893 10.8226 3.83175 11.5 3.83334C18.2084 3.83334 22.0417 11.5 22.0417 11.5C21.46 12.5883 20.7662 13.6129 19.9717 14.5571M13.5317 13.5317C13.2685 13.8141 12.9511 14.0407 12.5984 14.1978C12.2458 14.355 11.8651 14.4395 11.479 14.4463C11.093 14.4531 10.7096 14.3821 10.3516 14.2375C9.9936 14.0929 9.6684 13.8777 9.39539 13.6047C9.12239 13.3316 8.90717 13.0064 8.76257 12.6485C8.61797 12.2905 8.54696 11.907 8.55377 11.521C8.56058 11.135 8.64508 10.7543 8.80221 10.4016C8.95935 10.0489 9.18591 9.73154 9.46837 9.46834" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M0.958374 0.958328L22.0417 22.0417" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0_235_139">
            <rect width="23" height="23" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </button>
  );
}

export default ToggleThemeSwitch;
