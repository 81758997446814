import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Formik } from 'formik';
import Alert from '../../../../../components/presentational/Alert/Alert';
import { getErrors } from '../../../../../core/utilities/formValidator';
import { validations } from '../../../../../core/config/form/validations';
import Input from '../../../../../components/presentational/FormElements/Input/Input';
import Button from '../../../../../components/presentational/Button/Button';
import { constants } from '../../../../../core/modules/constants';
import { endpoints } from '../../../../../core/config/endpoints';
import { registerUser } from '../../../../../core/modules/User/UserActions';
import success from '../../../../../../assets/images/success.png';
import './Sidebar.scss';
import Icon from '../../../../../components/presentational/Icon/Icon';
import variantConst from '../../../../../core/config/constants/variantConst/variantConst';

const Sidebar = ({
  dispatchRegisterUser,
  passwordError,
  isAuthenticated,
  registered,
  loading,
  email,
  name,
  t,
}) => {
  if (!registered && isAuthenticated) return null;

  const formRef = React.useRef(null);
  const [showPassword, setShowPassword] = React.useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);
  const onSubmit = (values) => {
    const { name: userName, email: userEmail, plainPassword } = values;

    dispatchRegisterUser(userName, userEmail, plainPassword);
  };

  return (
    <>
      <div className="tickets-sidebar-arrow" />

      <div className="tickets-sidebar">
        {registered && (
          <div className="tickets-registered">
            <img src={success} alt="Registration successful" />
            {t('user_registration_successful')}
          </div>
        )}

        {!registered && (
          <>
            <div className="tickets-sidebar__header">{t('ticket_sign_up')}</div>

            <div className="tickets-sidebar__subheader">{t(variantConst.ticketSignUpOnPage)}</div>

            <Alert
              variant="warning"
              heading={t('ticket_sign_up_benefits')}
              arrow="bottom"
            >
              <ul>
                <li>{t('ticket_sign_up_purchase_history')}</li>
                <li>{t('ticket_sign_up_return_change_date')}</li>
                <li>{t('ticket_sign_up_promotions')}</li>
              </ul>
            </Alert>

            <div className="tickets-sidebar-credentials">
              <div className="tickets-sidebar-email">
                <span className="tickets-sidebar-email__label">{t('common_email')}:</span>

                {email}
              </div>

              <Formik
                enableReinitialize
                validateOnChange={false}
                onSubmit={onSubmit}
                validate={(values) => getErrors(values, {
                  email: [validations.email],
                  plainPassword: [validations.required],
                }, formRef)}
                initialValues={{
                  email,
                  name,
                  plainPassword: '',
                }}
                render={(formProps) => {
                  const {
                    values,
                    handleSubmit,
                    setFieldValue,
                    errors,
                  } = formProps;
                  const { plainPassword } = values;

                  return (
                    <form
                      ref={formRef}
                      onSubmit={handleSubmit}
                    >
                      <Input
                        name="password"
                        label={t('login_password')}
                        type={showPassword ? 'text' : 'password'}
                        value={plainPassword}
                        onChange={(e) => setFieldValue('plainPassword', e.target.value)}
                        error={t(errors.plainPassword) || passwordError}
                        disabled={loading}
                        icon={(
                          <button
                            className="input-icon__button"
                            type="button"
                            onClick={toggleShowPassword}
                            disabled={loading}
                          >
                            <Icon icon="view" /> {t(showPassword ? 'login_hide' : 'login_show')}
                          </button>
                        )}
                      />

                      <Button
                        className="tickets-sidebar-submit"
                        disabled={loading || !plainPassword}
                        onClick={handleSubmit}
                      >
                        {t('common_save')}
                      </Button>
                    </form>
                  );
                }}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

Sidebar.propTypes = {
  dispatchRegisterUser: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  registered: PropTypes.bool,
  passwordError: PropTypes.string,
};

Sidebar.defaultProps = {
  loading: false,
  registered: false,
  passwordError: null,
};

const mapStateToProps = (state) => ({
  isAuthenticated: !!state.token.getIn([constants.token.type.USER, 'token']),
  loading: state.request.getIn([endpoints.REGISTER_USER.name, 'loading']),
  passwordError: state.request.getIn([endpoints.REGISTER_USER.name, 'error', 'fields', 'plainPassword']),
  registered: state.order.get('registered'),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchRegisterUser: (name, email, plainPassword) => (
    dispatch(registerUser(name, email, plainPassword))
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Sidebar));
