import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Button from '../../components/presentational/Button/Button';
import './EventTemplate-VARIANT.scss';
import Icon from '../../components/presentational/Icon/Icon';
import ContentBlock from '../pages/CMS/ContentBlocks/ContentBlock';
import SearchForm from '../Forms/SearchForm/SearchForm';
import EventSearchForm from '../../components/presentational/Forms/EventSearchForm/EventSearchForm';
import Select from '../../components/containers/FormElements/Select/Select';
import { getObjectNestedData } from '../../core/utilities/object';
import { setSearchValues, submitSearch } from '../../core/modules/Search/SearchActions';
import variantConst from '../../core/config/constants/variantConst/variantConst';
// eslint-disable-next-line import/extensions
import LightBox from './Partials/LightBox/LightBox-VARIANT';
import { breakpointSet } from '../../core/utilities/responsive';

const activeEventTemplate = ({
  onSetSearchValues,
  onInitiateSearch,
  activeEvent,
  t,
  displayLightBox,
}) => {
  const [selectedRoute, setSelectedRoute] = React.useState({ label: t('common_all_cities'), value: 'all-cities' });
  const [selectedRoutes, setSelectedRoutes] = React.useState(null);
  const [mobile, setMobile] = React.useState(breakpointSet('xs', 'sm'));
  const searchContainer = React.useRef();
  const search = React.useRef();

  const handleResize = () => {
    const newMobileValues = breakpointSet('xs', 'sm');

    if (mobile !== newMobileValues) {
      setMobile({
        mobile: newMobileValues,
      });
    }
  };

  const handleScroll = () => {
    const { current: container } = searchContainer;
    const { current: searchForm } = search;

    if (!mobile && searchContainer && search) {
      const containerHeight = container.getBoundingClientRect().height;
      const searchHeight = searchForm.getBoundingClientRect().height;
      const maxMargin = containerHeight - searchHeight - 40;
      const containerFromTop = container.getBoundingClientRect().top;
      const marginTop = Math.abs(containerFromTop);

      if (containerFromTop <= 0 && marginTop < maxMargin) {
        searchForm.style.transform = `translate3d(0, ${marginTop}px, 0)`;
      }

      if (containerFromTop <= 0 && marginTop >= maxMargin) {
        searchForm.style.transform = `translate3d(0, ${maxMargin}px, 0)`;
      }

      if (containerFromTop > 0) {
        searchForm.style.transform = 'translate3d(0, 0, 0)';
      }
    }
  };

  React.useEffect(() => {
    const appContainer = document.querySelector('.app-container');

    appContainer.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      appContainer.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [mobile, searchContainer, search]);

  const initialValues = {
    to: {
      name: getObjectNestedData(activeEvent.city, 'title'),
      number: getObjectNestedData(activeEvent.city, 'originId'),
    },
    departureDate: activeEvent.date ? activeEvent.date.split('T')[0] : new Date(),
    departureTime: '00:00',
  };

  const handleFormSubmit = (formData) => {
    const {
      departureDateObj,
      returnDateObj,
      departureDate,
      returnDate,
      ...values
    } = formData;

    const dateDeparture = departureDateObj && departureDateObj.toISOString().split('T')[0];
    const dateReturn = returnDateObj && returnDateObj.toISOString().split('T')[0];
    const { isBike: onlyBikes } = activeEvent;

    const searchValues = {
      ...values,
      departureDate: dateDeparture,
      returnDate: dateReturn,
      onlyBikes,
      shouldTargetBlank: true,
    };

    onSetSearchValues(searchValues);
    onInitiateSearch(searchValues);
  };

  const setRoutes = (option, allRoutes) => {
    const filteredRoutes = allRoutes.filter((routesArray) => { // eslint-disable-line
      return routesArray
        .filter((route) => route.fromStation.originId === option.value)
        .length;
    });

    if (option.value === 'all-cities') {
      setSelectedRoutes(allRoutes);

      return;
    }

    setSelectedRoutes(filteredRoutes);
  };

  const chunkRoutes = (allRoutes) => {
    const chunkedRoutes = [];
    let breakIndex = 0;

    allRoutes.forEach((route, i) => {
      const { title } = route.fromStation;

      if (i < allRoutes.length - 1) {
        const { title: nextTitle } = allRoutes[i + 1].fromStation;

        if (title !== nextTitle) {
          chunkedRoutes.push(allRoutes.slice(breakIndex, i + 1));

          breakIndex = i + 1;

          return;
        }
      }

      if (breakIndex === allRoutes.length - 1) {
        chunkedRoutes.push(allRoutes.slice(breakIndex));
      }
    });

    if (breakIndex < allRoutes.length - 1) {
      chunkedRoutes.push(allRoutes.slice(breakIndex));
    }

    return chunkedRoutes;
  };

  const getRoutesOptions = (activeEventRoutes) => {
    const filteredRoutes = activeEventRoutes
      .filter((event, index, self) => (
        self.map((e) => e.fromStation.title).indexOf(event.fromStation.title) === index));

    const selectOptions = filteredRoutes.map(({ fromStation }) => ({
      label: fromStation.title,
      value: fromStation.originId,
    }));

    selectOptions.unshift({ label: t('common_all_cities'), value: 'all-cities' });

    return selectOptions;
  };

  const selectOptions = getRoutesOptions(activeEvent.routes);
  const eventRoutes = (!!selectedRoute && selectedRoutes) || chunkRoutes(activeEvent.routes);

  return (
    <div className="event-page-wrap">
      <div className="event-page-header">
        <div className="event-page-header-top">
          {activeEvent.category && (
            <span>{activeEvent.category.title}</span>
          )}

          <Button variant="inline-link" route={t(variantConst.eventTemplateRouteBack)}>
            <Icon icon="left" />

            {t('login_go_back')}
          </Button>
        </div>

        {
          activeEvent.title && (
            <h1 className="event-page-header-bottom">
              {activeEvent.title}
            </h1>
          )
        }
      </div>

      <div className="event-wrap">
        <div className="event">
          <div className="event-content">
            {activeEvent && activeEvent.contentBlocks && (
              activeEvent.contentBlocks.map((block) => (
                <ContentBlock block={block} key={block.id} />
              ))
            )}
          </div>

          <div ref={searchContainer} className="event-sidebar-wrap">
            <div ref={search} className="event-sidebar">
              {activeEvent.place && (
                <div className="event-sidebar-header">
                  <Icon icon="location-pin" />

                  <div>
                    <div>{t('activeEvents_venue')}</div>
                    <div className="event-sidebar-header__location">{activeEvent.place}</div>
                  </div>
                </div>
              )}

              {!activeEvent.hasRoutes ? (
                <>
                  <div className="event-sidebar-title">
                    <h3 className="event-sidebar-main-title">{t('ticket_search_find_bus_trip')}</h3>
                  </div>

                  <div className="event-sidebar-form">
                    <SearchForm
                      initialValues={initialValues}
                      onSubmit={handleFormSubmit}
                      render={(props) => (
                        <EventSearchForm {...props} activeEvent={activeEvent} />
                      )}
                    />
                  </div>
                </>
              ) : (
                <div>
                  <div className="event-sidebar-select">
                    <Select
                      name="routes-select"
                      value={selectedRoute}
                      options={selectOptions}
                      onChange={(name, newValue) => {
                        setSelectedRoute(newValue);
                        setRoutes(newValue, chunkRoutes(activeEvent.routes));
                      }}
                    />
                  </div>

                  <div className="routes-list">
                    {eventRoutes.map((routesArray) => (
                      <div key={routesArray.id} className="routes-list-item">
                        <div className="routes-list-item-left">
                          <div className="routes-list-item-icon">
                            <Icon icon="bus" />
                          </div>
                        </div>

                        <div className="routes-list-item-content">
                          <div className="routes-list-item__title">
                            {!routesArray[0].backwardDateFrom ? (
                              <>
                                {routesArray[0].fromStation.title} -&nbsp;
                                {routesArray[0].toStation.title}
                              </>
                            ) : (
                              <>
                                {routesArray[0].fromStation.title} -&nbsp;
                                {routesArray[0].toStation.title} -&nbsp;
                                {routesArray[0].fromStation.title}
                              </>
                            )}
                          </div>

                          <div className="routes-list-item-info-wrap">
                            {routesArray.map(({
                              displayUrl,
                              url,
                              timeFrom,
                              timeTo,
                              dateFrom,
                              notice,
                              toStation,
                              fromStation,
                              backwardDateFrom,
                              backwardTimeFrom,
                            }) => (
                              <div key={toStation.originId} className="routes-list-item-info">
                                {timeFrom && (
                                  <div>
                                    <div>
                                      <b>{timeFrom}</b> &nbsp;
                                      {timeTo && ''}
                                    </div>

                                    {notice && (
                                      <div className="routes-list-item-info__notice">{notice}</div>
                                    )}
                                  </div>
                                )}

                                {displayUrl ? (
                                  <a href={url} className="button">{t('common_button_buy')}</a>
                                ) : (
                                  <Button onClick={() => handleFormSubmit({
                                    to: {
                                      name: getObjectNestedData(toStation, 'title'),
                                      number: getObjectNestedData(toStation, 'originId'),
                                    },
                                    from: {
                                      name: getObjectNestedData(fromStation, 'title'),
                                      number: getObjectNestedData(fromStation, 'originId'),
                                    },
                                    departureDate: dateFrom,
                                    departureTime: timeFrom,
                                    isForward: !backwardDateFrom,
                                    returnDate: backwardDateFrom,
                                    returnTime: backwardTimeFrom,
                                  })}
                                  >
                                    {t('common_button_buy')}
                                  </Button>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="event-facebook-link">
          <button
            onClick={() => {
              window.open(
                `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(window.location.href)}`,
                'facebook-share-dialog',
                'width=626,height=436',
              );
            }}
          >
            {t('activeEvents_invite_friends')}
          </button>
        </div>
      </div>

      {displayLightBox && <LightBox event={activeEvent} />}
    </div>
  );
};

activeEventTemplate.propTypes = {
  activeEvent: PropTypes.shape(),
  onSetSearchValues: PropTypes.func.isRequired,
  onInitiateSearch: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  displayLightBox: PropTypes.bool,
};

activeEventTemplate.defaultProps = {
  activeEvent: {},
  displayLightBox: false,
};

const mapDispatchToProps = (dispatch) => ({
  onInitiateSearch: (formData) => dispatch(submitSearch(formData)),
  onSetSearchValues: (formData) => dispatch(setSearchValues(formData)),
});

export default connect(null, mapDispatchToProps)(
  (withTranslation())(activeEventTemplate),
);
