/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../../containers/common/App/App';
import './SwichButtonTheme.scss';
import './DislexiaButton.scss';
import './TextSizeButton.scss';
import Icon from '../Icon/Icon';

function DisabilityButtonsContainer() {
  const {
    printTheme,
    togglePrintTheme,
    setPrintTheme,
    openDisabilityControl,
    setOpenVisabilityControl,
    removeImages,
    removeAllImages,
    setRemoveImages,
  } = useContext(ThemeContext);

  const { t } = useTranslation();

  const [lastFocusedElement, setLastFocusedElement] = useState(null);
  const [fontSize, setFontSize] = useState(localStorage.getItem('fontSize'));
  const [theme, setTheme] = useState(localStorage.getItem('setTheme'));

  const containerRef = useRef(null);
  const switchButtonContainerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current && openDisabilityControl) {
      setLastFocusedElement(document.activeElement);
      containerRef.current.focus();
    }
  }, [openDisabilityControl]);

  const toggleButtonsContainer = () => {
    setOpenVisabilityControl((curr) => (curr === 'closeButtonConatainer' ? '' : 'closeButtonConatainer'));
    if (openDisabilityControl) {
      if (lastFocusedElement) {
        lastFocusedElement.focus();
        setLastFocusedElement(null);
      }
    }
  };

  const handleToggle = (newSize) => {
    localStorage.setItem('fontSize', (newSize));
    setFontSize(newSize);
  };

  const toggleDarkTheme = (item) => {
    localStorage.setItem('setTheme', item);
    setTheme(theme !== 'dark' ? 'dark' : '');
  };

  const toggleWhiteTheme = (item) => {
    localStorage.setItem('setTheme', item);
    setTheme(theme !== 'white' ? 'white' : '');
  };

  const resetTheme = () => {
    if (theme !== '') {
      setTheme('');
      localStorage.removeItem('setTheme');
    }

    if (fontSize !== '') {
      setFontSize('');
      localStorage.removeItem('fontSize');
    }

    if (removeImages !== '') {
      setRemoveImages('');
      localStorage.removeItem('removImageTheme');
    }

    if (printTheme !== '') {
      setPrintTheme('');
      localStorage.removeItem('setDyslexiaTheme');
    }
  };

  useEffect(() => {
    document.querySelector('html').style.fontSize = fontSize;
    document.querySelector('#app').classList.toggle('large-font-theme', fontSize === '19px');
    document.querySelector('#app').classList.toggle('medium-font-theme', fontSize === '17px' || fontSize === '18px');
    document.querySelector('body').classList.toggle('dark', theme === 'dark');
    document.querySelector('body').classList.toggle('white', theme === 'white');
  }, [fontSize, theme]);

  useEffect(() => {
    const switchButtonContainer = switchButtonContainerRef.current;
    const focusableElements = switchButtonContainer.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex=\'-1\'])',
    );

    const firstFocusableElement = focusableElements[0];
    const lastFocusableElement = focusableElements[focusableElements.length - 1];

    const handleKeyDown = (event) => {
      if (event.key === 'Tab') {
        if (event.shiftKey) {
          if (document.activeElement === firstFocusableElement) {
            event.preventDefault();
            lastFocusableElement.focus();
          }
        } else if (document.activeElement === lastFocusableElement) {
          event.preventDefault();
          firstFocusableElement.focus();
        }
      }
    };

    switchButtonContainer.addEventListener('keydown', handleKeyDown);

    return () => {
      switchButtonContainer.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div ref={switchButtonContainerRef} tabIndex={-1} className={`swich-button-container ${openDisabilityControl}`}>
      <div className="disabilities-container-header">
        <h3 aria-label={t('accessibility_container_title_label')} tabIndex={-1} ref={containerRef} className="disabilities-container-title">
          {t('accessibility_container_title')}
        </h3>
        <button aria-label={t('exit_from_modal')} className="close-disabilities-container" onClick={toggleButtonsContainer}>
          <Icon icon="cancel" width="18px" height="18px" />
        </button>
      </div>

      <div className="button-select-container">
        <h4 className="button-select-container-title">{t('accessibility_container_swich_title')}</h4>
        <div>

          <div className="accessibility-swich-button">
            <div className="swich-button-box">
              <label className="switch">
                <input
                  aria-label={t('button_hide_img')}
                  type="checkbox"
                  checked={removeImages === 'remove-images-theme'}
                  onChange={() => removeAllImages(removeImages !== 'remove-images-theme' ? 'remove-images-theme' : '')}
                />
                <span className="slider round" />
              </label>
            </div>
            <span className="swich-button-title">{t('button_hide_img')}</span>
          </div>

          <div className="accessibility-swich-button switch-dislexia-button-container">
            <div className="switch-dislexia-button-box">
              <label className="switch switch-dislexia-button">
                <input
                  aria-label={t('button_dyslexia')}
                  type="checkbox"
                  checked={printTheme === 'dyslexia-theme'}
                  onChange={() => togglePrintTheme(printTheme !== 'dyslexia-theme' ? 'dyslexia-theme' : '')}
                />
                <span className="slider round" />
              </label>
            </div>
            <span className="swich-button-title">{t('button_dyslexia')}</span>
          </div>

          <div className="theme-select-container">
            <h4 className="theme-select-container-title">{t('accessibility_font_size_title')}</h4>

            <div className="theme-select-button-container">
              <button
                aria-label={t('button_size_small')}
                className={`text-size-button regular-text-size ${fontSize === '17px' ? 'buton-is-active' : ''}`}
                type="button"
                aria-pressed={fontSize === '17px'}
                onClick={() => handleToggle('17px')}
              >A
              </button>

              <button
                aria-label={t('button_size_medium')}
                className={`text-size-button midle-text-size ${fontSize === '18px' ? 'buton-is-active' : ''}`}
                type="button"
                aria-pressed={fontSize === '18px'}
                onClick={() => handleToggle('18px')}
              >A
              </button>

              <button
                aria-label={t('button_size_large')}
                className={`text-size-button large-text-size ${fontSize === '19px' ? 'buton-is-active' : ''}`}
                type="button"
                aria-pressed={fontSize === '19px'}
                onClick={() => handleToggle('19px')}
              >A
              </button>
            </div>
          </div>

          <div className="theme-select-container">
            <h4 className="theme-select-container-title">{t('accessibility_theme_title')}</h4>

            <div className="theme-select-button-container">
              <button
                aria-label={t('background_color_black')}
                className={`theme-button ${theme === 'dark' ? 'buton-is-active' : ''}`}
                type="button"
                aria-pressed={theme === 'dark'}
                onClick={() => toggleDarkTheme(theme !== 'dark' ? 'dark' : '')}
              >
                {t('button_black')}
              </button>

              <button
                aria-label={t('background_color_white')}
                className={`theme-button ${theme === 'white' ? 'buton-is-active' : ''}`}
                type="button"
                aria-pressed={theme === 'white'}
                onClick={() => toggleWhiteTheme(theme !== 'white' ? 'white' : '')}
              >
                {t('button_white')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="reset-theme-button-container">
        <button className="reset-theme-button" onClick={resetTheme}>{t('button_remove_selection')}</button>
      </div>
    </div>
  );
}

export default DisabilityButtonsContainer;
