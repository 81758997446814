import { List } from 'immutable';
import { constants } from '../constants';

const initialState = List([]);

const StopsReducer = (state = initialState, action) => {
  switch (action.type) {
  case constants.data.collections.stops.SET_STOPS_LIST:
    return List(action.payload.data);
    
  default:
    return state;
  }
};

export default StopsReducer;
