import numeral from 'numeral';

numeral.register('locale', 'lt', {
  delimiters: {
    thousands: ' ',
    decimal: ',',
  },
  ordinal: () => '.',
  currency: {
    symbol: '€',
  },
});
numeral.locale('lt');

export const formatMoney = (value) => numeral(value / 100).format('0,0.00.[00] $');
