import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {
  selectOrderTicketType,
} from '../../../../../core/modules/Order/OrderActions';
import Button from '../../../../../components/presentational/Button/Button';
import './FamilyDiscount-ab.scss';
import Icon from '../../../../../components/presentational/Icon/Icon';

const FamilyDiscountAb = ({
  t,
  dispatchSelectTicketType,
  loading,
  order,
}) => {
  const {
    hash, canApplyFamilyDiscount, familyTicket,
  } = order;

  if (!canApplyFamilyDiscount) return null;

  const [conditionsOpen, setConditionsOpen] = React.useState(false);
  const toggleConditions = () => setConditionsOpen(!conditionsOpen);
  const chooseFamilyDiscount = () => dispatchSelectTicketType(hash, 3);
  const cancelFamilyDiscount = () => dispatchSelectTicketType(hash, 1);
  const tc3 = t('checkout_family_ticket_info_description_3');
  const tc4 = t('checkout_family_ticket_info_description_4');
  const hasMoreConditions = tc3 && tc4;
  const familyTicketMoreUrl = t('url_cms_family_ticket');
  const learnMoreLink = familyTicketMoreUrl
    ? <Link to={t('url_cms_family_ticket')} target="_blank" className="checkout-family-learn-more">{t('checkout_learn_more')}</Link>
    : null;

  return (
    <div className="checkout-family">
      <div className={`checkout-family-container ${conditionsOpen ? 'is-open' : ''}`}>
        <Icon icon="family" className="family-icon" />

        <div className="checkout-family-conditions-container">
          <div className="checkout-family-conditions-label">
            {t('checkout_family_ticket_info_title')} <span className="highlight">{t('checkout_family_ticket_info_discount')}</span>
          </div>

          {conditionsOpen && (
            <div className="checkout-family-conditions">
              <div>{t('checkout_family_ticket_info_description_1')}</div>

              <div>
                {t('checkout_family_ticket_info_description_2')}
                {!hasMoreConditions && (
                  <>
                    {' '}
                    {learnMoreLink}
                  </>
                )}
              </div>

              {hasMoreConditions && (
                <>
                  <div>{tc3}</div>

                  <div>
                    {tc4}
                    {' '}
                    {learnMoreLink}
                  </div>
                </>
              )}
            </div>
          )}

          <div className="checkout-family-conditions-switch-container">
            <Button
              variant="inline-link-dotted"
              className="checkout-family-conditions-switch"
              onClick={toggleConditions}
              ariaExpanded={conditionsOpen}
              small
            >
              <span>
                {t(conditionsOpen ? 'common_less' : 'common_all_conditions')}
                <Icon icon={`chevron-${conditionsOpen ? 'up' : 'down'}`} />
              </span>
            </Button>
          </div>
        </div>

        <div className="checkout-family-spacer" />

        {!familyTicket && (
          <div className="checkout-family-quarter-length-container">
            <Button
              disabled={loading}
              variant="white"
              onClick={chooseFamilyDiscount}
              className={`checkout-family-trigger ${conditionsOpen ? 'is-open' : ''}`}
              small
              ariaLabel={t('choose_this_link_title')}
            >
              {t('common_choose') === ''
                ? <Icon icon="chevron-down" />
                : t('common_choose')}
            </Button>
          </div>
        )}

        {familyTicket && (
          <div className="checkout-family-quarter-length-container">
            <Button
              disabled={loading}
              onClick={cancelFamilyDiscount}
              className={`checkout-family-trigger ${conditionsOpen ? 'is-open' : ''}`}
              variant="danger"
              small
            >
              <Icon icon="cancel" />

              {t('checkout_deselect_family_ticket')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

FamilyDiscountAb.propTypes = {
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  order: PropTypes.shape().isRequired,
  dispatchSelectTicketType: PropTypes.func.isRequired,
};

FamilyDiscountAb.defaultProps = {
  loading: false,
};

const mapStateToProps = (state) => ({
  loading: state.order.getIn(['status', 'loading']),
  order: state.order.get('data'),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSelectTicketType: (orderHash, ticketType) => (
    dispatch(selectOrderTicketType(orderHash, ticketType))
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(FamilyDiscountAb));
