import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import withDocumentHead from '../../common/DocumentHead/withDocumentHead';
import ETicketLoginItem from '../../../components/presentational/ETicketLoginItem/ETicketLoginItem';
import './ETicketLogin.scss';
import { openModal } from '../../../core/modules/Components/Modal/ModalActions';
import Login from '../ETicket/Partials/Login/Login';
import { eTicketLoginData } from '../../../core/dummyData/loginData';

const mapDispatchToProps = (dispatch) => ({
  onOpenLoginModal: (cityId, pinType) => dispatch(
    openModal(<Login cityId={cityId} pinType={pinType} />),
  ),
});

const ETicketLogin = ({ onOpenLoginModal }) => (
  <div className="eticket-login">
    {eTicketLoginData.map((ticket, index) => (
      <ETicketLoginItem
        key={index}
        imageSrc={ticket.imageSrc}
        imageAlt={ticket.imageAlt}
        title={ticket.title}
        onLoginButtonClick={() => onOpenLoginModal(ticket.id, ticket.pinType)}
      />
    ))}
  </div>
);

ETicketLogin.propTypes = {
  onOpenLoginModal: PropTypes.func.isRequired,
};

export default connect(
  null,
  mapDispatchToProps,
)(withTranslation()(withDocumentHead(ETicketLogin)));
