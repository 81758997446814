import {
  call,
  all,
  takeLatest,
  put,
} from 'redux-saga/effects';
import { constants } from '../constants';
import { startRequest } from '../Request/RequestSaga';
import { endpoints } from '../../config/endpoints';
import { redirect } from '../../utilities/routing';
import { history } from '../../historyAndStore';
import { getLink, addQueryToLink, routes } from '../../config/routes';
import i18n from '../../../../i18next';
import { getObjectNestedData } from '../../utilities/object';
import { setTickets } from './TicketSearchActions';

function* findByTicket(action) {
  const { ticketNr, email } = action.payload;

  const { response } = yield call(
    startRequest,
    endpoints.FIND_TICKET_BY_NUMBER.name,
    endpoints.FIND_TICKET_BY_NUMBER.url.path(),
    { ticketNr, email },
  );

  if (response) {
    redirect(
      history,
      getLink(i18n.t(routes.tickets.index), { orderHash: response.data.data.hash }),
    );
  }
}

function* findByTravel(action) {
  const { destination, name, date } = action.payload;

  const { response } = yield call(
    startRequest,
    endpoints.FIND_TICKET_BY_TRAVEL.name,
    endpoints.FIND_TICKET_BY_TRAVEL.url.path(),
    { destination, name, date },
  );

  if (response) {
    const data = getObjectNestedData(response, 'data.data');
    const hasMultipleTickets = data.length > 1;

    if (hasMultipleTickets) {
      yield put(setTickets(data));

      redirect(
        history,
        addQueryToLink(
          i18n.t(routes.ticketSearchByTravel.index),
          {
            destination,
            name,
            date,
          },
        ),
      );

      return;
    }

    redirect(
      history,
      getLink(i18n.t(routes.tickets.index), { orderHash: data[0].hash }),
    );
  }
}

export default function* TicketSearchSaga() {
  yield all([
    takeLatest(constants.ticketSearch.FIND_BY_TICKET, findByTicket),
    takeLatest(constants.ticketSearch.FIND_BY_TRAVEL, findByTravel),
  ]);
}
