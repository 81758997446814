import React from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';
import FieldContainer from '../FieldContainer/FieldContainer';
import './Select-VARIANT.scss';
import Icon from '../../Icon/Icon';

const Select = ({
  id,
  value,
  onTop,
  options,
  name,
  disabled,
  multi,
  required,
  onChange,
  onBlur,
  error,
  placeholder,
  menuOpened,
  onMenuOpen,
  onMenuClose,
  outerRef,
}) => (
  <FieldContainer error={error} disabled={disabled} outerRef={outerRef}>
    <ReactSelect
      className={`select ${menuOpened ? 'is-opened' : ''} ${onTop ? 'is-on-top' : ''}`}
      classNamePrefix="select"
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      id={id}
      name={name}
      isDisabled={disabled}
      options={options}
      isSearchable={false}
      isMulti={multi}
      placeholder={placeholder}
      required={required}
      onChange={(newValue) => onChange(name, newValue)}
      onBlur={() => onBlur(name, true)}
      value={value}
      components={{
        DropdownIndicator: () => (
          <div className="select__indicator">
            <Icon icon="arrow-down" />
          </div>
        ),
      }}
    />
  </FieldContainer>
);

Select.propTypes = {
  id: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape(),
    PropTypes.array,
  ]),
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape()),
  multi: PropTypes.bool,
  onTop: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  menuOpened: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onMenuOpen: PropTypes.func,
  onMenuClose: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.string,
  outerRef: PropTypes.shape(),
};

Select.defaultProps = {
  outerRef: null,
  value: '',
  id: null,
  onBlur: () => null,
  onMenuOpen: () => null,
  onMenuClose: () => null,
  disabled: false,
  menuOpened: false,
  onTop: false,
  required: false,
  multi: false,
  options: [],
  error: null,
  placeholder: '',
};

export default Select;
