export const redirect = (history, link, state = null) => {
  history.push(link, state);
};
export const replace = (history, link, state = null) => {
  history.replace(link, state);
};

export const getPathListRecursively = (obj, routesList = []) => {
  let newRoutesList = [...routesList];
  Object.entries(obj).forEach((entry) => {
    if (typeof entry[1] === 'object') {
      newRoutesList = getPathListRecursively(entry[1], newRoutesList);
    } else {
      newRoutesList.push(entry[1]);
    }
  });
  return newRoutesList;
};
