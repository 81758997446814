import { List } from 'immutable';
import { constants } from '../constants';

const initialState = List([]);

const RoutesReducer = (state = initialState, action) => {
  switch (action.type) {
  case constants.data.collections.routes.SET_ROUTES_LIST:
    return List(action.payload.routes);
  
  default:
    return state;
  }
};

export default RoutesReducer;
