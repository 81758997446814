import { constants } from '../../modules/constants';

export const orderEndpoints = {
  ORDER_INITIALIZE: {
    name: 'ORDER_INITIALIZE',
    url: {
      method: 'POST',
      path: () => '/orders',
    },
    token: constants.token.type.USER,
  },
  PASSENGER_ADD: {
    name: 'PASSENGER_ADD',
    url: {
      method: 'POST',
      path: (orderHash) => `/orders/${orderHash}/items`,
    },
  },
  PASSENGER_REMOVE: {
    name: 'PASSENGER_REMOVE',
    url: {
      method: 'DELETE',
      path: (orderHash, passengerId) => `/orders/${orderHash}/items/${passengerId}`,
    },
  },
  PASSENGER_UPDATE_NAME: {
    name: 'PASSENGER_UPDATE_NAME',
    url: {
      method: 'PUT',
      path: (orderHash, passengerId) => `/orders/${orderHash}/items/${passengerId}`,
    },
  },
  PASSENGER_UPDATE_PART_DISCOUNT: {
    name: 'PASSENGER_UPDATE_PART_DISCOUNT',
    url: {
      method: 'PUT',
      path: (orderHash, passengerId, part) => `/orders/${orderHash}/items/${passengerId}/parts/${part}/discount`,
    },
  },
  ORDER_ADD_COUPON: {
    name: 'ORDER_ADD_COUPON',
    url: {
      method: 'POST',
      path: (orderHash) => `/orders/${orderHash}/coupons`,
    },
  },
  ORDER_REMOVE_COUPON: {
    name: 'ORDER_REMOVE_COUPON',
    url: {
      method: 'DELETE',
      path: (orderHash) => `/orders/${orderHash}/coupons`,
    },
  },
  ORDER_SELECT_PAYMENT_METHOD: {
    name: 'ORDER_SELECT_PAYMENT_METHOD',
    url: {
      method: 'POST',
      path: (orderHash) => `/orders/${orderHash}/payments`,
    },
  },
  ORDER_SELECT_TICKET_TYPE: {
    name: 'ORDER_SELECT_TICKET_TYPE',
    url: {
      method: 'PUT',
      path: (orderHash, ticketType) => `/orders/${orderHash}/ticketType/${ticketType}`,
    },
  },
  ORDER_ADD_BIKE: {
    name: 'ORDER_ADD_BIKE',
    url: {
      method: 'POST',
      path: (orderHash, passengerId, direction) => `/orders/${orderHash}/items/${passengerId}/bike/${direction}`,
    },
  },
  ORDER_REMOVE_BIKE: {
    name: 'ORDER_REMOVE_BIKE',
    url: {
      method: 'DELETE',
      path: (orderHash, passengerId, direction) => `/orders/${orderHash}/items/${passengerId}/bike/${direction}`,
    },
  },
  ORDER_SUBMIT: {
    name: 'ORDER_SUBMIT',
    url: {
      method: 'PUT',
      path: (orderHash) => `/orders/${orderHash}`,
    },
  },
  ORDER_GET: {
    name: 'ORDER_GET',
    url: {
      method: 'GET',
      path: (orderHash) => `/orders/${orderHash}`,
    },
  },
};
