import React from 'react';
import PropTypes from 'prop-types';
import SelectView from '../../../presentational/FormElements/Select/Select';

class Select extends React.Component {
  constructor() {
    super();
    
    this.select = React.createRef();
    
    this.state = {
      menuOpened: false,
      onTop: false,
    };
  }
  
  getPositioning = () => {
    const select = this.select.current;
    const selectOffsetY = select.getBoundingClientRect().y;
    const selectHeight = select.getBoundingClientRect().height;
    const screenHeight = window.innerHeight;
    const leftSpace = screenHeight - selectOffsetY - selectHeight;
    
    return leftSpace < 301;
  };
  
  onMenuOpen = () => {
    const onTop = this.getPositioning();
    
    this.setState({
      menuOpened: true,
      onTop,
    });
  };
  
  onMenuClose = () => {
    this.setState({
      menuOpened: false,
    });
  };
  
  render() {
    const { menuOpened, onTop } = this.state;
    
    return (
      <SelectView
        {...this.props}
        outerRef={this.select}
        onTop={onTop}
        menuOpened={menuOpened}
        onMenuOpen={this.onMenuOpen}
        onMenuClose={this.onMenuClose}
      />
    );
  }
}

Select.propTypes = {
  locale: PropTypes.bool,
  id: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape(),
    PropTypes.array,
  ]),
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape()),
  multi: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  inline: PropTypes.bool,
  hasColor: PropTypes.bool,
  creatable: PropTypes.bool,
  small: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  error: PropTypes.string,
  outerRef: PropTypes.shape(),
};

Select.defaultProps = {
  outerRef: null,
  locale: false,
  value: '',
  id: null,
  onBlur: () => null,
  disabled: false,
  required: false,
  inline: false,
  multi: false,
  hasColor: false,
  creatable: false,
  small: false,
  options: [],
  error: null,
  placeholder: 'Select',
};

export default Select;
