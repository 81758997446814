import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const withDocumentHead = (Component) => (props) => {
  const [documentTitle, setDocumentTitle] = React.useState('');
  const [documentDescription, setDocumentDescription] = React.useState('');

  return (
    <>
      <Helmet>
        <title>{documentTitle}</title>

        {documentDescription !== '' && (
          <meta name="description" content={documentDescription} />
        )}

      </Helmet>

      <Component
        {...props}
        setDocumentTitle={setDocumentTitle}
        setDocumentDescription={setDocumentDescription}
      />
    </>
  );
};

withDocumentHead.propTypes = {
  Component: PropTypes.node.isRequired,
};

export default withDocumentHead;
