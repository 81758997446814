export const eurolinesRoutesEndpoints = {
  GET_EUROLINES_ROUTES: {
    name: 'GET_EUROLINES_ROUTES',
    url: {
      method: 'GET',
      path: (limit = 30) => `/routes?limit=${limit}`,
    },
  },
  GET_EUROLINES_ROUTE: {
    name: 'GET_EUROLINES_ROUTE',
    url: {
      method: 'GET',
      path: (slug) => `/routes/${slug}`,
    },
  },
};
