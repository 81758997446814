import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import './TopUpHistory.scss';
import { formatDate, formatTime } from '../../../../../core/utilities/date';
import { formatMoney } from '../../../../../core/utilities/money';
import { getHistoryRecordsGroupedByYear } from '../../../../../core/utilities/groupByYear';
import { getCurrentTicket } from '../../../../../core/modules/ETicket/ETicketActions';
import IconContentContainer from '../../../../../components/presentational/Eticket/IconContentContainer';
import withDocumentHead from '../../../../common/DocumentHead/withDocumentHead';
import variantConst from '../../../../../core/config/constants/variantConst/variantConst';

const TopUpHisory = ({
  topUpHistory,
  t,
  type,
  dispatchGetCurrentTicket,
  setDocumentTitle,
  setDocumentDescription,
}) => {
  const isFirstType = type === 1;
  const ticketType = isFirstType ? 'is-tikis' : 'is-druskininkai';
  const topUpHistoryGroupedByYear = getHistoryRecordsGroupedByYear(topUpHistory);

  React.useEffect(() => {
    dispatchGetCurrentTicket();
  }, []);

  React.useEffect(() => {
    if (t) {
      setDocumentTitle(`${t(variantConst.documentTitle)} ${t('document_title_eticket_top_up_history')}`);

      setDocumentDescription(t('document_title_description_top_up_history'));
    }
  }, [t]);

  return (
    <>
      <h1 className="eTicket-top-up-history-title">
        {t('eTicket_replenishment_history')}
      </h1>

      {topUpHistoryGroupedByYear && Object.keys(topUpHistoryGroupedByYear).reverse().map((year) => (
        <Fragment key={year}>
          <div className="eTicket-divider">
            <div>{year}</div>

            <div className="divider" />
          </div>

          <ul className="eTicket-top-up-history-list">
            {topUpHistoryGroupedByYear[year].map((historyItem) => (
              <li
                key={historyItem.createdAt}
                className={`eTicket-top-up-history-list-item ${ticketType}`}
              >
                <div className={`eTicket-top-up-history-list-item__info ${ticketType}`}>
                  <div className={`eTicket-history-list-item__title ${ticketType}`}>{historyItem.title}</div>

                  <div className={`eTicket-history-list-item__dates ${ticketType}`}>
                    {!isFirstType && <>{t('eTicket_replenishment_data')}:</>}

                    <IconContentContainer icon="calendar">
                      {formatDate(isFirstType
                        ? historyItem.validFrom
                        : historyItem.createdAt)}
                    </IconContentContainer>

                    <IconContentContainer icon={isFirstType ? 'calendar' : 'clock'}>
                      {isFirstType
                        ? formatDate(historyItem.validTo)
                        : formatTime(historyItem.createdAt)}
                    </IconContentContainer>
                  </div>
                </div>

                { isFirstType && (
                  <div className="eTicket-history-list-item__time is-hidden-mobile">
                    <IconContentContainer icon="calendar">
                      {formatDate(historyItem.createdAt)}
                    </IconContentContainer>

                    <IconContentContainer icon="clock">
                      {formatTime(historyItem.createdAt)}
                    </IconContentContainer>
                  </div>
                )}

                <div className={`eTicket-top-up-history-list-item__amount ${ticketType}`}>

                  <div className="eTicket-history-list-item__time is-hidden-desktop">
                    {!isFirstType && t('eTicket_replenishment_data')}
                    {!isFirstType && ':'}

                    <IconContentContainer icon="calendar">
                      {formatDate(historyItem.createdAt)}
                    </IconContentContainer>

                    <IconContentContainer icon="clock">
                      {formatTime(historyItem.createdAt)}
                    </IconContentContainer>
                  </div>

                  <div className="eTicket-top-up-history-list-item__price">
                    {
                      historyItem.discount && (
                        <div className="discount">
                          {historyItem.discount}
                        </div>
                      )
                    }

                    <div className="amount">
                      {formatMoney(historyItem.amount)}
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </Fragment>
      ))}
    </>
  );
};

TopUpHisory.propTypes = {
  dispatchGetCurrentTicket: PropTypes.func.isRequired,
  topUpHistory: PropTypes.arrayOf(PropTypes.shape()),
  t: PropTypes.func.isRequired,
  setDocumentTitle: PropTypes.func.isRequired,
  setDocumentDescription: PropTypes.func.isRequired,
  type: PropTypes.number,
};

TopUpHisory.defaultProps = {
  topUpHistory: [],
  type: null,
};

const mapStateToProps = (state) => ({
  topUpHistory: state.eTicket.getIn(['cardContent', 'servicesHistory']),
  type: state.eTicket.getIn(['cardContent', 'type']),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchGetCurrentTicket: () => dispatch(getCurrentTicket()),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(withDocumentHead(TopUpHisory)),
);
