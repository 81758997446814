import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { formatMoney } from '../../../../../core/utilities/money';
import Coupon from '../Coupon/Coupon';
import { removeCoupon } from '../../../../../core/modules/Order/OrderActions';
import './Sidebar.scss';
import { tripDirections } from '../../../../../core/config/constants/tickets';
import Preloader from '../../../../../components/presentational/Preloader/Preloader';
import { endpoints } from '../../../../../core/config/endpoints';
import Icon from '../../../../../components/presentational/Icon/Icon';
import ButtonClose from '../../../../../components/presentational/ButtonClose/ButtonClose';
import Button from '../../../../../components/presentational/Button/Button';

const Sidebar = ({
  t,
  dispatchRemoveCoupon,
  order,
  loading,
  submitting,
  removingCoupon,
}) => {
  if (!order) return null;

  const {
    hash,
    fullTravelName,
    travelTrips,
    itemsCount,
    bikesCount,
    discounts,
    originalPrice,
    total,
  } = order;
  const [menuIsOpen, setMenuOpen] = React.useState(false);
  const [contentIsHidden, setContentIsHidden] = React.useState(true);
  const toggleMenuOpen = () => setMenuOpen(!menuIsOpen);
  const toggleContentHidden = () => setContentIsHidden(!contentIsHidden);
  const onRemoveCoupon = () => dispatchRemoveCoupon(hash);
  const orderIsMultiDirection = travelTrips.forward && travelTrips.backward;
  const forwardIsMultipart = travelTrips.forward && travelTrips.forward.trips.length > 1;
  const backwardIsMultipart = travelTrips.backward && travelTrips.backward.trips.length > 1;
  const orderIsMultiPart = forwardIsMultipart || backwardIsMultipart;
  const orderIsFullySinglePart = !forwardIsMultipart && !backwardIsMultipart;

  return (
    <div className="checkout-sidebar-wrapper">
      <div className="checkout-sidebar">
        <h2 className="checkout-sidebar__header">
          {t('checkout_trip_details')}
        </h2>

        <div className="checkout-sidebar__alt-header">
          {fullTravelName}
        </div>

        <div className={`checkout-sidebar-content-wrapper ${menuIsOpen ? 'is-open' : ''}`}>
          {(!orderIsFullySinglePart || orderIsMultiPart) && (
            <div className="checkout-sidebar-content-top">
              {!orderIsFullySinglePart && <h3 className="checkout-sidebar-content-header">{fullTravelName}</h3>}

              {orderIsMultiPart && (
                <div>
                  <Button className="checkout-sidebar-content-toggle" variant="inline-link-dotted" onClick={toggleContentHidden}>
                    <span>
                      {t('checkout_route_information')}

                      <Icon icon={`chevron-${contentIsHidden ? 'down' : 'up'}`} />
                    </span>
                  </Button>
                </div>
              )}
            </div>
          )}

          {(!orderIsMultiPart || !contentIsHidden) && tripDirections.map((direction) => {
            const travelTrip = travelTrips[direction];

            if (!travelTrip) return null;

            const {
              displayRoute,
              name,
              trips,
              departureDate,
              departureTime,
              arrivalDate,
              arrivalTime,
              carrier,
              duration,
            } = travelTrip;

            return (
              <div
                className={`
                  checkout-sidebar-content 
                  ${orderIsFullySinglePart ? 'is-fully-single-part' : ''}
                  ${orderIsMultiDirection && orderIsFullySinglePart ? '' : 'arrow'}
                `}
                key={direction}
              >
                {orderIsFullySinglePart && <h3 className="checkout-sidebar-content-header">{name}</h3>}

                {!orderIsMultiPart && (
                  <div className="checkout-sidebar-content-route-name">
                    {t('checkout_order_trip')} {name}
                  </div>
                )}

                {orderIsMultiPart && (
                  <div className="checkout-sidebar-trip-info">
                    <div className="checkout-sidebar-trip-info__label">
                      {t(`checkout_trip_${direction}`)}
                    </div>

                    <div className="checkout-sidebar-trip-info__title">
                      {name}
                    </div>

                    {displayRoute && (
                      <div className="checkout-sidebar-content-route-name">
                        {t('checkout_order_trip')} {name}
                      </div>
                    )}

                    {!displayRoute && (
                      <div className="checkout-sidebar-trips-info">
                        {trips.map((trip, tripIndex) => {
                          const {
                            departureTimeString,
                            fromStopName,
                            arrivalTimeString,
                            toStopName,
                            tripId,
                          } = trip;
                          const middleCircle = tripIndex !== 0
                            && tripIndex < (trips.length - 1);

                          return (
                            <div className="checkout-sidebar-trip-info-single-trip" key={tripId}>
                              <div className={`circle ${middleCircle ? 'middle' : ''}`} />

                              <b>{departureTimeString} </b>

                              {fromStopName}

                              <Icon icon="long-arrow-right" />

                              <b> {arrivalTimeString} </b>

                              {toStopName}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}

                <div className="checkout-sidebar-content-route-times">
                  <div className="checkout-sidebar-content-route-time">
                    <div className="checkout-sidebar-content-route-time-left">
                      <div className="checkout-sidebar-content-route-time__label">{t('checkout_order_departure')}:</div>

                      <div className="checkout-sidebar-content-route-time__date">{departureDate}</div>
                    </div>

                    <div className="checkout-sidebar-content-route-time-right">
                      <Icon icon="clock" />
                      {departureTime}
                    </div>
                  </div>

                  <div className="checkout-sidebar-content-route-time">
                    <div className="checkout-sidebar-content-route-time-left">
                      <div className="checkout-sidebar-content-route-time__label">{t('checkout_order_return')}:</div>

                      <div className="checkout-sidebar-content-route-time__date">{arrivalDate}</div>
                    </div>

                    <div className="checkout-sidebar-content-route-time-right">
                      <Icon icon="clock" />
                      {arrivalTime}
                    </div>
                  </div>
                </div>

                <div className="checkout-sidebar-content-property">
                  <div className="checkout-sidebar-content-property-label">{t('checkout_order_trip_duration')}</div>

                  <div className="checkout-sidebar-content-property-value bold">{duration}</div>
                </div>

                <div className="checkout-sidebar-content-property">
                  <div className="checkout-sidebar-content-property-label">{t('checkout_order_carrier')}</div>

                  <div className="checkout-sidebar-content-property-value">{carrier}</div>
                </div>
              </div>
            );
          })}

          <div className="checkout-sidebar-route-property checkout-sidebar-count">
            <div className="checkout-sidebar-route-property-label">
              {t('checkout_order_passengers')}
            </div>

            <div className="checkout-sidebar-route-property-value center">
              {itemsCount}
            </div>
          </div>

          {!!bikesCount && (
            <div className="checkout-sidebar-route-property checkout-sidebar-count">
              <div className="checkout-sidebar-route-property-label">
                {t('checkout_bikes')}:
              </div>

              <div className="checkout-sidebar-route-property-value center">
                {bikesCount}
              </div>
            </div>
          )}

          <div className="checkout-sidebar-passengers-prices">
            <div className="checkout-sidebar-route-property">
              <div className="checkout-sidebar-route-property-label">
                {t('checkout_order_sum')}
              </div>

              <div className="checkout-sidebar-route-property-value right">
                {formatMoney(originalPrice)}
              </div>
            </div>

            {discounts.map((discount) => (
              <div className="checkout-sidebar-route-property" key={discount.name}>
                <div className="checkout-sidebar-route-property-label">
                  {discount.name}

                  {discount.type === 'coupon' && (
                    <p className="discount-coupone-message">
                      {t('discount_coupon_message')} 
                      <ButtonClose
                        onClick={onRemoveCoupon}
                        className="checkout-sidebar-remove-coupon"
                        disabled={removingCoupon}
                      />
                    </p>
                  )}
                </div>

                <div className="checkout-sidebar-route-property-value right">
                  {formatMoney(discount.price)}
                </div>
              </div>
            ))}
          </div>

          <div className="checkout-sidebar-to-pay">
            <div className="checkout-sidebar-to-pay__label">
              {t('checkout_order_to_pay')}
            </div>

            <div className="checkout-sidebar-to-pay__value">
              {loading && !submitting
                ? <Preloader className="checkout-sidebar-preloader" fullWidth />
                : formatMoney(total)}
            </div>
          </div>
        </div>

        <div className="checkout-sidebar-total-to-pay">
          {t('checkout_total_to_pay')}:&nbsp;
          {loading && !submitting
            ? <Preloader className="checkout-sidebar-preloader" />
            : formatMoney(total)}

          <div
            className="checkout-sidebar-total-to-pay-button"
            onClick={toggleMenuOpen}
            role="button"
            tabIndex={0}
          >
            <Icon icon={`chevron-${menuIsOpen ? 'up' : 'down'}`} />
          </div>
        </div>

        <Coupon />
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  t: PropTypes.func.isRequired,
  dispatchRemoveCoupon: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  removingCoupon: PropTypes.bool,
  order: PropTypes.shape(),
};

Sidebar.defaultProps = {
  loading: false,
  submitting: false,
  removingCoupon: false,
  order: null,
};

const mapStateToProps = (state, props) => ({
  loading: state.order.getIn(['status', 'loading']),
  submitting: state.request.getIn([endpoints.ORDER_SUBMIT.name, 'loading']),
  removingCoupon: state.request.getIn([endpoints.ORDER_REMOVE_COUPON.name, 'loading']),
  sidebar: state.order.getIn(['data', 'items', props.index]),
  order: state.order.get('data'),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchRemoveCoupon: (orderHash) => (
    dispatch(removeCoupon(orderHash))
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Sidebar));
