import { Map } from 'immutable';
import { constants } from '../../constants';

const initialState = Map();

const RouteMapReducer = (state = initialState, action) => {
  switch (action.type) {
  case constants.components.routeMap.SET_POLYLINES:
  {
    const { trackId, direction, polylines } = action.payload;

    return state.setIn([trackId, direction], polylines);
  }

  default:
    return state;
  }
};

export default RouteMapReducer;
