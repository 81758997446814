import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { dateDiffInDays } from '../../../core/utilities/date';
import SearchResultsRouteView from '../../presentational/SearchResultsRoute/SearchResultsRoute';
import { openModal } from '../../../core/modules/Components/Modal/ModalActions';
import RouteSchedule from '../../../containers/modals/RouteSchedule/RouteSchedule';

class SearchResultsRoute extends React.Component {
  constructor() {
    super();

    this.state = {
      transferDropdownActive: false,
    };
  }

  toggleTransferDropdown = () => {
    this.setState((prevState) => ({
      transferDropdownActive: !prevState.transferDropdownActive,
    }));
  };

  getTripDurationTimes = (route) => {
    const { departureTime, arrivalTime } = route;
    const daysDiff = dateDiffInDays(new Date(departureTime.date), new Date(arrivalTime.date));

    return {
      departureTime: departureTime.text,
      arrivalTime: arrivalTime.text,
      daysDiff,
    };
  };

  render() {
    const {
      route, onOpenRouteSchedule, searchValues, exchangeButtonProps,
    } = this.props;
    const { transferDropdownActive } = this.state;

    if (!route) return null;

    return (
      <SearchResultsRouteView
        route={route}
        transferDropdownActive={transferDropdownActive}
        tripTimes={this.getTripDurationTimes(route)}
        toggleTransferDropdown={this.toggleTransferDropdown}
        openRouteSchedule={() => onOpenRouteSchedule(route)}
        searchValues={searchValues}
        exchangeButtonProps={exchangeButtonProps}
      />
    );
  }
}

SearchResultsRoute.propTypes = {
  route: PropTypes.shape(),
  onOpenRouteSchedule: PropTypes.func.isRequired,
  searchValues: PropTypes.shape(),
  exchangeButtonProps: PropTypes.shape(),
};

SearchResultsRoute.defaultProps = {
  route: null,
  searchValues: null,
  exchangeButtonProps: null,
};

const mapDispatchToProps = (dispatch) => ({
  onOpenRouteSchedule: (route) => dispatch(openModal(<RouteSchedule route={route} />)),
});

export default connect(
  null,
  mapDispatchToProps,
)(SearchResultsRoute);
