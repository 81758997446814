import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { routes } from '../../../core/config/routes';
import logo from '../../../../assets/logo/logo-VARIANT.png';
import LanguageDropdown from './Partials/LanguageDropdown/LanguageDropdown';
import HeaderButton from './Partials/HeaderButton/HeaderButton';
import Dropdown from '../../../components/presentational/Dropdown/Dropdown';
import Icon from '../../../components/presentational/Icon/Icon';
// eslint-disable-next-line import/extensions
import getHeaderItems from './getHeaderItems-VARIANT';
import { openModal } from '../../../core/modules/Components/Modal/ModalActions';
import Login from '../../modals/Login/Login';
import Contact from '../../modals/Contact/Contact';
import User from './Partials/User/User';
import { constants } from '../../../core/modules/constants';
import './Header-VARIANT.scss';
import { insert } from '../../../core/utilities/array';
import variantConst from '../../../core/config/constants/variantConst/variantConst';
import { breakpointSet } from '../../../core/utilities/responsive';
import MenuOverlay from './Partials/MenuOverlay/MenuOverlay';
import { closeMenuOverlay } from '../../../core/modules/Components/MenuOverlay/MenuOverlayActions';
import ToggleThemeSwitch from '../../../components/presentational/DisabilityButtons/SwichButtonTheme';

const Header = ({
  t,
  location,
  userIsAuthenticated,
  openLoginModal,
  openContactModal,
  defaultLocale,
  locale,
  displayMenuOverlay,
  dispatchCloseMenuOverlay,
}) => {
  const [dropdownKey, setDropdownKey] = React.useState(0);
  const [mobile, setMobile] = React.useState(false);
  const incrementDropdownKey = () => setDropdownKey((prevKey) => prevKey + 1);
  const onOpenLoginModal = (e) => {
    incrementDropdownKey();
    openLoginModal(e);
  };
  const onOpenContactModal = (e) => {
    incrementDropdownKey();
    openContactModal(e);
  };
  const onRouteClick = () => {
    incrementDropdownKey();
  };

  const menuItemLogin = {
    children: !userIsAuthenticated ? t('common_login') : <User />,
    className: `header-user-dropdown-container ${userIsAuthenticated ? variantConst.menuItemLoginClassName : ''
    }`,
    icon: variantConst.menuItemLoginIcon,
    onClick: !userIsAuthenticated ? onOpenLoginModal : undefined,
    id: 'user',
    position: 'left',
  };

  const items = getHeaderItems({
    t,
    onOpenContactModal,
    onRouteClick,
    userIsAuthenticated,
    onOpenLoginModal,
    defaultLocale,
    locale,
    User,
  });

  const handleResize = () => {
    const changesView = breakpointSet('sm', 'xs');

    setMobile(changesView);

    if (!changesView) dispatchCloseMenuOverlay();
  };

  React.useEffect(() => {
    setMobile(breakpointSet('sm', 'xs'));
  }, []);

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const menuItems = insert(items, 2, menuItemLogin);
  const checkLogo = logo === '/logo-ab.png' ? 'autobusubilietai.lt' : 'eurolines.lt';

  return (
    <header className="header-wrapper">
      <a className="screenreader-text" id="screenreader-text" href="#page-content">
        {t('screenreader_text')}
      </a>

      <div className="header">
        {!displayMenuOverlay && (
          <Link to={t(routes.homepage)} className="header-logo-link" aria-label={t('homepage_link')}>
            <img src={logo} alt={`${checkLogo} Logo`} className="header-logo" />
            <span className="logo-text">{checkLogo}</span>
          </Link>
        )}

        <nav className="header-menus">
          {!displayMenuOverlay && (
            <div className="header-left-buttons">
              {menuItems
                .filter((menuItem) => menuItem.position === 'left')
                .map((menuItem, i) => (
                  <HeaderButton key={i} {...menuItem} />
                ))}
            </div>
          )}
          <div className="disability-container-button-mobile">
            <ToggleThemeSwitch />
          </div>
          {mobile
            ? <MenuOverlay menuItems={menuItems} />
            : (
              <Dropdown
                key={dropdownKey}
                className="header-menu-dropdown"
                trigger={{
                  className: 'header-menu-dropdown-trigger',
                  children: <Icon icon="hamburger" />,
                }}
                content={{
                  className: 'header-menu-dropdown-content',
                  children: (
                    <>
                      {menuItems
                        .filter((menuItem) => menuItem.position === 'right')
                        .map((menuItem, i) => (
                          <HeaderButton
                            key={i}
                            {...menuItem}
                            className={(
                              menuItem.to
                              && menuItem.to.includes(t(routes.eticket.index))
                              && location.pathname.includes(t(routes.eticket.index))
                            ) ? 'is-highlighted' : ''}
                          />
                        ))}

                      {menuItems
                        .filter((menuItem) => menuItem.position === 'left')
                        .map((menuItem, i) => (
                          <HeaderButton key={i} {...menuItem} />
                        ))}
                      <div className="disability-container-button">
                        <ToggleThemeSwitch />
                      </div>
                      <LanguageDropdown />
                    </>
                  ),
                }}
              />
            )}
        </nav>
      </div>
    </header>
  );
};

Header.propTypes = {
  t: PropTypes.func.isRequired,
  openLoginModal: PropTypes.func.isRequired,
  dispatchCloseMenuOverlay: PropTypes.func.isRequired,
  location: PropTypes.shape().isRequired,
  openContactModal: PropTypes.func.isRequired,
  userIsAuthenticated: PropTypes.bool,
  displayMenuOverlay: PropTypes.bool,
  locale: PropTypes.string,
  defaultLocale: PropTypes.string.isRequired,
};

Header.defaultProps = {
  userIsAuthenticated: false,
  displayMenuOverlay: false,
  locale: '',
};

const mapStateToProps = (state) => ({
  userIsAuthenticated: state.token.hasIn([constants.token.type.USER, 'token']),
  locale: state.locale,
  defaultLocale: state.locales.get('default'),
  displayMenuOverlay: state.components.menuOverlay.get('opened'),
});

const mapDispatchToProps = (dispatch) => ({
  openLoginModal: () => dispatch(openModal(<Login />)),
  openContactModal: () => dispatch(openModal(<Contact />, 'big')),
  dispatchCloseMenuOverlay: () => dispatch(closeMenuOverlay()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withTranslation()(Header)));
