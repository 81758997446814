import React from 'react';
import PropTypes from 'prop-types';
import './ContentBlock-VARIANT.scss';
import OnlyText from './OnlyText/OnlyText';
import Accordion from './Accordion/Accordion';
import TextAndImage from './TextAndImage/TextAndImage';
import Information from './Information/Information';

const contentBlocksByType = {
  1: OnlyText,
  2: TextAndImage,
  3: TextAndImage,
  4: TextAndImage,
  5: TextAndImage,
  6: TextAndImage,
  7: TextAndImage,
  8: Accordion,
  9: Information,
};

const ContentBlock = ({ block }) => {
  const { type, active } = block;
  const ContentContainer = contentBlocksByType[type];
  if (!ContentContainer || !active) return null;

  return <ContentContainer {...block} />;
};

ContentBlock.propTypes = {
  block: PropTypes.shape().isRequired,
};

export default ContentBlock;
