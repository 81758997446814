import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getEvents, removeEvent } from '../../../core/modules/Events/EventsActions';
import '../../EventTemplate/EventTemplate.scss';
import { withTracker } from '../../common/Tracker/withTracker';
import { setSearchValues } from '../../../core/modules/Search/SearchActions';
import { endpoints } from '../../../core/config/endpoints';
import Preloader from '../../../components/presentational/Preloader/Preloader';
import NotFound from '../NotFound/NotFound';
import EventTemplate from '../../EventTemplate/EventTemplate';
import withDocumentHead from '../../common/DocumentHead/withDocumentHead';

class Event extends Component {
  componentDidMount() {
    const { match } = this.props;

    this.fetchEvent(match.params);
  }

  componentDidUpdate(prevProps) {
    const {
      match,
      activeEvent,
      setDocumentTitle,
      setDocumentDescription,
      t,
    } = this.props;

    if (prevProps.activeEvent !== activeEvent && activeEvent !== null) {
      const { metaDescription } = activeEvent;

      setDocumentTitle(t('document_title', { title: t('search') }));
      setDocumentDescription(t('document_meta_content_default', { content: metaDescription || '' }));
    }

    this.fetchEvent(match.params, prevProps.match.params);
  }

  componentWillUnmount() {
    const { onRemoveEvent } = this.props;

    onRemoveEvent();
  }

  fetchEvent = (currentParams, prevParams = {}) => {
    const { category, event } = currentParams;
    const { category: prevCategory, event: prevEvent } = prevParams;

    if (category !== prevCategory && event !== prevEvent) {
      const { onGetEvents } = this.props;

      onGetEvents(category, event);
    }
  };

  render() {
    const { activeEvent, loading } = this.props;

    if (loading) return <Preloader className="event-page-wrap__preloader" fullWidth />;

    if (!loading && !activeEvent) return <NotFound />;

    if (activeEvent && !Array.isArray(activeEvent)) {
      return <EventTemplate activeEvent={activeEvent} />;
    }

    return null;
  }
}

Event.propTypes = {
  onGetEvents: PropTypes.func.isRequired,
  onRemoveEvent: PropTypes.func.isRequired,
  match: PropTypes.shape().isRequired,
  activeEvent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape()),
    PropTypes.shape(),
  ]),
  loading: PropTypes.bool,
  setDocumentTitle: PropTypes.func.isRequired,
  setDocumentDescription: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

Event.defaultProps = {
  activeEvent: null,
  loading: false,
};

const MapStateToProps = (state) => ({
  activeEvent: state.data.collections.events.get('list'),
  loading: state.request.getIn([endpoints.GET_EVENTS_BY_CATEGORY_EVENT.name, 'loading']),
});

const MapDispatchToProps = (dispatch) => ({
  onGetEvents: (category, event) => dispatch(getEvents(category, event)),
  onRemoveEvent: () => dispatch(removeEvent()),
  onSetSearchValues: (formData) => dispatch(setSearchValues(formData)),
});

export default connect(
  MapStateToProps,
  MapDispatchToProps,
)(withTranslation()(withDocumentHead(withTracker((Event)))));
