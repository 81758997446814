import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Button from '../../../Button/Button';
import Tooltip from '../../../../containers/Tooltip/Tooltip';
import Icon from '../../../Icon/Icon';
import ButtonClose from '../../../ButtonClose/ButtonClose';

const RoutePriceUnavailable = ({
  t,
  toggleTransferDropdown,
  transferDropdownActive,
  stepsCount,
  mobile,
}) => {
  const showButton = stepsCount > 1 && toggleTransferDropdown;
  return (
    <div className="search-results-route-purchase-content">
      <div className="search-results-route-purchase is-unavailable">
        <div className="search-results-route-purchase-tooltip" role="contentinfo" aria-label={t('route_ticket_not_sellable_online_notice')}>
          <Tooltip
            light
            title={t('alert_important')}
            content={t('route_ticket_not_sellable_online_notice')}
          >
            <Icon icon="information" />
          </Tooltip>
        </div>
        {!mobile
          ? (
            <>
              {!showButton && (
                <div className="search-results-route-purchase__text">
                  {t('route_ticket_not_sellable_online')}
                </div>
              )}

              {showButton && !transferDropdownActive && (
                <Button
                  onClick={toggleTransferDropdown}
                  variant="inverted"
                >
                  {t('common_more')}
                </Button>
              )}

            </>
          ) : (
            <div className="search-results-route-purchase__text__mobile">
              {t('route_ticket_not_sellable_online')}
            </div>
          )}
        {showButton && transferDropdownActive && (
          <div
            onClick={toggleTransferDropdown}
            role="button"
            tabIndex={0}
            className="search-results-close-dropdown"
          >
            <span>{t('common_close')}</span>

            <ButtonClose />
          </div>
        )}
      </div>
    </div>
  );
};

RoutePriceUnavailable.propTypes = {
  t: PropTypes.func.isRequired,
  toggleTransferDropdown: PropTypes.func,
  transferDropdownActive: PropTypes.bool,
  stepsCount: PropTypes.number.isRequired,
  mobile: PropTypes.bool,
};

RoutePriceUnavailable.defaultProps = {
  toggleTransferDropdown: null,
  transferDropdownActive: false,
  mobile: false,
};

export default withTranslation()(RoutePriceUnavailable);
