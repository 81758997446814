import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Input from '../../../../../components/presentational/FormElements/Input/Input';
import Button from '../../../../../components/presentational/Button/Button';
import { submitOrder } from '../../../../../core/modules/Order/OrderActions';
import Checkbox from '../../../../../components/presentational/FormElements/Checkbox/Checkbox';
import { openModal } from '../../../../../core/modules/Components/Modal/ModalActions';
import TermsAndConditions from '../../../../modals/TermsAndConditions/TermsAndConditions';
import './OrderSubmit.scss';

const OrderSubmit = ({
  t,
  updateSubmitForm,
  loading,
  openTermsAndConditionsModal,
  userEmail,
  order,
  formProps,
}) => {
  const { isPersonal: phoneRequired } = order;
  const [timeStamp] = React.useState(new Date().getTime());
  const onTermsAndConditionsClick = (e) => {
    e.preventDefault();
    openTermsAndConditionsModal();
  };
  const {
    values,
    handleSubmit,
    errors,
  } = formProps;
  const {
    email, phone, newsletter, termsAndConditions,
  } = values;

  return (
    <div className="checkout-item">
      <div className="wave wave-top" />

      <div className="checkout-item-content no-top-border">
        {!userEmail && (
          <div className="checkout-submit-input-col checkout-submit-email">
            <Input
              label={t('form_enter_email_address')}
              ariaLabel={t('form_enter_email_address')}
              name={`em.${timeStamp}.ail`}
              real-name="email"
              type="text"
              value={email}
              onChange={(e) => updateSubmitForm('email', e.target.value)}
              disabled={loading}
              error={t(errors.email)}
              required
            />
          </div>
        )}

        {phoneRequired && (
          <div className="checkout-submit-input-col">
            <Input
              type="tel"
              label={t('form_enter_phone_number')}
              name={`pho.${timeStamp}.ne`}
              real-name="phone"
              autocapitalize="none"
              value={phone}
              onChange={(e) => updateSubmitForm('phone', e.target.value)}
              disabled={loading}
              error={t(errors.phone)}
              required={phoneRequired}
            />
          </div>
        )}

        <div className="checkout-submit-agreements">
          <Checkbox
            id="checkout-submit-newsletterID"
            name="checkout-submit-newsletter"
            disabled={loading}
            value={newsletter}
            onChange={(e) => updateSubmitForm('newsletter', e.target.checked)}
            label={t('checkout_newsletter_agreement')}
          />

          <Checkbox
            required
            error={t(errors.termsAndConditions)}
            id="checkout-submit-terms-and-conditions"
            name="termsAndConditions"
            disabled={loading}
            value={termsAndConditions}
            onChange={(e) => updateSubmitForm('termsAndConditions', e.target.checked)}
            label={(
              <>
                {t('checkout_tc_agreement_1')}
                {' '}
                <a
                  className="checkout_submit_tc_button"
                  onClick={onTermsAndConditionsClick}
                  href={t('url_cms_tc_agreement')}
                >
                  {t('checkout_tc_agreement_2')}
                </a>
                {' '}
                {t('checkout_tc_agreement_3')}
              </>
            )}
          />
        </div>

        <Button
          className="checkout-submit-buy"
          disabled={loading}
          onClick={handleSubmit}
          variant="brand"
        >
          {t('common_button_buy')}
        </Button>
      </div>
    </div>
  );
};

OrderSubmit.propTypes = {
  t: PropTypes.func.isRequired,
  updateSubmitForm: PropTypes.func.isRequired,
  formProps: PropTypes.shape().isRequired,
  openTermsAndConditionsModal: PropTypes.func.isRequired,
  order: PropTypes.shape().isRequired,
  loading: PropTypes.bool,
  userEmail: PropTypes.string,
};

OrderSubmit.defaultProps = {
  userEmail: '',
  loading: false,
};

const mapStateToProps = (state) => ({
  userEmail: state.user.getIn(['data', 'email']),
  userPhone: state.user.getIn(['data', 'phone']),
  order: state.order.get('data'),
  loading: state.order.getIn(['status', 'loading']),
});

const mapDispatchToProps = (dispatch) => ({
  openTermsAndConditionsModal: () => dispatch(openModal(<TermsAndConditions />)),
  dispatchSubmitOrder: (orderHash, orderData) => (
    dispatch(submitOrder(orderHash, orderData))
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OrderSubmit));
