import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { routes } from '../../../../../../core/config/routes';
import { closeMenuOverlay } from '../../../../../../core/modules/Components/MenuOverlay/MenuOverlayActions';
import { logoutUser } from '../../../../../../core/modules/User/UserActions';
import Submenu from '../../../../../../components/presentational/Submenu/Submenu';

const UserSubmenu = ({
  t,
  dispatchCloseMenuOverlay,
  dispatchLogoutUser,
  name,
}) => {
  const handleLinkClick = () => dispatchCloseMenuOverlay();
  const handleLogoutClick = () => {
    dispatchCloseMenuOverlay();
    dispatchLogoutUser();
  };

  return (
    <Submenu submenuTitle={name}>
      <Link
        className="submenu-item"
        to={t(routes.user.index)}
        onClick={handleLinkClick}
      >
        {t('profile_edit')}
      </Link>

      <div
        tabIndex={0}
        className="submenu-item"
        role="button"
        onClick={handleLogoutClick}
      >
        {t('user_account_logout')}
      </div>
    </Submenu>
  );
};

UserSubmenu.propTypes = {
  t: PropTypes.func.isRequired,
  dispatchCloseMenuOverlay: PropTypes.func.isRequired,
  dispatchLogoutUser: PropTypes.func.isRequired,
  name: PropTypes.string,
};

UserSubmenu.defaultProps = {
  name: '',
};

const mapStateToProps = (state) => ({
  name: state.user.getIn(['data', 'name']),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchCloseMenuOverlay: () => dispatch(closeMenuOverlay()),
  dispatchLogoutUser: () => dispatch(logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserSubmenu));
