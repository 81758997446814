import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getTopRoutes } from '../../../core/modules/TopRoutesStations/TopRoutesStationsActions';
import './TopRoutes.scss';
import Sidebar from './Partials/Sidebar';
import { getLink, routes } from '../../../core/config/routes';
import withDocumentHead from '../../common/DocumentHead/withDocumentHead';
import { withTracker } from '../../common/Tracker/withTracker';
import { sortLocale } from '../../../core/config/constants/sortLocale';
import variantConst from '../../../core/config/constants/variantConst/variantConst';

class TopRoutes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuOpen: true,
    };
  }

  componentDidMount() {
    const { onGetTopRoutes, locale } = this.props;

    onGetTopRoutes(locale);
  }

  componentDidUpdate() {
    const { t, setDocumentTitle, setDocumentDescription } = this.props;

    if (t) {
      setDocumentTitle(`${t(variantConst.documentTitle)} ${t(variantConst.documentTitleTopRoutes)}`);

      setDocumentDescription(t(variantConst.documentDescriptionTopRoutes));
    }
  }

  sortRoutes = () => {
    const { allTopRoutes } = this.props;

    return Array.from(allTopRoutes).sort((a, b) => {
      const nameA = a.fromStopName.toLowerCase();
      const nameB = b.fromStopName.toLowerCase();

      return nameA.localeCompare(nameB, sortLocale);
    });
  };

  chunkRoutes = () => {
    const sortedRoutes = this.sortRoutes();
    const chunkedRoutes = [];
    let breakIndex = 0;

    sortedRoutes.forEach((route, i) => {
      const { fromStopName } = route;

      if (i < sortedRoutes.length - 1) {
        if (fromStopName[0] !== sortedRoutes[i + 1].fromStopName[0]) {
          chunkedRoutes.push(sortedRoutes.slice(breakIndex, i + 1));

          breakIndex = i + 1;

          return;
        }
      }

      if (breakIndex === sortedRoutes.length - 1) {
        chunkedRoutes.push(sortedRoutes.slice(breakIndex));
      }
    });

    if (breakIndex < sortedRoutes.length - 1) {
      chunkedRoutes.push(sortedRoutes.slice(breakIndex));
    }

    return chunkedRoutes;
  };

  render() {
    const { t, topRoutes } = this.props;
    const { menuOpen } = this.state;
    const chunkedRoutes = this.chunkRoutes();

    return (
      <div className="top-routes">
        <Sidebar
          topRoutes={topRoutes.take(10)}
          onOpenMenu={() => this.setState({ menuOpen: !menuOpen })}
          menuOpen={menuOpen}
        />

        <div className="top-routes-content">
          <div className="top-routes-content__title">
            {t('common_all_popular_routes_in_the_country')}
          </div>

          <ul className="top-routes-stations-list">
            {chunkedRoutes.map((routesArray, i) => (
              <li key={`routes-array-${i}`}>
                <div className="top-routes-stations-list__title">
                  {routesArray[0].fromStopName[0]}
                </div>

                <ul className="top-routes-stations-list-inner">
                  {routesArray.map((route) => {
                    if (!route.fromStopName || !route.toStopName) return null;

                    return (
                      <li
                        className="top-routes-stations-list-item"
                        key={route.fromStopName + route.toStopName}
                      >
                        <Link
                          tabIndex={-1}
                          to={getLink(t(routes.topRoutes.route), { slug: route.slug })}
                        >
                          {route.fromStopName} - {route.toStopName}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

TopRoutes.propTypes = {
  t: PropTypes.func.isRequired,
  onGetTopRoutes: PropTypes.func.isRequired,
  topRoutes: PropTypes.shape(),
  allTopRoutes: PropTypes.shape(),
  setDocumentTitle: PropTypes.func.isRequired,
  setDocumentDescription: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
};

TopRoutes.defaultProps = {
  topRoutes: List([]),
  allTopRoutes: List([]),
};

const mapStateToProps = (state) => ({
  locale: state.locale,
  topRoutes: state.data.collections.topRoutesAndStations.getIn(['routes', state.locale]),
  allTopRoutes: state.data.collections.topRoutesAndStations.getIn(['routes', state.locale]),
});

const mapDispatchToProps = (dispatch) => ({
  onGetTopRoutes: (locale) => dispatch(getTopRoutes(locale)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(withDocumentHead(withTracker(TopRoutes))));
