import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './PersonalDataForm.scss';
import { Formik } from 'formik';
import Input from '../../../components/presentational/FormElements/Input/Input';
import Button from '../../../components/presentational/Button/Button';
import { validations } from '../../../core/config/form/validations';
import { getErrors } from '../../../core/utilities/formValidator';
import { updateUser } from '../../../core/modules/User/UserActions';
import { userEndpoints } from '../../../core/config/endpoints/user';
import Alert from '../../../components/presentational/Alert/Alert';

class PersonalDataForm extends React.Component {
  constructor(props) {
    super(props);

    this.form = React.createRef();
  }

  onSubmit = ({ email, name, phone }) => {
    const { dispatchUpdateUser } = this.props;

    dispatchUpdateUser(email, name, phone);
  };

  render() {
    const {
      t, updateCurrentUserErrors, userEmail, username, phoneNumber,
    } = this.props;
    const { global, fields } = updateCurrentUserErrors;
    const updateFieldsErrors = fields || {};

    return (
      <div>
        <div className="personal-data-form-header">{t('user_personal_data')}</div>

        <Formik
          validateOnChange={false}
          enableReinitialize
          onSubmit={this.onSubmit}
          initialValues={{
            name: username,
            email: userEmail,
            phone: phoneNumber,
          }}

          validate={(values) => getErrors(values, {
            email: [validations.email],
            name: [validations.required],
          }, this.form)}

          render={(formProps) => {
            const {
              values,
              handleSubmit,
              setFieldValue,
              errors,
            } = formProps;

            const {
              name, email, phone,
            } = values;

            return (
              <form onSubmit={handleSubmit} ref={this.form}>
                {global && global.map((error) => (
                  <Alert
                    key={error}
                    variant="danger"
                    heading={t('common_error_exclamation')}
                  >
                    {error}
                  </Alert>
                ))}

                <div className="personal-data-form-input-row">
                  <Input
                    name="email"
                    id="email"
                    value={email}
                    label={t('user_email')}
                    type="text"
                    error={t(errors.email) || updateFieldsErrors.email}
                    onChange={(e) => setFieldValue('email', e.target.value)}
                  />
                </div>

                <div className="personal-data-form-input-row">
                  <Input
                    name="name"
                    id="name"
                    value={name}
                    label={t('user_name')}
                    error={t(errors.name) || updateFieldsErrors.name}
                    type="text"
                    onChange={(e) => setFieldValue('name', e.target.value)}
                  />
                </div>

                <div className="personal-data-form-input-row">
                  <Input
                    name="phone"
                    id="phone"
                    value={phone}
                    label={t('user_phone_number')}
                    type="text"
                    error={t(errors.phone) || updateFieldsErrors.phone}
                    onChange={(e) => setFieldValue('phone', e.target.value)}
                  />
                </div>

                <Button
                  className="personal-data-form-change-details-request-button"
                  type="submit"
                >
                  {t('user_form_save_changes')}
                </Button>
              </form>
            );
          }}
        />
      </div>
    );
  }
}

PersonalDataForm.propTypes = {
  t: PropTypes.func.isRequired,
  dispatchUpdateUser: PropTypes.func.isRequired,
  updateCurrentUserErrors: PropTypes.shape(),
  userEmail: PropTypes.string,
  username: PropTypes.string,
  phoneNumber: PropTypes.string,
};

PersonalDataForm.defaultProps = {
  updateCurrentUserErrors: {},
  userEmail: '',
  username: '',
  phoneNumber: '',
};

const mapStateToProps = (state) => ({
  updateCurrentUserErrors: state.request.getIn([userEndpoints.UPDATE_CURRENT_USER.name, 'error']),
  userEmail: state.user.getIn(['data', 'email']),
  username: state.user.getIn(['data', 'name']),
  phoneNumber: state.user.getIn(['data', 'phone']),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchUpdateUser: (email, name, phone) => dispatch(updateUser(email, name, phone)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PersonalDataForm));
