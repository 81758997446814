import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { destroyOrder, getOrder, initializeOrder } from '../../../core/modules/Order/OrderActions';
import Sidebar from './Partials/Sidebar/Sidebar';
import NotFound from '../NotFound/NotFound';
// eslint-disable-next-line import/extensions
import Discount from './Partials/Discount/Discount-VARIANT.js';
import Preloader from '../../../components/presentational/Preloader/Preloader';
import { routes } from '../../../core/config/routes';
import { orderEndpoints } from '../../../core/config/endpoints/order';
import Alert from '../../../components/presentational/Alert/Alert';
import withDocumentHead from '../../common/DocumentHead/withDocumentHead';
import { withTracker } from '../../common/Tracker/withTracker';
import OrderForm from './Partials/OrderForm/OrderForm';
import './Checkout.scss';
import variantConst from '../../../core/config/constants/variantConst/variantConst';

const Checkout = ({
  t,
  dispatchGetOrder,
  dispatchDestroyOrder,
  dispatchInitializeOrder,
  order,
  match,
  location,
  loading,
  checkoutErrors,
  setDocumentTitle,
  setDocumentDescription,
}) => {
  const { state: locationState } = location;
  const { initialOrder } = locationState || {};
  const { params, path } = match;
  const { orderHash: urlOrderHash } = params;
  const isErrorRoute = path === t(routes.checkout.error);

  React.useEffect(() => {
    if (initialOrder) {
      dispatchInitializeOrder(initialOrder);
      return;
    }

    if (urlOrderHash) dispatchGetOrder(urlOrderHash);
  }, [initialOrder, urlOrderHash]);

  React.useEffect(() => function cleanup() {
    dispatchDestroyOrder();
  }, []);

  React.useEffect(() => {
    if (t) {
      setDocumentTitle(`${t(variantConst.documentTitle)} ${t(variantConst.documentTitleOrderCheckout)}`);

      setDocumentDescription(t(variantConst.documentDescriptionOrderCheckout));
    }
  }, [t]);

  if (!order && loading) {
    return (
      <div className="preloader-wrap">
        <Preloader fullWidth fullHeight />
      </div>
    );
  }

  if (!initialOrder && !urlOrderHash) return <NotFound />;

  if (!order) {
    return checkoutErrors.length
      ? checkoutErrors.map((error) => (
        <Alert
          key={error}
          variant="danger"
          heading={t('common_error_exclamation')}
        >
          {error}
        </Alert>
      ))
      : <NotFound />;
  }

  return (
    <div className="checkout">
      <div className="checkout-info">
        <h1 className="checkout-info__title">
          {t('checkout_order_info')}
        </h1>

        {isErrorRoute && (
          <Alert
            variant="danger"
            heading={t('common_error_exclamation')}
          >
            {t('checkout_payment_cancelled')}
          </Alert>
        )}

        {checkoutErrors.map((error) => (
          <Alert
            key={error}
            variant="danger"
            heading={t('common_error_exclamation')}
          >
            {error}
          </Alert>
        ))}

        <Discount />

        <OrderForm />
      </div>

      <Sidebar />
    </div>
  );
};

Checkout.propTypes = {
  t: PropTypes.func.isRequired,
  dispatchInitializeOrder: PropTypes.func.isRequired,
  dispatchDestroyOrder: PropTypes.func.isRequired,
  dispatchGetOrder: PropTypes.func.isRequired,
  location: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
  loading: PropTypes.bool,
  order: PropTypes.shape(),
  checkoutErrors: PropTypes.arrayOf(PropTypes.string),
  setDocumentTitle: PropTypes.func.isRequired,
  setDocumentDescription: PropTypes.func.isRequired,
};

Checkout.defaultProps = {
  loading: false,
  order: null,
  checkoutErrors: [],
};

const mapStateToProps = (state) => ({
  loading: state.order.getIn(['status', 'loading']),
  order: state.order.get('data'),
  checkoutErrors: Object
    .entries(orderEndpoints)
    .reduce((accumulator, [endpointName]) => (
      accumulator.concat(state.request.getIn([endpointName, 'error', 'global']))
    ), [])
    .filter(Boolean),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchGetOrder: (orderHash) => dispatch(getOrder(orderHash)),
  dispatchDestroyOrder: () => dispatch(destroyOrder()),
  dispatchInitializeOrder: (order) => dispatch(initializeOrder(order)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(withDocumentHead(withTracker(Checkout))),
);
