import { regExpValid } from './regex';
import { focusOnError } from './form';

export const validate = (value, validation) => {
  const errors = [];

  validation.forEach((expression) => {
    const valid = expression.customCheck
      ? expression.customCheck(value)
      : regExpValid(expression.regex, value);

    if (!valid) errors.push(expression.message);
  });

  return errors.length ? errors[errors.length - 1] : null;
};

// TODO: change add isRequired and isEmpty
const getErrorsEntries = (values, schemaEntry, form) => {
  const errors = {};
  
  Object.entries(schemaEntry).forEach((entry) => {
    const name = entry[0];

    if (typeof entry[1] === 'object' && entry[1].length) {
      const value = values[name];
      const validationList = entry[1];
      
      const error = validate(value, validationList);
      
      if (error) errors[name] = error;
  
      return;
    }
    
    const nestedErrors = getErrorsEntries(values[name], entry[1]);

    if (Object.keys(nestedErrors).length) {
      errors[name] = getErrorsEntries(values[name], entry[1]);
    }
  });
  
  if (form && errors) focusOnError(form, errors);
  
  return errors;
};

export const getErrors = (values, schema, form) => getErrorsEntries(values, schema, form);
