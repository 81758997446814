import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Preloader from '../../../../../components/presentational/Preloader/Preloader';
import { getLink, routes } from '../../../../../core/config/routes';
import withDocumentHead from '../../../../common/DocumentHead/withDocumentHead';
import variantConst from '../../../../../core/config/constants/variantConst/variantConst';

const Stations = ({
  t,
  stations,
  loading,
  abroad,
  setDocumentTitle,
  setDocumentDescription,
}) => {
  if (loading) return <Preloader fullHeight fullWidth />;

  if (!stations) return null;

  const stantionsListTranlation = t(`common_all_popular_stations_${abroad ? 'abroad' : 'in_the_country'}`);

  React.useEffect(() => {
    if (abroad) {
      setDocumentTitle(`${t(variantConst.documentTitle)} ${t(variantConst.documentTitleStationsListAbroad)}`);
      setDocumentDescription(t(variantConst.documentDescriptionStationsListAbroad));

      return;
    }

    setDocumentTitle(`${t(variantConst.documentTitle)} ${t(variantConst.documentTitleStationsList)}`);
    setDocumentDescription(t(variantConst.documentDescriptionStationsList));
  }, [abroad]);

  return (
    <>
      <div className="top-routes-content__title is-stations">
        {stantionsListTranlation}
      </div>

      <ul className="top-routes-stations-list">
        {stations.map((stationsArray, index) => (
          <li className="top-routes-stations-list-group" key={`stations-array-${index}`}>
            <div className="top-routes-stations-list__title">
              {abroad ? stationsArray[0].country.title : stationsArray[0].title[0]}
            </div>

            <ul className="top-routes-stations-list-inner">
              {stationsArray.map(({ title, type, urlTitle }, i) => {
                const route = type === 'i'
                  ? t(routes.topStations.abroad.station)
                  : t(routes.topStations.local.station);

                return (
                  <li className="top-routes-stations-list-item" key={title + i}>
                    <Link to={getLink(route, { station: urlTitle })}>
                      {title}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </li>
        ))}
      </ul>
    </>
  );
};

Stations.propTypes = {
  t: PropTypes.func.isRequired,
  setDocumentTitle: PropTypes.func.isRequired,
  setDocumentDescription: PropTypes.func.isRequired,
  stations: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape())),
  abroad: PropTypes.bool,
  loading: PropTypes.bool,
};

Stations.defaultProps = {
  stations: null,
  abroad: false,
  loading: false,
};

export default withTranslation()(withDocumentHead(Stations));
