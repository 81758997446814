import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import DatepickerView from '../../presentational/Datepicker/Datepicker';
import { getTranslations } from '../../../core/utilities/date';

const Datepicker = ({ locale, t, ...props }) => {
  const [translations, setTranslations] = React.useState(getTranslations(t));

  React.useEffect(() => {
    setTranslations(getTranslations(t));
  }, [locale]);

  return (
    <DatepickerView
      translations={translations}
      {...props}
    />
  );
};

Datepicker.propTypes = {
  t: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  locale: state.locale,
});

export default connect(mapStateToProps)(withTranslation()(Datepicker));
