export const ticketSearchEndpoints = {
  FIND_TICKET_BY_NUMBER: {
    name: 'FIND_TICKET_BY_NUMBER',
    url: {
      method: 'POST',
      path: () => '/orders/search/byTicket',
    },
  },
  FIND_TICKET_BY_TRAVEL: {
    name: 'FIND_TICKET_BY_TRAVEL',
    url: {
      method: 'POST',
      path: () => '/orders/search/byTravel',
    },
  },
};
