import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip-lite';
import './Tooltip.scss';

class Tooltip extends React.Component {
  constructor(props) {
    super(props);
    
    this.content = React.createRef();
    this.toggleButton = React.createRef();
    
    const screen = window.innerWidth;
    
    this.state = {
      isMobile: screen < 1024,
      tipOpen: false,
    };
  }
  
  componentDidMount() {
    this.connectToggleMode();
    window.addEventListener('resize', this.handleResize);
  }
  
  componentWillUnmount() {
    this.disconnectToggleMode();
    window.removeEventListener('resize', this.handleResize);
  }
  
  connectToggleMode = () => {
    document.addEventListener('mousedown', this.bodyClick);
  };
  
  disconnectToggleMode = () => {
    document.removeEventListener('mousedown', this.bodyClick);
  };
  
  handleResize = () => {
    const { isMobile } = this.state;
    const screen = window.innerWidth;
    
    if (screen < 1024 && !isMobile) {
      this.setState({
        isMobile: true,
      }, this.connectToggleMode);
    }
    
    if (screen >= 1024 && isMobile) {
      this.setState({
        isMobile: false,
        tipOpen: false,
      }, this.disconnectToggleMode);
    }
  };
  
  toggleTip = () => {
    const { tipOpen } = this.state;
    
    this.setState({
      tipOpen: !tipOpen,
    });
  };
  
  bodyClick = (e) => {
    const { isMobile } = this.state;
    
    if (
      !isMobile
      || (this.content.current && this.content.current.contains(e.target))
      || (this.toggleButton.current && this.toggleButton.current.contains(e.target))
    ) {
      return;
    }
    
    this.setState({
      tipOpen: false,
    });
  };
  
  render() {
    const {
      children, content, light, inline, title,
    } = this.props;
    const { tipOpen, isMobile } = this.state;
    
    if (!content) return children;
    
    const options = {
      tipContentClassName: `tooltip-content-wrap ${light ? 'is-light' : ''}`,
      tipContentHover: true,
      content: (
        <div ref={this.content}>
          {title && <div className="tooltip-header">{title}</div>}
  
          <div className="tooltip-content">{content}</div>
        </div>
      ),
      tagName: 'span',
      arrowSize: 5,
    };
    const childOptions = {};
    
    if (isMobile) {
      options.isOpen = tipOpen;
      childOptions.onClick = () => this.toggleTip();
    }
    
    return (
      <div className={`tooltip-wrap ${inline ? 'is-inline' : ''}`}>
        <ReactTooltip {...options}>
          <div {...childOptions} ref={this.toggleButton}>
            {children}
          </div>
        </ReactTooltip>
      </div>
    );
  }
}

Tooltip.propTypes = {
  children: PropTypes.node,
  content: PropTypes.node,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  light: PropTypes.bool,
  inline: PropTypes.bool,
};

Tooltip.defaultProps = {
  children: null,
  content: null,
  title: null,
  light: false,
  inline: false,
};

export default Tooltip;
