import React from 'react';
import CMSContent from '../../pages/CMS/CMSContent';
import './TermsAndConditions.scss';
import variantConst from '../../../core/config/constants/variantConst/variantConst';

const TermsAndConditions = () => (
  <div className="terms-and-conditions-modal">
    <CMSContent page={variantConst.termsAndConditionsCmsId} />
  </div>
);

export default TermsAndConditions;
