/* eslint-disable react/no-danger */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  cancelFetchRefundInfo,
  fetchRefundInfo,
  refundTicket,
} from '../../../../../core/modules/TicketReturn/TicketReturnActions';
import { endpoints } from '../../../../../core/config/endpoints';
import Alert from '../../../../../components/presentational/Alert/Alert';
import Preloader from '../../../../../components/presentational/Preloader/Preloader';
import { formatMoney } from '../../../../../core/utilities/money';
import Button from '../../../../../components/presentational/Button/Button';
import { getLink, routes } from '../../../../../core/config/routes';
import success from '../../../../../../assets/images/success.png';
import './ReturnBox.scss';

const ReturnBox = ({
  t,
  loadingRefundInfo,
  loadingRefundTicket,
  ticketId,
  orderHash,
  refundTicketErrors,
  fetchRefundInfoErrors,
  refundInfo,
  dispatchRefundTicket,
  dispatchFetchRefundInfo,
  dispatchCancelFetchRefundInfo,
}) => {
  React.useEffect(() => {
    if (ticketId) dispatchFetchRefundInfo(orderHash, ticketId);
  }, [ticketId]);

  React.useEffect(() => function cleanup() {
    dispatchCancelFetchRefundInfo();
  }, []);

  if (loadingRefundInfo) return <Preloader fullWidth />;
  if (!refundInfo) {
    return (
      fetchRefundInfoErrors.map((error) => (
        <Alert
          key={error}
          variant="danger"
          heading={t('common_error_exclamation')}
        >
          {error}
        </Alert>
      ))
    );
  }
  const ticketsLink = getLink(t(routes.tickets.index), { orderHash });
  const onFullRefund = () => dispatchRefundTicket(orderHash, ticketId, 'full');
  const onBackwardRefund = () => dispatchRefundTicket(orderHash, ticketId, 'backward');
  const {
    travelName, travelDuration, itemCount, ticketType, full, backward, refunded, hasBike,
  } = refundInfo;

  return (
    <>
      {[...refundTicketErrors, ...fetchRefundInfoErrors].map((error) => (
        <Alert
          key={error}
          variant="danger"
          heading={t('common_error_exclamation')}
        >
          {error}
        </Alert>
      ))}

      <div className="ticket-return-box">
        <div className="ticket-return-box__heading">{t('ticket_return_trip')}</div>

        <div className="ticket-return-box__route">{travelName}</div>

        <div className="ticket-return-box__duration">{t('route_ticket_duration')} {travelDuration}</div>

        {refunded && (
          <div className="ticket-return-box-success">
            <img src={success} alt="Refund successful" />

            <div className="ticket-return-box-success-message">{t('ticket_return_successfull')}</div>

            <div className="ticket-return-box-money-message">{t('ticket_return_money_will_return_in_x_days')}</div>

            <Button
              variant="inline-link"
              route={getLink(t(routes.tickets.index), { orderHash })}
            >
              <span>{t('ticket_return_another_one')}</span>
            </Button>
          </div>
        )}

        {!refunded && (
          <div className="ticket-return-box-content">
            {!backward && !full && (
              <div className="ticket-return-no-refund">
                <div className="ticket-return-no-refund__message">{t('ticket_return_cant_be_return')}</div>

                <Button
                  className="ticket-return-no-refund__link"
                  route={ticketsLink}
                  variant="inline-link"
                  disabled={loadingRefundTicket}
                >
                  <span>{t('common_go_back_to_list')}</span>
                </Button>
              </div>
            )}

            {!!full && (
              <div className="ticket-return-direction">
                <div className="ticket-return-direction__heading">
                  {t(backward ? 'ticket_return_departure_and_return' : 'ticket_return_departure')}
                </div>

                {ticketType === 3 && (
                  <div className="ticket-return-direction__count">
                    {t('ticket_return_amount_warning', { count: itemCount })}
                  </div>
                )}

                {!backward && (
                  <div
                    className="ticket-return-direction__status"
                    dangerouslySetInnerHTML={{
                      __html: t('ticket_status', {
                        status: `<span class="ticket-return-direction__status-value">${t('ticket_status_3')}</span>`,
                      }),
                    }}
                  />
                )}

                <div className="ticket-return-direction__percent-discount">
                  {t('ticket_return_amount')}
                </div>

                {hasBike && (
                  <div className="ticket-return-direction__reminder">
                    {t('ticket_return_bicycle_reminder')}
                  </div>
                )}

                <div className="ticket-return-direction__amount">
                  {formatMoney(full)}
                </div>

                <div className="ticket-return-buttons">
                  <Button
                    className="ticket-return-submit-button"
                    onClick={onFullRefund}
                    variant="brand"
                    disabled={loadingRefundTicket}
                  >
                    {t('ticket_return_confirm_return')}
                  </Button>

                  <Button
                    className="ticket-return-cancel-button"
                    route={ticketsLink}
                    variant="inline-link"
                    disabled={loadingRefundTicket}
                  >
                    <span>{t('ticket_return_cancel')}</span>
                  </Button>
                </div>
              </div>
            )}

            {!!backward && (
              <div className="ticket-return-direction">
                <div className="ticket-return-direction__heading">
                  {t('ticket_return_return')}
                </div>

                {ticketType === 3 && (
                  <div className="ticket-return-direction__count">
                    {t('ticket_return_amount_warning', { count: itemCount })}
                  </div>
                )}

                <div className="ticket-return-direction__percent-discount">
                  {t('ticket_return_amount')}
                </div>

                {hasBike && (
                  <div className="ticket-return-direction__reminder">
                    {t('ticket_return_bicycle_reminder')}
                  </div>
                )}

                <div className="ticket-return-direction__amount">
                  {formatMoney(backward)}
                </div>

                <div className="ticket-return-buttons">
                  <Button
                    className="ticket-return-submit-button"
                    onClick={onBackwardRefund}
                    variant="brand"
                    disabled={loadingRefundTicket}
                  >
                    {t('ticket_return_confirm_return')}
                  </Button>

                  <Button
                    className="ticket-return-cancel-button"
                    route={getLink(t(routes.tickets.index), { orderHash })}
                    variant="inline-link"
                    disabled={loadingRefundTicket}
                  >
                    <span>{t('ticket_return_cancel')}</span>
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

ReturnBox.propTypes = {
  t: PropTypes.func.isRequired,
  dispatchCancelFetchRefundInfo: PropTypes.func.isRequired,
  dispatchFetchRefundInfo: PropTypes.func.isRequired,
  dispatchRefundTicket: PropTypes.func.isRequired,
  orderHash: PropTypes.string.isRequired,
  ticketId: PropTypes.number.isRequired,
  refundInfo: PropTypes.shape(),
  loadingRefundInfo: PropTypes.bool,
  loadingRefundTicket: PropTypes.bool,
  fetchRefundInfoErrors: PropTypes.arrayOf(PropTypes.string),
  refundTicketErrors: PropTypes.arrayOf(PropTypes.string),
};

ReturnBox.defaultProps = {
  refundTicketErrors: [],
  fetchRefundInfoErrors: [],
  refundInfo: null,
  loadingRefundInfo: false,
  loadingRefundTicket: false,
};

const mapStateToProps = (state) => ({
  loadingRefundTicket: state.request.getIn([endpoints.REFUND_TICKET.name, 'loading']),
  refundTicketErrors: state.request.getIn([endpoints.REFUND_TICKET.name, 'error', 'global']),
  loadingRefundInfo: state.request.getIn([endpoints.FETCH_REFUND_INFO.name, 'loading']),
  fetchRefundInfoErrors: state.request.getIn([endpoints.FETCH_REFUND_INFO.name, 'error', 'global']),
  refundInfo: state.ticketReturn,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchCancelFetchRefundInfo: () => dispatch(cancelFetchRefundInfo()),
  dispatchFetchRefundInfo: (orderHash, ticketId) => dispatch(fetchRefundInfo(orderHash, ticketId)),
  dispatchRefundTicket: (orderHash, ticketId, refundType) => dispatch(
    refundTicket(orderHash, ticketId, refundType),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ReturnBox));
