import React from 'react';
import PropTypes from 'prop-types';
import './SearchResultsRouteMobile.scss';
import { withTranslation } from 'react-i18next';
import { getObjectNestedData } from '../../../../core/utilities/object';
import RoutePrice from '../Partials/RoutePrice/RoutePrice';
import TransferRoute from '../Partials/TransferRoute/TransferRoute';
import Icon from '../../Icon/Icon';

class SearchResultsRouteMobile extends React.Component {
  constructor() {
    super();

    this.state = {
      infoDropdownOpen: false,
    };
  }

  setDropdown = () => {
    this.setState((prevState) => ({
      infoDropdownOpen: !prevState.infoDropdownOpen,
    }));
  };

  // TODO: check if route is defined
  render() {
    const {
      t,
      route,
      tripDuration,
      exchangeButtonProps,
      isForward,
    } = this.props;
    const { infoDropdownOpen } = this.state;
    const { sellable, steps } = route;
    const { departureTime, arrivalTime } = tripDuration;
    const { carrierLogoSmall, carrierPublicName } = getObjectNestedData(route.steps[0], 'sellData') || {};
    return (
      <>
        <div className="search-results-route is-mobile">
          <div className={`
            search-results-route-header
            ${steps.length > 1 ? 'has-steps' : ''}
            ${!carrierLogoSmall ? 'has-no-carrier' : ''}`}
          >
            {steps.length > 1 && (
              <div className="has-steps__title">
                {t('ticket_search_transfer_notification_message')}
              </div>
            )}
            <div className="search-results-route-header-wrapper">
              <div className="search-results-route-header-info">
                <div className="search-results-route-header-info__title">{route.title}</div>

                <div className="search-results-route-header-info__time">
                  <b>{departureTime}</b> - {arrivalTime}
                </div>
              </div>
              <div className="search-results-route-features-carrier">
                <span className="search-results-route-features-carrier-text">
                  {carrierPublicName}
                </span>
  
                {carrierLogoSmall && steps.length === 1 
                && <img src={carrierLogoSmall} alt={carrierPublicName} />}

                {!carrierLogoSmall && carrierPublicName}
              </div>
            </div>
          </div>
          {infoDropdownOpen && (
            <div className="search-results-route-dropdown is-mobile">
              {steps.map((step, i) => (
                <React.Fragment key={`route-step-${i}`}>
                  <TransferRoute
                    route={step}
                    isFirst={i === 0}
                    isLast={steps.length - 1 === i}
                  />
                </React.Fragment>
              ))}
            </div>
          )}

          <div className="search-results-route-divider">
            <button
              aria-expanded={infoDropdownOpen}
              aria-label="dropdown"
              onClick={this.setDropdown}
              className="search-results-route-divider__button"
            >
              <Icon icon={`chevron-${infoDropdownOpen ? 'up' : 'down'}`} />
            </button>
          </div>

          <div className="search-results-route-footer promo-price-wrapper">
            <RoutePrice
              mobile
              isForward={isForward}
              sellable={sellable}
              route={route}
              exchangeButtonProps={exchangeButtonProps}
              transferDropdownActive={infoDropdownOpen}
              toggleTransferDropdown={this.setDropdown}
            />
          </div>
        </div>
      </>
    );
  }
}

SearchResultsRouteMobile.propTypes = {
  t: PropTypes.func.isRequired,
  route: PropTypes.shape(),
  tripDuration: PropTypes.shape(),
  exchangeButtonProps: PropTypes.shape(),
  isForward: PropTypes.bool,
};

SearchResultsRouteMobile.defaultProps = {
  route: null,
  tripDuration: null,
  exchangeButtonProps: null,
  isForward: true,
};

export default withTranslation()(SearchResultsRouteMobile);
