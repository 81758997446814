import { Map } from 'immutable';
import { constants } from '../constants';

const initialState = Map({});

const EurolinesTopRoutesReducer = (state = initialState, action) => {
  switch (action.type) {
  case constants.eurolinesRoutes.SET_ROUTES:
    return state.set('routes', action.payload.eurolinesTopRoutes);

  case constants.eurolinesRoutes.SET_ROUTE:
    return state.set(action.payload.slug, action.payload.eurolinesTopRoute);

  default:
    return state;
  }
};

export default EurolinesTopRoutesReducer;
