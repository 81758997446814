import { constants } from '../constants';

export const submitSearch = (values) => ({
  type: constants.search.SUBMIT_SEARCH,
  payload: {
    values,
  },
});

export const submitSearchBySlug = (slug) => ({
  type: constants.search.SUBMIT_SEARCH_BY_SLUG,
  payload: {
    slug,
  },
});

export const setShowDates = (showDates) => ({
  type: constants.search.SET_SHOW_DATES,
  payload: {
    showDates,
  },
});

export const setSlugSearchValues = (slug, values) => ({
  type: constants.search.SET_SLUG_SEARCH_VALUES,
  payload: {
    slug,
    values,
  },
});

export const setSearchValues = (values) => ({
  type: constants.search.SET_SEARCH_VALUES,
  payload: {
    values,
  },
});

export const clearSearchValues = () => ({
  type: constants.search.CLEAR_SEARCH_VALUES,
  payload: {},
});
