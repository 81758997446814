import {
  all,
  put,
  takeEvery,
  call,
} from 'redux-saga/effects';

import { constants } from '../../constants';
import { startRequest } from '../../Request/RequestSaga';
import { endpoints } from '../../../config/endpoints';
import { passwordResetSentTo, setNewPasswordUser } from './ResetPasswordActions';

export function* onRequestPasswordReset(action) {
  const { response } = yield call(
    startRequest,
    endpoints.REQUEST_PASSWORD_RESET.name,
    endpoints.REQUEST_PASSWORD_RESET.url.path(),
    action.payload,
  );

  if (response) {
    yield put(passwordResetSentTo(action.payload.email));
  }
}

export function* setNewPasswordSaga(action) {
  const { hash, plainPassword } = action.payload;

  const { response } = yield call(
    startRequest,
    endpoints.SET_NEW_PASSWORD.name,
    endpoints.SET_NEW_PASSWORD.url.path(hash),
    { plainPassword },
  );

  if (response) {
    yield put(setNewPasswordUser(response.data.data));
  }
}

export default function* ResetPasswordSaga() {
  yield all([
    takeEvery(constants.components.resetPasswordRequest.START, onRequestPasswordReset),
    takeEvery(constants.components.resetPasswordRequest.SET_NEW_PASSWORD, setNewPasswordSaga),
  ]);
}
