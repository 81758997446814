/* eslint-disable react/no-danger */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Accordion-VARIANT.scss';
import Icon from '../../../../../components/presentational/Icon/Icon';

const Accordion = ({ parts, title }) => {
  const [openAccordions, setOpenAccordions] = useState([]);
  const toggleAccordion = (id) => {
    const accordionIsOpen = openAccordions.includes(id);

    setOpenAccordions(
      accordionIsOpen
        ? openAccordions.filter((openAccordionId) => openAccordionId !== id)
        : openAccordions.concat([id]),
    );
  };

  return (
    <div className="content-block accordion">
      {title && <h3>{title}</h3>}

      <ul>
        {parts.map((part) => {
          const accordionIsOpen = openAccordions.includes(part.id);

          if (!part.content) return null;

          return (
            <li className={`no-bullet ${accordionIsOpen ? 'active' : ''}`} key={part.id}>
              <button className="question" onClick={() => toggleAccordion(part.id)} aria-expanded={accordionIsOpen}>
                <p>
                  <span>{part.title}</span>
                </p>

                <Icon icon={accordionIsOpen ? 'minus' : 'plus'} />
              </button>

              {accordionIsOpen && (
                <div className="answer">
                  <p dangerouslySetInnerHTML={{ __html: part.content }} />
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

Accordion.propTypes = {
  parts: PropTypes.arrayOf(PropTypes.shape()),
  title: PropTypes.string,
};

Accordion.defaultProps = {
  parts: [],
  title: null,
};

export default Accordion;
