import { Map } from 'immutable';
import { constants } from '../constants';

const initialState = Map({});

const EventsReducer = (state = initialState, action) => {
  switch (action.type) {
  case constants.events.SET_EVENTS_CATEGORIES:
    return state.set('categories', action.payload.categories);
  case constants.events.SET_EVENTS:
    return state.set('list', action.payload.events);
  case constants.events.REMOVE_EVENT:
    return state.remove('list');
  
  default:
    return state;
  }
};

export default EventsReducer;
