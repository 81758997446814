import { applyMiddleware, compose, createStore } from 'redux';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { persistReducer } from 'redux-persist';
import immutableTransform from 'redux-persist-transform-immutable';
import * as Sentry from '@sentry/browser';
import rootReducer from '../../modules/reducers';
import rootSaga from '../../modules/sagas';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['token', 'tickets', 'user', 'locale'],
  transforms: [immutableTransform()],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function configureStore(initialState, context = {}) {
  const devMode = process.env.ENV !== 'prod';
  const sagaMiddleware = createSagaMiddleware({
    onError: (error) => {
      Sentry.captureException(error);

      if (devMode) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
  });

  const middlewares = [
    sagaMiddleware,
  ];

  /* eslint-disable no-underscore-dangle */
  const composeEnhancers = (devMode && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
  const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(...middlewares)),
    initialState,
    devMode && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  );
  /* eslint-enable */

  sagaMiddleware.run(rootSaga, context);

  if (module.hot) {
    module.hot.accept('../../modules/reducers.js', () => {
      store.replaceReducer(require('../../modules/reducers.js').default);
    });
  }

  return store;
}
