/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './LanguageDropdown.scss';
import { withTranslation } from 'react-i18next';
import Dropdown from '../../../../../components/presentational/Dropdown/Dropdown';
import { redirect } from '../../../../../core/utilities/routing';
import { history } from '../../../../../core/historyAndStore';
import Icon from '../../../../../components/presentational/Icon/Icon';

class LanguageDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      opened: false,
    };
  }

  handleOpened = () => this.setState({ opened: true });

  handleClosed = () => this.setState({ opened: false });

  handleKeyPress = (event, locale) => {
    if (event.key === 'Enter') {
      redirect(history, `${this.props.defaultLocale === locale ? '' : `/${locale}`}`);
      this.props.i18n.changeLanguage(locale);
      this.handleClosed();
    }
  };

  render() {
    const { opened } = this.state;
    const {
      i18n, locales, language, defaultLocale, t,
    } = this.props;

    const getLanguageName = (locale) => {
      const languageList = [{ name: 'lt', language: t('is_lithuanian_language') },
        { name: 'en', language: t('is_english_language') },
        { name: 'de', language: t('is_german_language') },
        { name: 'lv', language: t('is_latvian_language') },
        { name: 'pl', language: t('is_polish_language') },
        { name: 'ee', language: t('is_estonian_language') },
        { name: 'ru', language: t('is_russian_language') },
        { name: 'ua', language: t('is_ukrainian_language') }];
      return languageList.filter((item) => item.name === locale)[0]?.language || '';
    };

    return (
      <>
        <Dropdown
          className="language-dropdown"
          onShow={this.handleOpened}
          onHide={this.handleClosed}
          trigger={{
            className: 'language-dropdown-trigger header-button text-button',
            children: (
              <>
                {language && language.toLocaleUpperCase()}
                <Icon icon={`chevron-${opened ? 'up' : 'down'}`} />
              </>
            ),
            'aria-label': getLanguageName(language),
          }}
          content={{
            children: locales.map((locale) => (
              <div
                key={locale}
                tabIndex={0}
                className="language-dropdown-item"
                aria-label={getLanguageName(locale)}
                role="button"
                aria-disabled={locale === language ? 'true' : 'false'}
                onClick={() => {
                  redirect(history, `${defaultLocale === locale ? '' : `/${locale}`}`);
                  i18n.changeLanguage(locale);
                }}
                onKeyPress={(event) => this.handleKeyPress(event, locale)}
              >
                {locale}
              </div>
            )),
          }}
        />
      </>
    );
  }
}

LanguageDropdown.propTypes = {
  i18n: PropTypes.shape(),
  locales: PropTypes.arrayOf(PropTypes.string).isRequired,
  language: PropTypes.string,
  defaultLocale: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

LanguageDropdown.defaultProps = {
  i18n: {},
  language: 'LT',
};

const mapStateToProps = (state) => ({
  locales: state.locales.get('list'),
  defaultLocale: state.locales.get('default'),
  language: state.locale,
});

export default connect(mapStateToProps)(withTranslation()(LanguageDropdown));
