import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { formatMoney } from '../../../../../core/utilities/money';
import withDocumentHead from '../../../../common/DocumentHead/withDocumentHead';
import Button from '../../../../../components/presentational/Button/Button';
import { routes } from '../../../../../core/config/routes';
import Datepicker from '../../../../../components/containers/Datepicker/Datepicker';
import { formatDateToYYYYMMDD } from '../../../../../core/utilities/date';
import { getErrors } from '../../../../../core/utilities/formValidator';
import { validations } from '../../../../../core/config/form/validations';
import { validateEticketService } from '../../../../../core/modules/ETicket/ETicketActions';
import { redirect } from '../../../../../core/utilities/routing';
import { history } from '../../../../../core/historyAndStore';
import Select from '../../../../../components/containers/FormElements/Select/Select';
import './Service.scss';
import Icon from '../../../../../components/presentational/Icon/Icon';
import { endpoints } from '../../../../../core/config/endpoints';
import variantConst from '../../../../../core/config/constants/variantConst/variantConst';

const Service = ({
  service,
  t,
  dispatchValidateEticketService,
  serviceError,
  servicesHistory,
  setDocumentTitle,
  setDocumentDescription,
}) => {
  const [initialValues, setInitialValues] = React.useState({
    validFrom: service.hasDynamicDate && service.validFrom.split('T')[0],
    date: service.hasDynamicDate && service.validFrom.split('T')[0],
    amount: Number(service.amount),
  });
  const formRef = React.useRef(null);

  React.useEffect(() => {
    if (!serviceError) return;

    const { serviceId, validFrom: nextValidFrom } = serviceError;
    const { id } = service;

    if (id === serviceId && nextValidFrom) {
      setInitialValues((prevInitialValues) => ({
        ...prevInitialValues,
        validFrom: nextValidFrom,
        date: prevInitialValues.date < nextValidFrom ? nextValidFrom : prevInitialValues.date,
      }));
    }
  }, [serviceError]);

  React.useEffect(() => {
    if (t) {
      setDocumentTitle(`${t(variantConst.documentTitle)} ${t('document_title_eticket_services')}`);

      setDocumentDescription(t('document_description_eticket_services'));
    }
  }, [t]);

  const selectOptions = () => {
    const arrayOptions = Array.from({ length: 51 }, (v, i) => (
      i < 3 ? null : {
        value: i * 100,
        label: `${i} €`,
      }
    ));

    return arrayOptions.splice(3);
  };

  return (
    <Formik
      enableReinitialize
      validateOnChange
      onSubmit={({ date, amount }) => {
        if (date) {
          dispatchValidateEticketService(service, date);
        } else {
          redirect(history, {
            pathname: t(routes.eticket.checkout.index),
            state: {
              initialOrder: service.hasDynamicDate ? {
                amount,
                serviceNr: Number(service.id),
                validFrom: date,
              } : {
                amount,
                serviceNr: Number(service.id),
              },
            },
          });
        }
      }}
      validate={(values) => getErrors(values, { date: [validations.required] }, formRef)}
      initialValues={initialValues}
      render={(formProps) => {
        const {
          values, handleSubmit, setFieldValue, errors,
        } = formProps;
        const { date, amount, validFrom } = values;
        return (
          <form
            ref={formRef}
            onSubmit={handleSubmit}
            className="eTicket-submit"
          >
            <div className="eTicket-services-list-item">
              <div className={`eTicket-services-list-item-content ${service.hasDynamicDate ? '' : 'has-height'}`}>
                <div className="eTicket-services-list-item-header">
                  <span className="eTicket-services-list-item-header-title">{service.title}</span>
                </div>

                {service.type === 1
                  ? (
                    <>
                      {!service.hasDynamicDate && (
                        <p className="eTicket-services-list-item-content-month">
                          <Icon icon="calendar" />
                          {t('from')}{' '}
                          {formatDateToYYYYMMDD(new Date(service.validFrom))}
                        </p>
                      )}

                      {service.hasDynamicDate && (
                        <Datepicker
                          name="date"
                          onChange={(value) => setFieldValue('date', formatDateToYYYYMMDD(value))}
                          value={date}
                          label={t('eTicket_select_date_label')}
                          minDate={new Date(validFrom)}
                          error={t(errors.date)}
                          required
                          disabledDaysRanges={servicesHistory.map((servicesHistoryItem) => ({
                            from: new Date(servicesHistoryItem.validFrom.split('T')[0]),
                            to: new Date(servicesHistoryItem.validTo.split('T')[0]),
                          }))}
                        />
                      )}

                      <div className="eTicket-services-list-item-content-amount">
                        {formatMoney(service.amount)}
                      </div>
                    </>
                  )
                  : (
                    <Select
                      placeholder={t('common_button_choose')}
                      name="amount"
                      options={selectOptions()}
                      value={selectOptions().filter((option) => option.value === amount)}
                      onChange={(inputName, { value }) => setFieldValue('amount', value)}
                    />
                  )}

              </div>

              <div className={`eTicket-services-list-item-action ${service.hasDynamicDate ? 'has-padding' : ''}`}>
                <Button
                  type="submit"
                  disabled={!values.amount}
                >
                  {t('eTicket_add_cash')}
                </Button>
              </div>
            </div>
          </form>
        );
      }}
    />
  );
};

Service.propTypes = {
  t: PropTypes.func.isRequired,
  setDocumentTitle: PropTypes.func.isRequired,
  setDocumentDescription: PropTypes.func.isRequired,
  service: PropTypes.shape().isRequired,
  dispatchValidateEticketService: PropTypes.func.isRequired,
  serviceError: PropTypes.shape(),
  servicesHistory: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

Service.defaultProps = {
  serviceError: null,
};

const mapDispatchToProps = (dispatch) => ({
  dispatchValidateEticketService: (serviceId, validFromDate) => dispatch(
    validateEticketService(serviceId, validFromDate),
  ),
});

const mapStateToProps = (state) => ({
  servicesHistory: state.eTicket.getIn(['cardContent', 'servicesHistory']),
  serviceError: state.request.getIn([endpoints.E_TICKET_VALIDATE_SERVICE.name, 'error']),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(
  withDocumentHead(Service),
));
