import { all } from 'redux-saga/effects';
import FooterSaga from './CMS/Components/Footer/FooterSaga';
import StopsSaga from './Stops/StopsSaga';
import SearchSaga from './Search/SearchSaga';
import ModalSaga from './Components/Modal/ModalSaga';
import TopRoutesStationsSaga from './TopRoutesStations/TopRoutesStationsSaga';
import UserSaga from './User/UserSaga';
import ResetPasswordSaga from './Components/ResetPassword/ResetPasswordSaga';
import CMSSaga from './CMS/CMSSaga';
import ContactSaga from './Contact/ContactSaga';
import NewsletterSaga from './Components/Newsletter/NewsletterSaga';
import BannersSaga from './Data/Collections/Banners/BannersSaga';
import ETicketSaga from './ETicket/ETicketSaga';
import OrderSaga from './Order/OrderSaga';
import PaymentSaga from './Data/Collections/Payment/PaymentSaga';
import TicketSearchSaga from './TicketSearch/TicketSearchSaga';
import LocalesSaga from './Locales/LocalesSaga';
import TicketReturnSaga from './TicketReturn/TicketReturnSaga';
import EventsSaga from './Events/EventsSaga';
import TicketChangeSaga from './TicketChange/TicketChangeSaga';
import StationsSaga from './Stations/StationsSaga';
import RouteMapSaga from './Components/RouteMap/RouteMapSaga';
import EurolinesTopRoutesSaga from './EurolinesTopRoutes/EurolinesTopRoutesSaga';
import BlogsSaga from './Blogs/BlogsSaga';

export default function* rootSaga() {
  yield all([
    StopsSaga(),
    SearchSaga(),
    ModalSaga(),
    FooterSaga(),
    TopRoutesStationsSaga(),
    UserSaga(),
    ResetPasswordSaga(),
    ContactSaga(),
    EurolinesTopRoutesSaga(),
    CMSSaga(),
    BannersSaga(),
    ETicketSaga(),
    NewsletterSaga(),
    OrderSaga(),
    PaymentSaga(),
    TicketSearchSaga(),
    LocalesSaga(),
    TicketReturnSaga(),
    EventsSaga(),
    TicketChangeSaga(),
    StationsSaga(),
    RouteMapSaga(),
    BlogsSaga(),
  ]);
}
