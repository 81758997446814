import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import variantConst from '../../../core/config/constants/variantConst/variantConst';
import playStore from '../../../../assets/images/googlePlayStore.png';
import appStore from '../../../../assets/images/appleAppStore.png';

const AppStores = ({ t }) => (
  <div className="app-stores-container">
    <span>{t(variantConst.downloadMobileApp)}</span>

    <div className="row-container">
      <a href={t(variantConst.appGooglePlayStoreLink)} rel="noopener noreferrer" target="_blank">
        <img className="app-stores-img" src={playStore} alt="Google play store logo" />
      </a>

      <a href={t(variantConst.appAppleStoreLink)} rel="noopener noreferrer" target="_blank">
        <img className="app-stores-img" src={appStore} alt="Apple app store logo" />
      </a>
    </div>
  </div>
);

AppStores.propTypes = {
  t: PropTypes.func.isRequired,
};

export default (withTranslation()(AppStores));
