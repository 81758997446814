import { Map } from 'immutable';
import { constants } from '../constants';

const initialState = Map({});

const ETicketReducer = (state = initialState, action) => {
  switch (action.type) {
  case constants.token.type.E_TICKET:
    return state.set('values', action.payload.values);
  case constants.eTicket.SET_CURRENT:
    return state.set('cardContent', action.payload.cardContent);
  case constants.eTicket.order.SET_STATUS:
    return state.set('status', action.payload.status);
  case constants.eTicket.order.SET_DATA:
    return state.set('order', action.payload.data);
  default:
    return state;
  }
};

export default ETicketReducer;
