import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { cityTypes } from '../../../../../core/config/constants/cityTypes';
import withDocumentHead from '../../../../common/DocumentHead/withDocumentHead';
import CMSContent from '../../../CMS/CMSContent';
import './FAQ.scss';
import variantConst from '../../../../../core/config/constants/variantConst/variantConst';

const FAQ = ({
  t, setDocumentTitle, setDocumentDescription, cityId,
}) => {
  const cityFaqPageKeyMapper = {
    [cityTypes.DRUSKININKAI]: '107a2a9dd97d10c604acb97bf5f97184',
    [cityTypes.ALYTUS]: '565282d5bf6540ff8ecfe01c5de54ebf',
    [cityTypes.MARIJAMPOLE]: 'dfdd744ff3bd7126beff2bd048d594fc',
  };

  const page = cityFaqPageKeyMapper[cityId] || '';

  useEffect(() => {
    if (t) {
      setDocumentTitle(`${t(variantConst.documentTitle)} ${t('document_title_eticket_faq')}`);

      setDocumentDescription(t('document_description_eticket_faq'));
    }
  }, [t]);

  return (
    <div className="faq-container">
      <CMSContent page={page} />
    </div>
  );
};

FAQ.propTypes = {
  t: PropTypes.func.isRequired,
  setDocumentTitle: PropTypes.func.isRequired,
  setDocumentDescription: PropTypes.func.isRequired,
  cityId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  cityId: state.eTicket.getIn(['cardContent', 'cityId']),
});

export default connect(mapStateToProps)(withTranslation()(withDocumentHead(FAQ)));
