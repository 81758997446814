import { constants } from '../constants';

export const submitContact = (values) => ({
  type: constants.contact.SUBMIT,
  payload: {
    values,
  },
});

export const setContactStatus = (status) => ({
  type: constants.contact.SET_STATUS,
  payload: {
    status,
  },
});
