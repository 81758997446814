import { Map } from 'immutable';
import { constants } from '../constants';

const initialState = Map({});

const ContactReducer = (state = initialState, action) => {
  switch (action.type) {
  case constants.contact.SET_STATUS:
    return state.set('submitted', action.payload.status);

  default:
    return state;
  }
};

export default ContactReducer;
