import {
  all,
  put,
  call,
  takeEvery,
} from 'redux-saga/effects';
import { constants } from '../constants';
import { startRequest } from '../Request/RequestSaga';
import { endpoints } from '../../config/endpoints';
import { setContactStatus } from './ContactActions';

export function* submitContantForm(action) {
  const data = {
    email: action.payload.values.email,
    message: action.payload.values.message,
    token: action.payload.values.token,
    sendCopy: action.payload.values.sendCopy,
  };

  const { response } = yield call(
    startRequest,
    endpoints.CONTACT.name,
    endpoints.CONTACT.url.path(),
    data,
  );

  if (response) {
    yield all([
      put(setContactStatus(response.data.data.success)),
    ]);
  }
}

export default function* ContactSaga() {
  yield all([
    takeEvery(constants.contact.SUBMIT, submitContantForm),
  ]);
}
