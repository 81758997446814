import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { List } from 'immutable';
import './Sidebar.scss';
import { Link } from 'react-router-dom';
import { getLink, routes } from '../../../../core/config/routes';
import Icon from '../../../../components/presentational/Icon/Icon';

const Sidebar = ({
  t, topRoutes, menuOpen, onOpenMenu,
}) => (
  <div className="top-routes-sidebar-container">
    <div className="top-routes-sidebar">
      <div className="sidebar-arrow" />

      <div className="top-routes-sidebar-title">
        <div className="top-routes-sidebar__icon">
          <Icon icon="road" />
        </div>

        <div className="top-routes-sidebar-title-text">
          <div className="top-routes-sidebar-title__title">{t('common_popular_tickets')}</div>

          <div className="top-routes-sidebar-title__subtitle">
            {t('common_top_ten')}
          </div>
        </div>
      </div>

      <ul className={`top-routes-stations-list ${!menuOpen ? 'is-hidden' : ''}`}>
        {Array.from(topRoutes).map((topRoute, i) => (
          <Link
            className="top-routes-stations-list-item"
            key={topRoute.fromStopName + topRoute.toStopName}
            to={getLink(t(routes.topRoutes.route), { slug: topRoute.slug })}
          >
            <div>
              <span>{i + 1}. {topRoute.fromStopName} - {topRoute.toStopName}</span>
            </div>

            <Icon icon="arrow-right" />
          </Link>
        ))}
      </ul>

      <button
        className="sidebar-menu-switch"
        onClick={onOpenMenu}
      >
        <Icon icon={`chevron-${menuOpen ? 'up' : 'down'}`} />
      </button>
    </div>
  </div>
);

Sidebar.propTypes = {
  t: PropTypes.func.isRequired,
  topRoutes: PropTypes.shape(),
  menuOpen: PropTypes.bool,
  onOpenMenu: PropTypes.func.isRequired,
};

Sidebar.defaultProps = {
  topRoutes: List([]),
  menuOpen: false,
};

export default withTranslation()(Sidebar);
