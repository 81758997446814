import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import './HeaderButton-VARIANT.scss';
import Icon from '../../../../../components/presentational/Icon/Icon';

const HeaderButton = ({
  icon, ...props
}) => {
  const {
    className, children, id, position, ...rest
  } = props;
  let ButtonComponent = 'div';

  if (props.to) {
    ButtonComponent = NavLink;
  }

  if (position === 'left' && !props.to) {
    ButtonComponent = 'button';
  }

  const ariaLabel = typeof children === 'string' ? children : null;

  if (id === 'user' && !ariaLabel) {
    ButtonComponent = 'div';
  }

  return (
    <ButtonComponent
      className={`
        header-button
        ${position}-button
        ${icon ? 'icon-button' : 'text-button'}
        ${id === 'events' ? 'is-active' : ''}
        ${className}    
      `}
      aria-label={ariaLabel || undefined}
      exact={props.to === '/'}
      {...rest}
    >
      {icon && <Icon icon={icon} />}

      <span className="header-button-text">{children}</span>
    </ButtonComponent>
  );
};

HeaderButton.propTypes = {
  position: PropTypes.string.isRequired,
  icon: PropTypes.string,
  to: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  id: PropTypes.string,
};

HeaderButton.defaultProps = {
  icon: null,
  to: null,
  className: '',
  children: null,
  id: null,
};

export default HeaderButton;
