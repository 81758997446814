import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Route } from 'react-router';
import { constants } from '../../../core/modules/constants';
import UserLogin from './Partials/Login/UserLogin';
import { routes } from '../../../core/config/routes';
import Details from './Partials/Details/Details';
import History from './Partials/History/History';
import UserSidebar from './Partials/Sidebar/UserSidebar';
import UserTicket from './Partials/Ticket/UserTicket';
import './User.scss';
import { withTracker } from '../../common/Tracker/withTracker';
import LocalizedSwitch from '../../common/LocalizedSwitch/LocalizedSwitch';

const User = ({ isAuthenticated }) => {
  if (isAuthenticated) {
    return (
      <div className="user-page">
        <UserSidebar />

        <div className="user-pages" aria-live="polite">
          <LocalizedSwitch>
            <Route exact path={routes.user.index} component={History} />
            <Route exact path={routes.user.details} component={Details} />
            <Route exact path={routes.user.orderHistory} component={UserTicket} />
          </LocalizedSwitch>
        </div>
      </div>
    );
  }
  return <UserLogin />;
};

User.propTypes = {
  isAuthenticated: PropTypes.bool,
};

User.defaultProps = {
  isAuthenticated: false,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.token.hasIn([constants.token.type.USER, 'token']),
});

export default connect(mapStateToProps, null)((withTracker(User)));
