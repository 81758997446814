import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { getTimeFromSeconds } from '../../../core/utilities/date';
import SearchResultsRouteFeatures
from '../../containers/SearchResultsRoute/Partials/Features/Features';
import TransfersDropdown from './Partials/TransfersDropdown/TransfersDropdown';
import RoutePrice from './Partials/RoutePrice/RoutePrice';
import SearchResultsRouteMobile from './SearchResultsRouteMobile/SearchResultsRouteMobile';
import './SearchResultsRoute.scss';
import Icon from '../Icon/Icon';
import Button from '../Button/Button';

const SearchResultsRoute = (props) => {
  const {
    t,
    route,
    tripTimes,
    transferDropdownActive,
    toggleTransferDropdown,
    openRouteSchedule,
    searchValues,
    exchangeButtonProps,
  } = props;
  const { sellable, duration, steps } = route;
  const { hours, mins } = getTimeFromSeconds(duration.value);
  const { departureTime, arrivalTime, daysDiff } = tripTimes;
  const { isForward } = searchValues;
  const isFirstStepWalking = steps[0].travelMode === 'WALKING';
  const stepsCount = steps.length;
  const transferCount = isFirstStepWalking ? stepsCount - 2 : stepsCount - 1;
  const checkForStepsCount = isFirstStepWalking ? stepsCount > 2 : stepsCount > 1;
  const transferCountLabel = transferCount > 1 ? 'transfer_plural' : 'transfer';

  return (
    <>
      <div className="search-results-route is-desktop">
        <div className="search-results-route-time">
          <div className="search-results-route-time__value">
            <span>
              <b>{departureTime}</b> - {arrivalTime}
            </span>

            {!!daysDiff && (
              <>
                {' '}
                <div className="search-results-route-time__diff">
                  (+{daysDiff}d)
                </div>
              </>
            )}
          </div>

          <div className="search-results-route-time__duration">
            {t('route_ticket_duration')}:&nbsp;
            {hours !== 0 && t('route_duration_hours', { hours })}&nbsp;
            {t('route_duration_minutes', { mins })}
          </div>
        </div>

        <div className={`
            search-results-route-info
            ${checkForStepsCount ? 'has-steps' : ''}
            ${transferDropdownActive ? 'is-active' : ''}`}
        >
          <span>{route.title}</span>

          {steps && checkForStepsCount ? (
            <div className="search-results-route-info-buttons">
              <Button variant="inline-link-dotted" onClick={toggleTransferDropdown} ariaExpanded={transferDropdownActive}>
                <span>
                  {transferCount}&nbsp;
                  {t(`route_${transferCountLabel}`)}
                </span>
              </Button>

              <Button variant="inline-link-dotted" onClick={openRouteSchedule}>
                <span>{t('route_trip_map')}</span>
              </Button>
            </div>
          ) : (
            <Button variant="inline-link-dotted" onClick={openRouteSchedule} ariaExpanded="false">
              <Icon icon="list" />

              <span>{t('route_schedule')}</span>
            </Button>
          )}
        </div>

        {steps && (isFirstStepWalking ? stepsCount === 2 : stepsCount === 1) && (
          <SearchResultsRouteFeatures route={route.steps[0]} />
        )}

        <div className="search-results-route-purchase-wrap promo-price-wrapper">
          <RoutePrice
            isForward={isForward}
            sellable={sellable}
            route={route}
            exchangeButtonProps={exchangeButtonProps}
            toggleTransferDropdown={toggleTransferDropdown}
            transferDropdownActive={transferDropdownActive}
          />
        </div>
      </div>
      <hr className="hr-line" />

      {transferDropdownActive && <TransfersDropdown route={route} />}

      <SearchResultsRouteMobile
        isForward={isForward}
        route={route}
        tripDuration={tripTimes}
        exchangeButtonProps={exchangeButtonProps}
      />
    </>
  );
};

SearchResultsRoute.propTypes = {
  t: PropTypes.func.isRequired,
  route: PropTypes.shape(),
  tripTimes: PropTypes.shape(),
  transferDropdownActive: PropTypes.bool,
  toggleTransferDropdown: PropTypes.func.isRequired,
  openRouteSchedule: PropTypes.func.isRequired,
  searchValues: PropTypes.shape(),
  exchangeButtonProps: PropTypes.shape(),
};

SearchResultsRoute.defaultProps = {
  route: null,
  transferDropdownActive: false,
  tripTimes: null,
  searchValues: null,
  exchangeButtonProps: null,
};

export default withTranslation()(SearchResultsRoute);
