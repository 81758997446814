import {
  all,
  put,
  call,
  takeEvery,
} from 'redux-saga/effects';
import { endpoints } from '../../../../config/endpoints';
import { constants } from '../../../constants';
import { addFooterColumns } from './FooterActions';
import { startRequest } from '../../../Request/RequestSaga';
import { getObjectNestedData } from '../../../../utilities/object';

export function* getCustomFooter(getEndpointsFooterAction) {
  const endpointsFooter = getEndpointsFooterAction.payload.endpointFooter;

  const responses = yield all(endpointsFooter.map((endpointFooter) => call(
    startRequest,
    endpoints.GET_CUSTOM_FOOTER.name,
    endpoints.GET_CUSTOM_FOOTER.url.path(endpointFooter),
  )));

  const footerColumns = responses.map((response) => {
    const data = getObjectNestedData(response, 'response.data.data');

    if (data) {
      const name = response.response.config.url.split('/').pop();

      return { name, data };
    }

    return null;
  });

  if (footerColumns.filter(Boolean).length) {
    yield put(addFooterColumns(footerColumns));
  }
}

export default function* FooterSaga() {
  yield all([
    takeEvery(constants.components.footer.GET_CUSTOM_FOOTER, getCustomFooter),
  ]);
}
