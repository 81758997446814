import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { destroyOrder, getOrder } from '../../../core/modules/Order/OrderActions';
import Preloader from '../../../components/presentational/Preloader/Preloader';
import NotFound from '../NotFound/NotFound';
import Sidebar from './Partials/Sidebar/Sidebar';
import './TicketChange.scss';
import Exchange from './Partials/Exchange/Exchange';
import Alert from '../../../components/presentational/Alert/Alert';
import { withTracker } from '../../common/Tracker/withTracker';

const TicketChange = ({
  t,
  match,
  order,
  loading,
  dispatchGetOrder,
  dispatchDestroyOrder,
}) => {
  const { orderHash, activeTicketId } = match.params;

  React.useEffect(() => {
    dispatchGetOrder(orderHash);

    return function cleanup() {
      dispatchDestroyOrder();
    };
  }, [orderHash]);

  if (loading) return <Preloader fullHeight fullWidth />;

  if (!order) return <NotFound />;

  const { hash, tickets, ticketType } = order;
  const activeTicket = !activeTicketId
    ? tickets[0]
    : tickets.filter((ticket) => ticket.ticketId.toString() === activeTicketId)[0];

  if (!activeTicket) return <NotFound />;

  const { canChange } = activeTicket;

  return (
    <div className="ticket-change">
      <Sidebar
        tickets={tickets}
        orderHash={hash}
        ticketType={ticketType}
      />

      <div className="ticket-change-content">
        <div className="ticket-change-content__header">
          {t('ticket_date_change')}
        </div>

        {!canChange && (
          <Alert
            variant="danger"
            heading={t('common_error_exclamation')}
          >
            {t('order_change_not_allowed')}
          </Alert>
        )}

        {canChange && (
          <Exchange
            key={activeTicket.ticketId}
            orderHash={hash}
            activeTicket={activeTicket}
            tickets={tickets}
            ticketType={ticketType}
          />
        )}
      </div>
    </div>
  );
};

TicketChange.propTypes = {
  t: PropTypes.func.isRequired,
  dispatchGetOrder: PropTypes.func.isRequired,
  dispatchDestroyOrder: PropTypes.func.isRequired,
  match: PropTypes.shape().isRequired,
  order: PropTypes.shape(),
  loading: PropTypes.bool,
};

TicketChange.defaultProps = {
  loading: false,
  order: null,
};

const mapStateToProps = (state) => ({
  loading: state.order.getIn(['status', 'loading']),
  order: state.order.get('data'),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchGetOrder: (orderHash) => dispatch(getOrder(orderHash)),
  dispatchDestroyOrder: () => dispatch(destroyOrder()),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withTranslation()(withTracker(TicketChange)));
