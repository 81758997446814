import { constants } from '../../../constants';

export const getPaymentMethods = () => ({
  type: constants.data.collections.payment.GET_PAYMENT_METHODS,
  payload: {},
});

export const setPaymentMethods = (methods) => ({
  type: constants.data.collections.payment.SET_PAYMENT_METHODS,
  payload: { methods },
});
