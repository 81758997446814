export const ticketReturnEndpoints = {
  FETCH_REFUND_INFO: {
    name: 'FETCH_REFUND_INFO',
    url: {
      method: 'GET',
      path: (orderHash, ticketId) => `/orders/${orderHash}/tickets/${ticketId}/refund`,
    },
  },
  REFUND_TICKET: {
    name: 'REFUND_TICKET',
    url: {
      method: 'POST',
      path: (orderHash, ticketId, refundType) => `/orders/${orderHash}/tickets/${ticketId}/refund/${refundType}`,
    },
  },
};
