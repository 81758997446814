import { Map } from 'immutable';
import { constants } from '../constants';

const initialState = Map({});

const BlogsReducer = (state = initialState, action) => {
  switch (action.type) {
  case constants.blogs.SET_BLOGS:
    return state.set('list', action.payload.blogs);

  case constants.blogs.SET_BLOG:
    return state.setIn(['blog', action.payload.slug], action.payload.blog);

  case constants.blogs.REMOVE_BLOGS_LIST:
    return state.remove('list');

  default:
    return state;
  }
};

export default BlogsReducer;
