import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Datepicker from '../../../containers/Datepicker/Datepicker';
import { formatDateToYYYYMMDD, validateDateFormat } from '../../../../core/utilities/date';
import Timepicker from '../../../containers/Timepicker/Timepicker';
import Checkbox from '../../FormElements/Checkbox/Checkbox';
import Button from '../../Button/Button';
import RouteAutocomplete from '../../../containers/FormElements/RouteAutocomplete/RouteAutocomplete';
import './SearchForm.scss';
import { getObjectNestedData } from '../../../../core/utilities/object';
import ButtonClose from '../../ButtonClose/ButtonClose';
import variantConst from '../../../../core/config/constants/variantConst/variantConst';

const SearchFormView = (props) => {
  const {
    formProps,
    stopsList,
    mobile,
    submitting,
    t,
    onGetStops,
    stopsLoading,
    onKeyPress,
    handleClick,
  } = props;
  const {
    values, errors, setFieldValue, setErrors,
  } = formProps;

  const {
    departureDate, departureTime, returnDate, returnTime,
  } = values;

  const openReturnCalendarOnRender = true;

  return (
    <>
      <div className="search-form">
        <div className="search-form-field">
          <label htmlFor="route-from" className="search-form-field__label">
            {t(mobile ? 'ticket_search_from' : 'ticket_search_travelling_from')}
          </label>

          <RouteAutocomplete
            placeholder={t(variantConst.ticketSearchPlaceholderFrom)}
            name="from.number"
            id="route-from"
            error={t(getObjectNestedData(errors, 'from.number'))}
            suggestions={Array.from(stopsList)}
            suggestionValue={(suggestion) => suggestion.name}
            onChange={({ number, name }) => {
              setErrors({
                from: null,
                to: null,
              });

              setFieldValue('from', { number, name });
            }}
            onInput={(value) => onGetStops(value, values.to.number)}
            loading={stopsLoading}
            value={values.from.name}
            onKeyPress={onKeyPress}
            handleClick={handleClick}
          />
        </div>

        <div className="search-form-field">
          <label htmlFor="route-to" className="search-form-field__label">
            {t(mobile ? 'ticket_search_to' : 'ticket_search_travelling_to')}
          </label>

          <RouteAutocomplete
            placeholder={t(variantConst.ticketSearchPlaceholderTo)}
            name="to.number"
            id="route-to"
            error={
              errors.from ? null : t(getObjectNestedData(errors, 'to.number'))
            }
            suggestions={Array.from(stopsList)}
            suggestionValue={(suggestion) => suggestion.name}
            onChange={({ number, name }) => {
              setErrors({
                from: null,
                to: null,
              });

              setFieldValue('to', { number, name });
            }}
            onInput={(value) => onGetStops(value, values.from.number)}
            loading={stopsLoading}
            value={values.to.name}
            onKeyPress={onKeyPress}
            handleClick={handleClick}
          />
        </div>

        <div className="search-form-field">
          <Datepicker
            id="depart-date"
            name="departureDate"
            onKeyPress={onKeyPress}
            onChange={(date) => {
              const formattedDate = formatDateToYYYYMMDD(date);

              setFieldValue('departureDateObj', date);
              setFieldValue('departureDate', formattedDate);
              setFieldValue('departureTime', '00:00');

              if (!values.isForward) {
                setFieldValue('returnTime', '00:00');
              }
            }}
            value={departureDate}
            label={t('ticket_search_departure_date_time')}
            dateLabel={t('choose_time')}
            minDate={new Date()}
            timepicker={(
              <Timepicker
                id="depart-time"
                name="departureTime"
                value={departureTime}
                onChange={(time) => setFieldValue('departureTime', time)}
              />
            )}
            error={validateDateFormat(departureDate) ? null : t('invalid_date')}
          />
        </div>

        <div className="search-form-field">
          {!values.isForward ? (
            <>
              <ButtonClose
                className="search-form-field__button"
                ariaLabel={t('exit_button')}
                onClick={() => {
                  setFieldValue('isForward', true);
                  setFieldValue('returnDateObj', null);
                  setFieldValue('returnDate', null);
                  setFieldValue('returnTime', null);
                }}
              />

              <Datepicker
                id="return-date"
                label={t('ticket_search_return_date_time')}
                onChange={(date) => {
                  const formattedDate = formatDateToYYYYMMDD(date);

                  setFieldValue('returnDateObj', date);
                  setFieldValue('returnDate', formattedDate);
                  setFieldValue('returnTime', '00:00');
                }}
                value={returnDate || departureDate}
                minDate={new Date(departureDate)}
                openOnRender={openReturnCalendarOnRender}
                timepicker={(
                  <Timepicker
                    id="return-time"
                    name="returnTime"
                    value={returnTime || departureTime}
                    onChange={(time) => setFieldValue('returnTime', time)}
                  />
                )}
                error={validateDateFormat(returnDate) ? null : t('invalid_date')}
              />
            </>
          ) : (
            <Checkbox
              id="ticket-backward-checkbox"
              name={t('ticket_search_would_buy_round_trip')}
              label={t('ticket_search_would_buy_round_trip')}
              onChange={() => {
                setFieldValue('isForward', false);
                setFieldValue('returnDateObj', values.departureDateObj);
                setFieldValue('returnDate', values.departureDate);
                setFieldValue('returnTime', values.departureTime);
              }}
              value={!values.isForward}
            />
          )}
        </div>
      </div>

      <div className="search-form-button">
        <Button
          big
          type="submit"
          variant="danger"
          loading={submitting}
          ariaLabel={t('ticket_search_search_trip')}
        >
          {t('ticket_search_search_trip')}
        </Button>
      </div>
    </>
  );
};

SearchFormView.propTypes = {
  formProps: PropTypes.shape().isRequired,
  stopsList: PropTypes.shape(),
  mobile: PropTypes.bool,
  submitting: PropTypes.bool,
  t: PropTypes.func.isRequired,
  onGetStops: PropTypes.func.isRequired,
  stopsLoading: PropTypes.bool,
  onKeyPress: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
};

SearchFormView.defaultProps = {
  stopsList: null,
  mobile: false,
  submitting: false,
  stopsLoading: false,
};

export default withTranslation()(SearchFormView);
