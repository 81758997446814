export default {
  newsletterButtonVariant: '',
  eventTemplateRouteBack: 'routes_events_index',
  knowBoxArrowCircle: 'undo-arrow-circle',
  knowBoxClock: 'clock',
  menuItemLoginIcon: 'profile',
  menuItemLoginClassName: 'is-always-visible',
  loyaltyTicketInfoTitle: 'loyalty_ticket_info_title',
  loyaltyDiscountPageTitle: 'loyalty_discount_page_title',
  homepageSearchFormTitle: 'ticket_search_find_bus_trip',
  homepageSearchFormSubtitle: 'ticket_search_routes_lt_and_eu',
  ticketSignUpOnPage: 'ticket_sign_up_on_page',
  termsAndConditionsCmsId: '38687b39457a690824d87c05f0043ec2',
  busIcon: 'green-bus',
  documentTitle: 'document_title',
  documentTitleHomepage: 'document_title_homepage',
  documentDescriptionHomepage: 'document_description_homepage',
  documentTitleSearch: 'document_title_search',
  documentDescriptionSearch: 'document_description_search',
  documentTitleOrderCheckout: 'document_title_order_checkout',
  documentDescriptionOrderCheckout: 'document_description_order_checkout',
  documentTitleCMS: 'document_title_cms',
  documentDescriptionCMS: 'document_description_cms',
  documentTitleTopRoutes: 'document_title_top_routes',
  documentDescriptionTopRoutes: 'document_description_top_routes',
  documentTitleStationsList: 'document_title_stations_list',
  documentDescriptionStationsList: 'document_description_stations_list',
  documentTitleStationsListAbroad: 'document_title_stations_list_abroad',
  documentDescriptionStationsListAbroad: 'document_description_stations_list_abroad',
  documentTitleStation: 'document_title_station',
  documentDescriptionStationList: 'document_description_station',
  documentTitleUserLogin: 'document_title_user_login',
  documentDescriptionUserLogin: 'document_description_user_login',
  documentTitleUserOrdersHistory: 'document_title_user_orders_history',
  documentDescriptionUserOrdersHistory: 'document_description_user_orders_history',
  documentTitleUserAccountDetails: 'document_title_user_account_details',
  documentDescriptionUserAccountDetails: 'document_description_user_account_details',
  downloadMobileApp: 'download_mobile_app',
  appGooglePlayStoreLink: 'app_google_play_store_link',
  appAppleStoreLink: 'app_apple_store_link',
  ticketSearchPlaceholderFrom: 'ticket_search_placeholder_from_web',
  ticketSearchPlaceholderTo: 'ticket_search_placeholder_to_web',
  urlCmsDiscounts: 'url_cms_discounts',
};
