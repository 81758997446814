import { constants } from '../constants';

export const initializeOrder = (order) => ({
  type: constants.order.INITIALIZE,
  payload: {
    order,
  },
});

export const setOrderStatus = (status) => ({
  type: constants.order.SET_STATUS,
  payload: {
    status,
  },
});

export const setOrderData = (data) => ({
  type: constants.order.SET_DATA,
  payload: {
    data,
  },
});

export const addPassenger = (orderHash) => ({
  type: constants.order.PASSENGER_ADD,
  payload: {
    orderHash,
  },
});

export const updatePassengerName = (orderHash, passengerId, name) => ({
  type: constants.order.PASSENGER_UPDATE_NAME,
  payload: {
    orderHash,
    passengerId,
    name,
  },
});

export const updatePassengerPartDiscount = (orderHash, passengerId, part, discountId) => ({
  type: constants.order.PASSENGER_UPDATE_PART_DISCOUNT,
  payload: {
    orderHash,
    passengerId,
    part,
    discountId,
  },
});

export const removePassenger = (orderHash, passengerId) => ({
  type: constants.order.PASSENGER_REMOVE,
  payload: {
    orderHash,
    passengerId,
  },
});

export const addCoupon = (orderHash, couponNumber) => ({
  type: constants.order.ADD_COUPON,
  payload: { orderHash, couponNumber },
});

export const removeCoupon = (orderHash) => ({
  type: constants.order.REMOVE_COUPON,
  payload: { orderHash },
});

export const setPaymentMethods = (methods) => ({
  type: constants.order.SET_PAYMENT_METHODS,
  payload: { methods },
});

export const selectOrderPaymentMethod = (orderHash, paymentMethodId, countryId) => ({
  type: constants.order.SELECT_ORDER_PAYMENT_METHOD,
  payload: { orderHash, paymentMethodId, countryId },
});

export const selectOrderTicketType = (orderHash, ticketType) => ({
  type: constants.order.SELECT_TICKET_TYPE,
  payload: { orderHash, ticketType },
});

export const addBike = (orderHash, passengerId, direction) => ({
  type: constants.order.ADD_BIKE,
  payload: { orderHash, passengerId, direction },
});

export const removeBike = (orderHash, passengerId, direction) => ({
  type: constants.order.REMOVE_BIKE,
  payload: { orderHash, passengerId, direction },
});

export const destroyOrder = () => ({
  type: constants.order.DESTROY,
  payload: {},
});

export const submitOrder = (orderHash, orderData) => ({
  type: constants.order.SUBMIT,
  payload: { orderHash, orderData },
});

export const getOrder = (orderHash) => ({
  type: constants.order.GET,
  payload: { orderHash },
});

export const setOrderRegistered = (registered) => ({
  type: constants.order.SET_REGISTERED,
  payload: {
    registered,
  },
});

export const addLoyaltyDiscount = (orderHash) => ({
  type: constants.order.ADD_LOYALTY_DISCOUNT,
  payload: {
    orderHash,
  },
});

export const removeLoyaltyDiscount = (orderHash) => ({
  type: constants.order.REMOVE_LOYALTY_DISCOUNT,
  payload: {
    orderHash,
  },
});
