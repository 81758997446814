import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getCMSPage } from '../../../core/modules/CMS/CMSActions';
import ContentBlock from './ContentBlocks/ContentBlock';
import Preloader from '../../../components/presentational/Preloader/Preloader';
import CMSSidebar from './CMSSidebar';
import './CMSContent.scss';
import NotFound from '../NotFound/NotFound';
import withDocumentHead from '../../common/DocumentHead/withDocumentHead';
import variantConst from '../../../core/config/constants/variantConst/variantConst';

const CMSContent = ({
  page, getPageContent, pageContent, setDocumentTitle, t, setDocumentDescription,
}) => {
  React.useEffect(() => {
    if (page) getPageContent(page);
  }, [page]);

  React.useEffect(() => {
    if (pageContent) {
      setDocumentTitle(`${t(variantConst.documentTitle)} ${t(variantConst.documentTitleCMS, { cmsTitle: pageContent.title })}`);

      setDocumentDescription(t(variantConst.documentDescriptionCMS));
    }
  }, [pageContent]);

  return (
    <div className={`cms-content-wrapper ${(pageContent && pageContent.loading) ? 'is-loading' : ''}`}>
      {pageContent && (
        <>
          {pageContent.error && <NotFound />}

          {pageContent.loading && <Preloader fullHeight fullWidth />}

          {!pageContent.error && !pageContent.loading && (
            <>
              <CMSSidebar menuFolder={pageContent.menuFolder} />

              <div className="cms-content" id="cms-content" tabIndex="-1" aria-live="polite">
                {pageContent.title && (
                  <h1 className="cms-content__title">{pageContent.title}</h1>
                )}

                {pageContent
                  .contentBlocks
                  .map((contentBlock) => (
                    <ContentBlock block={contentBlock} key={contentBlock.id} />
                  ))}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

CMSContent.propTypes = {
  getPageContent: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  page: PropTypes.string,
  pageContent: PropTypes.shape(),
  setDocumentTitle: PropTypes.func.isRequired,
  setDocumentDescription: PropTypes.func.isRequired,
};

CMSContent.defaultProps = {
  pageContent: null,
  page: '',
};

const mapStateToProps = (state, props) => ({
  pageContent: state.data.collections.cms.getIn(['pages', state.locale, props.page]),
});

const mapDispatchToProps = (dispatch) => ({
  getPageContent: (cmsPage) => dispatch(getCMSPage(cmsPage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(withDocumentHead(CMSContent)),
);
