import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import './Login.scss';
import { Formik } from 'formik';
import Input from '../../../../../components/presentational/FormElements/Input/Input';
import { getErrors } from '../../../../../core/utilities/formValidator';
import { validations } from '../../../../../core/config/form/validations';
import Button from '../../../../../components/presentational/Button/Button';
import { eTicketLogin } from '../../../../../core/modules/ETicket/ETicketActions';
import Alert from '../../../../../components/presentational/Alert/Alert';
import { eTicketEndpoints } from '../../../../../core/config/endpoints/eTicket';
import { setRequestError } from '../../../../../core/modules/Request/RequestActions';
import Icon from '../../../../../components/presentational/Icon/Icon';
import withDocumentHead from '../../../../common/DocumentHead/withDocumentHead';
import variantConst from '../../../../../core/config/constants/variantConst/variantConst';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: false,
    };

    this.form = React.createRef();
  }

  componentDidMount() {
    const { setDocumentTitle, setDocumentDescription, t } = this.props;

    if (t) {
      setDocumentTitle(`${t(variantConst.documentTitle)} ${t('document_title_eticket_login')}`);

      setDocumentDescription(t('document_description_eticket_login'));
    }
  }

  componentWillUnmount() {
    const { unsetETicketError } = this.props;

    unsetETicketError();
  }

  toggleShowPassword = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  onSubmit = ({
    cardNr, pin, cityId, pinType,
  }) => {
    const { dispatchETicketLogin } = this.props;

    if (pinType === 1) {
      pin = cardNr.slice(-6);
    }
    dispatchETicketLogin(cardNr, pin, cityId, pinType);
  };

  render() {
    const { showPassword } = this.state;
    const {
      eTicketLoginErrors, t, eTicketLoginLoading, cityId, pinType,
    } = this.props;

    return (
      <div className="eTicket-login">
        <div className="eTicket-login-column">
          <Formik
            validateOnChange={false}
            onSubmit={this.onSubmit}
            initialValues={{
              cardNr: (pinType === 1 ? 9440401400 : ''),
              pin: '',
              cityId,
              pinType,
            }}
            validate={(values) => getErrors(values, {
              cardNr: [validations.eTicket.cardNr],
            }, this.form)}
            render={(formProps) => {
              const {
                values,
                handleSubmit,
                setFieldValue,
                errors,
              } = formProps;
              const { cardNr, pin } = values;

              return (
                <form onSubmit={handleSubmit} ref={this.form}>
                  <div className={eTicketLoginLoading ? 'loading-container__overlay' : ''} />
                  <div className="eticket-login-column-container">
                    { eTicketLoginErrors.length !== null
                      && eTicketLoginErrors.map((error) => (
                        <Alert
                          key={error}
                          variant="danger"
                          heading={t('common_error_exclamation')}
                        >
                          {error}
                        </Alert>
                      ))}

                    <div className="eticket-login-form-input-row">
                      <Input
                        name="cardNr"
                        id="cardNr"
                        label={t('e_ticket_form_card_number')}
                        type="text"
                        value={cardNr}
                        onChange={(e) => setFieldValue('cardNr', e.target.value)}
                        error={t(errors.cardNr)}
                        disabled={eTicketLoginLoading}
                      />
                    </div>

                    { pinType !== 1
                          && (
                            <div className="eticket-login-form-input-row">
                              <Input
                                name="pin"
                                id="pin"
                                label={t('e_ticket_form_password')}
                                type={showPassword ? 'text' : 'password'}
                                value={pin}
                                error={t(errors.pin)}
                                disabled={eTicketLoginLoading}
                                onChange={(e) => setFieldValue('pin', e.target.value)}
                                icon={(
                                  <button
                                    className="input-icon__button"
                                    type="button"
                                    onClick={this.toggleShowPassword}
                                    disabled={eTicketLoginLoading}
                                  >
                                    <Icon icon="view" /> {t(showPassword ? 'login_hide' : 'login_show')}
                                  </button>
                                )}
                              />
                            </div>
                          ) }

                  </div>

                  <div className="eTicket-login-form-actions">
                    <Button
                      variant="brand"
                      type="submit"
                      loading={eTicketLoginLoading}
                    >
                      {t('login_connect')}
                    </Button>
                  </div>
                </form>
              );
            }}
          />
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  t: PropTypes.func.isRequired,
  setDocumentTitle: PropTypes.func.isRequired,
  setDocumentDescription: PropTypes.func.isRequired,
  dispatchETicketLogin: PropTypes.func.isRequired,
  eTicketLoginErrors: PropTypes.arrayOf(PropTypes.string),
  unsetETicketError: PropTypes.func.isRequired,
  eTicketLoginLoading: PropTypes.bool,
  cityId: PropTypes.number,
  pinType: PropTypes.number,
};

Login.defaultProps = {
  eTicketLoginLoading: false,
  eTicketLoginErrors: [],
  cityId: null,
  pinType: null,
};

const mapStateToProps = (state) => ({
  eTicketLoginLoading: state.request.getIn([eTicketEndpoints.E_TICKET_LOGIN.name, 'loading']),
  eTicketLoginErrors: state.request.getIn([eTicketEndpoints.E_TICKET_LOGIN.name, 'error', 'global']),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchETicketLogin: (cardNr, pin, cityId) => dispatch(eTicketLogin(cardNr, pin, cityId)),
  unsetETicketError: () => dispatch(setRequestError(eTicketEndpoints.E_TICKET_LOGIN.name), {}),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(withDocumentHead(Login)),
);
