/* eslint-disable react/no-danger */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import { Link } from 'react-router-dom';
import Button from '../../../../../components/presentational/Button/Button';
import StationMap from '../StationMap/StationMap';
import './Station-VARIANT.scss';
import { getTopRoutes } from '../../../../../core/modules/TopRoutesStations/TopRoutesStationsActions';
import { getLink, routes } from '../../../../../core/config/routes';
import { endpoints } from '../../../../../core/config/endpoints';
import NotFound from '../../../NotFound/NotFound';
import { getStation } from '../../../../../core/modules/Stations/StationsActions';
import Preloader from '../../../../../components/presentational/Preloader/Preloader';
import Icon from '../../../../../components/presentational/Icon/Icon';
import withDocumentHead from '../../../../common/DocumentHead/withDocumentHead';
import variantConst from '../../../../../core/config/constants/variantConst/variantConst';

const Station = ({
  t,
  match,
  topRoutes,
  onGetAllTopRoutes,
  stationLoading,
  dispatchGetStation,
  stations,
  setDocumentTitle,
  setDocumentDescription,
  locale,
}) => {
  const { params } = match;
  const { station } = params;

  useEffect(() => {
    if (!topRoutes.size) onGetAllTopRoutes(locale);
  }, []);

  useEffect(() => {
    dispatchGetStation(station);
  }, [station]);

  const [mapVisible, showMap] = useState(false);
  const stationData = stations.get(station);

  useEffect(() => {
    if (stationData) {
      setDocumentTitle(`${t(variantConst.documentTitle)} ${t(variantConst.documentTitleStation, { stationTitle: stationData.title || '' })}`);
      setDocumentDescription(t(variantConst.documentDescriptionStationList));
    }
  }, [stationData]);

  const sortRoutes = () => {
    const { code: currentStationId } = stationData;
    const filteredRoutes = Array.from(topRoutes)
      .filter(({ fromStopId, toStopId }) => (
        fromStopId === currentStationId && toStopId !== currentStationId
      ));

    return filteredRoutes.sort((a, b) => a.toStopName.localeCompare(b.toStopName));
  };

  const groupRoutes = () => {
    const sortedRoutes = sortRoutes();

    const groups = [];
    const groupData = {};
    sortedRoutes.forEach((route) => {
      const routeFirstLetter = route.toStopName[0];

      if (!groups.includes(routeFirstLetter)) groups.push(routeFirstLetter);

      groupData[routeFirstLetter] = [
        ...groupData[routeFirstLetter] || [],
        route,
      ];
    });

    return groups.map((group) => groupData[group]);
  };

  if (stationLoading) return <Preloader fullWidth fullHeight />;

  if (!stationData && !stationLoading) return <NotFound />;

  if (stationData) {
    const {
      title, content, mapTitle, type,
    } = stationData;
    const groupedRoutes = groupRoutes();

    return (
      <div className="station-wrap">
        <div className="station-header">
          <div className="station-header__title">{mapTitle}</div>

          <Button
            route={t(type === 'l' ? routes.topStations.local.index : routes.topStations.abroad.index)}
            variant="inline-link"
            className="station-header__action"
          >
            <Icon icon="arrow-long-left" />

            <span>{t('common_popular_station_go_back')}</span>
          </Button>
        </div>

        <div className="station-content">
          <div className={`station-map ${!mapVisible ? 'is-disabled' : ''}`}>
            {mapVisible ? (
              <StationMap
                station={stationData}
                loadingElement={<div style={{ height: '100%' }} />}
                containerElement={<div style={{ height: '100%' }} />}
                mapElement={<div style={{ height: '100%' }} />}
                googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.GOOGLE_MAPS_KEY}`}
              />
            ) : (
              <Button
                variant="brand"
                className="route-schedule-modal-map__btn"
                onClick={() => showMap(true)}
              >
                {t('common_button_view')}
              </Button>
            )}
          </div>

          {content && (
            <div className="station-info" dangerouslySetInnerHTML={{ __html: content }} />
          )}
        </div>

        {!!groupedRoutes.length && (
          <>
            <div className="top-routes-stations-list__title">
              {t('common_popular_routes', { station: title })}:
            </div>

            <ul className="top-routes-stations-list">
              {groupedRoutes.map((routesGroup) => (
                <li className="top-routes-stations-list-group" key={`routes-array-${routesGroup[0].toStopName[0]}`}>
                  <div className="top-routes-stations-list__title">
                    {routesGroup[0].toStopName[0]}
                  </div>

                  <ul className="top-routes-stations-list-inner">
                    {routesGroup.map((route) => (
                      <li
                        className="top-routes-stations-list-item"
                        key={route.fromStopId + route.toStopId}
                      >
                        <Link
                          tabIndex={-1}
                          to={getLink(t(routes.topRoutes.route), { slug: route.slug })}
                        >
                          {route.toStopName}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    );
  }

  return null;
};

Station.propTypes = {
  t: PropTypes.func.isRequired,
  setDocumentTitle: PropTypes.func.isRequired,
  setDocumentDescription: PropTypes.func.isRequired,
  match: PropTypes.shape().isRequired,
  onGetAllTopRoutes: PropTypes.func.isRequired,
  dispatchGetStation: PropTypes.func.isRequired,
  stationLoading: PropTypes.bool,
  topRoutes: PropTypes.shape(),
  stations: PropTypes.instanceOf(Map).isRequired,
  locale: PropTypes.string.isRequired,
};

Station.defaultProps = {
  stationLoading: false,
  topRoutes: List([]),
};

const MapStateToProps = (state) => ({
  stationLoading: state.request.getIn([endpoints.GET_STATION.name, 'loading']),
  topRoutes: state.data.collections.topRoutesAndStations.getIn(['routes', state.locale]),
  stations: state.data.collections.stations,
  locale: state.locale,
});

const MapDispatchToProps = (dispatch) => ({
  onGetAllTopRoutes: (locale) => dispatch(getTopRoutes(locale)),
  dispatchGetStation: (slug) => dispatch(getStation(slug)),
});

export default connect(
  MapStateToProps,
  MapDispatchToProps,
)(withTranslation()(withDocumentHead(Station)));
