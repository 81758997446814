import React from 'react';
import { breakpointSet } from '../../../../../core/utilities/responsive';
import UserDropdown from './UserDropdown/UserDropdown';
import UserSubmenu from './UserSubmenu/UserSubmenu';

const User = () => {
  const [mobile, setMobile] = React.useState(false);

  React.useEffect(() => {
    setMobile(breakpointSet('sm', 'xs'));
  }, []);

  if (mobile) return <UserSubmenu />;

  return <UserDropdown />;
};

export default User;
