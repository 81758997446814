export const ticketChangeEndpoints = {
  SEARCH_ROUTES_BY_PART: {
    name: 'SEARCH_ROUTES_BY_PART',
    url: {
      method: 'POST',
      path: (orderHash, partId) => `/orders/${orderHash}/parts/${partId}/search`,
    },
  },
  SUBMIT_ORDER_CHANGE: {
    name: 'SUBMIT_ORDER_CHANGE',
    url: {
      method: 'POST',
      path: (orderHash, exchangeAll) => `/orders/${orderHash}/tickets/change?all=${exchangeAll}`,
    },
  },
};
