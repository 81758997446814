import { routes } from '../config/routes';
import { constants } from '../modules/constants';

const replaceParams = (url, redirectUrl, clientId) => url
  .replace('%redirect_uri%', redirectUrl)
  .replace('%client_id%', clientId);

export const generateOAuthRedirect = (provider) => {
  // Live works with both www/no www in url
  // Force usage of url with no www, because the api supports only a single url per channel
  const host = location.protocol === 'https:' ? location.host.replace('www.', '') : location.host;
  let redirect = `${location.protocol}//${host}`;

  switch (provider) {
  case constants.social.facebook.name:
    redirect += routes.oauthFacebookReturn;

    return replaceParams(
      constants.social.facebook.oauth_url,
      redirect,
      process.env.OAUTH_FACEBOOK_CLIENT_ID,
    );

  case constants.social.google.name:
    redirect += routes.oauthGoogleReturn;

    return replaceParams(
      constants.social.google.oauth_url,
      redirect,
      process.env.OAUTH_GOOGLE_CLIENT_ID,
    );
  default:
    return '';
  }
};
