import React from 'react';
import PropTypes from 'prop-types';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { Manager, Reference, Popper } from 'react-popper';
import { formatDate } from '../../../core/utilities/date';
import Icon from '../Icon/Icon';
import './Datepicker.scss';
import './DatepickerPlugin-VARIANT.scss';
import Navbar from './Partials/Navbar';

const DatepickerView = (props) => {
  const {
    id,
    name,
    minDate,
    value,
    label,
    required,
    error,
    onChange,
    onKeyPress,
    timepicker,
    openOnRender,
    translations,
    disabledDaysRanges,
    dateLabel,
  } = props;
  const inputRef = React.useRef(null);  
  const accessibilityContainerRef = React.useRef(null);
  const [isPopperOpen, setIsPopperOpen] = React.useState(false);

  const handleInputKeyDown = (event) => {
    const pressedKeyboardKey = event.key;

    if (event.shiftKey && pressedKeyboardKey === 'Tab') { 
      inputRef.current.blur();
      setIsPopperOpen(false);
      return;
    }

    if (pressedKeyboardKey === 'Escape') {      
      return;
    }

    if (pressedKeyboardKey === 'Enter' || !pressedKeyboardKey === 'ArrowDown') {
      event.preventDefault();
      event.stopPropagation();

      if (!accessibilityContainerRef.current) return;

      accessibilityContainerRef.current.focus();  
    }
  };

  const handleInputFocus = () => {
    setIsPopperOpen(true);    
  };

  const handleInputChange = (event) => {
    const pressedKeyboardKey = event.key;

    if (pressedKeyboardKey === 'Enter') {
      return;
    }

    const inputValue = event.target.value;    

    onChange(inputValue); 
  };

  const handleContentButtonKeyDown = (event) => {
    const pressedKeyboardKey = event.key;    

    if (pressedKeyboardKey === 'Escape') {
      setIsPopperOpen(false); 
      inputRef.current.focus();
    }
  };

  React.useEffect(() => {
    if (openOnRender) inputRef.current.focus();    
  }, []);
  
  const today = new Date();
  const oneDaysBeforeToday = new Date(today);
  oneDaysBeforeToday.setDate(today.getDate() - 1);

  return (
    <div
      aria-label={label}
      className="datepicker-container-wrap"
    >
      {!!label && (
        <label htmlFor="depart-date" className={`datepicker-label ${error ? 'has-error' : ''}`}>
          {label}

          {required && (
            <span className="datepicker-label__required">*</span>
          )}
        </label>
      )}      

      <div className={`datepicker-container ${error ? 'has-error' : ''}`}>
        <Manager>
          <Reference>
            {({ ref }) => (
              <>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <div htmlFor={id} ref={ref} className="datepicker-input-container">
                  <span className="date-select-label-text">{dateLabel}</span>
                  <Icon icon="calendar" />

                  <DayPickerInput
                    onDayChange={onChange}
                    value={value}
                    format="YYYY-MM-DD"
                    type="date"
                    formatDate={(date) => formatDate(date)}
                    inputProps={{
                      id,
                      name,                      
                      onKeyPress,
                      ref: inputRef,
                      value,
                      onKeyDown: handleInputKeyDown,    
                      onFocus: handleInputFocus,     
                      onChange: handleInputChange,                                               
                    }}
                    overlayComponent={({                      
                      children,
                      classNames,
                      selectedDay,
                      ...overlayProps
                    }) => (
                      <>
                        {
                          isPopperOpen && !error && (                        
                            <Popper                                   
                              placement="bottom-start"
                              modifiers={[
                                {
                                  name: 'preventOverflow',
                                  enabled: false,
                                },
                                {
                                  name: 'hide',
                                  enabled: false,
                                },
                                {
                                  name: 'offset',
                                  options: {
                                    offset: [0, 10],
                                  },
                                },
                              ]}
                            >
                              {({
                                ref: popperRef,
                                style,
                                placement,
                              }) => (
                                <div
                                  className="DayPickerInput-OverlayWrapper"
                                  ref={popperRef}
                                  style={style}
                                  data-placement={placement}
                                  {...overlayProps}
                                >
                                  <div 
                                    ref={accessibilityContainerRef} 
                                    className="DayPickerInput-OverlayWrapper__accessiblity-container" 
                                    tabIndex={0} 
                                    role="button"
                                    aria-label={
                                      translations.datePicker
                                    }
                                    onKeyDown={handleContentButtonKeyDown}
                                  >
                                    {children}
                                  </div>
                                </div>
                              )}
                            </Popper>
                          )
                        }
                      </>
                    )}
                    dayPickerProps={{
                      ...translations,
                      captionElement: () => null,
                      navbarElement: (navbarProps) => (
                        <Navbar
                          months={translations.months}
                          navigationTranslations={translations.calendarNavigationTranslations}
                          {...navbarProps}
                        />
                      ),
                      firstDayOfWeek: 1,
                      fromMonth: minDate,
                      disabledDays: [
                        { before: oneDaysBeforeToday },
                        ...disabledDaysRanges,
                      ],
                      renderDay: (day) => {
                        const dayFormatted = formatDate(day);
                        const isPast = day < new Date();
                        const isToday = dayFormatted === formatDate(new Date()); 
                        const disableTabIndex = isPast && !isToday;            
                      
                        return (
                          <button                            
                            className="DayPicker-Day__button"                                             
                            tabIndex={
                              disableTabIndex ? -1 : 0
                            }                            
                            aria-label={dayFormatted}        
                            type="button"      
                            onKeyDown={handleContentButtonKeyDown}
                          >
                            {day.getDate()}

                            <span className="DayPicker-Day__button__overlay" />
                          </button>
                        );
                      },                                                                        
                    }}
                  />
                </div>
              </>
            )}
          </Reference>
        </Manager>

        {timepicker}
      </div>

      {!!error && (
        <div className="datepicker__error" role="alert">
          {error}
        </div>
      )}
    </div>
  );
};

DatepickerView.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    PropTypes.string,
  ]),
  minDate: PropTypes.instanceOf(Date),
  label: PropTypes.string,
  dateLabel: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string,
  timepicker: PropTypes.node,
  openOnRender: PropTypes.bool,
  translations: PropTypes.shape(),
  disabledDaysRanges: PropTypes.arrayOf(PropTypes.shape()),
};

DatepickerView.defaultProps = {
  id: null,
  name: null,
  value: new Date(),
  minDate: new Date('1990-01-01'),
  label: null,
  dateLabel: null,
  required: false,
  openOnRender: false,
  error: null,
  timepicker: null,
  translations: {},
  onKeyPress: () => null,
  disabledDaysRanges: [],
};

export default DatepickerView;
