import { constants } from '../constants';

const initialState = null;

const TicketReturnReducer = (state = initialState, action) => {
  switch (action.type) {
  case constants.ticketReturn.SET_REFUND_INFO:
    return action.payload.refundInfo;

  case constants.ticketReturn.SET_REFUNDED:
    return {
      ...state,
      refunded: true,
    };

  default:
    return state;
  }
};

export default TicketReturnReducer;
