import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import logo from '../../../../assets/logo/logo-VARIANT.png';
import './Login.scss';
import LoginForm from './Partials/LoginForm/LoginForm';
import ResetPasswordRequestForm from './Partials/ResetPasswordRequestForm/ResetPasswordRequestForm';
import { userEndpoints } from '../../../core/config/endpoints/user';
import { setDisableUiClosing } from '../../../core/modules/Components/Modal/ModalActions';
import { resetPasswordEndpoints } from '../../../core/config/endpoints/resetPassword';
import SocialLoginButton from './Partials/Social/SocialLoginButton';
import { constants } from '../../../core/modules/constants';

class Login extends React.Component {
  constructor(props) {
    super(props);
    const { resetPassword } = props;

    this.state = {
      resetPassword,
    };
  }

  componentDidUpdate(prevProps) {
    const { loginUserLoading, resetPasswordLoading, dispatchSetDisableUiClosing } = this.props;
    const {
      loginUserLoading: prevLoginUserLoading,
      resetPasswordLoading: prevResetPasswordLoading,
    } = prevProps;
    const formLoadingStateChanged = loginUserLoading !== prevLoginUserLoading
      || resetPasswordLoading !== prevResetPasswordLoading;

    if (formLoadingStateChanged) {
      dispatchSetDisableUiClosing(loginUserLoading || resetPasswordLoading);
    }
  }

  toggleLoginModalContent = () => this.setState((prevState) => (
    { resetPassword: !prevState.resetPassword }
  ));

  render() {
    const { resetPassword } = this.state;
    const { t } = this.props;

    return (
      <div className="login-modal" aria-live="polite">
        <img className="login-modal-logo" src={logo} alt="Logo" />

        {resetPassword
          ? <ResetPasswordRequestForm toggleLoginModalContent={this.toggleLoginModalContent} />
          : <LoginForm toggleLoginModalContent={this.toggleLoginModalContent} />}
        <div className="divider-with-text login-divider">{t('or')}</div>
        <SocialLoginButton 
          provider={constants.social.google.name}
          icon={constants.social.google.name}
          ariaLabel={t('login_with_google')}
        >
          <span>{t('login_social_google')}</span>
        </SocialLoginButton>
        <SocialLoginButton
          provider={constants.social.facebook.name}
          icon={constants.social.facebook.name}
          ariaLabel={t('login_with_facebook')}
        >
          <span>{t('login_social_facebook')}</span>
        </SocialLoginButton>
      </div>
    );
  }
}

Login.propTypes = {
  resetPassword: PropTypes.bool,
  loginUserLoading: PropTypes.bool,
  resetPasswordLoading: PropTypes.bool,
  dispatchSetDisableUiClosing: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

Login.defaultProps = {
  resetPassword: false,
  loginUserLoading: false,
  resetPasswordLoading: false,
};

const mapStateToProps = (state) => ({
  loginUserLoading: state.request.getIn([userEndpoints.LOGIN_USER.name, 'loading']),
  resetPasswordLoading: state.request.getIn([resetPasswordEndpoints.REQUEST_PASSWORD_RESET.name, 'loading']),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSetDisableUiClosing: (uiClosing) => dispatch(setDisableUiClosing(uiClosing)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Login));
