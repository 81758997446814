import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { getTimeFromSeconds } from '../../../../../../core/utilities/date';
import RouteLine from '../../RouteLine/RouteLine';
import SearchResultsRouteFeatures
from '../../../../../containers/SearchResultsRoute/Partials/Features/Features';
import Icon from '../../../../Icon/Icon';

const TransferRouteMobile = (props) => {
  const {
    t, route, isFirst, isLast, tripDuration,
  } = props;
  const { duration, transitDetails, travelMode } = route;
  const { departureStop, arrivalStop } = transitDetails;
  const { hours, mins } = getTimeFromSeconds(duration.value);
  const { departureTime, arrivalTime } = tripDuration;

  return (
    <>
      <RouteLine
        isFirst={isFirst}
        isLast={isLast}
        travelMode={travelMode}
      />

      <div className="transfer-route-info is-mobile">
        <div className="transfer-route-info-header">
          <div className="transfer-route-info__departure">
            <span><b>{departureTime}</b> - {arrivalTime}</span>
          </div>

          <div className="transfer-route-info__stop">
            {departureStop.name}

            <Icon icon="long-arrow-right" />

            <b>{arrivalTime}</b>&nbsp;{arrivalStop.name}
          </div>

          <div className="transfer-route-info__duration">
            {t('route_ticket_duration')}:&nbsp;

            {t('route_duration_hours', { hours })}&nbsp;
            {t('route_duration_minutes', { mins })}
          </div>
        </div>

        <SearchResultsRouteFeatures
          route={route}
          hasCarrier={!(isFirst && isLast)}
        />
      </div>
    </>
  );
};

TransferRouteMobile.propTypes = {
  t: PropTypes.func.isRequired,
  route: PropTypes.shape(),
  tripDuration: PropTypes.shape(),
  isLast: PropTypes.bool,
  isFirst: PropTypes.bool,
};

TransferRouteMobile.defaultProps = {
  route: null,
  tripDuration: null,
  isFirst: false,
  isLast: false,
};

export default withTranslation()(TransferRouteMobile);
