import { constants } from '../../modules/constants';

export const eTicketEndpoints = {
  E_TICKET_VALIDATE_SERVICE: {
    name: 'E_TICKET_VALIDATE_SERVICE',
    url: {
      method: 'POST',
      path: (serviceId) => `/eticket/service/${serviceId}`,
    },
    token: constants.token.type.E_TICKET,
  },
  E_TICKET_ORDER_SUBMIT: {
    name: 'E_TICKET_ORDER_SUBMIT',
    url: {
      method: 'PUT',
      path: (orderHash) => `/eticket/orders/${orderHash}/submit`,
    },
    token: constants.token.type.E_TICKET,
  },
  E_TICKET_ORDER_SELECT_PAYMENT_METHOD: {
    name: 'E_TICKET_ORDER_SELECT_PAYMENT_METHOD',
    url: {
      method: 'POST',
      path: (orderHash, paymentMethodId) => `/eticket/orders/${orderHash}/payments/${paymentMethodId}`,
    },
    token: constants.token.type.E_TICKET,
  },
  E_TICKET_ORDER_INITIALIZE: {
    name: 'E_TICKET_ORDER_INITIALIZE',
    url: {
      method: 'POST',
      path: () => '/eticket/order',
    },
    token: constants.token.type.E_TICKET,
  },
  E_TICKET_ORDER_GET: {
    name: 'E_TICKET_ORDER_GET',
    url: {
      method: 'GET',
      path: (orderHash) => `/eticket/orders/${orderHash}`,
    },
    token: constants.token.type.E_TICKET,
  },
  E_TICKET_LOGIN: {
    name: 'E_TICKET_LOGIN',
    url: {
      method: 'POST',
      path: () => '/eticket/login',
    },
  },
  GET_CURRENT_TICKET: {
    name: 'GET_CURRENT_TICKET',
    url: {
      method: 'GET',
      path: () => '/eticket/card',
    },
    token: constants.token.type.E_TICKET,
  },
};
