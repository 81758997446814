import { Map } from 'immutable';
import { constants } from '../../constants';

const initialState = Map({ opened: false });

const MenuOverlayReducer = (state = initialState, action) => {
  switch (action.type) {
  case constants.components.menuOverlay.OPEN:
    return state.set('opened', true);

  case constants.components.menuOverlay.CLOSE:
    return initialState;

  default:
    return state;
  }
};

export default MenuOverlayReducer;
