import { constants } from '../constants';

export const getCurrentUser = () => ({
  type: constants.user.GET_CURRENT,
  payload: {},
});

export const setCurrentUser = (currentUser = null) => ({
  type: constants.user.SET_CURRENT,
  payload: currentUser,
});

export const loginUser = (username, password) => ({
  type: constants.user.LOGIN,
  payload: {
    username, password,
  },
});

export const loginSocial = (provider, code) => ({
  type: constants.user.LOGIN_SOCIAL,
  payload: {
    provider, code,
  },
});

export const updateUser = (email, name, phone) => ({
  type: constants.user.UPDATE_CURRENT,
  payload: {
    email, name, phone,
  },
});

export const updateUserPassword = (plainPassword) => ({
  type: constants.user.UPDATE_PASSWORD,
  payload: {
    plainPassword,
  },
});

export const getUserOrders = () => ({
  type: constants.user.GET_ORDERS,
  payload: {},
});

export const setUserOrders = (list) => ({
  type: constants.user.SET_ORDERS,
  payload: list,
});

export const getUserOrder = (id) => ({
  type: constants.user.GET_ORDER,
  payload: { id },
});

export const logoutUser = () => ({
  type: constants.user.LOGOUT,
  payload: {},
});

export const registerUser = (name, email, plainPassword) => ({
  type: constants.user.REGISTER,
  payload: {
    name, email, plainPassword,
  },
});

export const deleteUser = (currentUser) => ({
  type: constants.user.DELETE,
  payload: { currentUser },
});
