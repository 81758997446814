export const blogsEndpoints = {
  GET_BLOGS: {
    name: 'GET_BLOGS',
    url: {
      method: 'GET',
      path: () => '/blogs',
    },
  },

  GET_BLOG: {
    name: 'GET_BLOG',
    url: {
      method: 'GET',
      path: (blog) => `/blogs/${blog}`,
    },
  },
};
