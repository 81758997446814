import { constants } from '../constants';

export const getBlogs = () => ({
  type: constants.blogs.GET_BLOGS,
  payload: {},
});

export const setBlogs = (blogs) => ({
  type: constants.blogs.SET_BLOGS,
  payload: {
    blogs,
  },
});

export const getBlog = (slug) => ({
  type: constants.blogs.GET_BLOG,
  payload: {
    slug,
  },
});

export const setBlog = (slug, blog) => ({
  type: constants.blogs.SET_BLOG,
  payload: {
    slug, blog,
  },
});

export const removeBlogsList = () => ({
  type: constants.blogs.REMOVE_BLOGS_LIST,
  payload: {},
});
