import { Map } from 'immutable';
import { constants } from '../../constants';

const initialState = Map({});

const HomepageReducer = (state = initialState, action) => {
  switch (action.type) {
  case constants.components.homepage.SET_ERRORS:
    return state.set('errors', action.payload.errors);

  default:
    return state;
  }
};

export default HomepageReducer;
