import React from 'react';
import PropTypes from 'prop-types';
import Input from '../Input/Input';
import './NumberPicker.scss';
import Icon from '../../Icon/Icon';

const NumberPicker = ({
  id,
  value,
  name,
  required,
  onIncrement,
  onDecrement,
  onBlur,
  disabled,
  ariaLabel,
  addPassenger,
  removePassenger,
}) => {
  const handleIncrement = (event) => {
    if (event.key === 'Enter' || event.type === 'click') {
      // eslint-disable-next-line no-unused-expressions
      !disabled && onIncrement();
    }
  };
  const handleDecrement = (event) => {
    if (event.key === 'Enter' || event.type === 'click') {
      // eslint-disable-next-line no-unused-expressions
      !disabled && onDecrement();
    }
  };

  return (
    <div className="number-picker">
      <div className="number-picker__button" onKeyDown={handleDecrement} onClick={handleDecrement} role="button" tabIndex={0} aria-label={removePassenger}>
        <Icon icon="minus" />
      </div>

      <Input
        ariaLabel={ariaLabel}
        disabled="true"
        name={name}
        onBlur={onBlur}
        id={id}
        value={value}
        required={required}
        onChange={() => null}
        type="number"
      />

      <div className="number-picker__button" onKeyDown={handleIncrement} onClick={handleIncrement} role="button" tabIndex={0} aria-label={addPassenger}>
        <Icon icon="plus" />
      </div>
    </div>
  );
};

NumberPicker.propTypes = {
  id: PropTypes.string,
  value: PropTypes.number,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onIncrement: PropTypes.func.isRequired,
  onDecrement: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  ariaLabel: PropTypes.string,
  addPassenger: PropTypes.string,
  removePassenger: PropTypes.string,
};

NumberPicker.defaultProps = {
  id: null,
  value: null,
  disabled: false,
  required: false,
  onBlur: () => null,
  ariaLabel: '',
  addPassenger: '',
  removePassenger: '',
};

export default NumberPicker;
