import {
  all,
  call,
  takeEvery,
  put,
} from 'redux-saga/effects';
import { constants } from '../constants';
import { startRequest } from '../Request/RequestSaga';
import { endpoints } from '../../config/endpoints';
import { getObjectNestedData } from '../../utilities/object';
import { setEvents, setEventsCategories } from './EventsActions';

function* getEventsSaga(action) {
  const { category, event } = action.payload;
  const { response } = yield call(
    startRequest,
    endpoints.GET_EVENTS_BY_CATEGORY_EVENT.name,
    endpoints.GET_EVENTS_BY_CATEGORY_EVENT.url.path(category, event),
  );

  if (response) {
    const data = getObjectNestedData(response, 'data.data');

    yield put(setEvents(data));
  }
}

function* getEventCategoriesSaga() {
  const { response } = yield call(
    startRequest,
    endpoints.GET_EVENTS_CATEGORIES.name,
    endpoints.GET_EVENTS_CATEGORIES.url.path(),
  );

  if (response) {
    const data = getObjectNestedData(response, 'data.data');

    yield put(setEventsCategories(data));
  }
}

export default function* SearchSaga() {
  yield all([
    takeEvery(constants.events.GET_EVENTS_BY_CATEGORY_EVENT, getEventsSaga),
    takeEvery(constants.events.GET_EVENTS_CATEGORIES, getEventCategoriesSaga),
  ]);
}
