import { constants } from '../constants';

export const addRoundTripTicket = (type, ticket) => ({
  type: constants.roundTripTickets.SET_ROUND_TRIP_TICKET,
  payload: {
    type,
    ticket,
  },
});

export const removeRoundTripTicket = (type) => ({
  type: constants.roundTripTickets.REMOVE_ROUND_TRIP_TICKET,
  payload: {
    type,
  },
});
