import React from 'react';
import { Route } from 'react-router';
import { routes } from '../../../core/config/routes';
import ETicket from '../../pages/ETicket/ETicket';
import Event from '../../pages/Event/Event';
import Events from '../../pages/Events/Events';

export default ({
  defaultLocale, locale,
}) => ([
  locale === defaultLocale && (
    <Route
      key={routes.eticket.index}
      path={routes.eticket.index}
      component={ETicket}
    />
  ),
  <Route
    exact
    key={routes.events.event}
    path={routes.events.event}
    component={Event}
  />,
  <Route
    exact
    key={routes.events.index}
    path={[routes.events.eventCategory, routes.events.index]}
    component={Events}
  />,
]);
