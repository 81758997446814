import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import '../NotFound/NotFound.scss';
import './PinReset.scss';
import { withTracker } from '../../common/Tracker/withTracker';

const PinReset = ({
  t,
}) => (
  <div className="not-found-container">
    <div className="not-found-container-column pin-reset-image">
      <div className="not-found-header-row">
        {t('pin_desktop_message')}
      </div>

      <div className="not-found-subheader-row">
        {t('pin_desktop_info')}
      </div>
    </div>
  </div>
);

PinReset.propTypes = {
  t: PropTypes.func.isRequired,
};

export default (withTranslation()(withTracker(PinReset)));
