import { Map } from 'immutable';
import { constants } from '../../constants';

const initialState = Map({});

const ModalReducer = (state = initialState, action) => {
  switch (action.type) {
  case constants.components.modal.OPEN:
    return state
      .set('opened', true)
      .set('content', action.payload.content)
      .set('size', action.payload.size)
      .set('disableUiClosing', action.payload.disableUiClosing);

  case constants.components.modal.CLOSE:
    return state
      .set('opened', false);

  case constants.components.modal.RESET:
    return initialState;

  case constants.components.modal.SET_DISABLE_UI_CLOSING:
    return state
      .set('disableUiClosing', action.payload.disableUiClosing);

  default:
    return state;
  }
};

export default ModalReducer;
