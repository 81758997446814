import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Button from '../Button/Button';
import './ETicketLoginItem.scss';

const ETicketLoginItem = ({
  title, imageSrc, imageAlt, t, onLoginButtonClick,
}) => (
  <div className="eticket-login-item">
    <h3 className="eticket-login-item-title">{title}</h3>
    {imageSrc && <img className="eticket-login-item-img" src={imageSrc} alt={imageAlt} />}
    <Button
      variant="brand"
      type="submit"
      isFullWidth
      onClick={onLoginButtonClick}
    >
      {t('login_connect')}
    </Button>
  </div>
);

ETicketLoginItem.propTypes = {
  title: PropTypes.string,
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  t: PropTypes.func.isRequired,
  onLoginButtonClick: PropTypes.func.isRequired,
};

ETicketLoginItem.defaultProps = {
  title: 'Title',
  imageSrc: null,
  imageAlt: 'imageAlt',
};

export default withTranslation()(ETicketLoginItem);
