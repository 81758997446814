const msPerDay = 1000 * 60 * 60 * 24;
const weekdayCodes = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
const monthCodes = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

export const formatDate = (date) => {
  const d = new Date(date);
  const year = d.getFullYear();
  let month = d.getMonth() + 1;
  let day = d.getDate();

  if (month < 10) month = `0${month}`;
  if (day < 10) day = `0${day}`;

  return [year, month, day].join('-');
};

export function formatDateToYYYYMMDD(date) {
  // Check if the input is a valid Date object
  if (!(date instanceof Date) || isNaN(date)) {
    return null;
  }

  // Extract the year, month, and day components from the Date object
  const year = date.getFullYear().toString();
  let month = (date.getMonth() + 1).toString();
  let day = date.getDate().toString();

  // Auto-correct month if it has less than two digits
  if (month.length < 2) {
    month = `0${month}`;
  }

  // Auto-correct day if it has less than two digits
  if (day.length < 2) {
    day = `0${day}`;
  }

  // Concatenate the components to form the formatted date string
  return `${year}-${month}-${day}`;
}

export function validateDateFormat(input) {
  // Define a regular expression to match the desired format
  const regex = /^\d{4}-\d{2}-\d{2}$/;

  // Test the input against the regular expression
  return regex.test(input);
}

export const formatTime = (date) => {
  const d = new Date(date);
  let hours = d.getHours();
  let minutes = d.getMinutes();

  if (hours < 10) hours = `0${hours}`;
  if (minutes < 10) minutes = `0${minutes}`;

  return [hours, minutes].join(':');
};

export const getTimeFromSeconds = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const mins = Math.floor((seconds - (hours * 3600)) / 60);

  return { hours, mins };
};

export const getYear = (date) => {
  const d = new Date(date);
  return d.getFullYear();
};

export const getMonth = (date) => {
  const d = new Date(date);

  return String(`0${d.getMonth() + 1}`).slice(-2);
};

export const getNextMonth = (date) => {
  const d = new Date(date);
  const m = d.setMonth(d.getMonth() + 1, [1]);
  const nextMonth = new Date(m).getMonth();

  return String(`0${nextMonth + 1}`).slice(-2);
};

export const dateDiffInDays = (a, b) => {
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / msPerDay);
};

export const getDay = (date) => {
  const d = new Date(date);
  return d.getDate();
};

export const getTranslations = (t) => {
  const months = monthCodes.map((month) => t(`calendar_month_names_${month}`, ''));
  const weekdaysShort = weekdayCodes.map((day) => t(`calendar_day_names_short_${day}`, ''));
  const weekdaysLong = weekdayCodes.map((day) => t(`calendar_day_names_${day}`, ''));

  return {
    months: months.filter(Boolean).length ? months : null,
    weekdaysShort: weekdaysShort.filter(Boolean).length ? weekdaysShort : null,
    weekdaysLong: weekdaysLong.filter(Boolean).length ? weekdaysLong : null,
    calendarNavigationTranslations: {
      backwards: t('backwards', ''),
      forward: t('forward', ''),      
    },
    datePicker: t('date_picker', ''),
  };
};
