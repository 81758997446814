export const loyaltyEndpoints = {
  ADD_LOYALTY_DISCOUNT: {
    name: 'ADD_LOYALTY_DISCOUNT',
    url: {
      method: 'PUT',
      path: (hash) => `/orders/${hash}/loyalty`,
    },
  },
  REMOVE_LOYALTY_DISCOUNT: {
    name: 'REMOVE_LOYALTY_DISCOUNT',
    url: {
      method: 'DELETE',
      path: (hash) => `/orders/${hash}/loyalty`,
    },
  },
};
