import { Map } from 'immutable';
import { constants } from '../constants';

const initialState = Map({});

const OrderReducer = (state = initialState, action) => {
  switch (action.type) {
  case constants.order.SET_STATUS:
    return state.set('status', action.payload.status);

  case constants.order.SET_DATA:
    return state.set('data', action.payload.data);

  case constants.order.SET_REGISTERED:
    return state.set('registered', action.payload.registered);

  default:
    return state;
  }
};

export default OrderReducer;
