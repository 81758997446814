import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { getErrors } from '../../../../../core/utilities/formValidator';
import { validations } from '../../../../../core/config/form/validations';
import { submitOrder } from '../../../../../core/modules/Order/OrderActions';
import Passengers from '../Passengers/Passengers';
import Payments from '../Payments/Payments';
import OrderSubmit from '../OrderSubmit/OrderSubmit';

const OrderForm = ({
  dispatchSubmitOrder,
  userEmail,
  userPhone,
  order,
  passengers,
  paymentMethodId,
  paymentMethodCountryId,
}) => {
  const { hash, isPersonal: phoneRequired } = order;
  const formRef = React.useRef(null);
  const onSubmitOrder = (values) => {
    const { phone, email, newsletter } = values;
    const orderData = {
      newsletter,
      email,
      ...(phone ? { phone } : {}),
    };
    dispatchSubmitOrder(hash, orderData);
  };
  const [submitForm, setSubmitForm] = React.useState({
    email: userEmail,
    phone: userPhone,
    newsletter: false,
    termsAndConditions: false,
  });
  const updateSubmitForm = (key, value) => setSubmitForm({
    ...submitForm,
    [key]: value,
  });

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      onSubmit={onSubmitOrder}
      validate={(values) => getErrors(values, {
        items: {
          ...(passengers.reduce((accumulator, passenger, passengerIndex) => ({
            ...accumulator,
            [`items[${passengerIndex}].name`]: [validations.required, validations.name],
          }), {})),
        },
        paymentMethodCountryId: [validations.requiredPositiveNumber],
        paymentMethodId: [validations.requiredPositiveNumber],
        email: [validations.email],
        phone: phoneRequired ? [validations.required] : [],
        termsAndConditions: [validations.requiredBool],
      }, formRef)}
      initialValues={{
        ...submitForm,
        paymentMethodId,
        paymentMethodCountryId,
        items: {
          ...(passengers.reduce((accumulator, passenger, passengerIndex) => ({
            ...accumulator,
            [`items[${passengerIndex}].name`]: passenger.name,
          }), {})),
        },
      }}
      render={(formProps) => {
        const { handleSubmit } = formProps;

        return (
          <form
            ref={formRef}
            onSubmit={handleSubmit}
          >
            <Passengers formProps={formProps} />

            <Payments formProps={formProps} />

            <OrderSubmit
              formProps={formProps}
              updateSubmitForm={updateSubmitForm}
            />
          </form>
        );
      }}
    />
  );
};

OrderForm.propTypes = {
  dispatchSubmitOrder: PropTypes.func.isRequired,
  order: PropTypes.shape().isRequired,
  userEmail: PropTypes.string,
  userPhone: PropTypes.string,
  passengers: PropTypes.arrayOf(PropTypes.shape()),
  paymentMethodId: PropTypes.number,
  paymentMethodCountryId: PropTypes.number,
};

OrderForm.defaultProps = {
  paymentMethodId: 0,
  paymentMethodCountryId: 0,
  passengers: [],
  userEmail: '',
  userPhone: '',
};

const mapStateToProps = (state) => ({
  paymentMethodId: state.order.getIn(['data', 'lastPayment', 'paymentMethodId']),
  paymentMethodCountryId: state.order.getIn(['data', 'lastPayment', 'countryId']),
  passengers: state.order.getIn(['data', 'items']),
  userEmail: state.user.getIn(['data', 'email']),
  userPhone: state.user.getIn(['data', 'phone']),
  order: state.order.get('data'),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSubmitOrder: (orderHash, orderData) => (
    dispatch(submitOrder(orderHash, orderData))
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderForm);
