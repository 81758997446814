export const routes = {
  homepage: 'routes_homepage',
  kitchenSink: 'routes_kitchensink',
  searchResults: 'routes_search_results',
  passwordReset: 'routes_password_reset',
  oauthFacebookReturn: '/oauth/facebook',
  oauthGoogleReturn: '/oauth/google',
  pinDesktop: '/user/pin/reset',
  tickets: {
    index: 'routes_tickets_index',
    changed: 'routes_tickets_changed',
  },
  ticketSearchByTravel: {
    index: 'routes_ticket_search_by_travel_index',
  },
  checkout: {
    index: 'routes_checkout_index',
    hash: 'routes_checkout_hash',
    error: 'routes_checkout_error',
  },
  ticketReturn: {
    index: 'routes_ticket_return_index',
    ticket: 'routes_ticket_return_ticket',
  },
  ticketChange: {
    index: 'routes_ticket_change_index',
    ticket: 'routes_ticket_change_ticket',
  },
  eticket: {
    index: 'routes_eticket_index',
    checkout: {
      index: 'routes_eticket_checkout_index',
      hash: 'routes_eticket_checkout_hash',
      error: 'routes_eticket_checkout_error',
    },
    services: 'routes_eticket_services',
    success: 'routes_eticket_success',
    topUpHistory: 'routes_eticket_top_up_history',
    walletHistory: 'routes_eticket_wallet_history',
    faq: 'routes_eticket_wallet_faq',
  },
  topRoutes: {
    index: 'routes_top_routes_index',
    route: 'routes_top_routes_route',
  },
  user: {
    index: 'routes_user_index',
    orderHistory: 'routes_user_order_history',
    details: 'routes_user_details',
  },
  ticketSearch: 'routes_ticket_search',
  topStations: {
    local: {
      index: 'routes_top_stations_local_index',
      station: 'routes_top_stations_local_station',
    },
    abroad: {
      index: 'routes_top_stations_abroad_index',
      station: 'routes_top_stations_abroad_station',
    },
  },
  cms: {
    purchaseRules: 'routes_cms_purchase_rules',
    privacyPolicy: 'routes_cms_privacy_policy',
  },
  events: {
    index: 'routes_events_index',
    eventCategory: 'routes_events_event_category',
    event: 'routes_events_event',
  },
  eurolinesTopRoute: {
    index: 'routes_eurolines_top_routes_index',
  },
  blogs: {
    index: 'routes_blogs_index',
    blog: 'routes_blogs_blog',
  },
  buses: {
    businessClass: 'routes_buses_business_class',
    businessClassVip: 'routes_buses_business_class_vip',
  },
  promotions: {
    loyalty: 'routes_promotions_loyalty',
    familyTicket: 'routes_promotions_family_ticket',
  },
};

export const footerlessRoutesList = [
  routes.checkout.index,
  routes.tickets.index,
  routes.tickets.changed,
];

export const getLink = (route, params) => {
  let routeWithValues = route;

  Object.entries(params).forEach(([name, value]) => {
    routeWithValues = routeWithValues.replace(`/:${name}`, `/${value}`);
  });

  return routeWithValues;
};

export const addQueryToLink = (route, params) => {
  let routeWithValues = route;

  Object.entries(params).forEach(([name, value], i) => {
    routeWithValues += `${i ? '&' : '?'}${name}=${value}`;
  });

  return routeWithValues;
};
