import { Map } from 'immutable';
import { constants } from '../constants';

const initialState = Map({});

const CMSReducer = (state = initialState, action) => {
  switch (action.type) {
  case constants.data.collections.cms.SET_PAGE:
    return state.setIn(['pages', action.payload.locale, action.payload.page], action.payload.content);

  case constants.data.collections.cms.SET_MENU:
    return state.setIn(['menus', action.payload.locale, action.payload.menuFolder], action.payload.menuItems);

  default:
    return state;
  }
};

export default CMSReducer;
