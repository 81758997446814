export const breakpoints = {
  xs: 480,
  sm: 768,
  md: 1024,
  lg: 1366,
};

export const breakpoint = () => {
  const screen = window.innerWidth;
  const {
    xs,
    sm,
    md,
    lg,
  } = breakpoints;
  
  if (screen >= lg) return 'xl';
  if (screen >= md && screen < lg) return 'lg';
  if (screen >= sm && screen < md) return 'md';
  if (screen >= xs && screen < sm) return 'sm';
  
  return 'xs';
};

export const breakpointSet = (...args) => {
  let contains = false;
  
  args.forEach((size) => {
    if (breakpoint() === size) {
      contains = true;
    }
  });
  
  return contains;
};
