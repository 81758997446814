import { combineReducers } from 'redux';
import { RequestReducer } from './Request/RequestReducer';
import StopsReducer from './Stops/StopsReducer';
import ModalReducer from './Components/Modal/ModalReducer';
import UserReducer from './User/UserReducer';
import FooterReducer from './CMS/Components/Footer/FooterReducer';
import SearchReducer from './Search/SearchReducer';
import RoutesReducer from './Routes/RoutesReducer';
import TopRoutesStationsReducer from './TopRoutesStations/TopRoutesStationsReducer';
import ResetPasswordReducer from './Components/ResetPassword/ResetPasswordReducer';
import CMSReducer from './CMS/CMSReducer';
import NewsletterReducer from './Components/Newsletter/NewsletterReducer';
import BannersReducer from './Data/Collections/Banners/BannersReducer';
import ETicketReducer from './ETicket/ETicketReducer';
import OrderReducer from './Order/OrderReducer';
import TokenReducer from './Token/TokenReducer';
import PaymentReducer from './Data/Collections/Payment/PaymentReducer';
import LocalesReducer from './Locales/LocalesReducer';
import ActiveLocaleReducer from './Locales/ActiveLocaleReducer';
import RoundTripTickets from './RoundTripTickets/RoundTripTicketsReducer';
import TicketReturnReducer from './TicketReturn/TicketReturnReducer';
import EventsReducer from './Events/EventsReducers';
import TicketChangeReducer from './TicketChange/TicketChangeReducer';
import ContactReducer from './Contact/ContactReducer';
import StationsReducer from './Stations/StationsReducer';
import HomepageReducer from './Components/Homepage/HomepageReducer';
import RouteMapReducer from './Components/RouteMap/RouteMapReducer';
import BlogsReducer from './Blogs/BlogsReducer';
import LightBoxReducer from './Components/LightBox/LightBoxReducer';
import EurolinesTopRoutesReducer from './EurolinesTopRoutes/EurolinesTopRoutesReducer';
import MenuOverlayReducer from './Components/MenuOverlay/MenuOverlayReducer';
import TicketSearchReducer from './TicketSearch/TicketSearchReducer';

const rootReducer = combineReducers({
  request: RequestReducer,
  data: combineReducers({
    collections: combineReducers({
      stops: StopsReducer,
      routes: RoutesReducer,
      topRoutesAndStations: TopRoutesStationsReducer,
      cms: CMSReducer,
      banners: BannersReducer,
      payment: PaymentReducer,
      events: EventsReducer,
      blogs: BlogsReducer,
      stations: StationsReducer,
      eurolinesTopRoutes: EurolinesTopRoutesReducer,
    }),
    search: SearchReducer,
  }),
  components: combineReducers({
    modal: ModalReducer,
    resetPassword: ResetPasswordReducer,
    footer: FooterReducer,
    newsletter: NewsletterReducer,
    homepage: HomepageReducer,
    routeMap: RouteMapReducer,
    lightBox: LightBoxReducer,
    menuOverlay: MenuOverlayReducer,
  }),
  contact: ContactReducer,
  user: UserReducer,
  token: TokenReducer,
  eTicket: ETicketReducer,
  order: OrderReducer,
  locales: LocalesReducer,
  locale: ActiveLocaleReducer,
  roundTripTickets: RoundTripTickets,
  ticketReturn: TicketReturnReducer,
  ticketChange: TicketChangeReducer,
  ticketSearch: TicketSearchReducer,
});

export default rootReducer;
