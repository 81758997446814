import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Formik } from 'formik';
import Input from '../../../../../components/presentational/FormElements/Input/Input';
import Button from '../../../../../components/presentational/Button/Button';
import { loginUser } from '../../../../../core/modules/User/UserActions';
import { userEndpoints } from '../../../../../core/config/endpoints/user';
import { setRequestError } from '../../../../../core/modules/Request/RequestActions';
import './LoginForm.scss';
import { getErrors } from '../../../../../core/utilities/formValidator';
import { validations } from '../../../../../core/config/form/validations';
import Alert from '../../../../../components/presentational/Alert/Alert';
import Icon from '../../../../../components/presentational/Icon/Icon';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: false,
    };

    this.form = React.createRef();
  }

  componentWillUnmount() {
    const { unsetLoginError } = this.props;
    unsetLoginError();
  }

  toggleShowPassword = () => this.setState((prevState) => (
    { showPassword: !prevState.showPassword }
  ));

  onSubmit = ({ username, password }) => {
    const { dispatchLoginUser } = this.props;
    dispatchLoginUser(username, password);
  };

  render() {
    const { showPassword } = this.state;
    const {
      toggleLoginModalContent, loginUserLoading, loginUserErrors, t,
    } = this.props;
    const { global, fields } = loginUserErrors;
    const loginFieldsErrors = fields || {};

    return (
      <Formik
        validateOnChange={false}
        onSubmit={this.onSubmit}
        initialValues={{
          password: '',
          username: '',
        }}
        validate={(values) => getErrors(values, {
          username: [validations.email],
          password: [validations.required],
        }, this.form)}
        render={(formProps) => {
          const {
            values,
            handleSubmit,
            errors,
            setFieldValue,
          } = formProps;
          const { password, username } = values;

          return (
            <form onSubmit={handleSubmit} ref={this.form}>
              {global && global.map((error) => (
                <Alert
                  key={error}
                  variant="danger"
                  heading={t('common_error_exclamation')}
                >
                  {error}
                </Alert>
              ))}

              <div className="login-modal-input-row">
                <Input
                  name="username"
                  id="username"
                  label={t('login_username')}
                  type="text"
                  value={username}
                  autocapitalize="none"
                  onChange={(e) => setFieldValue('username', e.target.value)}
                  error={t(errors.username) || loginFieldsErrors.username}
                  disabled={loginUserLoading}
                />
              </div>

              <div className="login-modal-input-row">
                <Input
                  className="login-modal-input-row"
                  id="password"
                  name="password"
                  label={t('login_password')}
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setFieldValue('password', e.target.value)}
                  disabled={loginUserLoading}
                  error={t(errors.password) || loginFieldsErrors.password}
                  icon={(
                    <button
                      className="input-icon__button"
                      type="button"
                      onClick={this.toggleShowPassword}
                      disabled={loginUserLoading}
                    >
                      <Icon icon="view" /> {t(showPassword ? 'login_hide' : 'login_show')}
                    </button>
                  )}
                />
              </div>

              <div className="login-modal-actions">
                <Button
                  variant="brand"
                  type="submit"
                  disabled={loginUserLoading}
                >
                  {t('login_connect')}
                </Button>

                <Button
                  className="login-modal-text-button"
                  variant="inline-link"
                  onClick={toggleLoginModalContent}
                >
                  <span>{t('login_forgot_password')}</span>
                </Button>
              </div>
            </form>
          );
        }}
      />
    );
  }
}

LoginForm.propTypes = {
  toggleLoginModalContent: PropTypes.func.isRequired,
  dispatchLoginUser: PropTypes.func.isRequired,
  unsetLoginError: PropTypes.func.isRequired,
  loginUserLoading: PropTypes.bool,
  loginUserErrors: PropTypes.shape(),
  t: PropTypes.func.isRequired,
};

LoginForm.defaultProps = {
  loginUserLoading: false,
  loginUserErrors: {},
};

const mapStateToProps = (state) => ({
  loginUserLoading: state.request.getIn([userEndpoints.LOGIN_USER.name, 'loading']),
  loginUserErrors: state.request.getIn([userEndpoints.LOGIN_USER.name, 'error']),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchLoginUser: (username, password) => dispatch(loginUser(username, password)),
  unsetLoginError: () => dispatch(setRequestError(userEndpoints.LOGIN_USER.name, {})),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LoginForm));
