import {
  all,
  call,
  takeEvery,
  put,
} from 'redux-saga/effects';
import { constants } from '../../../constants';
import { startRequest } from '../../../Request/RequestSaga';
import { endpoints } from '../../../../config/endpoints';
import { setPaymentMethods } from './PaymentActions';

function* getPaymentMethods() {
  const { response } = yield call(
    startRequest,
    endpoints.GET_PAYMENT_METHODS.name,
    endpoints.GET_PAYMENT_METHODS.url.path(),
  );

  if (response) {
    yield put(setPaymentMethods(response.data.data));
  }
}

export default function* PaymentSaga() {
  yield all([
    takeEvery(constants.data.collections.payment.GET_PAYMENT_METHODS, getPaymentMethods),
  ]);
}
