import { Map } from 'immutable';
import { constants } from '../constants';

const initialState = Map({});

const StationsReducer = (state = initialState, action) => {
  switch (action.type) {
  case constants.data.collections.stations.SET_STATION:
    return state.set(action.payload.slug, action.payload.station);

  default:
    return state;
  }
};

export default StationsReducer;
