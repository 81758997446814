/* global google */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import {
  GoogleMap,
  withGoogleMap,
  DirectionsRenderer,
  Polyline,
  Marker,
  withScriptjs,
} from 'react-google-maps';
import marker from '../../../../../../assets/images/waypointOff.png';
import activeMarkerImg from '../../../../../../assets/images/waypointOn.png';
import routeStartOn from '../../../../../../assets/images/markerOn_a.png';
import routeEndOn from '../../../../../../assets/images/markerOn_b.png';
import routeStart from '../../../../../../assets/images/markerOff_a.png';
import routeEnd from '../../../../../../assets/images/markerOff_b.png';
import { getPolylines } from '../../../../../core/modules/Components/RouteMap/RouteMapActions';

const colors = {
  default: '#000000',
};

const findSmallestDistance = (decodedPath, point) => {
  let smallest = 9999999999;
  let smallestID = 0;

  for (let d = 0; d < decodedPath.length; d += 1) {
    const distance = google.maps.geometry.spherical.computeDistanceBetween(
      decodedPath[d], point,
    );
    if (distance < smallest) {
      smallest = distance;
      smallestID = d;
    }
  }

  return { id: smallestID, dist: smallest };
};

const trimPath = (path, from, to) => {
  const decodedPath = google.maps.geometry.encoding.decodePath(path);
  let trimmedPath;
  const startDist = findSmallestDistance(decodedPath, from);
  const endDist = findSmallestDistance(decodedPath, to);

  if (startDist.id > endDist.id) {
    trimmedPath = decodedPath.slice(endDist.id, startDist.id + 1);
  } else {
    trimmedPath = decodedPath.slice(startDist.id, endDist.id + 1);
  }

  return trimmedPath;
};

const parseTrackId = (trackId) => {
  let trackIdDirection = '';

  let trackIdReplaced = trackId;
  let indexOfDirection = trackId.lastIndexOf('_a-b');
  if (indexOfDirection > 0) {
    trackIdReplaced = trackId.substring(0, indexOfDirection);
    trackIdDirection = trackId.substring(indexOfDirection + 1, trackId.length);
  } else {
    indexOfDirection = trackId.lastIndexOf('_b-a');
    if (indexOfDirection > 0) {
      trackIdReplaced = trackId.substring(0, indexOfDirection);
      trackIdDirection = trackId.substring(indexOfDirection + 1, trackId.length);
    }
  }

  return { trackIdReplaced, trackIdDirection };
};

const RouteMap = ({
  stepsStops,
  route,
  onMarkerClick,
  activeMarker,
  dispatchGetPolylines,
  routeMapPolylines,
}) => {
  const [walkingDirections, setWalkingDirections] = React.useState([]);
  const [polyPaths, setPolyPaths] = React.useState([]);
  const [parsedSteps, setParsedSteps] = React.useState([]);
  const mapRef = React.useRef(null);
  const { startLocation, steps } = route;
  const polylineStyles = {
    default: {
      strokeColor: colors.default,
      strokeOpacity: 1.0,
      strokeWeight: 3,
      icons: [
        {
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            fillColor: colors.default,
            fillOpacity: 1,
          },
          offset: '0%',
        },
        {
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            fillColor: colors.default,
            fillOpacity: 1,
          },
          offset: '100%',
        },
      ],
    },
    walking: {
      strokeColor: colors.default,
      strokeOpacity: 0,
      strokeWeight: 3,
      icons: [
        {
          icon: {
            path: 'M 0,-0.5 0,0.5',
            strokeWeight: 3,
            strokeOpacity: 1,
            scale: 5,
          },
          offset: '50%',
          repeat: '10px',
        },
      ],
    },
  };

  const getWalkingDirections = (walkingSteps) => {
    walkingSteps.forEach((step) => {
      const {
        startLocation: origin,
        endLocation: destination,
      } = step;
      const directionsService = new google.maps.DirectionsService();

      directionsService.route(
        {
          origin,
          destination,
          travelMode: google.maps.TravelMode.WALKING,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK && result) {
            setWalkingDirections((prevDirections) => [...prevDirections, result]);
          }
        },
      );
    });
  };

  const fitMapZoom = () => {
    const stopsLocations = [];

    (steps || [route]).forEach((step) => {
      const {
        startLocation: stepStartLocation,
        endLocation: stepEndLocation,
        transitDetails,
      } = step;
      const { steps: stepStops } = transitDetails;

      (stepStops || [stepStartLocation, stepEndLocation]).forEach(({ lat, lng }) => {
        stopsLocations.push({
          location: new google.maps.LatLng(lat, lng),
        });
      });
    });

    const bounds = new google.maps.LatLngBounds();

    stopsLocations.forEach((waypoint) => bounds.extend(waypoint.location));
    mapRef.current.fitBounds(bounds);
  };

  React.useEffect(() => {
    const nextParsedSteps = (steps || [route]).map((step) => {
      const { travelMode, transitDetails } = step;

      if (travelMode === 'WALKING') return step;

      const { trackId } = transitDetails.line;

      return {
        ...step,
        ...parseTrackId(trackId),
      };
    });

    const transitSteps = nextParsedSteps.filter((step) => step.travelMode !== 'WALKING');
    const walkingSteps = nextParsedSteps.filter((step) => step.travelMode === 'WALKING');
    const trackIdsToFetch = nextParsedSteps.map((step) => step.trackIdReplaced).filter(Boolean);

    setParsedSteps(transitSteps);
    dispatchGetPolylines(trackIdsToFetch);
    getWalkingDirections(walkingSteps);
    fitMapZoom();
  }, []);

  React.useEffect(() => {
    if (parsedSteps.length) {
      const stepsPaths = parsedSteps.map((step) => {
        const {
          trackIdReplaced,
          trackIdDirection,
          startLocation: stepStartLocation,
          endLocation: stepEndLocation,
          transitDetails,
          travelMode,
          sellData,
        } = step;
        const { stops: stepStops } = transitDetails;
        const { actualTripId } = sellData;
        const stepPolylines = routeMapPolylines.getIn([trackIdReplaced, trackIdDirection]);
        const stepStartCoordinates = new google.maps.LatLng(
          stepStartLocation.lat, stepStartLocation.lng,
        );
        const stepEndCoordinates = new google.maps.LatLng(
          stepEndLocation.lat, stepEndLocation.lng,
        );

        if (!stepPolylines) {
          return {
            travelMode,
            id: actualTripId,
            style: 'default',
            path: [
              stepStartCoordinates,
              ...stepStops.map((stepStop) => new google.maps.LatLng(stepStop.lat, stepStop.lng)),
              stepEndCoordinates,
            ],
          };
        }

        return {
          travelMode,
          id: actualTripId,
          style: 'default',
          path: trimPath(
            stepPolylines,
            stepStartCoordinates,
            stepEndCoordinates,
          ),
        };
      }).filter(Boolean);

      setPolyPaths(stepsPaths);
    }
  }, [routeMapPolylines, parsedSteps]);

  React.useEffect(() => {
    if (activeMarker && mapRef.current) {
      mapRef.current.panTo(new google.maps.LatLng(activeMarker.lat, activeMarker.lng));
    }
  }, [activeMarker]);

  if (!stepsStops.length) return null;

  return (
    <GoogleMap
      defaultCenter={{ lat: startLocation.lat, lng: startLocation.lng }}
      defaultZoom={7}
      options={{
        disableDefaultUI: true,
        fullscreenControl: true,
        gestureHandling: 'cooperative',
      }}
      smoothCenter
      ref={mapRef}
    >
      {polyPaths.map((polyPath) => {
        const { path, style, id } = polyPath;

        return (
          <Polyline
            key={id}
            path={path}
            options={{
              ...(polylineStyles[style] || polylineStyles.default),
              clickable: false,
              geodesic: true,
            }}
          />
        );
      })}

      {walkingDirections.map((walkingDirection) => {
        const key = walkingDirection
          .geocoded_waypoints
          .map((geocodedWaypoint) => geocodedWaypoint.place_id)
          .join('_');

        return (
          <DirectionsRenderer
            key={key}
            directions={walkingDirection}
            options={{
              suppressMarkers: true,
              polylineOptions: polylineStyles.walking,
              preserveViewport: true,
            }}
          />
        );
      })}

      {stepsStops.map((stepsStopsArray, stopsArrayIndex) => {
        const filteredArray = stepsStopsArray.filter((stop) => stop.type !== 'wait');

        return (
          filteredArray.map((stop, i) => {
            const isStart = i === 0 && stopsArrayIndex === 0;
            const isEnd = stopsArrayIndex === stepsStops.length - 1
              && filteredArray.length - 1 === i;
            const activeStop = activeMarker && stop.id === activeMarker.id;
            const markerIcon = (activeStop && isStart && routeStartOn)
              || (activeStop && isEnd && routeEndOn)
              || (activeStop && activeMarkerImg)
              || (isStart && routeStart)
              || (isEnd && routeEnd)
              || marker;
            const isWaypoint = (markerIcon === marker) || (markerIcon === activeMarkerImg);

            return (
              <Marker
                key={stop.id + i}
                title={stop.name}
                name={stop.name}
                position={{ lat: stop.lat, lng: stop.lng }}
                icon={{
                  url: markerIcon,
                  anchor: isWaypoint
                    ? { x: 5, y: 5 }
                    : { x: 12, y: 32 },
                }}
                onClick={() => onMarkerClick(stop)}
              />
            );
          })
        );
      })}
    </GoogleMap>
  );
};

RouteMap.propTypes = {
  onMarkerClick: PropTypes.func.isRequired,
  dispatchGetPolylines: PropTypes.func.isRequired,
  stepsStops: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape())),
  route: PropTypes.shape(),
  activeMarker: PropTypes.shape(),
  routeMapPolylines: PropTypes.instanceOf(Map).isRequired,
};

RouteMap.defaultProps = {
  stepsStops: [],
  route: null,
  activeMarker: null,
};

const mapStateToProps = (state) => ({
  routeMapPolylines: state.components.routeMap,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchGetPolylines: (trackIds) => dispatch(getPolylines(trackIds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withScriptjs(withGoogleMap(RouteMap)));
