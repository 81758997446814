/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

const PartDiscountSelectButton = ({
  discounts, partId, loading, handleDiscount, t, directionTitle,
}) => {
  const handleRadioChange = (event) => {
    handleDiscount(partId, event.target.value);
  };

  return (
    <div className="discount-select-container" aria-label={`${t('discount_group_title')}${directionTitle}`} role="radiogroup">
      {discounts.map((discount) => {
        const label = t(discount.translationKey) || discount.name;

        return (
          <div
            key={discount.code || 0}
            className={`discount-select-box ${discount.selected ? 'discount-is_selected' : ''}`}
          >
            <label className="discount-select-label">
              <input
                className="discount-select-radio-button"
                type="radio"
                id={discount.code || 0}
                name={`checkout-passenger-discount-select-${partId}`}
                value={discount.code || 0}
                checked={discount.selected}
                onChange={handleRadioChange}
                disabled={loading}
              />
              <span className="discount-button-label-name">{label}</span>
            </label>
          </div>
        );
      })}
    </div>
  );
};

PartDiscountSelectButton.propTypes = {
  t: PropTypes.func.isRequired,
  handleDiscount: PropTypes.func.isRequired,
  discounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  partId: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  directionTitle: PropTypes.string,
};

PartDiscountSelectButton.defaultProps = {
  loading: false,
  directionTitle: '',
};

export default withTranslation()(PartDiscountSelectButton);
