import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Input from '../../../../../components/presentational/FormElements/Input/Input';
import {
  addBike,
  removeBike,
  removePassenger,
  updatePassengerName,
  updatePassengerPartDiscount,
} from '../../../../../core/modules/Order/OrderActions';
import Tooltip from '../../../../../components/containers/Tooltip/Tooltip';
import { formatMoney } from '../../../../../core/utilities/money';
import './Passenger-VARIANT.scss';
import ItemPartDiscountButton from '../ItemPartDiscountSelect/ItemsPartDiscountButton';
import Checkbox from '../../../../../components/presentational/FormElements/Checkbox/Checkbox';
import { tripDirections, ticketTypes } from '../../../../../core/config/constants/tickets';
import { endpoints } from '../../../../../core/config/endpoints';
import Preloader from '../../../../../components/presentational/Preloader/Preloader';
import Icon from '../../../../../components/presentational/Icon/Icon';
import variantConst from '../../../../../core/config/constants/variantConst/variantConst';
import BenefitsContent from '../../../CMS/BenefitsBlock';

const partsByDirectionReducer = (accumulator, part) => {
  const { direction } = part;

  if (!tripDirections.includes(direction)) return accumulator;
  if (!accumulator[direction]) accumulator[direction] = [];

  accumulator[direction].push(part);

  return accumulator;
};
const Passenger = ({
  t,
  passengerIndex,
  submitting,
  formProps,
  passenger,
  loading,
  orderHash,
  ticketType,
  travelTrips,
  dispatchAddBike,
  orderSubmitFieldsErrors,
  orderNameFieldsErrors,
  dispatchRemoveBike,
  dispatchRemovePassenger,
  dispatchUpdatePassengerName,
  dispatchUpdatePassengerPartDiscount,
}) => {
  const fieldsErrors = {
    ...orderSubmitFieldsErrors,
    ...orderNameFieldsErrors,
  };
  const {
    id, name, parts, total, bike,
  } = passenger;
  const {
    values,
    setFieldValue,
    setErrors,
    errors,
  } = formProps;
  const { items } = values;
  const passengerKey = `items[${passengerIndex}].name`;
  const value = items[passengerKey] || '';
  const [timeStamp] = React.useState(new Date().getTime());
  const handleBlur = () => {
    if (name !== value) {
      dispatchUpdatePassengerName(orderHash, id, value);
    }
  };
  const handleDiscount = (partId, discountValue) => (
    dispatchUpdatePassengerPartDiscount(
      orderHash,
      id,
      partId,
      discountValue,
    )
  );
  const handleBike = (add, direction) => {
    const bikeAction = add ? dispatchAddBike : dispatchRemoveBike;
    bikeAction(orderHash, id, direction);
  };

  const handleRemove = () => {
    if (passengerIndex) dispatchRemovePassenger(orderHash, id);
  };
  const partsByDirection = parts.reduce(partsByDirectionReducer, {});
  const isMultiDirection = Object.keys(partsByDirection).length > 1;
  const isMultiPart = parts.length > 1;
  const fulltrip = travelTrips.forward !== null && travelTrips.backward !== null;
  const isDiscoutText = parts[0].discounts;
  const filterSelectedDiscountText = isDiscoutText.filter((text) => text.selected === true);

  return (
    <div className="checkout-item" aria-live="polite">
      {!!passengerIndex && <div className="wave wave-top" />}

      <div className="checkout-item-content no-top-border no-bottom-border">
        <h2 className="checkout-item-header has-border has-padding">
          <span className="checkout-item__title">
            {t('checkout_passenger', { number: passengerIndex + 1 })}
          </span>

          {!!passengerIndex && (
            <button
              onClick={handleRemove}
              className="checkout-passenger-remove"
            >
              <span>{t('checkout_remove_passenger')}</span>

              <div className="button only-icon is-danger button-close"><Icon icon="cancel" /></div>
            </button>
          )}
        </h2>

        <div className="checkout-passenger__input">
          <Input
            name={`${timeStamp}.items.${passengerKey}`}
            real-name={`items.${passengerKey}`}
            value={value}
            onBlur={handleBlur}
            ariaLabel={t('checkout_passenger')}
            onChange={(e) => {
              setFieldValue('items', {
                ...items,
                [passengerKey]: e.target.value,
              });
              setErrors({
                ...errors,
                items: {
                  ...errors.items,
                  [passengerKey]: undefined,
                },
              });
            }}
            error={t((errors.items || {})[passengerKey]) || fieldsErrors[passengerKey]}
            loading={loading}
            required
          />
        </div>

        {ticketType === ticketTypes.promo && (
          <div className="checkout-passenger-part-promo-wrap">
            <div className="checkout-passenger-part-promo">
              <Tooltip
                light
                title={t('alert_important')}
                content={t('route_purchase_promo_explained')}
              >
                {t('route_purchase_promo')}
                <Icon icon="info" />
              </Tooltip>
            </div>
          </div>
        )}

        {tripDirections.map((direction) => {
          const directionBike = bike[direction];

          if (!directionBike.canApply) return null;

          const bikeKey = fulltrip
            ? `checkout_bicycle_place_reservation_${direction}`
            : 'checkout_bicycle_place_reservation';

          return (
            <div className="checkout-passenger-bicycle" key={bikeKey}>
              <Checkbox
                id={`${bikeKey}${id}`}
                name={bikeKey}
                label={<><Icon icon="bike" /><p>{t(bikeKey)}</p></>}
                value={directionBike.selected}
                onChange={(e) => handleBike(e.target.checked, direction)}
              />
            </div>
          );
        })}

        {isMultiPart && Object.keys(partsByDirection).map((direction) => {
          const directionParts = partsByDirection[direction];
          const isDiscoutTextSecond = parts[1].discounts;
          const filterSelectedDiscountTextSecond = isDiscoutTextSecond
            .filter((text) => text.selected === true);

          return (
            <React.Fragment key={direction}>
              {isMultiDirection && (
                <>
                  <div className="checkout-passenger-part-direction__title">
                    {t(`checkout_trip_${direction}`)}:
                  </div>
                  <div className="checkout-passenger-part-direction__name">
                    {travelTrips[direction].name}
                  </div>
                </>
              )}

              <div className="checkout-passenger-part-discounts">
                {directionParts.map((part, partIndex) => (
                  <div key={part.id} className="checkout-passenger-part-discount-container">
                    {partIndex === 0 ? <div className="first" /> : ''}

                    {partIndex < (directionParts.length - 1) ? <div className="not-last" /> : ''}

                    {partIndex === (directionParts.length - 1) ? <div className="last" /> : ''}

                    <div className={`checkout-passenger-part-discount ${part.ticketType === ticketTypes.promo ? 'is-promo-trip' : ''}`}>
                      <h3 className="checkout-passenger-part-discount__route">
                        {part.trips.fromStopName} - {part.trips.toStopName}
                      </h3>

                      <div className="checkout-passenger-part-discount__select">
                        <ItemPartDiscountButton
                          discounts={part.discounts}
                          partId={part.id}
                          handleDiscount={handleDiscount}
                          directionTitle={part.trips.fromStopName + part.trips.toStopName}
                        />
                      </div>

                      {isMultiPart && partIndex === 0
                        && filterSelectedDiscountText[0].code > 0 && (
                        <div className="discount-description-container" aria-live="polite">

                          {/* if stage API from stage: page="147" */}
                          <BenefitsContent page="259" benefitsName={filterSelectedDiscountText[0].name} />

                          <div className="discounts-description-block">
                            <Link
                              to={t(variantConst.urlCmsDiscounts)}
                              target="_blank"
                              aria-label={t('discount_description_link_aria_label')}
                              rel="noopener noreferrer"
                              className="discount-description-link"
                            >
                              {t('is_next')}
                            </Link>
                          </div>
                        </div>
                      )}

                      {isMultiPart && partIndex === 1
                        && filterSelectedDiscountTextSecond[0].code > 0 && (
                        <div className="discount-description-container" aria-live="polite">

                          {/* if stage API from stage: page="147" */}
                          <BenefitsContent page="259" benefitsName={filterSelectedDiscountTextSecond[0].name} />

                          <div className="discounts-description-block">
                            <Link
                              to={t(variantConst.urlCmsDiscounts)}
                              target="_blank"
                              aria-label={t('discount_description_link_aria_label')}
                              rel="noopener noreferrer"
                              className="discount-description-link"
                            >
                              {t('is_next')}
                            </Link>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </React.Fragment>
          );
        })}

        <div className="checkout-passenger-bottom">
          {!isMultiPart && parts && parts[0] && (
            <div className="checkout-passenger-discount-select-wrapper">
              <div className="checkout-passenger-discount-select">
                <ItemPartDiscountButton
                  discounts={parts[0].discounts}
                  partId={parts[0].id}
                  handleDiscount={handleDiscount}
                />
              </div>
            </div>
          )}

          <div className="checkout-passenger-bottom__spacer" />

          <div className="checkout-passenger-price checkout-passenger-price-desktop">
            <span className="checkout-passenger-price__label">{t('checkout_price')}</span>

            <span className="checkout-passenger-price__value">
              {loading && !submitting
                ? <Preloader className="checkout-passenger-preloader" />
                : formatMoney(total)}
            </span>
          </div>
        </div>

        {isMultiPart === false && filterSelectedDiscountText[0].code > 0 && (
          <div className="discount-description-container" aria-live="polite">

            {/* if stage API from stage: page="147" */}
            <BenefitsContent page="259" benefitsName={filterSelectedDiscountText[0].name} />

            <div className="discounts-description-block">
              <Link
                to={t(variantConst.urlCmsDiscounts)}
                target="_blank"
                aria-label={t('discount_description_link_aria_label')}
                rel="noopener noreferrer"
                className="discount-description-link"
              >
                {t('is_next')}
              </Link>
            </div>
          </div>
        )}

        <div className="checkout-passenger-price checkout-passenger-price-mobile">
          <span className="checkout-passenger-price__label">{t('checkout_price')}</span>

          <span className="checkout-passenger-price__value">
            {loading && !submitting
              ? <Preloader className="checkout-passenger-preloader" />
              : formatMoney(total)}
          </span>
        </div>
      </div>

      <div className="wave wave-bottom" />
    </div>
  );
};

Passenger.propTypes = {
  t: PropTypes.func.isRequired,
  formProps: PropTypes.shape().isRequired,
  ticketType: PropTypes.number.isRequired,
  dispatchRemovePassenger: PropTypes.func.isRequired,
  dispatchAddBike: PropTypes.func.isRequired,
  dispatchRemoveBike: PropTypes.func.isRequired,
  dispatchUpdatePassengerName: PropTypes.func.isRequired,
  dispatchUpdatePassengerPartDiscount: PropTypes.func.isRequired,
  passengerIndex: PropTypes.number.isRequired,
  passenger: PropTypes.shape().isRequired,
  travelTrips: PropTypes.shape().isRequired,
  orderHash: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  orderSubmitFieldsErrors: PropTypes.shape(),
  orderNameFieldsErrors: PropTypes.shape(),
};

Passenger.defaultProps = {
  loading: false,
  submitting: false,
  orderSubmitFieldsErrors: {},
  orderNameFieldsErrors: {},
};

const mapStateToProps = (state, props) => ({
  ticketType: state.order.getIn(['data', 'ticketType']),
  orderHash: state.order.getIn(['data', 'hash']),
  passenger: state.order.getIn(['data', 'items', props.passengerIndex]),
  travelTrips: state.order.getIn(['data', 'travelTrips']),
  loading: state.order.getIn(['status', 'loading']),
  submitting: state.request.getIn([endpoints.ORDER_SUBMIT.name, 'loading']),
  orderSubmitFieldsErrors: state.request.getIn([endpoints.ORDER_SUBMIT.name, 'error', 'fields']),
  orderNameFieldsErrors: state.request.getIn([endpoints.PASSENGER_UPDATE_NAME.name, 'error', 'fields']),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchUpdatePassengerName: (orderHash, passengerId, name) => (
    dispatch(updatePassengerName(
      orderHash,
      passengerId,
      name,
    ))
  ),
  dispatchAddBike: (orderHash, passengerId, direction) => (
    dispatch(addBike(
      orderHash,
      passengerId,
      direction,
    ))
  ),
  dispatchRemoveBike: (orderHash, passengerId, direction) => (
    dispatch(removeBike(
      orderHash,
      passengerId,
      direction,
    ))
  ),
  dispatchUpdatePassengerPartDiscount: (orderHash, passengerId, part, discountId) => (
    dispatch(updatePassengerPartDiscount(
      orderHash,
      passengerId,
      part,
      discountId,
    ))
  ),
  dispatchRemovePassenger: (orderHash, passengerId) => (
    dispatch(removePassenger(orderHash, passengerId))
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Passenger));
