import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import RadioGroup from '../../../../../../../components/presentational/FormElements/Radio/RadioGroup/RadioGroup';
import Button from '../../../../../../../components/presentational/Button/Button';
import { getPaymentMethods } from '../../../../../../../core/modules/Data/Collections/Payment/PaymentActions';
import { selectEticketOrderPaymentMethod } from '../../../../../../../core/modules/ETicket/ETicketActions';
import { paymentMethodsCountry } from '../../../../../../../core/config/constants/eTicket';
import { routes } from '../../../../../../../core/config/routes';

const Payments = ({
  t,
  loading,
  paymentMethods,
  dispatchGetPaymentMethods,
  eTicketOrder,
  eTicketOrderPaymentMethod,
  dispatchSelectEticketOrderPaymentMethod,
}) => {
  React.useEffect(() => {
    if (!paymentMethods) dispatchGetPaymentMethods();
  }, [!!paymentMethods]);

  const selectedPaymentValue = () => {
    const selectedPaymentMethod = (
      paymentMethods[paymentMethodsCountry] || []
    ).filter(
      (paymentMethodByCountry) => paymentMethodByCountry.code === eTicketOrderPaymentMethod,
    )[0];

    return selectedPaymentMethod && `${selectedPaymentMethod.id}_${eTicketOrderPaymentMethod}`;
  };

  const renderRadio = (radio) => paymentMethods[paymentMethodsCountry]
      && paymentMethods[paymentMethodsCountry].map(({ logo, id, code }) => (
        <div className="checkout-payment-method" key={id}>
          {radio(`${id}_${code}`, logo)}
        </div>
      ));

  return paymentMethods && (
    <>
      <h3>{t('checkout_eticket_order_payment_header')}</h3>

      <div className="eTicket-checkout-payments">
        <div className="eTicket-checkout-payments-body">
          <div className="eTicket-checkout-payments-list">
            <RadioGroup
              onChange={(e) => dispatchSelectEticketOrderPaymentMethod(eTicketOrder.hash, ...e.target.value.split('_'))}
              name="checkout-payment-methods"
              value={selectedPaymentValue()}
              render={renderRadio}
              disabled={loading}
            />
          </div>

          <div className="eTicket-checkout-submit">
            <Button variant="brand" type="submit" disabled={loading}>{t('common_button_buy')}</Button>

            <span className="eTicket-checkout-submit-text">
              {t('checkout_eticket_order_agreement')}
              {' '}
              <a
                href={t(routes.cms.purchaseRules)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('checkout_eticket_order_agreement_1')}
              </a>
            </span>
          </div>

          <div className="eTicket-checkout-payments-text">
            <strong>
              {t('checkout_eticket_order_payment_time_1')} {' '}
              <em>{t('common_button_buy')}</em> {' '}
              {t('checkout_eticket_order_payment_time_3')}
            </strong>
          </div>
        </div>
      </div>
    </>
  );
};

Payments.propTypes = {
  t: PropTypes.func.isRequired,
  paymentMethods: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.shape())),
  dispatchGetPaymentMethods: PropTypes.func.isRequired,
  eTicketOrder: PropTypes.shape().isRequired,
  eTicketOrderPaymentMethod: PropTypes.string,
  dispatchSelectEticketOrderPaymentMethod: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

Payments.defaultProps = {
  paymentMethods: null,
  eTicketOrderPaymentMethod: '',
  loading: false,
};

const mapStateToProps = (state) => ({
  paymentMethods: state.data.collections.payment.get('methods'),
  eTicketOrderPaymentMethod: state.eTicket.getIn(['order', 'lastPayment', 'paymentMethod']),
  eTicketOrder: state.eTicket.get('order'),
  loading: state.eTicket.getIn(['status', 'loading']),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchGetPaymentMethods: () => dispatch(getPaymentMethods()),
  dispatchSelectEticketOrderPaymentMethod: (orderHash, paymentMethodId, countryId) => (
    dispatch(selectEticketOrderPaymentMethod(orderHash, +paymentMethodId, +countryId))
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(Payments),
);
