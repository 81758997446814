import { constants } from '../../constants';

export const openMenuOverlay = () => ({
  type: constants.components.menuOverlay.OPEN,
  payload: {},
});

export const closeMenuOverlay = () => ({
  type: constants.components.menuOverlay.CLOSE,
  payload: {},
});
