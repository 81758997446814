import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { formatDate, formatTime } from '../../../core/utilities/date';
import { getErrors } from '../../../core/utilities/formValidator';
import { validations } from '../../../core/config/form/validations';
import { getObjectNestedData } from '../../../core/utilities/object';
import { stopsEndpoints } from '../../../core/config/endpoints/stops';
import { getStopsList } from '../../../core/modules/Stops/StopsActions';

class SearchForm extends React.Component {
  constructor() {
    super();

    this.form = React.createRef();
    this.initialDate = new Date();
  }

  handleChange = (event, method, outerRef) => {
    if (event.charCode === 13 || method === 'click') {
      if (event.charCode === 13) {
        event.preventDefault();
      }

      const targetInput = event.charCode === 13
        ? event.target
        : outerRef && outerRef.current && outerRef.current.getElementsByTagName('INPUT')[0];

      if (targetInput) {
        const { form, value } = targetInput;
        const formInputs = Array.from(form.elements).filter((element) => element.tagName === 'INPUT');

        if (value.length >= 2) {
          const index = formInputs.indexOf(targetInput);

          if (!formInputs[0].value) {
            formInputs[0].focus();
            return;
          }

          if (!formInputs[1].value) {
            formInputs[1].focus();
            return;
          }

          formInputs[index + 1].focus();
        }
      }
    }
  };

  getInitialValues = () => {
    const { initialValues } = this.props;

    return {
      from: {
        name: getObjectNestedData(initialValues, 'from.name') || '',
        number: getObjectNestedData(initialValues, 'from.number') || '',
      },
      to: {
        name: getObjectNestedData(initialValues, 'to.name') || '',
        number: getObjectNestedData(initialValues, 'to.number') || '',
      },
      departureDate: initialValues.departureDate || formatDate(this.initialDate),
      departureTime: initialValues.departureTime || formatTime(this.initialDate),
      departureDateObj: initialValues.departureDateObj || this.initialDate,
      returnDateObj: initialValues.returnDateObj || null,
      returnDate: initialValues.returnDate || null,
      returnTime: initialValues.returnTime || null,
      isForward: initialValues.isForward === undefined ? true : initialValues.isForward,
      onlyInternet: initialValues.onlyInternet === 'true',
      onlyDirect: initialValues.onlyDirect === 'true',
      onlyBikes: initialValues.onlyBikes === 'true',
    };
  };

  render() {
    const { onSubmit, render } = this.props;

    return (
      <Formik
        validateOnChange={false}
        onSubmit={(values) => onSubmit(values)}
        validate={(values) => getErrors(values, {
          from: { number: [validations.requiredRouteFrom] },
          to: { number: [validations.requiredRouteTo] },
        }, this.form)}
        enableReinitialize
        initialValues={this.getInitialValues()}        
      >
        {
          (formProps) => (
            <form ref={this.form} onSubmit={formProps.handleSubmit}>
              {render(
                {
                  ...this.props,
                  formProps,
                  onKeyPress: this.handleChange,
                  handleClick: this.handleChange,
                },
              )}
            </form>
          )
        }
      </Formik>
    );
  }
}

SearchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  render: PropTypes.func.isRequired,
  initialValues: PropTypes.shape(),
};

SearchForm.defaultProps = {
  initialValues: {},
};

const mapStateToProps = (state) => ({
  stopsList: state.data.collections.stops,
  stopsLoading: state.request.getIn([stopsEndpoints.GET_STOPS_LIST.name, 'loading']),
});

const mapDispatchToProps = (dispatch) => ({
  onGetStops: (value, excludedValue) => dispatch(getStopsList(value, excludedValue)),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchForm));
