import { Map } from 'immutable';
import { constants } from '../../constants';

const initialState = Map({});

const LightBoxReducer = (state = initialState, action) => {
  switch (action.type) {
  case constants.components.lightBox.OPEN:
    return state
      .set('opened', true)
      .set('openedImage', action.payload.image);

  case constants.components.lightBox.CLOSE:
    return initialState;

  default:
    return state;
  }
};

export default LightBoxReducer;
