import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Autosuggest from 'react-autosuggest';
import FieldContainer from '../../../presentational/FormElements/FieldContainer/FieldContainer';
import './RouteAutocomplete.scss';
import { setStopsList } from '../../../../core/modules/Stops/StopsActions';
import Icon from '../../../presentational/Icon/Icon';

class RouteAutocomplete extends React.Component {
  constructor(props) {
    super(props);

    const { value, name } = props;

    this.state = { value };
    this.autosuggest = React.createRef();
    this.outerRef = React.createRef();
    this.fakeName = `${name}_${new Date().getTime()}`;
  }

  componentDidUpdate(prevProps) {
    this.changeInputValue(prevProps);
  }

  changeInputValue = (prevProps) => {
    const { value } = this.props;

    if (value !== prevProps.value) this.setState({ value });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    const { onInput } = this.props;

    onInput(value);
  };

  onSuggestionSelected = (event, { suggestion, highlightedSuggestion, method }) => {
    const { value } = this.state;
    const { dispatchSetStopsList } = this.props;
    const nextSuggestion = suggestion || highlightedSuggestion;

    if (value.length > 1 && nextSuggestion) {
      this.getSuggestionValue(nextSuggestion);

      this.setState({
        value: nextSuggestion.name,
      });

      const { handleClick } = this.props;
      handleClick(event, method, this.outerRef);
    }

    dispatchSetStopsList(null);
  };

  handleKeyPress = (e) => {
    const { onKeyPress } = this.props;

    onKeyPress(e);

    if (this.autosuggest && e.charCode === 13) {
      e.preventDefault();

      this.autosuggest.current.onBlur();
    }
  };

  onChange = (event, { newValue, method }) => {
    if (method !== 'enter') {
      this.setState({
        value: newValue,
      });
    }

    if (method === 'click') {
      const { handleClick } = this.props;
      this.setState({
        value: newValue,
      });

      handleClick(event, method, this.outerRef);
    }
  };

  renderSuggestion = (suggestion, { query }) => {
    const { name, country, region } = suggestion;
    const filteredName = name.split(' ').map((partOfName) => {
      if (partOfName.toLowerCase().slice(0, query.length) === query.toLowerCase()) {
        const matched = partOfName.slice(0, query.length);

        return (
          <>
            <span className="react-autosuggest__suggestion-matched">
              {matched}
            </span>

            {partOfName.slice(query.length)}
          </>
        );
      }

      return partOfName;
    });

    const { id, t } = this.props;

    if (!suggestion) return null;

    return (
      <div>
        {
          filteredName.length > 0 && (
            <span className="test" aria-label={id === 'route-from' ? t('ticket_search_travelling_from') : t('ticket_search_travelling_to')} />
          )
        }
        {filteredName.map((partOfName, i) => (
          <>
            <span key={`part-of-stop-name-${partOfName + i}`}>{partOfName} </span>
          </>
        ))}

        <span className="react-autosuggest__suggestion-country">
          ({country}, {region})
        </span>
      </div>
    );
  };

  getSuggestionValue = (suggestion) => {
    const { onChange, suggestionValue } = this.props;
    const { value } = this.state;

    if (value.length > 1) onChange(suggestion);

    return suggestionValue(suggestion);
  };

  renderInputComponent = (inputProps) => {
    const {
      id,
      name,
      label,
      type,
      disabled,
      required,
      error,
      inputError,
      placeholder,
      t,
      loading,
      value: propsValue,
    } = this.props;
    const { value, suggestions } = inputProps;

    return (
      <React.Fragment key={id}>
        <FieldContainer
          hidden={type === 'hidden'}
          disabled={disabled}
          label={label}
          required={required}
          error={inputError}
          outerRef={this.outerRef}
        >
          <input
            {...inputProps}
            className="form-input"
            aria-invalid={!!inputError}
            type={type}
            name={this.fakeName}
            real-name={name}
            placeholder={placeholder}
            key={id}
            id={id}
            required={required}
            disabled={disabled}
            autoComplete="off"
          />

          {error && !inputProps.value && (
            <div className="field-container__field-error" aria-live="polite" role="alert">
              <Icon icon="warning" />

              <div className="field-container__field-error-text">
                <div className="field-container__field-error__title">
                  {error}
                </div>
              </div>
            </div>
          )}

          {
            value.length > 1
            && suggestions.length === 0
            && !propsValue
            && !loading
            && (
              <div className="field-container__field-error" aria-live="polite" role="alert">
                <Icon icon="warning" />

                <div>
                  <div className="field-container__field-error__title">
                    {t('form_departure_place_not_found')}
                  </div>

                  <div>{t('form_check_for_mistakes')}</div>
                </div>
              </div>
            )
          }
        </FieldContainer>
      </React.Fragment>
    );
  };

  render() {
    const { suggestions, id } = this.props;
    const { value } = this.state;
    const inputProps = {
      value,
      onChange: this.onChange,
      onKeyPress: this.handleKeyPress,
      onBlur: this.onSuggestionSelected,
      suggestions,
    };

    return (
      <Autosuggest
        ref={this.autosuggest}
        id={id}
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={() => null}
        shouldRenderSuggestions={(suggestionValue) => suggestionValue.trim().length > 1}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        renderInputComponent={this.renderInputComponent}
        inputProps={inputProps}
        highlightFirstSuggestion
        focusInputOnSuggestionClick={false}
        onSuggestionSelected={this.onSuggestionSelected}
      />
    );
  }
}

RouteAutocomplete.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  suggestionValue: PropTypes.func.isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onBlur: PropTypes.func,
  error: PropTypes.string,
  inputError: PropTypes.string,
  placeholder: PropTypes.string,
  suggestions: PropTypes.arrayOf(PropTypes.shape()),
  t: PropTypes.func.isRequired,
  onInput: PropTypes.func.isRequired,
  dispatchSetStopsList: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onKeyPress: PropTypes.func.isRequired,
  handleClick: PropTypes.func,
};

RouteAutocomplete.defaultProps = {
  value: '',
  label: null,
  type: null,
  disabled: false,
  required: false,
  onBlur: () => null,
  handleClick: () => null,
  error: null,
  inputError: null,
  placeholder: null,
  suggestions: null,
  loading: false,
};

const mapDispatchToProps = (dispatch) => ({
  dispatchSetStopsList: (stopsList) => dispatch(setStopsList(stopsList)),
});

export default connect(null, mapDispatchToProps)(withTranslation()(RouteAutocomplete));
