import {
  all,
  put,
  takeEvery,
  takeLeading,
  call,
  select,
} from 'redux-saga/effects';
import { constants } from '../constants';
import { startRequest } from '../Request/RequestSaga';
import { endpoints } from '../../config/endpoints';
import { replace, redirect } from '../../utilities/routing';
import { setToken, setRefreshToken, clearTokens } from '../Token/TokenActions';
import { getObjectNestedData } from '../../utilities/object';
import {
  setCurrentTicket,
  setEticketOrderStatus,
  setEticketOrderData,
  eTicketLogout as eTicketLogoutAction,
} from './ETicketActions';
import { responseStatus } from '../../config/constants/api';
import { history } from '../../historyAndStore';
import { routes } from '../../config/routes';
import { setRequestError } from '../Request/RequestActions';
import { eTicketEndpoints } from '../../config/endpoints/eTicket';
import i18n from '../../../../i18next';

export function* eTicketLogin(action) {
  const { response } = yield call(
    startRequest,
    endpoints.E_TICKET_LOGIN.name,
    endpoints.E_TICKET_LOGIN.url.path(),
    action.payload,
  );

  if (response) {
    yield all([
      put(setToken(constants.token.type.E_TICKET, response.data.token)),
      put(setRefreshToken(constants.token.type.E_TICKET, response.data.refresh_token)),
    ]);
  }
}

export function* validateEticketService(action) {
  const { service, validFromDate } = action.payload;
  const { id } = service;

  const { response, error } = yield call(
    startRequest,
    endpoints.E_TICKET_VALIDATE_SERVICE.name,
    endpoints.E_TICKET_VALIDATE_SERVICE.url.path(service.id),
    { validFrom: validFromDate },
  );

  if (error) {
    const node = getObjectNestedData(error, 'response.data.errors.global').length > 0
        && document.getElementsByClassName('app-container')[0];
    if (node) {
      node.scrollTop = 0;
    }
    return;
  }

  if (response) {
    const responseData = getObjectNestedData(response, 'data.data');
    if (responseData.success) {
      redirect(history, {
        pathname: i18n.t(routes.eticket.checkout.index),
        state: {
          initialOrder: {
            amount: Number(service.amount),
            serviceNr: Number(service.id),
            validFrom: responseData.validFrom,
          },
        },
      });
    } else {
      yield put(
        setRequestError(
          eTicketEndpoints.E_TICKET_VALIDATE_SERVICE.name,
          { global: responseData.message, validFrom: responseData.validFrom, serviceId: id },
        ),
      );
    }
  }
}

export function* getCurrentCardSaga() {
  const { response, error } = yield call(
    startRequest,
    endpoints.GET_CURRENT_TICKET.name,
    endpoints.GET_CURRENT_TICKET.url.path(),
  );

  if (response && response.status === responseStatus.HTTP_OK) {
    const responseData = getObjectNestedData(response, 'data');
    const { data } = responseData;

    yield put(setCurrentTicket(data));
  } else {
    const errorCode = getObjectNestedData(error, 'response.data.code');
    if (errorCode === responseStatus.HTTP_UNAUTHORIZED) {
      yield put(eTicketLogoutAction());
    }
  }
}

export function* eTicketLogout() {
  yield all([
    put(clearTokens(constants.token.type.E_TICKET)),
    put(setCurrentTicket(null)),
  ]);
  redirect(history, i18n.t(routes.eticket.index));
}

function* executeEticketOrderAction(endpointName, url, data = undefined) {
  yield put(setEticketOrderStatus({ loading: true }));

  const { response, error } = yield call(
    startRequest,
    endpointName,
    url,
    data,
  );

  if (error) {
    yield put(setEticketOrderStatus());

    const node = (getObjectNestedData(error, 'response.data.errors.global').length > 0)
        && document.getElementsByClassName('app-container')[0];
    if (node) {
      node.scrollTop = 0;
    }

    return;
  }

  const redirectUrl = getObjectNestedData(response, 'data.data.url');
  if (redirectUrl) {
    window.location.replace(redirectUrl);
    return;
  }

  yield put(setEticketOrderData(response.data.data));
  yield put(setEticketOrderStatus());
}

function* initializeEticketOrder(action) {
  const { order } = action.payload;

  yield executeEticketOrderAction(
    endpoints.E_TICKET_ORDER_INITIALIZE.name,
    endpoints.E_TICKET_ORDER_INITIALIZE.url.path(),
    order,
  );

  const orderHash = yield select((state) => state.eTicket.getIn(['order', 'hash']));
  if (orderHash) replace(history, `${i18n.t(routes.eticket.checkout.index)}/${orderHash}`);
}

function* getEticketOrder(action) {
  const {
    orderHash,
  } = action.payload;

  yield executeEticketOrderAction(
    endpoints.E_TICKET_ORDER_GET.name,
    endpoints.E_TICKET_ORDER_GET.url.path(orderHash),
  );
}

function* selectEticketOrderPaymentMethod(action) {
  const {
    orderHash,
    paymentMethodId,
  } = action.payload;

  yield executeEticketOrderAction(
    endpoints.E_TICKET_ORDER_SELECT_PAYMENT_METHOD.name,
    endpoints.E_TICKET_ORDER_SELECT_PAYMENT_METHOD.url.path(orderHash, paymentMethodId),
  );
}

function* submitEticketOrder(action) {
  const {
    orderHash,
    orderData,
  } = action.payload;

  yield executeEticketOrderAction(
    endpoints.E_TICKET_ORDER_SUBMIT.name,
    endpoints.E_TICKET_ORDER_SUBMIT.url.path(orderHash),
    orderData,
  );
}

function* destroyEticketOrder() {
  yield all([
    put(setEticketOrderData()),
    put(setEticketOrderStatus()),
  ]);
}

export default function* ETicketSaga() {
  yield all([
    takeEvery(constants.eTicket.order.INITIALIZE, initializeEticketOrder),
    takeEvery(constants.eTicket.LOGIN, eTicketLogin),
    takeLeading(constants.eTicket.GET_CURRENT, getCurrentCardSaga),
    takeEvery(constants.eTicket.order.SELECT_ORDER_PAYMENT_METHOD, selectEticketOrderPaymentMethod),
    takeEvery(constants.eTicket.LOGOUT, eTicketLogout),
    takeEvery(constants.eTicket.order.GET, getEticketOrder),
    takeEvery(constants.eTicket.order.SUBMIT, submitEticketOrder),
    takeEvery(constants.eTicket.order.DESTROY, destroyEticketOrder),
    takeEvery(constants.eTicket.VALIDATE_SERVICE, validateEticketService),
  ]);
}
