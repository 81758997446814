import {
  all,
  put,
  takeEvery,
  delay,
} from 'redux-saga/effects';

import { constants } from '../../constants';
import { resetModal } from './ModalActions';

export function* onCloseModal() {
  yield delay(250);
  yield put(resetModal());
}

export default function* ModalSaga() {
  yield all([
    takeEvery(constants.components.modal.CLOSE, onCloseModal),
  ]);
}
