import {
  all,
  call,
  takeEvery,
  put,
} from 'redux-saga/effects';
import { constants } from '../constants';
import { startRequest } from '../Request/RequestSaga';
import { endpoints } from '../../config/endpoints';
import { getObjectNestedData } from '../../utilities/object';
import { setBlog, setBlogs } from './BlogsActions';

function* getBlogs() {
  const { response } = yield call(
    startRequest,
    endpoints.GET_BLOGS.name,
    endpoints.GET_BLOGS.url.path(),
  );

  if (response) {
    const data = getObjectNestedData(response, 'data.data');

    yield put(setBlogs(data));
  }
}

function* getBlog(action) {
  const { slug } = action.payload;
  const { response } = yield call(
    startRequest,
    endpoints.GET_BLOG.name,
    endpoints.GET_BLOG.url.path(slug),
  );

  if (response) {
    const data = getObjectNestedData(response, 'data.data');

    yield put(setBlog(slug, data));
  }
}

export default function* BlogsSaga() {
  yield all([
    takeEvery(constants.blogs.GET_BLOGS, getBlogs),
    takeEvery(constants.blogs.GET_BLOG, getBlog),
  ]);
}
