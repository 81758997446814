import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Switch } from 'react-router';
import { connect } from 'react-redux';

function localizeRoutePath(path, locale, t) {
  switch (typeof path) {
  case 'undefined':
    return undefined;
  case 'object':
    return path.map((value) => t(value)).filter(Boolean);
  default:
    return t(path);
  }
}

const LocalizedSwitch = ({ children, t, locale }) => (
  <Switch>
    {React.Children.map(children, (child) => {
      const validElement = React.isValidElement(child);

      if (!validElement) return child;

      const { staticRoute } = child.props;
      const localizedPath = staticRoute === true
        ? child.props.path : localizeRoutePath(child.props.path, locale, t);

      if (!localizedPath && typeof localizedPath !== 'undefined') return null;
      if (typeof localizedPath === 'object' && !localizedPath.length) return null;

      return React.cloneElement(child, {
        ...child.props,
        path: localizedPath,
      });
    })}
  </Switch>
);

LocalizedSwitch.propTypes = {
  children: PropTypes.node,
  locale: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

LocalizedSwitch.defaultProps = {
  children: [],
};

const mapStateToProps = (state) => ({
  locale: state.locale,
});

export default connect(mapStateToProps, null)(withTranslation()(LocalizedSwitch));
