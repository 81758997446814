import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Route } from 'react-router-dom';
import verifiedByVisa from '../../../../../assets/images/verified_by_visa-VARIANT.png';
import masterCardSecureCode from '../../../../../assets/images/mastercard_securecode.png';
import LocalizedSwitch from '../../LocalizedSwitch/LocalizedSwitch';
import { routes } from '../../../../core/config/routes';
import './Copyrights.scss';

const Copyrights = ({ t }) => (
  <div className="footer-copyrights">
    <div>
      <span className="footer-copyrights_1">{t('footer_copyright_1')}</span>

      <LocalizedSwitch>
        <Route
          exact
          path={Object.entries(routes.checkout).map(([, value]) => value)}
          render={() => (
            <>
              <img className="footer-checkout-img" src={verifiedByVisa} alt="Verified By Visa" />
              <img className="footer-checkout-img" src={masterCardSecureCode} alt="MasterCard SecureCode" />
            </>
          )}
        />
      </LocalizedSwitch>

      <span className="footer-copyrights_2">{t('footer_copyright_2')}</span>
    </div>
  </div>
);

Copyrights.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Copyrights);
