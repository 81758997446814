export const validations = {
  required: {
    regex: '/\\S/',
    message: 'validations_required_message',
  },
  requiredCoupon: {
    regex: '/\\S/',
    message: 'validations_coupon_invalid',
  },
  requiredBool: {
    customCheck: (value) => value,
    message: 'validations_requiredBool_message',
  },
  requiredPositiveNumber: {
    customCheck: (value) => typeof value === 'number' && value > 0,
    message: 'validations_required_message',
  },
  requiredRouteFrom: {
    regex: '/\\S/',
    message: 'validations_requiredRouteFrom_message',
  },
  requiredRouteTo: {
    regex: '/\\S/',
    message: 'validations_requiredRouteTo_message',
  },
  email: {
    regex: '/^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$/',
    message: 'validations_email_message',
  },
  name: {
    regex: '/^([^0-9]*)$/',
    message: 'validations_contains_numbers',
  },
  eTicket: {
    cardNr: {
      regex: '/\\S/',
      message: 'validations_eTicket_cardNr_message',
    },
    pin: {
      regex: '/\\S/',
      message: 'validations_eTicket_pin_message',
    },
  },
};
