/* eslint-disable react/no-danger */

import React from 'react';
import PropTypes from 'prop-types';
import './Information.scss';

const Information = ({ parts }) => (
  <div className="content-block information-container">
    {parts.map((part) => (
      <div
        className="information"
        key={part.id}
      >
        {part.title && (
          <h3>{part.title}</h3>
        )}

        <div className="information-row-container">
          {part.image && (
            <img
              className="information__image"
              src={part.image}
              style={{
                minHeight: '1px',
              }}
              alt={part.content}
            />
          )}

          {part.content && (
            <div
              className="content-block information-text"
              dangerouslySetInnerHTML={{ __html: part.content }}
              key={part.id}
            />
          )}
        </div>
      </div>
    ))}
  </div>
);

Information.propTypes = {
  parts: PropTypes.arrayOf(PropTypes.shape()),
};

Information.defaultProps = {
  parts: [],
};

export default Information;
