import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OverlayContent from './Partials/OverlayContent';
import {
  openMenuOverlay,
} from '../../../../../core/modules/Components/MenuOverlay/MenuOverlayActions';
import Icon from '../../../../../components/presentational/Icon/Icon';
import './MenuOverlay.scss';

const MenuOverlay = ({
  menuItems,
  displayMenuOverlay,
  dispatchOpenMenuOverlay,
}) => (
  <>
    {!displayMenuOverlay && (
      <div
        className="overlay-action-icon-container" 
        role="button" 
        tabIndex={0} 
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            dispatchOpenMenuOverlay();
          }
        }}
      >
        <Icon
          icon="hamburger"
          onClick={dispatchOpenMenuOverlay}
        />
      </div>
    )}

    {displayMenuOverlay && <OverlayContent menuItems={menuItems} />}
  </>
);
MenuOverlay.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dispatchOpenMenuOverlay: PropTypes.func.isRequired,
  displayMenuOverlay: PropTypes.bool,
};

MenuOverlay.defaultProps = {
  displayMenuOverlay: false,
};

const mapStateToProps = (state) => ({
  displayMenuOverlay: state.components.menuOverlay.get('opened'),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchOpenMenuOverlay: () => dispatch(openMenuOverlay()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuOverlay);
