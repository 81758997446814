import { Map } from 'immutable';
import { constants } from '../../../constants';

const initialState = Map({});

const PaymentReducer = (state = initialState, action) => {
  switch (action.type) {
  case constants.data.collections.payment.SET_PAYMENT_METHODS:
    return state.set('methods', action.payload.methods);

  default:
    return state;
  }
};

export default PaymentReducer;
