import { Map } from 'immutable';
import { constants } from '../constants';

const initialState = Map({
  forward: null,
  backward: null,
});

const RoundTripTicketsReducer = (state = initialState, action) => {
  switch (action.type) {
  case constants.roundTripTickets.SET_ROUND_TRIP_TICKET:
    return state.set(action.payload.type, action.payload.ticket);

  case constants.roundTripTickets.REMOVE_ROUND_TRIP_TICKET:
    return state.delete(action.payload.type, action.payload.ticket);

  default:
    return state;
  }
};

export default RoundTripTicketsReducer;
