import { constants } from '../constants';

export const validateEticketService = (service, validFromDate) => ({
  type: constants.eTicket.VALIDATE_SERVICE,
  payload: { service, validFromDate },
});

export const destroyEticketOrder = () => ({
  type: constants.eTicket.order.DESTROY,
  payload: {},
});

export const submitEticketOrder = (orderHash, orderData) => ({
  type: constants.eTicket.order.SUBMIT,
  payload: { orderHash, orderData },
});

export const selectEticketOrderPaymentMethod = (orderHash, paymentMethodId) => ({
  type: constants.eTicket.order.SELECT_ORDER_PAYMENT_METHOD,
  payload: { orderHash, paymentMethodId },
});

export const setEticketOrderData = (data) => ({
  type: constants.eTicket.order.SET_DATA,
  payload: {
    data,
  },
});

export const setEticketOrderStatus = (status) => ({
  type: constants.eTicket.order.SET_STATUS,
  payload: {
    status,
  },
});

export const initializeEticketOrder = (order) => ({
  type: constants.eTicket.order.INITIALIZE,
  payload: {
    order,
  },
});

export const getEticketOrder = (orderHash) => ({
  type: constants.eTicket.order.GET,
  payload: { orderHash },
});

export const eTicketLogin = (cardNr, pin, cityId) => ({
  type: constants.eTicket.LOGIN,
  payload: {
    cardNr, pin, cityId,
  },
});

export const eTicketLogout = () => ({
  type: constants.eTicket.LOGOUT,
  payload: {},
});

export const getCurrentTicket = () => ({
  type: constants.eTicket.GET_CURRENT,
  payload: {},
});

export const setCurrentTicket = (cardContent) => ({
  type: constants.eTicket.SET_CURRENT,
  payload: {
    cardContent,
  },
});
