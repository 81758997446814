import {
  all,
  call,
  takeEvery,
  put,
  select,
} from 'redux-saga/effects';
import { constants } from '../../../constants';
import { startRequest } from '../../../Request/RequestSaga';
import { endpoints } from '../../../../config/endpoints';
import { addBanner } from './BannersActions';
import { addQueryToLink } from '../../../../config/routes';
import { getObjectNestedData } from '../../../../utilities/object';

function* getBannerByZoneSaga(action) {
  const { searchValues, zone } = action.payload;
  const searchValuesHasRoute = searchValues
    && searchValues.from
    && searchValues.from.number
    && searchValues.to
    && searchValues.to.number;
  const searchRouteCode = searchValuesHasRoute ? `${searchValues.from.number}_${searchValues.to.number}` : '';
  const prevSearchRouteCode = yield select((state) => (
    state.data.collections.banners.getIn([zone, 'searchRouteCode'])
  ));
  const routeBannerAlreadyLoaded = prevSearchRouteCode === searchRouteCode;

  if (routeBannerAlreadyLoaded) return;

  const urlParams = searchValuesHasRoute ? {
    origin: searchValues.from.number,
    destination: searchValues.to.number,
  } : {};
  const bannerUrl = addQueryToLink(
    endpoints.GET_BANNER_BY_ZONE.url.path(zone),
    urlParams,
  );
  const { response, error } = yield call(
    startRequest,
    endpoints.GET_BANNER_BY_ZONE.name,
    bannerUrl,
  );

  if (error) return;

  const banner = getObjectNestedData(response, 'data.data');

  yield put(addBanner({ ...banner, zone, searchRouteCode }));
}

export default function* BannersSaga() {
  yield all([
    takeEvery(constants.data.collections.banners.GET_BANNER_BY_ZONE, getBannerByZoneSaga),
  ]);
}
