import React from 'react';
import PropTypes from 'prop-types';
import CMSContent from './CMSContent';
import { withTracker } from '../../common/Tracker/withTracker';

const CMSPage = ({ location }) => (
  <CMSContent page={location.pathname.split('/').filter(Boolean).pop()} />
);

CMSPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default withTracker(CMSPage);
