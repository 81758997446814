import { constants } from '../constants';

export const getCMSPage = (page) => ({
  type: constants.data.collections.cms.GET_PAGE,
  payload: { page },
});

export const setCMSPage = (page, locale, content) => ({
  type: constants.data.collections.cms.SET_PAGE,
  payload: { page, content, locale },
});

export const getCMSMenu = (menuFolder) => ({
  type: constants.data.collections.cms.GET_MENU,
  payload: { menuFolder },
});

export const setCMSMenu = (menuFolder, locale, menuItems) => ({
  type: constants.data.collections.cms.SET_MENU,
  payload: { menuFolder, menuItems, locale },
});
