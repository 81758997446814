import {
  all, call, put, select, takeEvery,
} from 'redux-saga/effects';
import { constants } from '../constants';
import { startRequest } from '../Request/RequestSaga';
import { endpoints } from '../../config/endpoints';
import { getObjectNestedData } from '../../utilities/object';
import { setRoutes } from '../Routes/RoutesActions';
import { replace } from '../../utilities/routing';
import { history } from '../../historyAndStore';
import { addQueryToLink, routes as appRoutes } from '../../config/routes';
import { removeRoundTripTicket } from '../RoundTripTickets/RoundTripTicketsActions';
import { setShowDates, setSlugSearchValues } from './SearchActions';
import { responseStatus } from '../../config/constants/api';
import i18n from '../../../../i18next';
import { formatDate, formatTime } from '../../utilities/date';

function* submitSearchSaga(action) {
  const { values } = action.payload;
  const ticketForward = yield select((state) => (
    state.roundTripTickets.get('forward')
  ));

  const {
    from,
    to,
    departureDate: departureDateOriginal,
    returnDate,
    departureDateObj,
    returnDateObj,
    departureTime: departureTimeOriginal,
    returnTime,
    isForward,
    onlyDirect,
    onlyInternet,
    onlyBikes,
    shouldTargetBlank = false,
  } = values;

  const fallbackDate = new Date();
  const departureTime = departureTimeOriginal || formatTime(fallbackDate);
  const departureDate = departureDateOriginal || formatDate(fallbackDate);

  const departureHours = departureTime.split(':')[0];
  const departureMinutes = departureTime.split(':')[1];
  let departure = (
    departureDateObj
    && departureDateObj instanceof Date
    && departureHours
    && departureMinutes
    && new Date(departureDateObj.setHours(departureHours, departureMinutes)).toISOString()
  ) || `${departureDate} ${departureTime}`;

  if (ticketForward) {
    const returnHours = returnTime.split(':')[0];
    const returnMinutes = returnTime.split(':')[1];
    const userSelectedReturnDateTime = returnDateObj
      ? new Date(returnDateObj.setHours(returnHours, returnMinutes)).toISOString()
      : `${returnDate} ${returnTime}`;
    const ticketForwardArivalDateTime = `${ticketForward.arrivalTime.date} ${ticketForward.arrivalTime.text}`;
    const utcDate = returnDateObj ? userSelectedReturnDateTime.split('T')[0] : null;
    const utcTime = returnDateObj ? userSelectedReturnDateTime.split('T')[1].split('.')[0] : null;

    departure = (returnDateObj ? `${utcDate} ${utcTime}` : userSelectedReturnDateTime) >= ticketForwardArivalDateTime
      ? userSelectedReturnDateTime
      : ticketForwardArivalDateTime;
  }

  if (departureDateObj && departureDateObj instanceof Date) {
    const originalDt = new Date(departure);
    departure = new Date(originalDt.setMinutes(
      originalDt.getMinutes() - originalDt.getTimezoneOffset(),
    )).toISOString();
  }

  const forwardTripIds = ticketForward
    ? ticketForward.steps
      .map((step) => step.sellData && step.sellData.actualTripId)
      .filter(Boolean)
      .join(',')
    : 0;
  const formData = {
    origin: ticketForward ? to.number : from.number,
    destination: ticketForward ? from.number : to.number,
    departuretime: departure,
    prices: '1',
    isback: `${isForward ? 0 : 1}`,
    forward: `${isForward ? 1 : forwardTripIds}`,
    onlyDirect: onlyDirect || false,
    onlyInternet: onlyInternet || false,
    onlyBikes: onlyBikes || false,
  };

  if (shouldTargetBlank && formData.origin && formData.destination) {
    const params = addQueryToLink(
      i18n.t(appRoutes.searchResults),
      {
        departureTime,
        departureDate,
        returnTime,
        returnDate,
        isForward,
        onlyDirect,
        onlyInternet,
        onlyBikes,
        from: from.number,
        fromStop: from.name,
        to: to.number,
        toStop: to.name,
      },
    );
    window.open(window.location.origin + params, '_blank');
  }

  if (!shouldTargetBlank && formData.origin && formData.destination) {
    replace(
      history,
      addQueryToLink(
        i18n.t(appRoutes.searchResults),
        {
          departureTime,
          departureDate,
          returnTime,
          returnDate,
          isForward,
          onlyDirect,
          onlyInternet,
          onlyBikes,
          from: from.number,
          fromStop: from.name,
          to: to.number,
          toStop: to.name,
        },
      ),
    );

    yield put(setRoutes());

    const { response } = yield call(
      startRequest,
      endpoints.INITIATE_SEARCH.name,
      endpoints.INITIATE_SEARCH.url.path(),
      formData,
      null,
      { 'Content-Type': 'application/json' },
    );

    if (response) {
      const data = getObjectNestedData(response, 'data.data');
      const { routes, showDates } = data;

      yield put(setRoutes(routes));
      yield put(setShowDates(showDates));
      yield put(removeRoundTripTicket('backward'));
    }
  }
}

function* submitSearchBySlugSaga(action) {
  yield put(setRoutes());

  const { slug } = action.payload;
  const { response, error } = yield call(
    startRequest,
    endpoints.INITIATE_SEARCH_BY_SLUG.name,
    endpoints.INITIATE_SEARCH_BY_SLUG.url.path(slug),
  );

  if (response) {
    const data = getObjectNestedData(response, 'data.data');
    const { searchResult, origin, destination } = data;
    const { routes, showDates } = searchResult;

    yield put(setRoutes(routes));
    yield put(setShowDates(showDates));
    yield put(setSlugSearchValues(slug, {
      from: origin,
      to: destination,
    }));
    yield put(removeRoundTripTicket('backward'));
    return;
  }

  const errorCode = getObjectNestedData(error, 'response.data.code');
  if (errorCode === responseStatus.HTTP_NOT_FOUND) {
    yield put(setSlugSearchValues(slug, null));
  }
}

export default function* SearchSaga() {
  yield all([
    takeEvery(constants.search.SUBMIT_SEARCH, submitSearchSaga),
    takeEvery(constants.search.SUBMIT_SEARCH_BY_SLUG, submitSearchBySlugSaga),
  ]);
}
