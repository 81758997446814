import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Input from '../../../components/presentational/FormElements/Input/Input';
import Button from '../../../components/presentational/Button/Button';
import { getErrors } from '../../../core/utilities/formValidator';
import { validations } from '../../../core/config/form/validations';
import { updateUserPassword } from '../../../core/modules/User/UserActions';
import { userEndpoints } from '../../../core/config/endpoints/user';
import Alert from '../../../components/presentational/Alert/Alert';
import Icon from '../../../components/presentational/Icon/Icon';

class ChangePasswordForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: false,
    };

    this.form = React.createRef();
  }

  toggleShowPassword = () => (
    this.setState((prevState) => (
      { showPassword: !prevState.showPassword }
    )));

  onSubmit = ({ password }) => {
    const { dispatchUpdateUserPassword } = this.props;

    dispatchUpdateUserPassword(password);
  };

  render() {
    const { showPassword } = this.state;
    const { t, changePasswordErrors } = this.props;
    const { global, fields } = changePasswordErrors;
    const changePasswordFieldsErrors = fields || {};

    return (
      <div>
        <div className="personal-data-form-header">{t('user_change_password')}</div>

        <Formik
          validateOnChange={false}
          enableReinitialize
          onSubmit={this.onSubmit}
          initialValues={{
            password: '',
          }}
          validate={(values) => getErrors(values, {
            password: [validations.required],
          }, this.form)}

          render={(formProps) => {
            const {
              values,
              handleSubmit,
              setFieldValue,
              errors,
            } = formProps;

            const {
              password,
            } = values;

            return (
              <form onSubmit={handleSubmit} ref={this.form}>
                {global && global.map((error) => (
                  <Alert
                    key={error}
                    variant="danger"
                    heading={t('common_error_exclamation')}
                  >
                    {error}
                  </Alert>
                ))}

                <div className="personal-data-form-input-row">
                  <Input
                    name="email"
                    id="password"
                    value={password}
                    label={t('user_password')}
                    type={showPassword ? 'text' : 'password'}
                    onChange={(e) => setFieldValue('password', e.target.value)}
                    error={t(errors.password) || changePasswordFieldsErrors.password}
                    icon={(
                      <button
                        className="input-icon__button"
                        type="button"
                        onClick={this.toggleShowPassword}
                      >
                        <Icon icon="view" /> {t(showPassword ? 'login_hide' : 'login_show')}
                      </button>
                    )}
                  />
                </div>

                <Button
                  className="personal-data-form-change-details-request-button"
                  type="submit"
                >
                  {t('user_form_save_changes')}
                </Button>
              </form>
            );
          }}
        />
      </div>
    );
  }
}

ChangePasswordForm.propTypes = {
  t: PropTypes.func.isRequired,
  dispatchUpdateUserPassword: PropTypes.func.isRequired,
  changePasswordErrors: PropTypes.shape(),
};

ChangePasswordForm.defaultProps = {
  changePasswordErrors: {},
};

const mapStateToProps = (state) => ({
  changePasswordErrors: state.request.getIn([userEndpoints.UPDATE_USER_PASSWORD.name, 'error']),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchUpdateUserPassword: (password) => dispatch(updateUserPassword(password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChangePasswordForm));
