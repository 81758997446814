import {
  call,
  all,
  takeLatest,
  put,
  cancel,
  fork,
  take,
} from 'redux-saga/effects';
import { constants } from '../constants';
import { startRequest } from '../Request/RequestSaga';
import { endpoints } from '../../config/endpoints';
import { setRefundInfo, setTicketRefunded } from './TicketReturnActions';

function* fetchRefundInfoCancellable(action) {
  const { orderHash, ticketId } = action.payload;

  const { response } = yield call(
    startRequest,
    endpoints.FETCH_REFUND_INFO.name,
    endpoints.FETCH_REFUND_INFO.url.path(orderHash, ticketId),
  );

  if (response) yield put(setRefundInfo(response.data.data));
}

function* fetchRefundInfo(action) {
  const fetchTask = yield fork(fetchRefundInfoCancellable, action);

  yield take(constants.ticketReturn.CANCEL_FETCH_REFUND_INFO);

  yield put(setRefundInfo(null));
  yield cancel(fetchTask);
}

function* refundTicket(action) {
  const { orderHash, ticketId, refundType } = action.payload;

  const { response } = yield call(
    startRequest,
    endpoints.REFUND_TICKET.name,
    endpoints.REFUND_TICKET.url.path(orderHash, ticketId, refundType),
  );

  if (response) yield put(setTicketRefunded());
}

export default function* TicketReturnSaga() {
  yield all([
    takeLatest(constants.ticketReturn.FETCH_REFUND_INFO, fetchRefundInfo),
    takeLatest(constants.ticketReturn.REFUND_TICKET, refundTicket),
  ]);
}
