import {
  call,
  all,
  takeLatest,
  put,
} from 'redux-saga/effects';
import { constants } from '../constants';
import { startRequest } from '../Request/RequestSaga';
import { endpoints } from '../../config/endpoints';
import { setPartRoutes } from './TicketChangeActions';
import { redirect } from '../../utilities/routing';
import { history } from '../../historyAndStore';
import { getLink, routes } from '../../config/routes';
import i18n from '../../../../i18next';

function* searchRoutesByPart(action) {
  const { orderHash, partId, date } = action.payload;

  const { response } = yield call(
    startRequest,
    endpoints.SEARCH_ROUTES_BY_PART.name,
    endpoints.SEARCH_ROUTES_BY_PART.url.path(orderHash, partId),
    { date },
  );

  if (response) yield put(setPartRoutes(response.data.data.routes));
}

function* submitOrderChange(action) {
  const { orderHash, exchangeAll, parts } = action.payload;

  const { response } = yield call(
    startRequest,
    endpoints.SUBMIT_ORDER_CHANGE.name,
    endpoints.SUBMIT_ORDER_CHANGE.url.path(orderHash, exchangeAll),
    parts,
  );

  if (response) {
    redirect(
      history,
      getLink(i18n.t(routes.tickets.changed), { orderHash: response.data.data.hash }),
    );
  }
}

export default function* TicketChangeSaga() {
  yield all([
    takeLatest(constants.ticketChange.SEARCH_ROUTES_BY_PART, searchRoutesByPart),
    takeLatest(constants.ticketChange.SUBMIT_ORDER_CHANGE, submitOrderChange),
  ]);
}
