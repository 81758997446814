import React from 'react';
import PropTypes from 'prop-types';
import Radio from '../Radio/Radio';

class RadioGroup extends React.Component {
  getRadioItem = (id, label, imgAltText) => {
    const {
      error,
      value,
      name,
      disabled,
      required,
      onChange,
      onBlur,
    } = this.props;

    return (
      <Radio
        id={id}
        label={label}
        value={value}
        name={name}
        error={error}
        required={required}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        altText={imgAltText}
      />
    );
  };
  
  render() {
    const {
      label,
      required,
      render,
      disabled,
      error,
    } = this.props;
    
    return (
      <div className="radio-group" role="radiogroup">
        {!!label && (
          <div className={`radio-group__label ${disabled ? 'is-disabled' : ''}`}>
            <span>{label}</span>
    
            {required && (<span className="radio-group__label-required">*</span>)}
          </div>
        )}
        
        {render(this.getRadioItem)}
        
        <div className="radio__error" role="alert">
          {error}
        </div>
      </div>
    );
  }
}

RadioGroup.propTypes = {
  render: PropTypes.func.isRequired,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  error: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
};

RadioGroup.defaultProps = {
  value: '',
  disabled: false,
  required: false,
  onBlur: () => null,
  error: null,
  label: '',
};

export default RadioGroup;
