import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';

export const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = (page) => {
    ReactGA.set({
      page,
      ...options,
    }, ['TOKSGA', 'GA']);
    ReactGA.pageview(page, ['TOKSGA', 'GA']);
  };

  const HOC = (props) => {
    const { location } = props;
    useEffect(() => trackPage(location.pathname), [
      location.pathname,
    ]);

    return <WrappedComponent {...props} />;
  };

  HOC.propTypes = {
    location: PropTypes.shape().isRequired,
  };

  return HOC;
};
