import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { constants } from '../../../core/modules/constants';
import { openModal } from '../../../core/modules/Components/Modal/ModalActions';
import NewPasswordForm from './Partials/NewPasswordForm/NewPasswordForm';

const PasswordReset = ({ match, openPasswordResetModal, isAuthenticated }) => {
  const { hash } = match.params;

  React.useEffect(() => {
    if (!isAuthenticated && hash) openPasswordResetModal(hash);
  }, [hash, isAuthenticated]);

  return null;
};

PasswordReset.propTypes = {
  openPasswordResetModal: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  match: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.token.hasIn([constants.token.type.USER, 'token']),
});

const mapDispatchToProps = (dispatch) => ({
  openPasswordResetModal: (hash) => dispatch(openModal(<NewPasswordForm hash={hash} />)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
