import { Map } from 'immutable';
import { constants } from '../../../constants';

const initialState = Map({});

const FooterReducer = (state = initialState, action) => {
  switch (action.type) {
  case constants.components.footer.ADD_FOOTER_COLUMNS:
    return state.set('footerColumns', action.payload.footerColumns);

  case constants.components.footer.RESET:
    return state.delete('footerColumns');

  default:
    return state;
  }
};
export default FooterReducer;
