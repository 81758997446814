import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import SearchForm from '../../Forms/SearchForm/SearchForm';
import SearchFormView from '../../../components/presentational/Forms/SearchForm/SearchForm';
import './Homepage-VARIANT.scss';
import { breakpointSet } from '../../../core/utilities/responsive';
import { redirect } from '../../../core/utilities/routing';
import { history } from '../../../core/historyAndStore';
import { addQueryToLink, routes } from '../../../core/config/routes';
import { setSearchValues } from '../../../core/modules/Search/SearchActions';
import KnowBox from '../../common/KnowBox/KnowBox';
import TopRoutesStations from '../../common/TopRoutesStations/TopRoutesStations';
import Newsletter from '../../../components/containers/Newsletter/Newsletter';
import withDocumentHead from '../../common/DocumentHead/withDocumentHead';
import { withTracker } from '../../common/Tracker/withTracker';
import { baseURL, endpoints } from '../../../core/config/endpoints';
import Alert from '../../../components/presentational/Alert/Alert';
import { setHomepageErrors } from '../../../core/modules/Components/Homepage/HomepageActions';
import { getBannerByZone } from '../../../core/modules/Data/Collections/Banners/BannersActions';
import variantConst from '../../../core/config/constants/variantConst/variantConst';

class Homepage extends Component {
  constructor() {
    super();

    this.state = {
      tablet: breakpointSet('xs', 'sm'),
      mobile: breakpointSet('xs'),
    };
  }

  componentDidMount() {
    const {
      t,
      setDocumentTitle,
      setDocumentDescription,
      getHomepageBanner,
    } = this.props;

    window.addEventListener('resize', this.handleResize);

    if (t) {
      setDocumentTitle(`${t(variantConst.documentTitle)} ${t(variantConst.documentTitleHomepage)}`);
      setDocumentDescription(t(variantConst.documentDescriptionHomepage));
    }

    getHomepageBanner();
  }

  componentWillUnmount() {
    const { clearHomepageErrors } = this.props;
    window.removeEventListener('resize', this.handleResize);
    clearHomepageErrors();
  }

  handleResize = () => {
    const { tablet, mobile } = this.state;
    const newTabletValues = breakpointSet('xs', 'sm');
    const newMobileValues = breakpointSet('xs');

    if (tablet !== newTabletValues) {
      this.setState({
        tablet: newTabletValues,
      });
    }

    if (mobile !== newMobileValues) {
      this.setState({
        mobile: newMobileValues,
      });
    }
  };

  handleFormSubmit = (formData) => {
    const { onSetSearchValues, t } = this.props;

    onSetSearchValues(formData);

    redirect(
      history,
      addQueryToLink(
        t(routes.searchResults),
        {
          ...formData,
          from: formData.from.number,
          fromStop: formData.from.name,
          to: formData.to.number,
          toStop: formData.to.name,
        },
      ),
    );
  };

  searchContainerBackgroundUrl = () => {
    const { locale } = this.props;
    const { tablet, mobile } = this.state;
    let backgroundSize = 'desktop';

    if (mobile) {
      backgroundSize = 'mobile';
    }

    if (tablet) {
      backgroundSize = 'tablet';
    }

    return `${baseURL}${endpoints.GET_DIRECT_BANNER_URL_BY_ZONE.url.path('main_front_page', backgroundSize, locale)}`;
  };

  render() {
    const {
      t, errors, homepageBanner,
    } = this.props;
    const { tablet, mobile } = this.state;
    const {
      imageMobile,
      imageTablet,
      imageDesktop,
      link,
      title,
      linkTarget,
    } = homepageBanner || {};
    const bannerImage = homepageBanner && (
      (mobile && imageMobile)
      || (tablet && imageTablet)
      || imageDesktop
    );

    return (
      <>
        <div
          className="search-container"
          style={{
            backgroundImage: `url(${this.searchContainerBackgroundUrl()})`,
          }}
        >
          <div className="search-form-wrap">
            <div className="search-form-title-wrap">
              <h1 className="search-form-title">
                {t(variantConst.homepageSearchFormTitle)}
              </h1>

              <div className="search-form-subtitle">
                {t(variantConst.homepageSearchFormSubtitle)}
              </div>
            </div>

            <SearchForm
              render={(props) => <SearchFormView {...props} mobile={tablet} />}
              onSubmit={(formData) => this.handleFormSubmit(formData)}
            />
          </div>
        </div>

        {
          errors.map((error) => (
            <Alert
              key={error}
              variant="danger"
              heading={t('common_error_exclamation')}
            >
              {error}
            </Alert>
          ))
        }

        {bannerImage && (
          <a href={link} target={linkTarget}>
            <img className="homepage-banner" src={bannerImage} alt={title} />
          </a>
        )}

        <KnowBox />

        <TopRoutesStations />

        <div className="homepage-newsletter-row">
          <div className="homepage-newsletter-container">
            <div className="homepage-newsletter">
              <Newsletter>
                <h3 className="homepage-newsletter-header">
                  <div className="has-flex">
                    <>{t('homepage_newsletter_subscribe_to_our')}</>

                    <div className="has-color">{t('homepage_newsletter_subscribe_to_news')}</div>

                    <>{t('homepage_newsletter_subscribe_and_receive')}</>
                  </div>

                  <div className="has-flex has-margin">
                    {t('homepage_newsletter_subscribe_special_offers')}
                  </div>
                </h3>
              </Newsletter>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Homepage.propTypes = {
  getHomepageBanner: PropTypes.func.isRequired,
  onSetSearchValues: PropTypes.func.isRequired,
  setDocumentTitle: PropTypes.func.isRequired,
  setDocumentDescription: PropTypes.func.isRequired,
  clearHomepageErrors: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  t: PropTypes.func.isRequired,
  homepageBanner: PropTypes.shape(),
  locale: PropTypes.string.isRequired,
};

Homepage.defaultProps = {
  homepageBanner: null,
  errors: [],
};

const mapDispatchToProps = (dispatch) => ({
  onSetSearchValues: (formData) => dispatch(setSearchValues(formData)),
  clearHomepageErrors: () => dispatch(setHomepageErrors()),
  getHomepageBanner: () => dispatch(getBannerByZone('main_after_search')),
});

const mapStateToProps = (state) => ({
  homepageBanner: state.data.collections.banners.get('main_after_search'),
  errors: state.components.homepage.get('errors'),
  locale: state.locale,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(withDocumentHead(withTracker(Homepage))));
