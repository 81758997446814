import { constants } from '../constants';

export const findByTicket = (ticketNr, email) => ({
  type: constants.ticketSearch.FIND_BY_TICKET,
  payload: {
    ticketNr,
    email,
  },
});

export const findByTravel = (destination, name, date) => ({
  type: constants.ticketSearch.FIND_BY_TRAVEL,
  payload: {
    destination,
    name,
    date,
  },
});

export const setTickets = (data) => ({
  type: constants.ticketSearch.SET_TICKETS,
  payload: {
    data,
  },
});
