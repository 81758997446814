import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import './Services.scss';
import { formatDate, getMonth, getNextMonth } from '../../../../../core/utilities/date';
import { formatMoney } from '../../../../../core/utilities/money';
import Button from '../../../../../components/presentational/Button/Button';
import { routes } from '../../../../../core/config/routes';
import Service from '../Service/Service';
import neutral from '../../../../../../assets/images/neutral.png';
import Alert from '../../../../../components/presentational/Alert/Alert';
import { eTicketEndpoints } from '../../../../../core/config/endpoints/eTicket';
import { getCurrentTicket } from '../../../../../core/modules/ETicket/ETicketActions';
import Icon from '../../../../../components/presentational/Icon/Icon';
import withDocumentHead from '../../../../common/DocumentHead/withDocumentHead';

const Services = (props) => {
  const {
    t,
    type,
    availableServices,
    currentServices,
    match,
    errors,
    dispatchGetCurrentTicket,
    setDocumentTitle,
  } = props;

  React.useEffect(() => {
    dispatchGetCurrentTicket();

    setDocumentTitle(t('document_title', { title: t('eTicket_available_services_title') }));
  }, []);

  const ticketType = (type === 1 ? 'is-tikis' : 'is-druskininkai');
  const { path } = match;
  const isEticketSuccessRoute = path === t(routes.eticket.success);

  const grouped = () => {
    const servicesByMonth = {};

    availableServices.forEach((item) => {
      const { month } = item;

      if (!servicesByMonth[month]) servicesByMonth[month] = [];
      servicesByMonth[month].push(item);
    });

    const objectsToArray = Object.keys(servicesByMonth)
      .map((month) => servicesByMonth[month]);
    return objectsToArray;
  };

  const activeMonthsNumbers = () => currentServices.map((service) => getMonth(service.validTill));

  const activeMonths = currentServices.map((service) => service.month);

  const nextAvailableMonth = () => {
    const currentServicesArray = currentServices.map((service) => getNextMonth(service.validTill));

    return currentServicesArray[0];
  };

  const replenishableService = (availableMonth) => activeMonths.includes(availableMonth);

  const availableServiceMonths = grouped().map((servicelist) => (servicelist[0].month));

  const checkForSuccess = availableServiceMonths.length === 0 && activeMonths.length > 0;

  return (
    <>
      <h1 className="eTicket-services-title">
        {t('eTicket_available_services_title')}
      </h1>

      {errors.map((error) => (
        <Alert
          key={error}
          variant="danger"
          heading={t('common_error_exclamation')}
        >
          {error}
        </Alert>
      ))}

      {isEticketSuccessRoute && (
        <div className="eTicket-payment-sucessful">
          <Icon icon="green-bus" />

          <div className="eTicket-payment-successful-text">
            {t('checkout_eticket_order_payment_successful')}
          </div>
        </div>
      ) }

      {currentServices.length > 0 && (
        <>
          { type === 1 && (
            <div className="eTicket-current-services">
              <div className="eTicket-services-subtitle">
                {t('eTicket_user_card_state')}
              </div>

              <ul className="eTicket-services-list has-border eTicket-services-list-ordered">
                {currentServices.map((service) => (
                  <li key={service.validTill}>
                    <div className="eTicket-services-list-item is-current-service eTicket-services-list-item-purchased">
                      <div className="eTicket-services-list-item-content">
                        <div className="eTicket-services-list-item-header">
                          <div className="eTicket-services-list-item-header-title has-background">{service.title}</div>
                        </div>

                        <p className="eTicket-services-list-item-current-month ">
                          <Icon icon="calendar" />
                          {t('from')}{' '}
                          {formatDate(service.validFrom)}
                        </p>

                        <div className="eTicket-services-list-item-info">
                          <div className="eTicket-services-list-item-valid-till">
                            <div>{t('eTicket_valid_till')}:</div>

                            {formatDate(service.validTill)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}

          { type === 2 && checkForSuccess && (
            <div className="eTicket-success">
              <img alt={neutral} src={neutral} />
              <div className="text-container">
                <p>{t('eTicket_active_services_months', {
                  months: t(`of_month_${activeMonthsNumbers()}`),
                })}
                </p>

                <p>{t('eTicket_closest_service_replenishment', {
                  nextMonth: t(`of_month_${nextAvailableMonth()}`),
                })}
                </p>
              </div>
            </div>
          )}
        </>
      )}

      {type === 1 && (
        <div className="eTicket-available">

          <ul className={`eTicket-services-list ${ticketType}`}>
            {
              availableServices.map((service) => (
                <li key={service.id}>
                  <Service service={service} type={type} />
                </li>
              ))
            }
          </ul>
        </div>
      )}

      {type === 2 && grouped().map((servicelist) => (
        <div
          key={servicelist[0].month}
          className={`eTicket-available has-padding 
             ${replenishableService(servicelist[0].month) 
          ? 'is-hidden' 
          : ''}`}
        >

          <div className={`eTicket-available-services ${ticketType}`}>
            <div className="eTicket-available-services-card-with-month">
              <div className="has-flex-direction">
                <div className="eTicket-available-services-card-with-month-inner-left">
                  <div className="eTicket-available-services-card-with-month-text">
                    {t('eTicket_this_month')}
                  </div>

                  <div className="eTicket-available-services-card-with-month-text is-bold has-border has-padding">
                    {servicelist[0].month}
                  </div>
                </div>

                <div className="eTicket-available-services-card-with-month-inner-right">
                  {t('eTicket_choose_option_which_fits_you_most')}
                </div>
              </div>

              <div className="eTicket-available-services-card-with-month-wrapper" />
            </div>

            <div className="eTicket-available-services-direction-arrow">
              <Icon icon="arrow-right" />
            </div>

            <ul className="eTicket-services-list">
              {servicelist.map((servicelistItem) => (
                <li key={type === 1 ? servicelistItem.title.length : servicelistItem.amount}>
                  <div className={`eTicket-services-list-item  ${ticketType}`}>
                    <div className={`eTicket-services-list-item-content ${ticketType}`}>

                      <div className="eTicket-services-list-item-header-title">
                        <div className="eTicket-services-list-item-content-title has-background">
                          {servicelistItem.title}
                        </div>
                      </div>

                      <div className={`eTicket-services-list-item-content-amount ${ticketType}`}>
                        {formatMoney(servicelistItem.amount)}
                      </div>
                    </div>

                    <div className={`eTicket-services-list-item-action ${ticketType}`}>
                      <Button route={{
                        pathname: t(routes.eticket.checkout.index),
                        state: {
                          initialOrder: {
                            amount: Number(servicelistItem.amount),
                            serviceNr: Number(servicelistItem.id),
                          },
                        },
                      }}
                      >
                        {t('eTicket_add_cash')}
                      </Button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </>
  );
};

Services.propTypes = {
  dispatchGetCurrentTicket: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  setDocumentTitle: PropTypes.func.isRequired,
  match: PropTypes.shape().isRequired,
  type: PropTypes.number,
  availableServices: PropTypes.arrayOf(PropTypes.shape()),
  currentServices: PropTypes.arrayOf(PropTypes.shape()),
  errors: PropTypes.arrayOf(PropTypes.string),
};

Services.defaultProps = {
  type: 1,
  availableServices: [],
  currentServices: [],
  errors: [],
};

const mapStateToProps = (state) => ({
  type: state.eTicket.getIn(['cardContent', 'type']),
  availableServices: state.eTicket.getIn(['cardContent', 'availableServices']),
  currentServices: state.eTicket.getIn(['cardContent', 'currentServices']),
  errors: Object
    .entries(eTicketEndpoints)
    .reduce((accumulator, [endpointName]) => (
      accumulator.concat(state.request.getIn([endpointName, 'error', 'global']))
    ), [])
    .filter(Boolean),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchGetCurrentTicket: () => dispatch(getCurrentTicket()),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(withDocumentHead(Services)),
);
