import * as React from 'react';
import {
  addDays,
  addMonths,
  subDays,
  format,
  isSameDay,
  isBefore,
  lastDayOfMonth,
  startOfMonth,
  differenceInDays, 
  differenceInCalendarMonths,
} from 'date-fns';
import PropTypes from 'prop-types';
import './DateSlider.scss';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { getTranslations } from '../../../core/utilities/date';
import Icon from '../Icon/Icon';

const DateSlider = ({
  endDate, date, onChangeSelectedDate, t, mobile,
}) => {
  function toUTCDate(dateString) {
    const localDate = new Date(dateString);
    const utcDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);
    return utcDate;
  }
  const firstSection = { marginLeft: '40px' };
  const scrollStartOffset = 11;
  const scrollRenderOffset = 9;
  const startDate = subDays(new Date(), 3);
  const lastDate = addDays(new Date(date), endDate);
  const containerRef = React.useRef();
  const focusedDayRef = React.useRef();
  const translations = getTranslations(t);
  const translatedWeekdays = translations.weekdaysLong;
  const translatedMonths = translations.months;
  const [scrollPosition, setScrollPosition] = (
    React.useState(scrollStartOffset)
  );
  const startingWeek = differenceInDays(new Date(lastDate), new Date(date));
  const visibleWeekdays = mobile ? 3 : 6;
  const scrollPositionStep = 2;
  const maxScrollPosition = 17;
  const minScrollPosition = 3;
  const isSelected = (day) => isSameDay(day, toUTCDate(date));
  const isFocused = (day) => (
    isSameDay(new Date(day), addDays(new Date(date), scrollPosition - startingWeek))
  );

  const getDayItemState = (day) => {
    if (isSelected(day)) {
      return 'selected';
    }
    if (isBefore(new Date(day), subDays(new Date(), 2))) {
      return 'disabled';
    }
    return '';
  };
  const dayElementStyle = (containerRef.current
    ? { width: (containerRef.current.clientWidth - visibleWeekdays * 4) / visibleWeekdays } : null
  );

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (date) {
        if (focusedDayRef.current) {
          focusedDayRef.current.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
        }
      }
    }, 0);
    return () => clearTimeout(timeout);
  }, []);

  React.useEffect(() => {
    if (date) {
      if (focusedDayRef.current) {
        focusedDayRef.current.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
      }
    }
  }, [date, scrollPosition]);

  React.useEffect(() => {
    setScrollPosition(scrollRenderOffset);
  }, [date]);
  
  function renderDays() {
    const months = [];
    for (let i = 0; i <= differenceInCalendarMonths(lastDate, startDate); i += 1) {
      const month = startOfMonth(addMonths(startDate, i));
      const start = i === 0 ? Number(format(startDate, 'd')) - 1 : 0;
      const end = i === differenceInCalendarMonths(lastDate, startDate) 
        ? Number(format(lastDate, 'd')) 
        : Number(format(lastDayOfMonth(month), 'd'));
      const days = [];
      for (let j = start; j < end; j += 1) {
        const newDay = addDays(month, j);
        days.push(
          <li>
            <button
              className={`dateDayItem ${getDayItemState(newDay)}`}
              ref={(isSelected(newDay) || isFocused(newDay)) ? focusedDayRef : null}
              key={newDay.toDateString()}
              aria-current={!!isSelected(newDay)}
              style={dayElementStyle}
              onClick={(
                isBefore(newDay, subDays(new Date(), 2))
                  ? null
                  : () => onChangeSelectedDate(newDay)
              )}
            >
              <div className="weekdayLabel">
                {translatedWeekdays ? translatedWeekdays[newDay.getDay()] : null}
              </div>
              <div className="dateLabel">
                {`${translatedMonths[newDay.getMonth()]} ${format(newDay, 'dd')}`}
              </div>
            </button>
          </li>,
        );
      }
      months.push(
        <div className="monthContainer" key={month}>
          <ul className="daysContainer" style={i === 0 ? firstSection : null}>
            {days}
          </ul>
        </div>,
      );
    }
    return <div ref={containerRef} className="dateListScrollable">{months}</div>;
  }

  return (
    <div className="container" aria-live="polite">
      <div className="buttonWrapper">
        <button className="button-date-slider" onClick={() => setScrollPosition(scrollPosition > minScrollPosition ? scrollPosition - scrollPositionStep : scrollPosition)} aria-label={t('ticket_backward')} aria-disabled={scrollPosition <= minScrollPosition}>
          <Icon icon="chevron-left" />
        </button>
      </div>
      {renderDays(scrollPosition)}
      <div className="buttonWrapper">
        <button className="button-date-slider" onClick={() => setScrollPosition(scrollPosition < maxScrollPosition ? scrollPosition + scrollPositionStep : scrollPosition)} aria-label={t('ticket_forward')} aria-disabled={scrollPosition >= maxScrollPosition}>
          <Icon icon="chevron-right" />
        </button>
      </div>
    </div>
  );
};

DateSlider.propTypes = {
  t: PropTypes.func.isRequired,
  endDate: PropTypes.number,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onChangeSelectedDate: PropTypes.func,
  mobile: PropTypes.bool,
};
DateSlider.defaultProps = {
  endDate: 10,
  date: new Date(),
  onChangeSelectedDate: null,
  mobile: null,
};

const mapStateToProps = (state) => ({
  locale: state.locale,
});

export default connect(mapStateToProps)(withTranslation()(DateSlider));
