import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Formik } from 'formik';
import Button from '../../../../../components/presentational/Button/Button';
import { getErrors } from '../../../../../core/utilities/formValidator';
import { validations } from '../../../../../core/config/form/validations';
import Input from '../../../../../components/presentational/FormElements/Input/Input';
import RadioGroup
from '../../../../../components/presentational/FormElements/Radio/RadioGroup/RadioGroup';
import { findByTicket, findByTravel } from '../../../../../core/modules/TicketSearch/TicketSearchActions';
import { endpoints } from '../../../../../core/config/endpoints';
import RouteAutocomplete
from '../../../../../components/containers/FormElements/RouteAutocomplete/RouteAutocomplete';
import { getObjectNestedData } from '../../../../../core/utilities/object';
import { getStopsList } from '../../../../../core/modules/Stops/StopsActions';
import { stopsEndpoints } from '../../../../../core/config/endpoints/stops';
import Datepicker from '../../../../../components/containers/Datepicker/Datepicker';
import { formatDateToYYYYMMDD } from '../../../../../core/utilities/date';
import './SearchForm.scss';

const formsValidations = {
  byTicket: {
    ticketNr: [validations.required],
    email: [validations.email],
  },
  byTravel: {
    to: {
      number: [validations.required],
    },
    date: [validations.required],
    name: [validations.required],
  },
};
const initialValues = {
  byTicket: {
    ticketNr: '',
    email: '',
  },
  byTravel: {
    name: '',
    date: formatDateToYYYYMMDD(new Date()),
    to: {
      number: '',
      name: '',
    },
  },
};
const SearchForm = ({
  t,
  dispatchFindByTicket,
  dispatchFindByTravel,
  findByTicketFieldErrors,
  findByTravelFieldErrors,
  loading,
  stopsList,
  stopsLoading,
  onGetStops,
}) => {
  const formRef = React.useRef(null);
  const [searchMethod, setSearchMethod] = React.useState('byTicket');
  const onMethodChange = (e) => setSearchMethod(e.target.value);
  const onFindByTicket = (values) => {
    const { ticketNr, email } = values;
    dispatchFindByTicket(ticketNr, email);
  };
  const onFindByTravel = (values) => {
    const { name, date, to } = values;
    const destination = to.number;
    dispatchFindByTravel(destination, name, date);
  };
  const fieldErrors = {
    ...findByTicketFieldErrors,
    ...findByTravelFieldErrors,
  };
  const handleEnter = (event) => {
    if (event.charCode === 13) {
      const { form, value } = event.target;

      if (value.length > 2) {
        const index = Array.prototype.indexOf.call(form, event.target);

        form.elements[index + 1].focus();
        event.preventDefault();
      }
    }
  };

  return (
    <div className="ticket-search-form">
      <div className="ticket-search-form__header">
        {t('ticket_search_pick_method_of_search')}
      </div>

      <Formik
        enableReinitialize
        initialValues={initialValues[searchMethod]}
        validateOnChange={false}
        onSubmit={searchMethod === 'byTicket' ? onFindByTicket : onFindByTravel}
        validate={(values) => getErrors(
          values,
          formsValidations[searchMethod],
          formRef,
        )}
        render={(formProps) => {
          const {
            values,
            handleSubmit,
            setFieldValue,
            setErrors,
            errors,
          } = formProps;

          return (
            <RadioGroup
              onChange={onMethodChange}
              name="ticket-search-form-method"
              value={searchMethod}
              disabled={loading}
              render={(radio) => (
                <form
                  ref={formRef}
                  onSubmit={handleSubmit}
                >
                  {radio('byTicket', t('ticket_search_ticket_number_method'))}

                  <div className="ticket-search-radio-content">
                    <div className="ticket-search-radio-subheader">
                      {t('ticket_search_ticket_number_method_instructions')}
                    </div>

                    {searchMethod === 'byTicket' && (
                      <>
                        <div className="ticket-search-form-input-row">
                          <div className="ticket-search-form-input">
                            <Input
                              label={t('ticket_search_ticket_number')}
                              name="ticketNr"
                              id="ticketNr"
                              value={values.ticketNr}
                              onChange={(e) => setFieldValue('ticketNr', e.target.value)}
                              disabled={loading}
                              error={t(errors.ticketNr) || fieldErrors.ticketNr}
                              required
                            />
                          </div>

                          <div className="ticket-search-form-input">
                            <Input
                              label={t('login_username')}
                              name="email"
                              id="email"
                              value={values.email}
                              onChange={(e) => setFieldValue('email', e.target.value)}
                              disabled={loading}
                              error={t(errors.email) || fieldErrors.email}
                              required
                            />
                          </div>
                        </div>

                        <div className="ticket-search-form-radio-footer">
                          {t('ticket_search_ticket_info')}
                        </div>
                      </>
                    )}
                  </div>

                  {radio('byTravel', t('ticket_search_ticket_credentials_method'))}

                  <div className="ticket-search-radio-content">
                    <div className="ticket-search-radio-subheader">
                      {t('ticket_search_ticket_credentials_method_instructions')}
                    </div>

                    {searchMethod === 'byTravel' && values.to && (
                      <>
                        <div className="ticket-search-form-input-row">
                          <div className="ticket-search-form-input">
                            <RouteAutocomplete
                              name="to.number"
                              id="route-destination"
                              value={values.to.name}
                              label={t('ticket_search_travelling_to')}
                              error={fieldErrors.destination}
                              inputError={t(getObjectNestedData(errors, 'to.number'))}
                              suggestions={Array.from(stopsList)}
                              suggestionValue={(suggestion) => suggestion.name}
                              onChange={({ number, name }) => {
                                setErrors({ to: null });
                                setFieldValue('to', { number, name });
                              }}
                              onInput={onGetStops}
                              onKeyPress={handleEnter}
                              loading={stopsLoading}
                              required
                            />
                          </div>

                          <div className="ticket-search-form-input ticket-search-form-date-input">
                            <Datepicker
                              id="depart-date"
                              name="date"
                              onChange={(date) => setFieldValue('date', formatDateToYYYYMMDD(date))}
                              value={values.date}
                              label={t('ticket_search_departure_date')}
                              minDate={new Date()}
                              error={t(errors.date) || fieldErrors.date}
                              required
                            />
                          </div>
                        </div>

                        <div className="ticket-search-form-input-row">
                          <div className="ticket-search-form-input">
                            <Input
                              label={t('checkout_full_name')}
                              name="name"
                              id="name"
                              value={values.name}
                              onChange={(e) => setFieldValue('name', e.target.value)}
                              disabled={loading}
                              error={t(errors.name) || fieldErrors.name}
                              required
                            />
                          </div>
                        </div>

                        <div className="ticket-search-form-radio-footer">
                          {t('ticket_search_ticket_info')}
                        </div>
                      </>
                    )}
                  </div>

                  <Button
                    type="submit"
                    className="ticket-search-submit"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {t('common_continue')}
                  </Button>
                </form>
              )}
            />
          );
        }}
      />
    </div>
  );
};

SearchForm.propTypes = {
  t: PropTypes.func.isRequired,
  onGetStops: PropTypes.func.isRequired,
  dispatchFindByTicket: PropTypes.func.isRequired,
  dispatchFindByTravel: PropTypes.func.isRequired,
  findByTicketFieldErrors: PropTypes.shape(),
  findByTravelFieldErrors: PropTypes.shape(),
  loading: PropTypes.bool,
  stopsList: PropTypes.shape(),
  stopsLoading: PropTypes.bool,
};

SearchForm.defaultProps = {
  stopsList: null,
  findByTicketFieldErrors: {},
  findByTravelFieldErrors: {},
  loading: false,
  stopsLoading: false,
};

const mapStateToProps = (state) => ({
  loading:
    state.request.getIn([endpoints.FIND_TICKET_BY_NUMBER.name, 'loading'])
    || state.request.getIn([endpoints.FIND_TICKET_BY_TRAVEL.name, 'loading']),
  stopsList: state.data.collections.stops,
  stopsLoading: state.request.getIn([stopsEndpoints.GET_STOPS_LIST.name, 'loading']),
  findByTicketFieldErrors: state.request.getIn([endpoints.FIND_TICKET_BY_NUMBER.name, 'error', 'field']),
  findByTravelFieldErrors: state.request.getIn([endpoints.FIND_TICKET_BY_TRAVEL.name, 'error', 'field']),
});

const mapDispatchToProps = (dispatch) => ({
  onGetStops: (value, excludedValue) => dispatch(getStopsList(value, excludedValue)),
  dispatchFindByTicket: (ticketNr, email) => dispatch(findByTicket(ticketNr, email)),
  dispatchFindByTravel: (destination, name, date) => dispatch(
    findByTravel(destination, name, date),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SearchForm));
