import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Button from '../../../Button/Button';
import Tooltip from '../../../../containers/Tooltip/Tooltip';
import { formatMoney } from '../../../../../core/utilities/money';
import { addRoundTripTicket } from '../../../../../core/modules/RoundTripTickets/RoundTripTicketsActions';
import { routes } from '../../../../../core/config/routes';
import { ticketTypes } from '../../../../../core/config/constants/tickets';
import RoutePriceUnavailable from '../RoutePriceUnavailable/RoutePriceUnavailable';
import { redirect } from '../../../../../core/utilities/routing';
import { history } from '../../../../../core/historyAndStore';
import Icon from '../../../Icon/Icon';

const RoutePrice = ({
  t,
  sellable,
  route,
  isTransfer,
  isForward,
  onAddRoundTripTicket,
  tickets,
  mobile,
  exchangeButtonProps,
  transferDropdownActive,
  toggleTransferDropdown,
}) => {
  const {
    price,
    cashdeskPrice,
    steps,
    promoPrice,
    promoPriceDiscounted,
    sellEndTime,
    promoAvailable,
  } = route;
  const notForward = !isForward || isForward === 'false';
  const onBuyClick = (ticketType) => {
    if (notForward) onAddRoundTripTicket(tickets ? 'backward' : 'forward', { ...route, ticketType });
  };
  const sellEndTimeDelta = sellEndTime && Math.floor(
    (new Date(sellEndTime).getTime() - new Date().getTime()) / 60000,
  );

  const sellEndTimePopUp = sellEndTimeDelta && sellEndTimeDelta <= 30 && (
    <div className="time-to-departure-wrap">
      <div className="time-to-departure-content">
        <span>{t('time_to_departure_short', { time: sellEndTimeDelta })}</span>

        <Tooltip
          light
          title={t('alert_important')}
          content={t('time_to_departure', { time: sellEndTimeDelta })}
        >
          <div className="icon-wrap">
            <Icon icon="info" />
          </div>
        </Tooltip>
      </div>
    </div>
  );

  const discount100 = sellable && price === 0 && (
    <div className="discount-100-wrap">
      <div className="time-to-departure-content">
        <span>{t('discount_100_description_short')}</span>

        <Tooltip
          light
          title={t('alert_important')}
          content={t('discount_100_description')}
        >
          <div className="icon-wrap">
            <Icon icon="info" />
          </div>
        </Tooltip>
      </div>
    </div>
  );

  const getInitialOrder = (ticketType) => steps && steps.map((step) => {
    if (!step.sellable) return null;

    const { sellData } = step;

    if (!sellData) return null;

    const {
      actualTripId,
      fromTripStopId,
      toTripStopId,
      ref,
    } = sellData;

    return {
      direction: 'forward',
      tripId: ref,
      actualTripId,
      fromTripStopId,
      toTripStopId,
      ticketType: (
        ticketType === ticketTypes.promo && sellData.promoAvailable
      ) ? ticketTypes.promo : ticketTypes.regular,
    };
  }).filter(Boolean);

  const getSellableSteps = () => steps && steps.map((step) => {
    if (!step.sellable) return null;

    const { sellData, transitDetails } = step;

    return { sellData, transitDetails };
  }).filter(Boolean);

  const buyButtonAction = (ticketType) => (notForward
    ? {
      onClick: () => onBuyClick(ticketType),
    }
    : {
      onClick: () => redirect(
        history,
        t(routes.checkout.index),
        { initialOrder: getInitialOrder(ticketType) },
      ),
    });

  const onClickWrapper = () => {
    if (exchangeButtonProps && exchangeButtonProps.onClick) {
      exchangeButtonProps.onClick({
        sellableSteps: getSellableSteps() || [],
      });
    }
  };

  if (sellable) {
    return (
      <>
        {promoAvailable && !exchangeButtonProps && (
          <div className="search-results-route-purchase-content search-results-route-purchase-promo">
            <div className="search-results-route-purchase">
              <div className="search-results-route-purchase-price">
                <div className="search-results-route-purchase-promo_price--explained">
                  <Tooltip
                    light
                    title={t('alert_important')}
                    content={t('route_purchase_promo_explained')}
                  >
                    {t('route_purchase_promo')}
                    <Icon icon="info" />
                  </Tooltip>
                </div>

                <div>{formatMoney(promoPriceDiscounted || promoPrice)}</div>

                {(promoPriceDiscounted && promoPriceDiscounted !== promoPrice) && (
                  <div className="search-results-route-purchase-price__desk">
                    {t('route_desk')}: {formatMoney(promoPrice)}
                  </div>
                )}
              </div>

              {!isTransfer && (
                <div className="search-results-route-purchase-action">
                  <Button {...buyButtonAction(ticketTypes.promo)}>
                    {t(`${(notForward) ? 'common_button_choose' : 'common_button_buy'}`)}
                  </Button>
                  {sellEndTimePopUp}
                  {discount100}
                </div>
              )}
            </div>
          </div>
        )}

        <div className="search-results-route-purchase-content">
          <div className="search-results-route-purchase">
            {!exchangeButtonProps && (
              <div className="search-results-route-purchase-price">
                <div>{formatMoney(price)}</div>

                {cashdeskPrice && cashdeskPrice !== price && (
                  <div className="search-results-route-purchase-price__desk">
                    {t('route_desk')}: {formatMoney(cashdeskPrice)}
                  </div>
                )}
              </div>
            )}

            {!isTransfer && (
              <div className={`search-results-route-purchase-action ${exchangeButtonProps ? 'is-exchange-action' : ''}`}>
                {exchangeButtonProps
                  ? (
                    <Button
                      {...exchangeButtonProps}
                      onClick={onClickWrapper}
                    />
                  )
                  : (
                    <Button {...buyButtonAction()}>
                      {t(`${(notForward) ? 'common_button_choose' : 'common_button_buy'}`)}
                    </Button>
                  )}
                {sellEndTimePopUp}
                {discount100}
              </div>
            )}
          </div>
        </div>
      </>
    );
  }

  return (
    <RoutePriceUnavailable
      mobile={mobile}
      transferDropdownActive={transferDropdownActive}
      toggleTransferDropdown={toggleTransferDropdown}
      stepsCount={steps ? steps.length : 0}
    />
  );
};

RoutePrice.propTypes = {
  t: PropTypes.func.isRequired,
  route: PropTypes.shape().isRequired,
  sellable: PropTypes.bool,
  isTransfer: PropTypes.bool,
  transferDropdownActive: PropTypes.bool,
  isForward: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  onAddRoundTripTicket: PropTypes.func,
  toggleTransferDropdown: PropTypes.func,
  tickets: PropTypes.shape(),
  exchangeButtonProps: PropTypes.shape(),
  mobile: PropTypes.bool,
};

RoutePrice.defaultProps = {
  mobile: false,
  sellable: false,
  isTransfer: false,
  transferDropdownActive: false,
  isForward: true,
  onAddRoundTripTicket: () => null,
  tickets: null,
  exchangeButtonProps: null,
  toggleTransferDropdown: null,
};

const mapStateToProps = (state) => ({
  tickets: state.roundTripTickets.get('forward'),
});

const mapDispatchToProps = (dispatch) => ({
  onAddRoundTripTicket: (type, ticketInfo) => dispatch(addRoundTripTicket(type, ticketInfo)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(RoutePrice));
