import { Map } from 'immutable';
import { constants } from '../../constants';

const initialState = Map({});

const ResetPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
  case constants.components.resetPasswordRequest.SENT_TO:
    return state.set('resetSentTo', action.payload.email);

  case constants.components.resetPasswordRequest.SET_NEW_PASSWORD_USER:
    return state.set('newPasswordUser', action.payload.user);

  default:
    return state;
  }
};

export default ResetPasswordReducer;
