import React from 'react';
import PropTypes from 'prop-types';
import './Alert-VARIANT.scss';
import Icon from '../Icon/Icon';

const variantsIcons = {
  success: 'check',
  info: 'info',
  warning: 'warning',
  danger: 'warning',
};

const Alert = (props) => {
  const {
    children, className, variant, heading, icon, border, arrow,
  } = props;
  const arrowClassName = arrow ? `arrow arrow-${arrow}` : '';
  const variantIcon = icon || variantsIcons[variant];

  return (
    <div className={`alert alert-variant-${variant || 'info'} ${border ? '' : 'no-border'} ${arrowClassName} ${className}`}>
      {variantIcon && (
        <Icon icon={variantIcon} className="alert-icon" />
      )}

      <div className="alert-content" role="alert" aria-live="polite">
        {heading && (
          <div className="alert-heading alert-spacing">
            {heading}
          </div>
        )}

        {children && (
          <div className="alert-spacing">
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

Alert.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  variant: PropTypes.oneOf(Object.keys(variantsIcons)),
  heading: PropTypes.node,
  icon: PropTypes.string,
  border: PropTypes.bool,
  arrow: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
};

Alert.defaultProps = {
  children: null,
  className: '',
  variant: '',
  heading: null,
  icon: null,
  border: true,
  arrow: null,
};

export default Alert;
