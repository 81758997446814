import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { formatDate, formatTime } from '../../../../../../core/utilities/date';
import { formatMoney } from '../../../../../../core/utilities/money';
import Button from '../../../../../../components/presentational/Button/Button';
import { getLink, routes } from '../../../../../../core/config/routes';
import Icon from '../../../../../../components/presentational/Icon/Icon';

const HistoryListResult = ({
  key, order, t,
}) => {
  const [listIsOpen, setListState] = useState(false);
  const toggleOrderList = () => setListState(!listIsOpen);

  return (
    <li key={key} className={`user-purchase-history ${listIsOpen ? 'is-opened' : ''}`}>
      <div className="user-purchase-history-info">
        <div className="user-purchase-history-info-destination">
          <div className="user-purchase-history-destination-name">
            {order.fullTravelName}
          </div>

          <div className="user-purchase-history-destination-ticket-purchase">
            <span>{t('user_order_purchase_date')}:</span>

            <div className="user-purchase-history-destination-ticket-purchase-time">
              <Icon icon="calendar" />

              {formatDate(order.lastPayment.paidDate)}
            </div>

            <div className="user-purchase-history-destination-ticket-purchase-time">
              <Icon icon="clock" />

              {formatTime(order.lastPayment.paidDate)}
            </div>
          </div>
        </div>

        <div className="user-purchase-history-tickets-amount">
          {t('user_order_tickets_amounts')}: {order.itemsCount}
        </div>
      </div>

      {listIsOpen && (
        <div className="user-purchase-history-mobile-list">
          <div className="user-history-list-item">
            <div className="user-history-list-item__date">{t('user_order_purchase_date')}:</div>

            <div className="has-flex">
              <div className="user-history-list-item__value">
                <Icon icon="calendar" />

                {formatDate(order.lastPayment.paidDate)}
              </div>

              <div className="user-history-list-item__value">
                <Icon icon="clock" />

                {formatTime(order.lastPayment.paidDate)}
              </div>
            </div>
          </div>

          <div className="user-history-list-item">
            <div className="user-history-list-item__label">{t('user_order_tickets_amounts')}:</div>

            <div className="user-history-list-item__amount">{order.itemsCount}</div>
          </div>
        </div>
      )}

      <div className="user-purchase-history-mobile-action">
        <div className="has-border" />
        <button
          className="mobile-action-list-switch"
          onClick={toggleOrderList}
        >
          <Icon icon={`chevron-${listIsOpen ? 'up' : 'down'}`} />
        </button>

        <div className="has-border" />
      </div>

      <div className="user-purchase-history-action">
        <div className="has-flex">
          <div className="user-purchase-history-action-amount">
            <div className="has-label">{t('user_order_paid_amount')}</div>
            {formatMoney(order.lastPayment.amount)}
          </div>

          <Button route={getLink(t(routes.user.orderHistory), { id: order.id })}>
            {t('user_review_order')}
          </Button>
        </div>
      </div>
    </li>
  );
};

HistoryListResult.propTypes = {
  key: PropTypes.string,
  order: PropTypes.shape(),
  t: PropTypes.func.isRequired,
};

HistoryListResult.defaultProps = {
  key: '',
  order: {},
};

export default (withTranslation()(HistoryListResult));
