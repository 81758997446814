import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { getErrors } from '../../../../../core/utilities/formValidator';
import { validations } from '../../../../../core/config/form/validations';
import Alert from '../../../../../components/presentational/Alert/Alert';
import Input from '../../../../../components/presentational/FormElements/Input/Input';
import Button from '../../../../../components/presentational/Button/Button';
import { endpoints } from '../../../../../core/config/endpoints';
import { setNewPasswordUser, setNewPassword } from '../../../../../core/modules/Components/ResetPassword/ResetPasswordActions';
import logo from '../../../../../../assets/logo/logo-VARIANT.png';
import successImage from '../../../../../../assets/images/success.png';
import { loginUser } from '../../../../../core/modules/User/UserActions';
import { setRequestError } from '../../../../../core/modules/Request/RequestActions';
import './NewPasswordForm.scss';
import Icon from '../../../../../components/presentational/Icon/Icon';

const NewPasswordForm = ({
  hash,
  passwordChangeErrors,
  passwordChangeLoading,
  loginUserErrors,
  loginUserLoading,
  newPasswordUser,
  t,
  dispatchSetNewPassword,
  dispatchUnsetNewPasswordUser,
  dispatchLoginNewPasswordUser,
  unsetLoginError,
  unsetPasswordChangeError,
}) => {
  React.useEffect(() => function cleanup() {
    dispatchUnsetNewPasswordUser();
    unsetLoginError();
    unsetPasswordChangeError();
  }, []);

  const { global, fields } = passwordChangeErrors;
  const { global: loginGlobal, fields: loginFields } = loginUserErrors;
  const [showPassword, setShowPassword] = React.useState(false);
  const toggleShowPassword = () => setShowPassword((prevShowPassword) => !prevShowPassword);
  const formRef = React.useRef(null);
  const onSubmit = ({ password }) => dispatchSetNewPassword(hash, password);
  const onLogin = ({ password }) => {
    const { email } = newPasswordUser;
    dispatchLoginNewPasswordUser(email, password);
  };

  return (
    <div className="new-password-modal">
      <img className="new-password-modal-logo" src={logo} alt="Logo" />

      {[
        ...(global || []),
        ...(loginGlobal || []),
        ...(Object.entries(loginFields || {}).map((entry) => entry[1])),
      ].map((error) => (
        <Alert
          key={error}
          variant="danger"
          heading={t('common_error_exclamation')}
        >
          {error}
        </Alert>
      ))}

      <Formik
        validateOnChange={false}
        onSubmit={newPasswordUser ? onLogin : onSubmit}
        initialValues={{
          password: '',
        }}
        validate={(values) => getErrors(values, {
          password: [validations.required],
        }, formRef)}
        render={(formProps) => {
          const {
            values,
            handleSubmit,
            errors,
            setFieldValue,
          } = formProps;
          const { password } = values;

          return (
            <form onSubmit={handleSubmit} ref={formRef}>
              {newPasswordUser && (
                <div className="new-password-success">
                  <img src={successImage} className="new-password-success-img" alt="reset password request success" />

                  <span className="new-password-success-text">{t('password_remind_reset_success')}</span>

                  <Button
                    variant="brand"
                    type="submit"
                    disabled={loginUserLoading}
                  >
                    {t('login_connect')}
                  </Button>
                </div>
              )}

              {!newPasswordUser && (
                <>
                  <div className="new-password-modal-input-row">
                    <Input
                      className="new-password-modal-input-row"
                      name="password"
                      label={t('reset_password_enter_new_password')}
                      type={showPassword ? 'text' : 'password'}
                      value={password}
                      onChange={(e) => setFieldValue('password', e.target.value)}
                      disabled={passwordChangeLoading}
                      error={t(errors.password) || (fields || {}).password}
                      icon={(
                        <button
                          className="input-icon__button"
                          type="button"
                          onClick={toggleShowPassword}
                          disabled={passwordChangeLoading}
                        >
                          <Icon icon="view" /> {t(showPassword ? 'login_hide' : 'login_show')}
                        </button>
                      )}
                    />
                  </div>

                  <div className="new-password-modal-actions">
                    <Button
                      variant="brand"
                      type="submit"
                      disabled={passwordChangeLoading}
                    >
                      {t('user_change_password')}
                    </Button>
                  </div>
                </>
              )}
            </form>
          );
        }}
      />
    </div>
  );
};

NewPasswordForm.propTypes = {
  t: PropTypes.func.isRequired,
  dispatchSetNewPassword: PropTypes.func.isRequired,
  dispatchUnsetNewPasswordUser: PropTypes.func.isRequired,
  dispatchLoginNewPasswordUser: PropTypes.func.isRequired,
  unsetLoginError: PropTypes.func.isRequired,
  unsetPasswordChangeError: PropTypes.func.isRequired,
  hash: PropTypes.string.isRequired,
  passwordChangeErrors: PropTypes.shape(),
  passwordChangeLoading: PropTypes.bool,
  loginUserErrors: PropTypes.shape(),
  loginUserLoading: PropTypes.bool,
  newPasswordUser: PropTypes.shape(),
};

NewPasswordForm.defaultProps = {
  passwordChangeErrors: {},
  passwordChangeLoading: false,
  loginUserErrors: {},
  loginUserLoading: false,
  newPasswordUser: null,
};

const mapStateToProps = (state) => ({
  passwordChangeErrors: state.request.getIn([endpoints.SET_NEW_PASSWORD.name, 'error']),
  passwordChangeLoading: state.request.getIn([endpoints.SET_NEW_PASSWORD.name, 'loading']),
  newPasswordUser: state.components.resetPassword.get('newPasswordUser'),
  loginUserErrors: state.request.getIn([endpoints.LOGIN_USER.name, 'error']),
  loginUserLoading: state.request.getIn([endpoints.LOGIN_USER.name, 'loading']),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSetNewPassword: (hash, password) => dispatch(setNewPassword(hash, password)),
  dispatchUnsetNewPasswordUser: () => dispatch(setNewPasswordUser(null)),
  dispatchLoginNewPasswordUser: (username, password) => dispatch(loginUser(username, password)),
  unsetLoginError: () => dispatch(setRequestError(endpoints.LOGIN_USER.name, {})),
  unsetPasswordChangeError: () => dispatch(
    setRequestError(endpoints.SET_NEW_PASSWORD.name, {}),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NewPasswordForm));
