import {
  all,
  put,
  takeEvery,
  call,
  select,
} from 'redux-saga/effects';

import { constants } from '../constants';
import { setCMSMenu, setCMSPage } from './CMSActions';
import { startRequest } from '../Request/RequestSaga';
import { endpoints } from '../../config/endpoints';

export function* getPageSaga(action) {
  const { page } = action.payload;
  const locale = yield select((state) => state.locale);
  const pageFromState = yield select((state) => state.data.collections.cms.getIn([
    'pages',
    locale,
    page,
  ]));

  if (pageFromState && !pageFromState.error) return;

  yield put(setCMSPage(page, locale, { loading: true }));

  const { response } = yield call(
    startRequest,
    endpoints.GET_CMS_PAGE.name,
    endpoints.GET_CMS_PAGE.url.path(page),
  );

  if (response) {
    yield put(setCMSPage(page, locale, response.data.data));

    return;
  }

  yield put(setCMSPage(page, locale, { error: true }));
}

export function* getMenuSaga(action) {
  const { menuFolder } = action.payload;
  const locale = yield select((state) => state.locale);
  const menuFromState = yield select((state) => state.data.collections.cms.getIn(['menus', locale, menuFolder]));

  if (menuFromState) return;

  yield put(setCMSMenu(menuFolder, locale, { loading: true }));

  const { response } = yield call(
    startRequest,
    endpoints.GET_CMS_MENU.name,
    endpoints.GET_CMS_MENU.url.path(menuFolder),
  );

  if (response) {
    yield put(setCMSMenu(menuFolder, locale, response.data.data));
    return;
  }

  yield put(setCMSMenu(menuFolder, locale, undefined));
}

export default function* CMSSaga() {
  yield all([
    takeEvery(constants.data.collections.cms.GET_PAGE, getPageSaga),
    takeEvery(constants.data.collections.cms.GET_MENU, getMenuSaga),
  ]);
}
