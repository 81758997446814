import { Map } from 'immutable';
import { constants } from '../../../constants';

const initialState = Map({});

const BannersReducer = (state = initialState, action) => {
  switch (action.type) {
  case constants.data.collections.banners.ADD_BANNER:
    return state.set(action.payload.zone, action.payload.id ? action.payload : null);

  default:
    return state;
  }
};

export default BannersReducer;
