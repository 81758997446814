import {
  all,
  call,
  takeEvery,
  put,
  select,
} from 'redux-saga/effects';
import ReactGA from 'react-ga';
import { constants } from '../constants';
import { startRequest } from '../Request/RequestSaga';
import { endpoints } from '../../config/endpoints';
import {
  setOrderData, setOrderStatus, setOrderRegistered,
} from './OrderActions';
import { replace } from '../../utilities/routing';
import { history } from '../../historyAndStore';
import { routes } from '../../config/routes';
import { getObjectNestedData } from '../../utilities/object';
import { orderEndpoints } from '../../config/endpoints/order';
import { setRequestsErrors } from '../Request/RequestActions';
import i18n from '../../../../i18next';

function* executeOrderAction(endpointName, url, data = undefined) {
  yield put(setOrderStatus({ loading: true }));

  const { response, error } = yield call(
    startRequest,
    endpointName,
    url,
    data,
  );

  if (error) {
    const node = (
      error.response
      && error.response.data
      && error.response.data.errors
      && (
        error.response.data.errors.global.length > 0
        || Object.keys(error.response.data.errors.fields).length > 0
      )
    ) && document.getElementsByClassName('app-container')[0];

    if (node) {
      node.scrollTop = 0;
    }

    yield put(setOrderStatus());

    return;
  }

  const redirectUrl = getObjectNestedData(response, 'data.data.url');
  if (redirectUrl) {
    ReactGA.ga('TOKS.send', 'pageview', {
      page: 'pirkejas_isejo_i_banka.html',
      hitCallback: () => {
        window.location.replace(redirectUrl);
        return false;
      },
    });
    ReactGA.ga('GA.send', 'pageview', {
      page: 'pirkejas_isejo_i_banka.html',
      hitCallback: () => {
        window.location.replace(redirectUrl);
        return false;
      },
    });
    return;
  }

  yield put(setOrderData(response.data.data));
  yield put(setOrderStatus());
}

function* initializeOrder(action) {
  const { order } = action.payload;

  yield executeOrderAction(
    endpoints.ORDER_INITIALIZE.name,
    endpoints.ORDER_INITIALIZE.url.path(),
    order,
  );

  const orderHash = yield select((state) => state.order.getIn(['data', 'hash']));
  if (orderHash) replace(history, `${i18n.t(routes.checkout.index)}/${orderHash}`);
}

function* addPassenger(action) {
  const { orderHash } = action.payload;

  yield executeOrderAction(
    endpoints.PASSENGER_ADD.name,
    endpoints.PASSENGER_ADD.url.path(orderHash),
  );
}

function* removePassenger(action) {
  const { orderHash, passengerId } = action.payload;

  yield executeOrderAction(
    endpoints.PASSENGER_REMOVE.name,
    endpoints.PASSENGER_REMOVE.url.path(orderHash, passengerId),
  );
}

function* updatePassengerName(action) {
  const { orderHash, passengerId, name } = action.payload;

  yield executeOrderAction(
    endpoints.PASSENGER_UPDATE_NAME.name,
    endpoints.PASSENGER_UPDATE_NAME.url.path(orderHash, passengerId),
    { name },
  );
}

function* updatePassengerPartDiscount(action) {
  const {
    orderHash, passengerId, part, discountId,
  } = action.payload;

  yield executeOrderAction(
    endpoints.PASSENGER_UPDATE_PART_DISCOUNT.name,
    endpoints.PASSENGER_UPDATE_PART_DISCOUNT.url.path(orderHash, passengerId, part),
    { discountId },
  );
}

function* addCoupon(action) {
  const {
    orderHash,
    couponNumber,
  } = action.payload;

  yield executeOrderAction(
    endpoints.ORDER_ADD_COUPON.name,
    endpoints.ORDER_ADD_COUPON.url.path(orderHash),
    { couponNumber },
  );
}

function* removeCoupon(action) {
  const {
    orderHash,
  } = action.payload;

  yield executeOrderAction(
    endpoints.ORDER_REMOVE_COUPON.name,
    endpoints.ORDER_REMOVE_COUPON.url.path(orderHash),
  );
}

function* selectOrderPaymentMethod(action) {
  const {
    orderHash,
    paymentMethodId,
    countryId,
  } = action.payload;

  yield executeOrderAction(
    endpoints.ORDER_SELECT_PAYMENT_METHOD.name,
    endpoints.ORDER_SELECT_PAYMENT_METHOD.url.path(orderHash),
    {
      paymentMethodId,
      countryId,
    },
  );
}

function* selectOrderTicketType(action) {
  const {
    orderHash,
    ticketType,
  } = action.payload;

  yield executeOrderAction(
    endpoints.ORDER_SELECT_TICKET_TYPE.name,
    endpoints.ORDER_SELECT_TICKET_TYPE.url.path(orderHash, ticketType),
  );
}

function* addBike(action) {
  const {
    orderHash,
    passengerId,
    direction,
  } = action.payload;

  yield executeOrderAction(
    endpoints.ORDER_ADD_BIKE.name,
    endpoints.ORDER_ADD_BIKE.url.path(orderHash, passengerId, direction),
  );
}

function* removeBike(action) {
  const {
    orderHash,
    passengerId,
    direction,
  } = action.payload;

  yield executeOrderAction(
    endpoints.ORDER_REMOVE_BIKE.name,
    endpoints.ORDER_REMOVE_BIKE.url.path(orderHash, passengerId, direction),
  );
}

function* destroyOrder() {
  yield all([
    put(setOrderData()),
    put(setOrderStatus()),
    put(setOrderRegistered()),
    put(setRequestsErrors(Object.keys(orderEndpoints).map((endpointName) => ({
      name: endpointName,
    })))),
  ]);
}

function* submitOrder(action) {
  const {
    orderHash,
    orderData,
  } = action.payload;

  yield executeOrderAction(
    endpoints.ORDER_SUBMIT.name,
    endpoints.ORDER_SUBMIT.url.path(orderHash),
    orderData,
  );
}

function* getOrder(action) {
  const {
    orderHash,
  } = action.payload;

  yield executeOrderAction(
    endpoints.ORDER_GET.name,
    endpoints.ORDER_GET.url.path(orderHash),
  );
}

function* addLoyaltyDiscount(action) {
  const {
    orderHash,
  } = action.payload;

  yield executeOrderAction(
    endpoints.ADD_LOYALTY_DISCOUNT.name,
    endpoints.ADD_LOYALTY_DISCOUNT.url.path(orderHash),
  );
}

function* removeLoyaltyDiscount(action) {
  const {
    orderHash,
  } = action.payload;

  yield executeOrderAction(
    endpoints.REMOVE_LOYALTY_DISCOUNT.name,
    endpoints.REMOVE_LOYALTY_DISCOUNT.url.path(orderHash),
  );
}

export default function* OrderSaga() {
  yield all([
    takeEvery(constants.order.INITIALIZE, initializeOrder),
    takeEvery(constants.order.PASSENGER_ADD, addPassenger),
    takeEvery(constants.order.PASSENGER_REMOVE, removePassenger),
    takeEvery(constants.order.PASSENGER_UPDATE_NAME, updatePassengerName),
    takeEvery(constants.order.PASSENGER_UPDATE_PART_DISCOUNT, updatePassengerPartDiscount),
    takeEvery(constants.order.ADD_COUPON, addCoupon),
    takeEvery(constants.order.REMOVE_COUPON, removeCoupon),
    takeEvery(constants.order.SELECT_ORDER_PAYMENT_METHOD, selectOrderPaymentMethod),
    takeEvery(constants.order.SELECT_TICKET_TYPE, selectOrderTicketType),
    takeEvery(constants.order.ADD_BIKE, addBike),
    takeEvery(constants.order.REMOVE_BIKE, removeBike),
    takeEvery(constants.order.DESTROY, destroyOrder),
    takeEvery(constants.order.SUBMIT, submitOrder),
    takeEvery(constants.order.GET, getOrder),
    takeEvery(constants.order.ADD_LOYALTY_DISCOUNT, addLoyaltyDiscount),
    takeEvery(constants.order.REMOVE_LOYALTY_DISCOUNT, removeLoyaltyDiscount),
  ]);
}
