import React from 'react';
import PropTypes from 'prop-types';

const Image = ({ part }) => (
  <img
    role="presentation"
    className="text-image__item text-image__image"
    src={part.image}
    style={{
      width: `${part.imageWidthInPercent}%`,
      minHeight: '1px',
    }}
    alt={part.content}
  />
);

Image.propTypes = {
  part: PropTypes.shape().isRequired,
};

export default Image;
