import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ModalView from '../../presentational/Modal/Modal';
import { closeModal } from '../../../core/modules/Components/Modal/ModalActions';

const Modal = (props) => <ModalView {...props} />;

Modal.propTypes = {
  opened: PropTypes.bool,
  content: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  size: PropTypes.string,
};

Modal.defaultProps = {
  opened: false,
  content: null,
  size: '',
};

const mapStateToProps = (state) => ({
  opened: state.components.modal.get('opened'),
  content: state.components.modal.get('content'),
  size: state.components.modal.get('size'),
  disableUiClosing: state.components.modal.get('disableUiClosing'),
});

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(closeModal()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Modal);
