import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { routes } from '../../../../../core/config/routes';
import './Sidebar.scss';
import Icon from '../../../../../components/presentational/Icon/Icon';

const Sidebar = ({ t, menuOpen, onOpenMenu }) => {
  const menuItems = [
    { title: t('common_in_lithuania'), path: t(routes.topStations.local.index) },
    { title: t('common_abroad'), path: t(routes.topStations.abroad.index) },
  ];

  return (
    <div className="top-routes-sidebar-container">
      <div className="top-routes-sidebar is-stations">
        <div className="sidebar-arrow" />

        <div className="top-routes-sidebar-title">
          <div className="top-routes-sidebar__icon">
            <Icon icon="bus-station" />
          </div>

          <div className="top-routes-sidebar-title-text">
            <div className="top-routes-sidebar-title__title">{t('common_bus_stations')}</div>
          </div>
        </div>

        <ul className={`top-routes-stations-list ${!menuOpen ? 'is-hidden' : ''}`}>
          {menuItems.map(({ title, path }) => (
            <li key={title + path} className="top-routes-stations-list-item">
              <NavLink
                className="top-routes-stations-list-item__link"
                activeClassName="is-active"
                to={path}
              >
                {title}

                <Icon icon="arrow-long-left" />
              </NavLink>
            </li>
          ))}
        </ul>

        <button className="sidebar-menu-switch" onClick={onOpenMenu}>
          <Icon icon={`chevron-${menuOpen ? 'up' : 'down'}`} />
        </button>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  t: PropTypes.func.isRequired,
  menuOpen: PropTypes.bool,
  onOpenMenu: PropTypes.func.isRequired,
};

Sidebar.defaultProps = {
  menuOpen: false,
};

export default withTranslation()(Sidebar);
