import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import './NotFound.scss';
import withDocumentHead from '../../common/DocumentHead/withDocumentHead';
import Button from '../../../components/presentational/Button/Button';
import variantConst from '../../../core/config/constants/variantConst/variantConst';

const NotFound = ({ t, setDocumentTitle }) => {
  React.useEffect(() => {
    if (t) {
      setDocumentTitle(t(variantConst.documentTitle, { title: t('404_header') }));
    }
  }, [t]);

  return (
    <div className="not-found-container">
      <div className="not-found-container-column">
        <div className="not-found-header-row">
          {`404 - ${t('404_header')}`}
        </div>

        <div className="not-found-subheader-row">
          {t('404_subheader')}
        </div>

        <Button
          className="not-found-link-row"
          variant="inline-link"
          route="/"
        >
          <span>{t('404_link')}</span>
        </Button>
      </div>
    </div>
  );
};

NotFound.propTypes = {
  t: PropTypes.func.isRequired,
  setDocumentTitle: PropTypes.func.isRequired,
};

export default withTranslation()(withDocumentHead(NotFound));
