import React from 'react';
import './Newsletter.scss';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import NewsletterForm from '../../../containers/Forms/NewsletterForm/NewsletterForm';
import Icon from '../Icon/Icon';
import variantConst from '../../../core/config/constants/variantConst/variantConst';

const Newsletter = (props) => {
  const {
    onSubmit, newsletterStatus, t, children,
  } = props;

  if (!newsletterStatus) {
    return (
      <div className="newsletter">
        <div className="newsletter-container">
          <div className="newsletter-header-row">
            {t('newsletter_header')}
          </div>

          {children}

          <NewsletterForm onSubmit={onSubmit} />
        </div>
      </div>
    );
  }

  return (
    <div className="newsletter">
      <div className="newsletter-container">
        <div className="newsletter-success-icon">
          <Icon icon={variantConst.busIcon} />
        </div>

        <div className="newsletter-success-header-row">
          {t('newsletter_form_success_message')}
        </div>
      </div>
    </div>
  );
};

Newsletter.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  newsletterStatus: PropTypes.bool,
  children: PropTypes.node,
};

Newsletter.defaultProps = {
  newsletterStatus: false,
  children: null,
};

export default withTranslation()(Newsletter);
