import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import { subscribeNewsletter, setSubscribtionStatus } from '../../../core/modules/Components/Newsletter/NewsletterActions';
import NewsletterView from '../../presentational/Newsletter/Newsletter';

const Newsletter = ({
  newsletterStatus,
  children,
  dispatchNewsletterSubscription,
  dispatchSetSubscribtionStatus,
}) => (
  <>
    <NewsletterView
      onSubmit={({ email }) => dispatchNewsletterSubscription(email)}
      newsletterStatus={newsletterStatus}
    >
      {children}
    </NewsletterView>
    <Prompt message={() => (newsletterStatus ? dispatchSetSubscribtionStatus() : null)} />
  </>
);

Newsletter.propTypes = {
  newsletterStatus: PropTypes.bool,
  dispatchNewsletterSubscription: PropTypes.func.isRequired,
  dispatchSetSubscribtionStatus: PropTypes.func.isRequired,
  children: PropTypes.node,
};

Newsletter.defaultProps = {
  newsletterStatus: false,
  children: null,
};

const mapStateToProps = (state) => ({
  newsletterStatus: state.components.newsletter.get('state'),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchNewsletterSubscription: (email) => dispatch(subscribeNewsletter(email)),
  dispatchSetSubscribtionStatus: () => dispatch(setSubscribtionStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Newsletter));
