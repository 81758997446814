import { hot } from 'react-hot-loader/root';
import React, {
  useEffect, useRef, createContext, useState,
} from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { loadReCaptcha } from 'react-recaptcha-google';
import { withTranslation } from 'react-i18next';
import KitchenSink from '../KitchenSink/KitchenSink';
import { routes } from '../../../core/config/routes';
import Homepage from '../../pages/Homepage/Homepage';
import { getStopsList } from '../../../core/modules/Stops/StopsActions';
import Header from '../Header/Header';
// eslint-disable-next-line import/extensions
import Footer from '../Footer/Footer-VARIANT.js';
import Modal from '../../../components/containers/Modal/Modal';
import SearchResults from '../../pages/SearchResults/SearchResults';
import CMSPage from '../../pages/CMS/CMSPage';
import Checkout from '../../pages/Checkout/Checkout';
import Tickets from '../../pages/Tickets/Tickets';
import TopRoutes from '../../pages/TopRoutes/TopRoutes';
import TicketSearch from '../../pages/TicketSearch/TicketSearch';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import TicketReturn from '../../pages/TicketReturn/TicketReturn';
import './App.scss';
import './BlackTheme.scss';
import './WhiteTheme.scss';
import './RemoveImagesTheme.scss';
import './DyslexiaTheme.scss';
import './FontThemes.scss';
import User from '../../pages/User/User';
import TopStations from '../../pages/TopStations/TopStations';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import TicketChange from '../../pages/TicketChange/TicketChange';
import withCookiesConsent from '../CookiesConsent/withCookiesConsent';
import PasswordReset from '../../modals/PasswordReset/PasswordReset';
import { constants } from '../../../core/modules/constants';
import { getCurrentUser } from '../../../core/modules/User/UserActions';
import LocalizedSwitch from '../LocalizedSwitch/LocalizedSwitch';
import i18n, { possibleLocales } from '../../../../i18next';
// eslint-disable-next-line import/extensions
import getVariantRoutes from './getVariantRoutes-VARIANT';
import SearchTicketByTravel from '../../pages/SearchTicketByTravel/SearchTicketByTravel';
import SocialLoginCallback from '../../modals/Login/Partials/Social/SocialLoginCallback';
import PinReset from '../../pages/PinReset/PinReset';
import DisabilityButtonsContainer from '../../../components/presentational/DisabilityButtons/DisabilityButtonsContainer';

export const ThemeContext = createContext(null);

const App = ({
  onGetStops, locale, isAuthenticated, dispatchGetCurrentUser, locales, location, defaultLocale,
}) => {
  const containerRef = useRef(null);
  const { pathname } = location;

  useEffect(() => {
    onGetStops();
    loadReCaptcha();
  }, []);

  useEffect(() => {
    if (isAuthenticated) dispatchGetCurrentUser();
  }, []);

  useEffect(() => {
    const pathnameRoot = pathname.split('/')[1];
    const changeLocale = (nextLocale) => {
      if (nextLocale !== locale) {
        i18n.changeLanguage(nextLocale);
      }
    };

    if (!pathnameRoot) {
      changeLocale(defaultLocale);
      return;
    }

    if (locales.length) {
      if (locales.includes(pathnameRoot)) {
        changeLocale(pathnameRoot);
        return;
      }

      changeLocale(defaultLocale);
      return;
    }

    if (possibleLocales.includes(pathnameRoot)) {
      changeLocale(pathnameRoot);
      return;
    }

    changeLocale(defaultLocale);
  }, [locales, pathname, locale, defaultLocale]);

  const [removeImages, setRemoveImages] = useState(localStorage.getItem('removImageTheme'));
  const [printTheme, setPrintTheme] = useState(localStorage.getItem('setDyslexiaTheme'));
  const [openDisabilityControl, setOpenVisabilityControl] = useState('');
  const [classSize, setClassSize] = useState('');

  const togglePrintTheme = (item) => {
    localStorage.setItem('setDyslexiaTheme', item);
    setPrintTheme(printTheme !== 'dyslexia-theme' ? 'dyslexia-theme' : '');
  };

  const removeAllImages = (item) => {
    localStorage.setItem('removImageTheme', item);
    setRemoveImages(removeImages !== 'remove-images-theme' ? 'remove-images-theme' : '');
  };

  return (
    <ThemeContext.Provider value={{
      printTheme,
      togglePrintTheme,
      setPrintTheme,
      openDisabilityControl,
      setOpenVisabilityControl,
      removeImages,
      removeAllImages,
      setRemoveImages,
      setClassSize,
      classSize,
    }}
    >
      <div key={locale} ref={containerRef} className={`app-container ${printTheme} ${removeImages}`}>
        <ErrorBoundary>
          <ScrollToTop containerRef={containerRef} />
          <Header />

          <div className="app">
            <DisabilityButtonsContainer />
            <div className="page-content" id="page-content">

              <LocalizedSwitch>
                <Route
                  exact
                  path={[routes.oauthFacebookReturn, routes.oauthGoogleReturn]}
                  component={Homepage}
                  staticRoute
                />
                <Route
                  exact
                  path={[routes.homepage, routes.passwordReset]}
                  component={Homepage}
                />
                <Route
                  exact
                  path={routes.kitchenSink}
                  component={KitchenSink}
                />
                <Route
                  exact
                  path={Object.entries(routes.checkout).map((entry) => entry[1])}
                  component={Checkout}
                />
                <Route
                  exact
                  path={routes.ticketSearch}
                  component={TicketSearch}
                />
                <Route
                  exact
                  path={Object.entries(routes.ticketReturn).map((entry) => entry[1])}
                  component={TicketReturn}
                />
                <Route
                  exact
                  path={Object.entries(routes.ticketChange).map((entry) => entry[1])}
                  component={TicketChange}
                />
                <Route
                  exact
                  path={[routes.searchResults, routes.topRoutes.route]}
                  component={SearchResults}
                />
                <Route
                  exact
                  path={Object.entries(routes.tickets).map((entry) => entry[1])}
                  component={Tickets}
                />
                <Route
                  exact
                  path={[routes.ticketSearchByTravel.index]}
                  component={SearchTicketByTravel}
                />
                <Route
                  exact
                  path={routes.topRoutes.index}
                  component={TopRoutes}
                />
                <Route
                  path={[
                    routes.topStations.local.index,
                    routes.topStations.abroad.index,
                  ]}
                  component={TopStations}
                />
                <Route
                  exact
                  path={Object.entries(routes.user).map((entry) => entry[1])}
                  component={User}
                />

                <Route
                  path={routes.pinDesktop}
                  component={PinReset}
                  staticRoute
                />
                {getVariantRoutes({ defaultLocale, locale })}

                <Route component={CMSPage} />
              </LocalizedSwitch>

              <LocalizedSwitch>
                <Route
                  exact
                  path={routes.passwordReset}
                  component={PasswordReset}
                />
                <Route
                  exact
                  path={[routes.oauthFacebookReturn, routes.oauthGoogleReturn]}
                  component={SocialLoginCallback}
                  staticRoute
                />
              </LocalizedSwitch>
            </div>
          </div>

          <Footer />
          <Modal />
        </ErrorBoundary>
      </div>
    </ThemeContext.Provider>
  );
};

App.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  location: PropTypes.shape().isRequired,
  onGetStops: PropTypes.func.isRequired,
  dispatchGetCurrentUser: PropTypes.func.isRequired,
  locales: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultLocale: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  onGetStops: () => dispatch(getStopsList()),
  dispatchGetCurrentUser: () => dispatch(getCurrentUser()),
});

const mapStateToProps = (state) => ({
  isAuthenticated: state.token.hasIn([constants.token.type.USER, 'token']),
  locale: state.locale,
  locales: state.locales.get('list'),
  defaultLocale: state.locales.get('default'),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(withCookiesConsent(hot(withRouter(App))))));
