import { constants } from '../constants';

const initialState = 'lt';

const ActiveLocaleReducer = (state = initialState, action) => {
  switch (action.type) {
  case constants.locales.SET_ACTIVE_LOCALE:
    return action.payload.locale;

  default:
    return state;
  }
};

export default ActiveLocaleReducer;
