import React from 'react';
import PropTypes from 'prop-types';
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
  InfoWindow,
} from 'react-google-maps';
import markerIcon from '../../../../../../assets/images/markerOn.png';
import './StationMap.scss';

const StationMap = ({ station }) => {
  const {
    latitude, longitude, id, title, address, mapTitle,
  } = station;

  return (
    <GoogleMap
      defaultCenter={{ lat: latitude, lng: longitude }}
      defaultZoom={14}
      smoothCenter
      options={{
        disableDefaultUI: true,
        fullscreenControl: true,
        gestureHandling: 'cooperative',
      }}
    >
      <Marker
        key={id}
        title={title}
        name={title}
        position={{ lat: latitude, lng: longitude }}
        icon={{ url: markerIcon }}
      >
        <InfoWindow>
          <div className="station-map-info">
            <div className="station-map-info__title">{mapTitle}</div>

            <div className="station-map-info-content">{address}</div>
          </div>
        </InfoWindow>
      </Marker>
    </GoogleMap>
  );
};

StationMap.propTypes = {
  station: PropTypes.shape(),
};

StationMap.defaultProps = {
  station: null,
};

export default withScriptjs(withGoogleMap(StationMap));
