import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { endpoints } from '../../../core/config/endpoints';
import Alert from '../../../components/presentational/Alert/Alert';
import SearchForm from './Partials/SearchForm/SearchForm';
import LoginSidebar from './Partials/LoginSidebar/LoginSidebar';
import './TicketSearch.scss';
import withDocumentHead from '../../common/DocumentHead/withDocumentHead';
import { withTracker } from '../../common/Tracker/withTracker';
import variantConst from '../../../core/config/constants/variantConst/variantConst';

const TicketSearch = ({
  t,
  findByTicketGlobalErrors,
  findByTravelGlobalErrors,
  setDocumentTitle,
}) => {
  const globalErrors = [
    ...findByTicketGlobalErrors,
    ...findByTravelGlobalErrors,
  ];

  React.useEffect(() => {
    if (t) {
      setDocumentTitle(t(variantConst.documentTitle, { title: t('ticket_search') }));
    }
  }, [t]);

  return (
    <div className="ticket-search">
      <h1 className="ticket-search__header">
        {t('ticket_search_login')}
      </h1>

      <div className="ticket-search__subheader">
        <span className="star">*</span> - {t('form_marked_fields_required')}.
      </div>

      <div className="ticket-search-content">
        <div className="ticket-search-form-container">
          {globalErrors.map((error) => (
            <Alert key={error} variant="danger" heading={t('common_error_exclamation')}>{error}</Alert>
          ))}

          <SearchForm />
        </div>

        <div className="ticket-search-sidebar-container">
          <LoginSidebar />
        </div>
      </div>
    </div>
  );
};

TicketSearch.propTypes = {
  t: PropTypes.func.isRequired,
  findByTicketGlobalErrors: PropTypes.arrayOf(PropTypes.any),
  findByTravelGlobalErrors: PropTypes.arrayOf(PropTypes.any),
  setDocumentTitle: PropTypes.func.isRequired,
};

TicketSearch.defaultProps = {
  findByTicketGlobalErrors: [],
  findByTravelGlobalErrors: [],
};

const mapStateToProps = (state) => ({
  findByTicketGlobalErrors: state.request.getIn([endpoints.FIND_TICKET_BY_NUMBER.name, 'error', 'global']),
  findByTravelGlobalErrors: state.request.getIn([endpoints.FIND_TICKET_BY_TRAVEL.name, 'error', 'global']),
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(withDocumentHead(withTracker(TicketSearch))),
);
