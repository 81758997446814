import { constants } from '../../modules/constants';

export const userEndpoints = {
  LOGIN_USER: {
    name: 'LOGIN_USER',
    url: {
      method: 'POST',
      path: () => '/login_check',
    },
  },
  LOGIN_USER_SOCIAL: {
    name: 'LOGIN_USER_SOCIAL',
    url: {
      method: 'POST',
      path: (provider, code) => `/login_check/${provider}?code=${code}`,
    },
  },
  REGISTER_USER: {
    name: 'REGISTER_USER',
    url: {
      method: 'POST',
      path: () => '/user/register',
    },
  },
  GET_CURRENT_USER: {
    name: 'GET_CURRENT_USER',
    url: {
      method: 'GET',
      path: () => '/user',
    },
    token: constants.token.type.USER,
  },
  UPDATE_CURRENT_USER: {
    name: 'UPDATE_CURRENT_USER',
    url: {
      method: 'PUT',
      path: () => '/user',
    },
    token: constants.token.type.USER,
  },
  UPDATE_USER_PASSWORD: {
    name: 'UPDATE_USER_PASSWORD',
    url: {
      method: 'PUT',
      path: () => '/user/password',
    },
    token: constants.token.type.USER,
  },
  GET_USER_ORDERS: {
    name: 'GET_USER_ORDERS',
    url: {
      method: 'GET',
      path: () => '/user/orders',
    },
    token: constants.token.type.USER,
  },
  GET_USER_ORDER: {
    name: 'GET_USER_ORDER',
    url: {
      method: 'GET',
      path: (id) => `/user/orders/${id}`,
    },
    token: constants.token.type.USER,
  },
  DELETE_USER: {
    name: 'DELETE_USER',
    url: {
      method: 'DELETE',
      path: () => '/user/remove',
    },
    token: constants.token.type.USER,
  },
};
