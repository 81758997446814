import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Formik } from 'formik';
import Button from '../../../../../components/presentational/Button/Button';
import { getErrors } from '../../../../../core/utilities/formValidator';
import { validations } from '../../../../../core/config/form/validations';
import Input from '../../../../../components/presentational/FormElements/Input/Input';
import './LoginSidebar.scss';
import Alert from '../../../../../components/presentational/Alert/Alert';
import { userEndpoints } from '../../../../../core/config/endpoints/user';
import { loginUser } from '../../../../../core/modules/User/UserActions';
import { setRequestError } from '../../../../../core/modules/Request/RequestActions';
import { openModal } from '../../../../../core/modules/Components/Modal/ModalActions';
import Login from '../../../../modals/Login/Login';
import Icon from '../../../../../components/presentational/Icon/Icon';

const LoginSidebar = ({
  t,
  dispatchLoginUser,
  unsetLoginError,
  loginLoading,
  loginErrors,
  openLoginModal,
}) => {
  React.useEffect(() => function cleanup() {
    unsetLoginError();
  }, []);

  const { global, fields } = loginErrors;
  const loginFieldsErrors = fields || {};
  const formRef = React.useRef(null);
  const [showPassword, setShowPassword] = React.useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);
  const onSubmit = (values) => {
    const { username, password } = values;
    dispatchLoginUser(username, password);
  };

  return (
    <div className="ticket-search-sidebar">
      <div className="ticket-search-sidebar__header">
        {t('ticket_search_password_question')}
      </div>

      <div className="ticket-search-sidebar__subheader">
        {t('ticket_search_login_user')}
      </div>

      <Formik
        validateOnChange={false}
        onSubmit={onSubmit}
        initialValues={{
          password: '',
          username: '',
        }}
        validate={(values) => getErrors(values, {
          username: [validations.email],
          password: [validations.required],
        }, formRef)}
        render={(formProps) => {
          const {
            values,
            handleSubmit,
            errors,
            setFieldValue,
          } = formProps;
          const { password, username } = values;

          return (
            <form onSubmit={handleSubmit} ref={formRef}>
              {global && global.map((error) => (
                <Alert
                  key={error}
                  variant="danger"
                  heading={t('common_error_exclamation')}
                >
                  {error}
                </Alert>
              ))}

              <div className="ticket-search-sidebar-input-row">
                <Input
                  name="username"
                  id="username"
                  label={t('login_username')}
                  type="text"
                  value={username}
                  onChange={(e) => setFieldValue('username', e.target.value)}
                  error={t(errors.username) || loginFieldsErrors.username}
                  disabled={loginLoading}
                />
              </div>

              <div className="ticket-search-sidebar-input-row">
                <Input
                  className="ticket-search-sidebar-input-row"
                  name="password"
                  id="password"
                  label={t('login_password')}
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setFieldValue('password', e.target.value)}
                  disabled={loginLoading}
                  error={t(errors.password) || loginFieldsErrors.password}
                  icon={(
                    <button
                      className="input-icon__button"
                      type="button"
                      onClick={toggleShowPassword}
                      disabled={loginLoading}
                    >
                      <Icon icon="view" /> {t(showPassword ? 'login_hide' : 'login_show')}
                    </button>
                  )}
                />
              </div>

              <div className="ticket-search-sidebar-actions">
                <Button
                  type="submit"
                  disabled={loginLoading}
                >
                  {t('common_continue')}
                </Button>

                <Button
                  variant="inline-link"
                  className="ticket-search-sidebar-text-button"
                  onClick={openLoginModal}
                >
                  <span>
                    {t('login_forgot_password')}
                  </span>
                </Button>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
};

LoginSidebar.propTypes = {
  t: PropTypes.func.isRequired,
  openLoginModal: PropTypes.func.isRequired,
  dispatchLoginUser: PropTypes.func.isRequired,
  unsetLoginError: PropTypes.func.isRequired,
  loginLoading: PropTypes.bool,
  loginErrors: PropTypes.shape(),
};

LoginSidebar.defaultProps = {
  loginLoading: false,
  loginErrors: {},
};

const mapStateToProps = (state) => ({
  loginLoading: state.request.getIn([userEndpoints.LOGIN_USER.name, 'loading']),
  loginErrors: state.request.getIn([userEndpoints.LOGIN_USER.name, 'error']),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchLoginUser: (username, password) => dispatch(loginUser(username, password)),
  openLoginModal: () => dispatch(openModal(<Login resetPassword />)),
  unsetLoginError: () => dispatch(setRequestError(userEndpoints.LOGIN_USER.name, {})),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LoginSidebar));
