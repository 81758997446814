import { constants } from '../constants';

export const getStopsList = (value, exludedValue) => ({
  type: constants.data.collections.stops.GET_STOPS_LIST,
  payload: {
    value,
    exludedValue,
  },
});

export const setStopsList = (data) => ({
  type: constants.data.collections.stops.SET_STOPS_LIST,
  payload: {
    data,
  },
});
