import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/extensions,import/no-unresolved
import * as icons from '../../../../../client/assets/icons';
import './Icon.scss';

const Icon = ({ icon, className, ...props }) => {
  const IconComponent = icons[icon];

  if (!IconComponent) return null;

  return (
    <IconComponent
      className={`icon icon-${icon} ${className}`}
      {...props}
    />
  );
};

Icon.propTypes = {
  icon: PropTypes.string,
  className: PropTypes.string,
};

Icon.defaultProps = {
  icon: '',
  className: '',
};

export default Icon;
