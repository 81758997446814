import {
  put,
  call,
  all,
  takeEvery,
  select,
} from 'redux-saga/effects';
import { constants } from '../constants';
import { startRequest } from '../Request/RequestSaga';
import { endpoints } from '../../config/endpoints';
import { getObjectNestedData } from '../../utilities/object';
import { setStation } from './StationsActions';

function* getStationSaga(action) {
  const { slug } = action.payload;
  const existingStation = yield select((state) => state.data.collections.stations.get(slug));

  if (existingStation) return;

  const { response } = yield call(
    startRequest,
    endpoints.GET_STATION.name,
    endpoints.GET_STATION.url.path(slug),
  );

  if (response) {
    const data = getObjectNestedData(response, 'data.data');

    yield put(setStation(slug, data));
  }
}

export default function* StationsSaga() {
  yield all([
    takeEvery(constants.data.collections.stations.GET_STATION, getStationSaga),
  ]);
}
