export const deleteCookie = (cookie) => {
  if (cookie) {
    document.cookie = `${cookie}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
  }
};

export const getCookie = (key) => {
  const b = document.cookie.match(`(^|[^;]+)\\s*${key}\\s*=\\s*([^;]+)`);
  return b ? b.pop() : '';
};
