import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getCMSMenu } from '../../../core/modules/CMS/CMSActions';
import Sidebar from '../../../components/presentational/Sidebar/Sidebar';

const CMSSidebar = ({ menu, menuFolder, getMenu }) => {
  React.useEffect(() => {
    if (menuFolder) getMenu(menuFolder);
  }, [menuFolder]);

  if (!menu) return null;

  return (
    <Sidebar menu={{
      ...menu,
      ...(menu && menu.items && {
        items: menu.items.map((item) => ({
          ...item,
          activeRoutes: [
            `${menu.link}/${item.slug}`,
            `/${item.slug}`,
          ],
        })),
      }),
    }}
    />
  );
};

CMSSidebar.propTypes = {
  menuFolder: PropTypes.string,
  getMenu: PropTypes.func.isRequired,
  menu: PropTypes.shape(),
};

CMSSidebar.defaultProps = {
  menuFolder: null,
  menu: null,
};

const mapStateToProps = (state, props) => ({
  menu: state.data.collections.cms.getIn(['menus', state.locale, props.menuFolder]),
});

const mapDispatchToProps = (dispatch) => ({
  getMenu: (menuFolder) => dispatch(getCMSMenu(menuFolder)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CMSSidebar);
