import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { endpoints } from '../../../core/config/endpoints';
import { parseQuery } from '../../../core/utilities/query';
import Preloader from '../../../components/presentational/Preloader/Preloader';
import NotFound from '../NotFound/NotFound';
import { setTickets, findByTravel } from '../../../core/modules/TicketSearch/TicketSearchActions';
import Ticket from '../Tickets/Partials/Ticket/Ticket';
import './SearchTicketByTravel.scss';

const SearchTicketByTravel = ({
  clearTicketsArray,
  orders,
  loading,
  location,
}) => {
  const parsedQuery = parseQuery(location.search);
  const { date, name, destination } = parsedQuery;
  const hasOrders = orders.length > 0;

  React.useEffect(() => () => {
    clearTicketsArray();
  }, [date, name, destination]);

  if (loading) return <Preloader fullHeight fullWidth />;

  if (!hasOrders) return <NotFound />;

  return (
    <div className="tickets-by-travel">
      <div className="tickets-by-travel-list">
        {orders.map((order) => (
          order.tickets.map((ticket) => (
            <Ticket
              key={ticket.ticketId}
              ticket={ticket}
              orderHash={order.hash}
            />
          ))
        ))}
      </div>
    </div>
  );
};

SearchTicketByTravel.propTypes = {
  location: PropTypes.shape().isRequired,
  clearTicketsArray: PropTypes.func.isRequired,
  orders: PropTypes.arrayOf(PropTypes.shape()),
  loading: PropTypes.bool,
};

SearchTicketByTravel.defaultProps = {
  orders: [],
  loading: false,
};

const mapStateToProps = (state) => ({
  loading: state.request.getIn([endpoints.FIND_TICKET_BY_TRAVEL.name, 'loading']),
  orders: state.ticketSearch,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchFindByTravel: (destination, name, date) => dispatch(
    findByTravel(destination, name, date),
  ),
  clearTicketsArray: () => dispatch(setTickets([])),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchTicketByTravel);
