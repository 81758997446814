import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { formatDate, formatTime } from '../../../../../core/utilities/date';
import { formatMoney } from '../../../../../core/utilities/money';
import './WalletHistory.scss';
import { getHistoryRecordsGroupedByYear } from '../../../../../core/utilities/groupByYear';
import { getCurrentTicket } from '../../../../../core/modules/ETicket/ETicketActions';
import Icon from '../../../../../components/presentational/Icon/Icon';
import withDocumentHead from '../../../../common/DocumentHead/withDocumentHead';
import variantConst from '../../../../../core/config/constants/variantConst/variantConst';

const WalletHistory = ({
  cardContent,
  t,
  dispatchGetCurrentTicket,
  setDocumentTitle,
  setDocumentDescription,
}) => {
  const { useHistory } = cardContent;
  const historyRecords = getHistoryRecordsGroupedByYear(useHistory);

  React.useEffect(() => {
    dispatchGetCurrentTicket();
  }, []);

  React.useEffect(() => {
    if (t) {
      setDocumentTitle(`${t(variantConst.documentTitle)} ${t('document_title_eticket_wallet_history')}`);

      setDocumentDescription(t('document_description_eticket_wallet_history'));
    }
  }, [t]);

  return (
    <>
      <h1 className="eTicket-wallet-history-title">
        {t('eTicket_services_history')}
      </h1>

      {historyRecords && Object.keys(historyRecords).reverse().map((year) => (
        <div key={year.toString()}>
          <div className="eTicket-divider">
            <div>{year}</div>

            <div className="divider" />
          </div>

          <ul className="eTicket-wallet-history-list">
            {historyRecords[year].map((history) => (
              <li key={history.createdAt}>
                <div className="eTicket-wallet-history-list-item">
                  <div className="eTicket-wallet-history-list-item-title">
                    {history.title}

                  </div>
                  <div className="eTicket-wallet-history-list-item-content">
                    <div className="eTicket-wallet-history-list-item-content-createdAt">
                      <div>
                        <Icon icon="calendar" />
                        {formatDate(history.createdAt)}
                      </div>

                      <div>
                        <Icon icon="clock" />
                        {formatTime(history.createdAt)}
                      </div>
                    </div>

                    <div className="eTicket-wallet-history-list-item-amount is-mobile">
                      {formatMoney(history.amount)}
                    </div>
                  </div>

                  <div className="eTicket-wallet-history-list-item-amount">
                    {formatMoney(history.amount)}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </>
  );
};

WalletHistory.propTypes = {
  dispatchGetCurrentTicket: PropTypes.func.isRequired,
  setDocumentTitle: PropTypes.func.isRequired,
  setDocumentDescription: PropTypes.func.isRequired,
  cardContent: PropTypes.shape(),
  t: PropTypes.func.isRequired,
};

WalletHistory.defaultProps = {
  cardContent: {},
};

const mapStateToProps = (state) => ({
  cardContent: state.eTicket.get('cardContent'),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchGetCurrentTicket: () => dispatch(getCurrentTicket()),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(withDocumentHead(WalletHistory)),
);
