import { constants } from '../constants';

export const getTopRoutes = (locale) => ({
  type: constants.data.collections.topRoutes.GET_TOP_ROUTES_LIST,
  payload: {
    locale,
  },
});

export const setTopRoutes = (list, locale) => ({
  type: constants.data.collections.topRoutes.SET_TOP_ROUTES_LIST,
  payload: {
    list,
    locale,
  },
});

export const getTopStations = (orderBy, type) => ({
  type: constants.data.collections.topStations.GET_TOP_STATIONS_LIST,
  payload: {
    orderBy,
    type,
  },
});

export const setTopStations = (list, locale, key) => ({
  type: constants.data.collections.topStations.SET_TOP_STATIONS_LIST,
  payload: {
    list,
    locale,
    key,
  },
});
