import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { routes } from '../../../core/config/routes';
import { constants } from '../../../core/modules/constants';
import Button from '../../../components/presentational/Button/Button';
import './KnowBox-VARIANT.scss';
import Icon from '../../../components/presentational/Icon/Icon';
import variantConst from '../../../core/config/constants/variantConst/variantConst';

const KnowBox = ({ t, isAuthenticated }) => {
  const ticketsRoute = isAuthenticated ? t(routes.user.index) : t(routes.ticketSearch);

  return (
    <div className="know-box-container">
      <div className="know-box">
        <div className="know-box-col-wrap">
          <div className="know-box-col know-box-text-col">
            <h2 className="know-box-text-col__title">{t('did_you_know_that')}</h2>

            <div className="know-box-text-col__text">{t('did_you_know_you_can_return_or_change_ticket')}</div>
          </div>
        </div>

        <div className="know-box-col-wrap">
          <div className="know-box-col know-box-action-col">
            <Icon icon={variantConst.knowBoxArrowCircle} />

            <div className="know-box-action-subcol">
              <h3 className="know-box-action-subcol__title">{t('ticket_return')}</h3>

              <div className="know-box-action-subcol__text">{t('ticket_return_explanation')}</div>

              <Button small variant="inverted" route={ticketsRoute}>{t('return_the_ticket')}</Button>
            </div>
          </div>
        </div>

        <div className="know-box-col-wrap">
          <div className="know-box-col know-box-action-col">
            <Icon icon={variantConst.knowBoxClock} />

            <div className="know-box-action-subcol">
              <h3 className="know-box-action-subcol__title">{t('trip_time_change')}</h3>

              <div className="know-box-action-subcol__text">{t('trip_time_change_explanation')}</div>

              <Button small variant="inverted" route={ticketsRoute}>{t('change_the_trip_time')}</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

KnowBox.propTypes = {
  t: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.token.hasIn([constants.token.type.USER, 'token']),
});

export default connect(mapStateToProps)(withTranslation()(KnowBox));
