import { constants } from '../../constants';

export const subscribeNewsletter = (email) => ({
  type: constants.components.newsletter.SUBSCRIBE,
  payload: {
    email,
  },
});

export const setSubscribtionStatus = (state) => ({
  type: constants.components.newsletter.SET_STATUS,
  payload: {
    state,
  },
});
