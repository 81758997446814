import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import './ButtonClose.scss';

const ButtonClose = ({ className, ariaLabel, ...props }) => (
  <Button
    icon="cancel"
    variant="danger"
    className={`button-close ${className}`}
    ariaLabel={ariaLabel}
    {...props}
  />
);

ButtonClose.propTypes = {
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
};

ButtonClose.defaultProps = {
  className: '',
  ariaLabel: '',
};

export default ButtonClose;
