import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';
import './IconContentContainer.scss';

const IconContentContainer = ({ icon, children }) => (
  <div className="icon-content-container">
    <Icon icon={icon} />
    {children}
  </div>
);

IconContentContainer.propTypes = {
  icon: PropTypes.string,
  children: PropTypes.node,
};

IconContentContainer.defaultProps = {
  icon: '',
  children: null,
};

export default IconContentContainer;
