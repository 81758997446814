import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './Details.scss';
import PersonalDataForm from '../../../../Forms/PersonalDataForm/PersonalDataForm';
import ChangePasswordForm from '../../../../Forms/ChangePasswordForm/ChangePasswordForm';
import withDocumentHead from '../../../../common/DocumentHead/withDocumentHead';
import variantConst from '../../../../../core/config/constants/variantConst/variantConst';
import DeleteUserForm from '../../../../Forms/DeleteUserForm/DeleteUserForm';

const Details = ({ t, setDocumentTitle, setDocumentDescription }) => {
  React.useEffect(() => {
    setDocumentTitle(`${t(variantConst.documentTitle)} ${t(variantConst.documentTitleUserAccountDetails)}`);
    setDocumentDescription(t(variantConst.documentDescriptionUserAccountDetails));
  }, [t]);

  return (
    <div className="user-details">
      <h1 className="user-details-header">{t('user_account_details')}</h1>
      <div className="user-details-subheader">
        <div className="has-color">*</div>
        - {t('user_account_form_must_select')}
      </div>

      <div className="user-details-form">
        <div className="user-details-form-change-personal-details">
          <PersonalDataForm />
        </div>

        <div className="user-details-form-change-password">
          <ChangePasswordForm />
          <DeleteUserForm />
        </div>
      </div>
    </div>
  );
};

Details.propTypes = {
  t: PropTypes.func.isRequired,
  setDocumentTitle: PropTypes.func.isRequired,
  setDocumentDescription: PropTypes.func.isRequired,
};

export default withTranslation()(withDocumentHead(Details));
