import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../Icon/Icon';

const Navbar = ({
  onPreviousClick,
  onNextClick,  
  className,
  showPreviousButton,
  showNextButton,
  localeUtils,
  month,
  months,
  navigationTranslations,  
}) => {
  const monthTranslation = months[month.getMonth()];
  const { formatMonthTitle } = localeUtils;

  const previousButtonRef = React.useRef(null);
  const nextButtonRef = React.useRef(null);

  const handleKeyDown = (event, callback) => {
    if (event.key === 'Escape') {      
      return;
    }

    if (event.key === 'Enter') {
      callback();
    }
  };

  return (
    <div className={className}>
      <span
        ref={previousButtonRef}
        className={`icon-wrap prev ${showPreviousButton ? '' : 'disabled'}`}
        onClick={() => {
          previousButtonRef.current.blur();
          onPreviousClick();
        }}
        onKeyDown={(event) => {
          handleKeyDown(event, onPreviousClick);
        }}
        role="button"
        tabIndex={
          showPreviousButton ? 0 : -1
        }
        aria-label={navigationTranslations.backwards}
      >
        <Icon icon="left" />
      </span>

      <span className="title">
        {monthTranslation ? `${monthTranslation} ${month.getFullYear()}` : formatMonthTitle(month)}
      </span>

      <span
        ref={nextButtonRef}
        className={`icon-wrap next ${showNextButton ? '' : 'disabled'}`}        
        onClick={() => {
          nextButtonRef.current.blur();
          onNextClick();
        }}
        onKeyDown={(event) => {
          handleKeyDown(event, onNextClick);
        }}
        role="button"
        tabIndex={
          showNextButton ? 0 : -1
        }
        aria-label={navigationTranslations.forward}
      >
        <Icon icon="left" />
      </span>
    </div>
  );
};

Navbar.propTypes = ({
  month: PropTypes.shape(),
  months: PropTypes.arrayOf(PropTypes.string),
  localeUtils: PropTypes.shape().isRequired,
  onPreviousClick: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  showPreviousButton: PropTypes.bool.isRequired,
  showNextButton: PropTypes.bool.isRequired,
  navigationTranslations: PropTypes.objectOf(PropTypes.string).isRequired,
});

Navbar.defaultProps = ({
  month: new Date(),
  months: {},
});

export default Navbar;
