import { constants } from '../constants';

export const getLocales = () => ({
  type: constants.locales.GET_LOCALES,
  payload: {},
});

export const setLocales = (locales) => ({
  type: constants.locales.SET_LOCALES,
  payload: {
    locales,
  },
});

export const setActiveLocale = (locale) => ({
  type: constants.locales.SET_ACTIVE_LOCALE,
  payload: {
    locale,
  },
});
