import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';
import './Submenu-VARIANT.scss';

const Submenu = ({ children, submenuTitle }) => {
  const [submenu, setSubmenu] = React.useState(false);
  const handleSubmenuClick = () => setSubmenu(!submenu);

  return (
    <>
      <button
        tabIndex={0}
        className={`submenu-title ${submenu ? 'is-active' : ''} mobile-user-meniu-button`}
        onClick={handleSubmenuClick}
        type="button"
      >
        {submenuTitle}

        <Icon icon={submenu ? 'minus' : 'add'} />
      </button>

      { submenu && children }
    </>
  );
};

Submenu.propTypes = {
  children: PropTypes.node,
  submenuTitle: PropTypes.string,
};

Submenu.defaultProps = {
  children: null,
  submenuTitle: '',
};

export default Submenu;
