import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Icon from '../../../../../components/presentational/Icon/Icon';
import { userEndpoints } from '../../../../../core/config/endpoints/user';
import Button from '../../../../../components/presentational/Button/Button';
import { generateOAuthRedirect } from '../../../../../core/utilities/socialLoginUrlGenerator';
import Preloader from '../../../../../components/presentational/Preloader/Preloader';

const SocialLoginButton = ({
  children, loginUserLoading, icon, provider, ariaLabel, 
}) => (
  <Button
    href={generateOAuthRedirect(provider)}
    className="social-login-item"
    variant="white"
    disabled={loginUserLoading}
    ariaLabel={ariaLabel}
  >
    {loginUserLoading && <Preloader fullHeight />}
    {(icon && !loginUserLoading) && <Icon icon={icon} />}
    {children}
  </Button>
);

SocialLoginButton.propTypes = {
  children: PropTypes.node,
  loginUserLoading: PropTypes.bool,
  icon: PropTypes.string,
  provider: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string,
};

SocialLoginButton.defaultProps = {
  children: null,
  loginUserLoading: false,
  icon: '',
  ariaLabel: '',
};

const mapStateToProps = (state) => ({
  loginUserLoading: state.request.getIn([userEndpoints.LOGIN_USER.name, 'loading']),
});

export default connect(mapStateToProps)(SocialLoginButton);
