import React from 'react';
import PropTypes from 'prop-types';
import './FieldContainer.scss';

const FieldContainer = ({
  children,
  label,
  id,
  inline,
  error,
  className,
  disabled,
  hidden,
  outerRef,
  required,
}) => {
  const childrenId = (children.length > 0 && children[0] !== 'undefined') ? children[0].props.id : null;
  const inlineClass = inline ? ' is-inline' : '';
  const disabledClass = disabled ? ' is-disabled' : '';
  const hiddenClass = hidden ? ' is-hidden' : '';
  const errorClass = error ? ' has-error' : '';
  const mainClasses = className
    + inlineClass
    + disabledClass
    + hiddenClass;

  return (
    <div ref={outerRef} className={`field-container ${mainClasses}`}>
      {!!label && (
        <label
          className={`field-container__label ${disabled ? 'is-disabled' : ''}`}
          htmlFor={id || childrenId}
          title={label}
        >
          <span>{label}</span>

          {required && (<span className="field-container__label-required">*</span>)}
        </label>
      )}

      <div className={`field-container__field ${errorClass}`}>
        {children}
      </div>

      {!!error && (
        <div className="field-container__error" role="alert" aria-live="polite">
          {error}
        </div>
      )}
    </div>
  );
};

FieldContainer.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
  id: PropTypes.string,
  inline: PropTypes.bool,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  error: PropTypes.string,
  className: PropTypes.string,
  outerRef: PropTypes.shape(),
  required: PropTypes.bool,
};

FieldContainer.defaultProps = {
  label: null,
  id: null,
  outerRef: null,
  inline: false,
  disabled: false,
  hidden: false,
  error: null,
  className: '',
  required: false,
};

export default FieldContainer;
