export const createRegExp = (exp) => {
  const flagSlashIndex = exp.lastIndexOf('/');
  const values = [
    exp.slice(1, flagSlashIndex),
    exp.slice(flagSlashIndex + 1),
  ];

  return new RegExp(values[0], values[1]);
};

export const regExpValid = (exp, value) => createRegExp(exp).test(value);
