import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { selectOrderPaymentMethod } from '../../../../../core/modules/Order/OrderActions';
import RadioGroup from '../../../../../components/presentational/FormElements/Radio/RadioGroup/RadioGroup';
import {
  getPaymentMethods,
  setPaymentMethods,
} from '../../../../../core/modules/Data/Collections/Payment/PaymentActions';
import './Payments.scss';

const Payments = ({
  t,
  loading,
  formProps,
  orderHash,
  paymentMethods,
  dispatchGetPaymentMethods,
  dispatchUnsetPaymentMethods,
  dispatchSelectOrderPaymentMethod,
  orderTotal,
}) => {
  React.useEffect(() => {
    if (!paymentMethods) dispatchGetPaymentMethods();

    return function cleanup() {
      dispatchUnsetPaymentMethods();
    };
  }, []);
  
  const { values, errors } = formProps;
  const { paymentMethodId, paymentMethodCountryId } = values;
  const hasError = errors.paymentMethodId || errors.paymentMethodCountryId;
  
  React.useEffect(() => {
    if (orderTotal === 0 && paymentMethods) {
      const [firstCountryMethods] = Object.values(paymentMethods);
      if (firstCountryMethods) {
        const [firstPaymentMethod] = firstCountryMethods;
        if (firstPaymentMethod) {
          const { id, countries } = firstPaymentMethod;
          const [firstCountry] = countries;
          if (firstCountry) {
            dispatchSelectOrderPaymentMethod(orderHash, id, firstCountry.id);
          }
        }
      }
    }
  }, [orderTotal, paymentMethods, orderHash]);

  if (!paymentMethods || orderTotal === 0) return null;

  return (
    <div className="checkout-item">
      <div className="wave wave-top" />

      <div className="checkout-item-content no-top-border no-bottom-border loading-container">
        <h2 className="checkout-item-header">
          <span className="checkout-item__title">{t('payment')}</span>
        </h2>
        <div className={loading ? 'loading-container__overlay' : ''} />
        <div className="checkout-payment-methods">
          {Object.keys(paymentMethods).map((countryTitle, countryIndex) => {
            const countryPaymentMethods = paymentMethods[countryTitle];
            const countryTabId = `checkout-payment-methods-tab-${countryTitle}`;

            return (
              <React.Fragment key={countryTitle}>
                <input
                  className="checkout-payment-methods__input"
                  name="paymentMethodCountryId"
                  type="radio"
                  id={countryTabId}
                  defaultChecked={countryIndex === 0}
                />

                <label className="checkout-payment-methods__label" htmlFor={countryTabId}>
                  {t(countryPaymentMethods[0].country.translationKey)}
                </label>

                <div className={`checkout-payment-methods__panel ${hasError ? 'has-error' : ''}`}>
                  <RadioGroup
                    error={t(hasError)}
                    onChange={(e) => dispatchSelectOrderPaymentMethod(orderHash, ...e.target.value.split('_'))}
                    name="paymentMethodId"
                    value={`${paymentMethodId}_${paymentMethodCountryId}`}
                    render={(radio) => countryPaymentMethods.map(({
                      logo, id, country, name, 
                    }) => (
                      <React.Fragment key={id}>
                        {radio(`${id}_${country.id}`, logo, name)}
                      </React.Fragment>
                    ))}
                  />
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>

      <div className="wave wave-bottom" />
    </div>
  );
};

Payments.propTypes = {
  t: PropTypes.func.isRequired,
  formProps: PropTypes.shape().isRequired,
  dispatchSelectOrderPaymentMethod: PropTypes.func.isRequired,
  dispatchGetPaymentMethods: PropTypes.func.isRequired,
  dispatchUnsetPaymentMethods: PropTypes.func.isRequired,
  paymentMethods: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.shape())),
  loading: PropTypes.bool,
  orderHash: PropTypes.string.isRequired,
  orderTotal: PropTypes.number.isRequired,
};

Payments.defaultProps = {
  paymentMethods: null,
  loading: false,
};

const mapStateToProps = (state) => ({
  paymentMethods: state.data.collections.payment.get('methods'),
  orderHash: state.order.getIn(['data', 'hash']),
  loading: state.order.getIn(['status', 'loading']),
  orderTotal: state.order.getIn(['data', 'total']),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchGetPaymentMethods: () => (
    dispatch(getPaymentMethods())
  ),
  dispatchSelectOrderPaymentMethod: (orderHash, paymentMethodId, countryId) => (
    dispatch(selectOrderPaymentMethod(orderHash, +paymentMethodId, +countryId))
  ),
  dispatchUnsetPaymentMethods: () => (
    dispatch(setPaymentMethods(null))
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Payments));
