export const parseQuery = (query) => {
  const params = {};
  
  decodeURI(query)
    .substring(1, query.length)
    .split('&')
    .forEach((queryItem) => {
      const splitQueryItem = queryItem.split('=');
      const [key, value] = splitQueryItem;
      
      params[key] = value;
    });
  
  return params;
};
