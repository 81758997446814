import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Formik } from 'formik';
import './Sidebar.scss';
import Timepicker from '../../../../../components/containers/Timepicker/Timepicker';
import Button from '../../../../../components/presentational/Button/Button';
import Alert from '../../../../../components/presentational/Alert/Alert';
import Checkbox from '../../../../../components/presentational/FormElements/Checkbox/Checkbox';
import SidebarContainer from '../../../../../components/presentational/Sidebar/Sidebar';
import { formatTime } from '../../../../../core/utilities/date';

const Sidebar = ({
  t, searchValues, onSubmit, banner, submitting,
}) => (
  <SidebarContainer menu={{ banner }}>
    <Formik
      validateOnChange={false}
      onSubmit={onSubmit}
      initialValues={searchValues}
      enableReinitialize
      render={(formProps) => {
        const {
          values,
          handleSubmit,
          setFieldValue,
        } = formProps;
        const {
          departureTime, onlyInternet, onlyDirect, onlyBikes,
        } = values;

        return (
          <form className="search-results-sidebar" onSubmit={handleSubmit}>
            <div className="search-results-sidebar-timepicker">
              <label htmlFor="departureTime" className="search-results-sidebar__title">
                {t('ticket_search_departure_time_from')}
              </label>

              <div className="search-results-sidebar-timepicker-inputs">
                <Timepicker
                  name="departureTime"
                  value={departureTime || formatTime(new Date())}
                  onChange={(value) => setFieldValue('departureTime', value)}
                  required
                />

                <Button
                  aria-label={t('ticket_search_search_trip')}
                  variant="danger"
                  type="submit"
                  icon="search"
                  big
                  disabled={submitting}
                  ariaLabel={t('ticket_search_search_trip')}
                />
              </div>
            </div>

            <Alert
              className="search-results-sidebar-alert"
              variant="warning"
              border={false}
              arrow="top"
            >
              {t('ticket_search_departure_time_from_explanation')}
            </Alert>

            <div className="search-results-sidebar-checkboxes-container">
              <div className="search-results-sidebar__title">
                {t('ticket_search_show_only')}
              </div>

              <div className="search-results-sidebar-checkboxes">
                <Checkbox
                  id="ticket_search_show_only_e-tickets"
                  name="onlyInternet"
                  onChange={(e) => {
                    setFieldValue(e.target.name, !onlyInternet);
                    handleSubmit();
                  }}
                  value={onlyInternet}
                  label={t('ticket_search_show_only_e-tickets')}
                  disabled={submitting}
                />

                <Checkbox
                  id="ticket_search_show_only_direct_routes"
                  name="onlyDirect"
                  onChange={(e) => {
                    setFieldValue(e.target.name, !onlyDirect);
                    handleSubmit();
                  }}
                  value={onlyDirect}
                  label={t('ticket_search_show_only_direct_routes')}
                  disabled={submitting}
                />

                <Checkbox
                  id="ticket_search_show_only_bike_routes"
                  name="onlyBikes"
                  onChange={(e) => {
                    setFieldValue(e.target.name, !onlyBikes);
                    handleSubmit();
                  }}
                  value={onlyBikes}
                  label={t('ticket_search_show_only_bike_routes')}
                  disabled={submitting}
                />
              </div>
            </div>
          </form>
        );
      }}
    />
  </SidebarContainer>
);

const mapStateToProps = (state) => ({
  banner: state.data.collections.banners.get('list_sidebar'),
});

Sidebar.propTypes = {
  banner: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  searchValues: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
  ])).isRequired,
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

Sidebar.defaultProps = {
  banner: null,
};

export default connect(mapStateToProps)(withTranslation()(Sidebar));
