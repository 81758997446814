import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import { render } from 'react-dom';
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/browser';
import './assets/images/favicon-ab.ico';
import './assets/images/favicon-el.ico';
import './assets/scss/main.scss';
import TagManager from 'react-gtm-module';
import { store, history, persistor } from './app/core/historyAndStore';
import i18n from './i18next';
import { getLocales } from './app/core/modules/Locales/LocalesActions';

store.dispatch(getLocales());

const rootElement = document.querySelector('#app');

if (process.env.ENV === 'prod') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    ignoreErrors: [
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
      'Object Not Found Matching Id',
      '\'block\' member of ScrollIntoViewOptions \'nearest\' is not a valid value for enumeration ScrollLogicalPosition.',
    ],
  });
}

function renderApp() {
  const Root = require('./app/containers/common/Root/Root.js').default;

  render(<Root store={store} history={history} persistor={persistor} />, rootElement);
}

i18n.on('initialized', () => {
  const tagManagerArgs = {
    gtmId: process.env.GTM_ID,
  };

  TagManager.initialize(tagManagerArgs);

  const gaIni = [
    {
      trackingId: process.env.GOOGLE_ANALYTICS_KEY,
      gaOptions: { name: 'GA' },
    },
    {
      trackingId: process.env.GOOGLE_TOKS_ANALYTICS_KEY,
      gaOptions: { name: 'TOKSGA' },
    },
  ];

  ReactGA.initialize(gaIni);

  renderApp();

  if (module.hot) {
    module.hot.accept(renderApp);
  }
});
