import {
  put,
  call,
  select,
} from 'redux-saga/effects';
import { startRequest } from '../Request/RequestSaga';
import { endpoints } from '../../config/endpoints';
import { setToken } from './TokenActions';
import { constants } from '../constants';
import { eTicketLogout } from '../ETicket/ETicketActions';
import { logoutUser } from '../User/UserActions';

export function* refreshTokenSaga(tokenType) {
  const refreshToken = yield select((state) => state.token.getIn([tokenType, 'refreshToken']));

  const { response } = yield call(
    startRequest,
    endpoints.REFRESH_TOKEN.name,
    endpoints.REFRESH_TOKEN.url.path(),
    {
      refresh_token: refreshToken,
    },
  );

  if (response) {
    yield put(setToken(tokenType, response.data.token));
  } else if (tokenType === constants.token.type.E_TICKET) {
    yield put(eTicketLogout());
  } else {
    yield put(logoutUser());
  }
}
