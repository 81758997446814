import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { routes } from '../../../core/config/routes';
import Button from '../../../components/presentational/Button/Button';
import preloader from '../../../../assets/images/preloader.gif';
import './BusStopLoader.scss';
import Icon from '../../../components/presentational/Icon/Icon';

const BusStopLoader = ({ t, children, className }) => (
  <div className={`bus-stop-loader ${className}`}>
    <div className="bus-stop-loader-background">
      <img src={preloader} alt="loading" />
    </div>

    {children}

    <Button
      className="bus-stop-loader-go-back"
      variant="inline-link"
      route={t(routes.homepage)}
    >
      <Icon icon="arrow-long-left" />
      <span>{t('404_link')}</span>
    </Button>
  </div>
);

BusStopLoader.propTypes = {
  t: PropTypes.func.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};

BusStopLoader.defaultProps = {
  children: null,
  className: '',
};

export default withTranslation()(BusStopLoader);
