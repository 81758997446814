import { Map } from 'immutable';
import { constants } from '../constants';

const initialState = Map({
  list: [],
  default: 'lt',
});

const LocalesReducer = (state = initialState, action) => {
  switch (action.type) {
  case constants.locales.SET_LOCALES:
    return state
      .set('list', action.payload.locales.filter((locale) => locale.enabled).map((locale) => locale.name))
      .set('default', action.payload.locales.filter((locale) => locale.default)[0].name);

  default:
    return state;
  }
};

export default LocalesReducer;
