import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SearchResultsRouteFeaturesView
from '../../../../presentational/SearchResultsRoute/Partials/Features/Features';
import { openModal } from '../../../../../core/modules/Components/Modal/ModalActions';
import RouteFeatures from '../../../../../containers/modals/RouteFeatures/RouteFeaturesModal';
import Icon from '../../../../presentational/Icon/Icon';

class SearchResultsRouteFeatures extends Component {
  getFeatures = (step) => {
    const { sellData } = step;
    const features = [];

    if (sellData) {
      const { bus, isFamily } = sellData;
      const validFeatures = Object.entries(bus).map((entry) => {
        const name = entry[0];
        const hasValue = entry[1] === 1;

        switch (true) {
        case name === 'isWifi' && hasValue: return { name, id: 'ABWIFIINFO' };

        case name === 'isBike' && hasValue: return { name, id: 'ABBICYCLEINFO' };

        case name === 'isBusiness' && hasValue: return { name, id: 'ABBUSINESSINFO' };

        case name === 'isBusinessVip' && hasValue: return { name, id: 'ABBUSINESSVIPINFO' };

        case name === 'isHandicapped' && hasValue: return { name, id: 'ABHANDICAPPEDINFO' };

        default: return null;
        }
      }).filter((feature) => !!feature);

      if (isFamily && isFamily === 1) {
        features.push({
          name: 'isFamily',
          id: 'ABFAMILYINFO',
        });
      }

      features.push(...validFeatures);
    }

    return features;
  };

  renderFeature = (feature) => {
    const { route } = this.props;
    const { sellData } = route;
    const bikePlaces = sellData.bus.bikePlaces - sellData.bikePlacesUsed;

    if (feature) {
      switch (feature.name) {
      case 'isWifi': return <Icon icon="wifi" />;
      case 'isBike': return (
        <>
          <Icon icon="bike" />

          {!!bikePlaces && <div className="search-results-route-features-item__label">{bikePlaces}</div>}
        </>
      );
      case 'isBusiness': return <Icon icon="play" />;
      case 'isBusinessVip': return <Icon icon="vip" />;
      case 'isHandicapped': return <Icon icon="disabled" />;
      case 'isFamily': return <Icon icon="family-percent" />;

      default: return null;
      }
    }

    return null;
  };

  openFeatureModal = (id) => {
    const { onOpenFeatureModal } = this.props;

    onOpenFeatureModal(id);
  };

  render() {
    const { route } = this.props;
    const features = this.getFeatures(route);

    return (
      <SearchResultsRouteFeaturesView
        {...this.props}
        features={features}
        renderFeature={(feature) => this.renderFeature(feature)}
        openFeatureModal={this.openFeatureModal}
      />
    );
  }
}

SearchResultsRouteFeatures.propTypes = {
  onOpenFeatureModal: PropTypes.func.isRequired,
  route: PropTypes.shape(),

};

SearchResultsRouteFeatures.defaultProps = {
  route: null,
};

const mapDispatchToProps = (dispatch) => ({
  onOpenFeatureModal: (id) => dispatch(openModal(<RouteFeatures id={id} />, 'large')),
});

export default connect(
  null,
  mapDispatchToProps,
)(SearchResultsRouteFeatures);
