import axios from 'axios';
import { userEndpoints } from './endpoints/user';
import { stopsEndpoints } from './endpoints/stops';
import { searchEndpoints } from './endpoints/search';
import { cmsEndpoints } from './endpoints/cms';
import { topRoutesStationsEndpoints } from './endpoints/topRoutesStations';
import { resetPasswordEndpoints } from './endpoints/resetPassword';
import { contactEndpoints } from './endpoints/contact';
import { bannersEndpoints } from './endpoints/banners';
import { orderEndpoints } from './endpoints/order';
import { tokenEndpoints } from './endpoints/token';
import { newsletterEndpoints } from './endpoints/newsletter';
import { eTicketEndpoints } from './endpoints/eTicket';
import { paymentEndpoints } from './endpoints/payment';
import { ticketSearchEndpoints } from './endpoints/ticketSearch';
import { localesEndpoints } from './endpoints/locales';
import { ticketReturnEndpoints } from './endpoints/ticketReturn';
import { eventsEndpoints } from './endpoints/events';
import { ticketChangeEndpoints } from './endpoints/ticketChange';
import { stationsEndpoints } from './endpoints/stations';
import { eurolinesRoutesEndpoints } from './endpoints/eurolinesRoutes';
import { blogsEndpoints } from './endpoints/blogs';
import { loyaltyEndpoints } from './endpoints/loyalty';
import { getCookie } from '../utilities/cookies';

export const baseURL = process.env.BASE_URL;
export const recaptchaSiteKey = process.env.RECAPTCHA_SITE_KEY;

export const defaultHeaders = { 'Content-Type': 'application/json', _ga: getCookie('_ga') };
export const formHeaders = { 'Content-Type': 'multipart/form-data' };
axios.defaults.withCredentials = true;
export const endpoints = {
  ...stopsEndpoints,
  ...searchEndpoints,
  ...userEndpoints,
  ...cmsEndpoints,
  ...topRoutesStationsEndpoints,
  ...resetPasswordEndpoints,
  ...contactEndpoints,
  ...bannersEndpoints,
  ...orderEndpoints,
  ...tokenEndpoints,
  ...newsletterEndpoints,
  ...eTicketEndpoints,
  ...paymentEndpoints,
  ...ticketSearchEndpoints,
  ...localesEndpoints,
  ...ticketReturnEndpoints,
  ...eventsEndpoints,
  ...ticketChangeEndpoints,
  ...stationsEndpoints,
  ...eurolinesRoutesEndpoints,
  ...blogsEndpoints,
  ...loyaltyEndpoints,
};
