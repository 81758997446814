import {
  all,
  takeLeading,
  put,
  select,
} from 'redux-saga/effects';
import axios from 'axios';
import { constants } from '../../constants';
import { chunkArray } from '../../../utilities/array';
import { setPolylines } from './RouteMapActions';

export function* getPolylinesSaga(action) {
  const { trackIds } = action.payload;
  const existingPolylines = yield select((state) => state.components.routeMap);

  const responses = yield all(trackIds.map((trackId) => {
    const hasPolylines = existingPolylines.has(trackId);

    if (hasPolylines) return null;

    const options = {
      method: 'get',
      baseURL: process.env.PIKAS_URL,
      url: `${trackId}.txt`,
    };

    return axios(options)
      .then((response) => ({ data: response.data, trackId }))
      .catch((err) => ({ error: err }));
  }));

  const preparedPolylinesChunks = responses.filter(Boolean).map((response) => {
    const { data, trackId } = response;

    if (!data) return null;

    const splitData = data.split(/\r\n/).filter(Boolean);

    if (splitData.length > 1 && !(splitData.length % 3)) {
      return chunkArray(splitData, 3).map((chunk) => ({
        trackId,
        direction: chunk[0],
        polylines: chunk[1],
      }));
    }

    return null;
  }).filter(Boolean);

  const preparedPolylines = [];

  preparedPolylinesChunks
    .forEach((chunk) => chunk.forEach((polylineData) => preparedPolylines.push(polylineData)));

  yield all(preparedPolylines
    .map((polylineData) => {
      const { trackId, direction, polylines } = polylineData;

      return put(setPolylines(trackId, direction, polylines));
    }));
}

export default function* RouteMapSaga() {
  yield all([
    takeLeading(constants.components.routeMap.GET_POLYLINES, getPolylinesSaga),
  ]);
}
