import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router';
import { withTranslation } from 'react-i18next';
import { constants } from '../../../core/modules/constants';
import './ETicket.scss';
import ETicketSidebar from './Partials/Sidebar/ETicketSidebar';
import Services from './Partials/Services/Services';
import FAQ from './Partials/FAQ/FAQ';
import WalletHistory from './Partials/WalletHistory/WalletHistory';
import TopUpHisory from './Partials/TopUpHistory/TopUpHisory';
import { routes } from '../../../core/config/routes';
import Checkout from './Partials/Checkout/Checkout';
import { getPathListRecursively } from '../../../core/utilities/routing';
import { withTracker } from '../../common/Tracker/withTracker';
import { isExpired } from '../../../core/utilities/eTicket';
import { getCurrentTicket } from '../../../core/modules/ETicket/ETicketActions';
import LocalizedSwitch from '../../common/LocalizedSwitch/LocalizedSwitch';
import { endpoints } from '../../../core/config/endpoints';
import Preloader from '../../../components/presentational/Preloader/Preloader';
import ETicketLogin from '../ETicketLogin/ETicketLogin';
import { closeModal } from '../../../core/modules/Components/Modal/ModalActions';

const ETicket = ({ dispatchGetCurrentTicket, dispatchCloseModal, ...props }) => {
  const { isAuthenticated, loading } = props;

  React.useEffect(() => {
    if (isAuthenticated) {
      dispatchGetCurrentTicket();
      dispatchCloseModal();
    }
  }, [isAuthenticated]);

  if (isAuthenticated) {
    const {
      type, cardNr, discount, lastOperationDate, validTill, amount, t,
    } = props;

    return (
      <div className={`eTicket-container ${isAuthenticated && 'has-logged'}`}>
        <ETicketSidebar
          type={type}
          cardNr={cardNr}
          discount={discount}
          lastOperationDate={lastOperationDate}
          validTill={validTill}
          amount={amount}
        />

        <div className="eTicket-page">
          <LocalizedSwitch>
            <Route exact path={routes.eticket.topUpHistory} component={TopUpHisory} />
            {type === 1 && (
              <Route
                exact
                path={routes.eticket.walletHistory}
                component={WalletHistory}
              />
            )}
            {type === 1 && <Route exact path={routes.eticket.faq} component={FAQ} />}

            {getPathListRecursively(routes.eticket.checkout).map(
              (path) => <Route exact path={path} component={Checkout} key={path} />,
            )}

            <Route exact path={routes.eticket.success} component={Services} />

            {isExpired(validTill)
              ? <Redirect to={t(routes.eticket.topUpHistory)} />
              : <Route component={Services} />}
          </LocalizedSwitch>
        </div>
      </div>
    );
  }

  if (loading) return <Preloader fullWidth className="eTicket-preloader" />;

  return (
    <div className="eTicket-container">
      <ETicketLogin />
    </div>
  );
};

ETicket.propTypes = {
  dispatchGetCurrentTicket: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  type: PropTypes.number,
  cardNr: PropTypes.number,
  discount: PropTypes.string,
  lastOperationDate: PropTypes.string,
  validTill: PropTypes.string,
  amount: PropTypes.number,
  loading: PropTypes.bool,
  dispatchCloseModal: PropTypes.func.isRequired,
};

ETicket.defaultProps = {
  isAuthenticated: false,
  type: 1,
  cardNr: 0,
  discount: '',
  lastOperationDate: '',
  validTill: '',
  amount: 0,
  loading: false,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.token.hasIn([constants.token.type.E_TICKET, 'token']),
  type: state.eTicket.getIn(['cardContent', 'type']),
  cardNr: state.eTicket.getIn(['cardContent', 'cardNr']),
  discount: state.eTicket.getIn(['cardContent', 'discount']),
  lastOperationDate: state.eTicket.getIn(['cardContent', 'lastOperationDate']),
  validTill: state.eTicket.getIn(['cardContent', 'validTill']),
  amount: state.eTicket.getIn(['cardContent', 'amount']),
  loading: state.request.getIn([endpoints.GET_CMS_PAGE.name, 'loading']),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchGetCurrentTicket: () => dispatch(getCurrentTicket()),
  dispatchCloseModal: () => dispatch(closeModal()),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withTranslation()((withTracker(ETicket))));
