import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './Contact.scss';
import { withTranslation } from 'react-i18next';
import ContactForm from '../../../components/presentational/Forms/ContactForm/ContactForm';
import { submitContact } from '../../../core/modules/Contact/ContactActions';
import Icon from '../../../components/presentational/Icon/Icon';
import variantConst from '../../../core/config/constants/variantConst/variantConst';

const Contact = ({ submitted, dispatchSubmitContact, t }) => {
  const onSubmit = (values) => {
    dispatchSubmitContact(values);
  };

  if (!submitted) {
    return (
      <div className="content-modal" aria-live="polite">
        <ContactForm onSubmit={onSubmit} />
      </div>
    );
  }

  return (
    <div className="content-modal">
      <div className="contact-form-container">
        <div className="contact-form-success-icon">
          <Icon icon={variantConst.busIcon} />
        </div>

        <div className="contact-form-success-header-row">
          {t('contact_form_success_message')}
        </div>
      </div>
    </div>
  );
};

Contact.propTypes = {
  submitted: PropTypes.bool,
  dispatchSubmitContact: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

Contact.defaultProps = {
  submitted: false,
};

const mapStateToProps = (state) => ({
  submitted: state.contact.get('submitted'),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSubmitContact: (values) => dispatch(submitContact(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Contact));
