import { constants } from '../constants';

export const setToken = (type, token) => ({
  type: constants.token.SET_TOKEN,
  payload: {
    type,
    token,
  },
});

export const setRefreshToken = (type, refreshToken) => ({
  type: constants.token.SET_REFRESH_TOKEN,
  payload: {
    type,
    refreshToken,
  },
});

export const clearTokens = (type) => ({
  type: constants.token.CLEAR_TOKENS,
  payload: { type },
});
