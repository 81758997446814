import {
  put,
  call,
  all,
  takeEvery,
  select,
} from 'redux-saga/effects';
import { constants } from '../constants';
import { startRequest } from '../Request/RequestSaga';
import { endpoints } from '../../config/endpoints';
import { setTopRoutes, setTopStations } from './TopRoutesStationsActions';
import { getObjectNestedData } from '../../utilities/object';

function* getTopRoutesSaga(action) {
  const { locale } = action.payload;
  const existingTopRoutes = yield select((state) => state.data.collections.topRoutesAndStations.getIn(['routes', locale]));

  if (existingTopRoutes && existingTopRoutes.size) return;

  const { response } = yield call(
    startRequest,
    endpoints.GET_TOP_ROUTES.name,
    endpoints.GET_TOP_ROUTES.url.path(100),
  );

  if (response) {
    const data = getObjectNestedData(response, 'data.data');

    yield put(setTopRoutes(data, locale));
  }
}

function* getTopStationsSaga(action) {
  const { orderBy, type } = action.payload;
  const key = `${orderBy || ''}_${type || ''}`;
  const locale = yield select((state) => state.locale);
  const existingTopStations = yield select((state) => state.data.collections.topRoutesAndStations.getIn(['stations', locale, key]));

  if (existingTopStations && existingTopStations.size) return;

  const { response } = yield call(
    startRequest,
    endpoints.GET_TOP_STATIONS.name,
    endpoints.GET_TOP_STATIONS.url.path(orderBy, type),
  );

  if (response) {
    const data = getObjectNestedData(response, 'data.data');

    yield put(setTopStations(data, locale, key));
  }
}

export default function* TopRoutesStationsSaga() {
  yield all([
    takeEvery(constants.data.collections.topRoutes.GET_TOP_ROUTES_LIST, getTopRoutesSaga),
    takeEvery(constants.data.collections.topStations.GET_TOP_STATIONS_LIST, getTopStationsSaga),
  ]);
}
