import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getCMSPage } from '../../../core/modules/CMS/CMSActions';
import ContentBlock from './ContentBlocks/ContentBlock';
import Preloader from '../../../components/presentational/Preloader/Preloader';
import './CMSContent.scss';
import NotFound from '../NotFound/NotFound';
import withDocumentHead from '../../common/DocumentHead/withDocumentHead';

const BenefitsContent = ({
  benefitsName, page, getPageContent, pageContent,
}) => {
  React.useEffect(() => {
    if (page) getPageContent(page);
  }, [page]);

  if (!pageContent) { return null; }

  return (
    <div className={`cms-content-wrapper ${(pageContent && pageContent.loading) ? 'is-loading' : ''}`}>
      {pageContent && (
        <>
          {pageContent.error && <NotFound />}

          {pageContent.loading && <Preloader fullHeight fullWidth />}

          {!pageContent.error && !pageContent.loading && (
            <>
              <div className="cms-content">
                {pageContent.contentBlocks
                  .filter((contentBlock) => contentBlock.title === benefitsName)
                  .map((contentBlock) => (
                    <ContentBlock block={contentBlock} key={contentBlock.id} />
                  ))}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

BenefitsContent.propTypes = {
  getPageContent: PropTypes.func.isRequired,
  page: PropTypes.string,
  benefitsName: PropTypes.string,
  pageContent: PropTypes.shape(),
};

BenefitsContent.defaultProps = {
  pageContent: null,
  page: '',
  benefitsName: '',
};

const mapStateToProps = (state, props) => ({
  pageContent: state.data.collections.cms.getIn(['pages', state.locale, props.page]),
});

const mapDispatchToProps = (dispatch) => ({
  getPageContent: (cmsPage) => dispatch(getCMSPage(cmsPage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(withDocumentHead(BenefitsContent)),
);
