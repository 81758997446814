import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { addPassenger, removePassenger } from '../../../../../core/modules/Order/OrderActions';
import NumberPicker from '../../../../../components/containers/FormElements/NumberPicker/NumberPicker';
import Passenger from '../Passenger/Passenger';
import './Passengers.scss';
import Button from '../../../../../components/presentational/Button/Button';
import Icon from '../../../../../components/presentational/Icon/Icon';

const Passengers = ({
  t, dispatchAddPassenger, dispatchRemovePassenger, order, loading, formProps,
}) => {
  const { hash, items } = order;
  const handleIncrement = () => dispatchAddPassenger(hash);
  const handleDecrement = () => {
    const passengerCount = items.length;
    if (passengerCount > 1) {
      dispatchRemovePassenger(hash, items[passengerCount - 1].id);
    }
  };

  return (
    <>
      <div className="checkout-passengers">
        <h2 className="checkout-passengers__number-picker-title">{t('checkout_passengers_count')}</h2>

        <NumberPicker
          name="checkout-passengers-number-picker"
          onIncrement={handleIncrement}
          onDecrement={handleDecrement}
          value={items.length}
          disabled={loading}
          ariaLabel={t('checkout_passengers_count')}
          addPassenger={t('add_person')}
          removePassenger={t('romove_person')}
        />
      </div>

      {items.map((passenger, passengerIndex) => (
        <Passenger
          key={passenger.id}
          passengerIndex={passengerIndex}
          formProps={formProps}
        />
      ))}

      <Button
        small
        variant="white"
        disabled={loading}
        onClick={handleIncrement}
        className="checkout-item checkout-passengers-add-passenger"
      >
        <Icon icon="plus" />
        {t('checkout_add_passenger')}
      </Button>
    </>
  );
};

Passengers.propTypes = {
  t: PropTypes.func.isRequired,
  formProps: PropTypes.shape().isRequired,
  dispatchAddPassenger: PropTypes.func.isRequired,
  dispatchRemovePassenger: PropTypes.func.isRequired,
  order: PropTypes.shape().isRequired,
  loading: PropTypes.bool,
};

Passengers.defaultProps = {
  loading: false,
};

const mapStateToProps = (state) => ({
  order: state.order.get('data'),
  loading: state.order.getIn(['status', 'loading']),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchAddPassenger: (orderHash) => dispatch(addPassenger(orderHash)),
  dispatchRemovePassenger: (orderHash, passengerId) => (
    dispatch(removePassenger(orderHash, passengerId))
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Passengers));
