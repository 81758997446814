/* eslint-disable no-console */

import React from 'react';
import Button from '../../../components/presentational/Button/Button';
import Input from '../../../components/presentational/FormElements/Input/Input';
import Checkbox from '../../../components/presentational/FormElements/Checkbox/Checkbox';
import RadioGroup
from '../../../components/presentational/FormElements/Radio/RadioGroup/RadioGroup';
import swed from '../../../../assets/images/oxidswedbank.png';
import sb from '../../../../assets/images/siauliubankas.png';
import Select from '../../../components/containers/FormElements/Select/Select';
import './KitchenSink.scss';
import Timepicker from '../../../components/containers/Timepicker/Timepicker';
import Datepicker from '../../../components/containers/Datepicker/Datepicker';
import Autocomplete from '../../../components/containers/FormElements/RouteAutocomplete/RouteAutocomplete';
import Alert from '../../../components/presentational/Alert/Alert';
import NumberPicker from '../../../components/containers/FormElements/NumberPicker/NumberPicker';
import { withTracker } from '../Tracker/withTracker';
import Icon from '../../../components/presentational/Icon/Icon';

const radiosWithImages = [
  {
    title: swed,
    id: 'radio-swed',
  },
  {
    title: sb,
    id: 'radio-sb',
  },
];
const selectOptions = [
  { value: 'no-discount', label: 'Be nuolaidos' },
  { value: '50-discount', label: '50% valst. komp. nuolaida' },
  { value: '80-discount', label: '80% valst. komp. nuolaida' },
];

class AutocompleteExample extends React.Component {
  constructor() {
    super();

    this.state = {
      value: '',
    };
  }

  handleChange = (value) => {
    this.setState({ value });
  };

  render() {
    const { value } = this.state; // eslint-disable-line

    return (
      <Autocomplete
        name="example-input"
        label="Važiuosiu iš"
        id="example-input-4"
        suggestions={[
          {
            name: 'C',
            year: 1972,
          },
          {
            name: 'Elm',
            year: 2012,
          },
        ]}
      />
    );
  }
}

const KitchenSink = () => (
  <div className="kitchen-sink" style={{ padding: '20px' }}>
    <div className="kitchen-sink-item">
      <Button onClick={() => console.log('testing')}>
        Pirkti
      </Button>

      <Button variant="brand" onClick={() => console.log('testing')}>
        Plačiau
      </Button>

      <Button variant="danger" onClick={() => console.log('testing')}>
        Plačiau
      </Button>

      <Button variant="inverted" onClick={() => console.log('testing')}>
        Plačiau
      </Button>

      <Button variant="white" onClick={() => console.log('testing')}>
        Plačiau
      </Button>

      <Button variant="white" small onClick={() => console.log('testing')}>
        Plačiau
      </Button>

      <Button big variant="danger" onClick={() => console.log('testing')}>
        Ieškoti kelionės
      </Button>

      <Button
        variant="danger"
        onClick={() => console.log('testing')}
        icon="search"
      />

      <Button
        big
        variant="danger"
        onClick={() => console.log('testing')}
        icon="search"
      />

      <Button
        big
        variant="danger"
        onClick={() => console.log('testing')}
        disabled
      >
        Ieškoti kelionės
      </Button>

      <Button
        big
        variant="danger"
        onClick={() => console.log('testing')}
        disabled
        icon="search"
      >
        Ieškoti kelionės
      </Button>

      <Button
        big
        variant="danger"
        onClick={() => console.log('testing')}
        loading
      >
        Ieškoti kelionės
      </Button>

      <Button
        big
        variant="danger"
        onClick={() => console.log('testing')}
        icon="search"
        loading
      />

      <Button
        route="/route"
        variant="danger"
        onClick={() => console.log('testing')}
      >
        With route
      </Button>
    </div>

    <div className="kitchen-sink-item">
      <Input
        name="example-input"
        label="Važiuosiu iš"
        id="example-input-1"
        value="Vilnius"
        onChange={() => console.log('on change')}
        placeholder="Pvz., Kaunas, Šilalė, Berlynas..."
      />

      <Input
        name="example-input"
        label="Važiuosiu iš"
        id="example-input-2"
        value="Vilnius"
        error="Example error."
        onChange={() => console.log('on change')}
        placeholder="Pvz., Kaunas, Šilalė, Berlynas..."
      />

      <Input
        name="example-input"
        label="Važiuosiu iš"
        id="example-input-3"
        value="Vilnius"
        onChange={() => console.log('on change')}
        placeholder="Pvz., Kaunas, Šilalė, Berlynas..."
        icon={(
          <button className="input-icon__button" onClick={() => null}>
            <Icon icon="view" /> Rodyti
          </button>
        )}
        required
      />

      <AutocompleteExample />

      <Select
        name="example-select"
        onChange={() => console.log('select onChange')}
        options={selectOptions}
      />

      <Datepicker
        onChange={(date) => console.log(date)}
        locale="lt"
        name="test datepicker"
      />

      <div className="kitchen-sink-timepicker">
        <Timepicker
          onChange={(date) => console.log(date)}
          value="10:00"
          label="timepicker label"
        />
      </div>

      <Datepicker
        onChange={(date) => console.log(date)}
        timepicker={(
          <Timepicker
            onChange={(date) => console.log(date)}
            value="10:00"
          />
        )}
      />
    </div>

    <div className="kitchen-sink-item">
      <Alert
        heading="Klaida!"
        variant="danger"
      >
        Neteisingas el. pašto adresas, patikslinkite.
      </Alert>

      <Alert
        heading="Klaida!"
        variant="danger"
        arrow="top"
      >
        Neteisingas el. pašto adresas, patikslinkite.
      </Alert>

      <Alert
        heading="Įspėjimas"
        variant="warning"
        arrow="right"
      >
        Neteisingas el. pašto adresas, patikslinkite.
      </Alert>

      <Alert
        heading="Sėkmė"
        variant="success"
        arrow="bottom"
      >
        Neteisingas el. pašto adresas, patikslinkite.
      </Alert>

      <Alert
        heading="Pranešimas"
        variant="info"
        arrow="left"
      >
        Neteisingas el. pašto adresas, patikslinkite.
      </Alert>
    </div>

    <div className="kitchen-sink-item">
      <Checkbox
        id="example-checkbox-1"
        name="example-checkbox"
        onChange={() => null}
        value
        label="tik internetu parduodamus bilietus"
      />

      <Checkbox
        id="example-checkbox-1"
        name="example-checkbox"
        onChange={() => null}
        label="tik internetu parduodamus bilietus"
        required
      />

      <Checkbox
        id="example-checkbox-1"
        name="example-checkbox"
        onChange={() => null}
        error="test error"
        label="tik internetu parduodamus bilietus"
      />
    </div>

    <div className="kitchen-sink-item">
      <RadioGroup
        onChange={() => console.log('radio button changed')}
        name="radio-group-1"
        value="radio-id-2"
        render={(radio) => (
          <>
            {
              [{ title: 'testing 1', id: 'radio-id-1' }, { title: 'testing 2', id: 'radio-id-2' }]
                .map(({ title, id }) => (
                  <React.Fragment key={id}>
                    {radio(id, title)}
                  </React.Fragment>
                ))
            }
          </>
        )}
      />

      <RadioGroup
        onChange={() => console.log('radio button changed')}
        name="radio-group-1"
        value="radio-id-2"
        error="test error"
        render={(radio) => (
          <>
            {
              [{ title: 'testing 1', id: 'radio-id-1' }, { title: 'testing 2', id: 'radio-id-2' }]
                .map(({ title, id }) => (
                  <React.Fragment key={id}>
                    {radio(id, title)}
                  </React.Fragment>
                ))
            }
          </>
        )}
      />

      <RadioGroup
        onChange={() => console.log('radio button changed')}
        name="radio-group-1"
        value="radio-sb"
        render={(radio) => (
          <>
            {radiosWithImages.map(({ title, id }) => (
              <React.Fragment key={id}>
                {radio(id, title)}
              </React.Fragment>
            ))}
          </>
        )}
      />
    </div>

    <div className="kitchen-sink-item">
      <NumberPicker
        name=""
        onIncrement={() => null}
        onDecrement={() => null}
        value={1}
      />
    </div>
  </div>
);

export default withTracker(KitchenSink);
