import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import DateSlider from '../../../components/presentational/DateSlider/DateSlider';
import { breakpointSet } from '../../../core/utilities/responsive';
import Form from './Partials/Form/Form';
import { endpoints } from '../../../core/config/endpoints';
import SearchResultsList from './Partials/SearchResultsList/SearchResultsList';
import {
  clearSearchValues,
  setSearchValues,
  submitSearch,
  submitSearchBySlug,
} from '../../../core/modules/Search/SearchActions';
import { parseQuery } from '../../../core/utilities/query';
import Sidebar from './Partials/Sidebar/Sidebar';
import { getBannerByZone } from '../../../core/modules/Data/Collections/Banners/BannersActions';
import './SearchResults-VARIANT.scss';
import RoundTripTicket from './Partials/RoundTripTicket/RoundTripTicket';
import Button from '../../../components/presentational/Button/Button';
import { removeRoundTripTicket } from '../../../core/modules/RoundTripTickets/RoundTripTicketsActions';
import { initializeOrder } from '../../../core/modules/Order/OrderActions';
import { tripDirections, ticketTypes } from '../../../core/config/constants/tickets';
import withDocumentHead from '../../common/DocumentHead/withDocumentHead';
import { withTracker } from '../../common/Tracker/withTracker';
import { formatDate, formatTime } from '../../../core/utilities/date';
import NotFound from '../NotFound/NotFound';
import { getObjectNestedData } from '../../../core/utilities/object';
import Icon from '../../../components/presentational/Icon/Icon';
import ButtonClose from '../../../components/presentational/ButtonClose/ButtonClose';
import variantConst from '../../../core/config/constants/variantConst/variantConst';
// eslint-disable-next-line import/extensions
import Loyalty from './Partials/Loyalty/Loyalty-VARIANT.js';

class SearchResults extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFormVisible: false,
      tablet: breakpointSet('xs', 'sm', 'md'),
      mobile: breakpointSet('xs', 'sm'),
      footerBannerSticky: false,
      footerBannerStickyDisabled: false,
      routesDate: null,
    };

    this.footerBannerWrap = React.createRef();
    this.searchResults = React.createRef();
  }

  componentDidMount() {
    const appContainer = document.querySelector('.app-container');
    const {
      onInitiateSearch, onSetSearchValues, match, onInitiateSearchBySlug,
    } = this.props;
    const { slug } = match.params;

    window.addEventListener('resize', this.handleResize);
    appContainer.addEventListener('scroll', this.handleScroll);

    this.getBanners();

    if (slug) {
      onInitiateSearchBySlug(slug);
      return;
    }

    const searchValues = this.getSearchValues();
    onSetSearchValues(searchValues);
    onInitiateSearch(searchValues);
  }

  componentDidUpdate(prevProps) {
    const {
      t,
      match,
      ticketForward,
      onInitiateSearch,
      setDocumentTitle,
      setDocumentDescription,
      onInitiateSearchBySlug,
      routes,
    } = this.props;
    const { slug } = match.params;
    const { from, to } = slug ? this.getSlugSearchValues(slug) : this.getSearchValues();
    const travelRoute = `${from.name} - ${to.name}`;

    this.setStickyFooterBanner();

    if (t) {
      setDocumentTitle(`${t(variantConst.documentTitle)} ${t(variantConst.documentTitleSearch, { route: travelRoute || '' })}`);
      setDocumentDescription(`${t(variantConst.documentDescriptionSearch, { route: travelRoute || '' })}`);
    }

    if (prevProps.ticketForward !== ticketForward) {
      onInitiateSearch(this.getSearchValues());
      return;
    }

    if (slug && getObjectNestedData(prevProps, 'match.params.slug') !== slug) {
      onInitiateSearchBySlug(slug);
    }

    if (prevProps.routes !== routes && routes.size > 0) {
      const departureDate = routes.get(0).legs[0].departureTime.date;

      if (!departureDate) return;
      this.setState({ routesDate: departureDate });
    }
  }

  componentWillUnmount() {
    const appContainer = document.querySelector('.app-container');
    const { onClearSearchValues, onRemoveTicket } = this.props;

    onClearSearchValues();

    window.removeEventListener('resize', this.handleResize);
    appContainer.removeEventListener('scroll', this.handleScroll);

    tripDirections.forEach((type) => onRemoveTicket(type));
  }

  getBanners = () => {
    const {
      onGetHeaderBanner,
      onGetSidebarBanner,
      onGetFooterBanner,
    } = this.props;
    const searchValues = this.getSearchValues();

    onGetFooterBanner(searchValues);
    onGetHeaderBanner(searchValues);
    onGetSidebarBanner(searchValues);
  };

  setStickyFooterBanner = () => {
    const { footerBanner } = this.props;
    const { footerBannerSticky, footerBannerStickyDisabled } = this.state;

    if (!footerBannerStickyDisabled
      && !footerBannerSticky
      && footerBanner
      && this.footerBannerWrap
      && this.searchResults
      && this.searchResults.current
    ) {
      const { current: searchResults } = this.searchResults;
      const containerFromTop = Math.abs(searchResults.getBoundingClientRect().bottom);

      if (window.innerHeight <= containerFromTop) {
        this.setState({ footerBannerSticky: true });
      }
    }
  };

  getSearchValues = () => {
    const { searchValues, location } = this.props;

    if (Object.keys(searchValues).length) return searchValues;

    const parsedQuery = parseQuery(location.search);
    const {
      from,
      to,
      fromStop,
      toStop,
      onlyDirect,
      onlyInternet,
      onlyBikes,
      isForward,
      departureDate,
      departureTime,
      returnDate,
      returnTime,
    } = parsedQuery;

    const departureDatetime = `${departureDate} ${departureTime}`;
    const returnDatetime = `${returnDate} ${returnTime}`;
    const datesMismatch = departureDatetime > returnDatetime;

    return {
      ...parsedQuery,
      isForward: isForward !== 'false',
      from: { number: from, name: fromStop },
      to: { number: to, name: toStop },
      onlyDirect: onlyDirect === 'true',
      onlyInternet: onlyInternet === 'true',
      onlyBikes: onlyBikes === 'true',
      returnDate: datesMismatch ? departureDate : returnDate,
      returnTime: datesMismatch ? departureTime : returnTime,
    };
  };

  getSlugSearchValues = (slug) => {
    const { slugs } = this.props;
    const slugData = slugs.get(slug);

    return {
      ...(
        slugData || {
          from: {},
          to: {},
        }
      ),
      isForward: true,
      onlyDirect: false,
      onlyInternet: false,
      onlyBikes: false,
      departureDate: formatDate(new Date()),
      departureTime: formatTime(new Date()),
    };
  };

  handleScroll = () => {
    const { footerBannerSticky, footerBannerStickyDisabled } = this.state;

    if (this.footerBannerWrap && this.searchResults && !footerBannerStickyDisabled) {
      const { current: searchResults } = this.searchResults;

      if (searchResults) {
        const containerFromTop = searchResults.getBoundingClientRect().bottom;

        if (window.innerHeight >= containerFromTop) {
          if (footerBannerSticky) this.setState({ footerBannerSticky: false });

          return;
        }

        if (window.innerHeight <= containerFromTop) {
          if (!footerBannerSticky) this.setState({ footerBannerSticky: true });
        }
      }
    }
  };

  handleResize = () => {
    const { mobile, tablet } = this.state;
    const newMobileValues = breakpointSet('xs', 'sm');
    const newTabletValues = breakpointSet('xs', 'sm', 'md');

    if (mobile !== newMobileValues) {
      this.setState({
        mobile: newMobileValues,
      });
    }

    if (tablet !== newTabletValues) {
      this.setState({
        tablet: newTabletValues,
      });
    }
  };

  handleFormSubmit = (formData) => {
    const {
      onInitiateSearch,
      onSetSearchValues,
      onGetSidebarBanner,
      onGetFooterBanner,
      onGetHeaderBanner,
    } = this.props;

    onInitiateSearch(formData);
    onSetSearchValues(formData);
    onGetFooterBanner(formData);
    onGetHeaderBanner(formData);
    onGetSidebarBanner(formData);
  };

  disableFooterBanner = () => {
    this.setState({
      footerBannerSticky: false,
      footerBannerStickyDisabled: true,
    });
  };

  initiateRoundTripOrder = () => {
    const { ticketForward, ticketBack, onInitializeOrder } = this.props;

    const data = Object.entries({ ticketForward, ticketBack }).map((entry, index) => {
      const { steps, ticketType } = entry[1];

      return steps.map((step) => {
        if (!step.sellable) return null;

        const { sellData } = step;

        if (!sellData) return null;

        const {
          actualTripId,
          fromTripStopId,
          toTripStopId,
          ref,
        } = sellData;

        return {
          direction: index > 0 ? 'backward' : 'forward',
          tripId: ref,
          actualTripId,
          fromTripStopId,
          toTripStopId,
          ticketType: (
            ticketType === ticketTypes.promo && sellData.promoAvailable
          ) ? ticketTypes.promo : ticketTypes.regular,
        };
      }).filter(Boolean);
    });

    onInitializeOrder([].concat(...data));
  };

  // TODO: switch empty routes checks
  render() {
    const {
      t,
      submittingSearch,
      submittingSearchBySlug,
      routes,
      headerBanner,
      footerBanner,
      ticketForward,
      ticketBack,
      onRemoveTicket,
      match,
      slugs,
      showDates,
    } = this.props;
    const {
      footerBannerSticky, isFormVisible, mobile, tablet, footerBannerStickyDisabled,
    } = this.state;
    const { slug } = match.params;
    const submitting = submittingSearch || submittingSearchBySlug;
    const slugData = slugs.get(slug);

    if (slug && !submitting && slugData === null) return <NotFound />;

    const searchValues = slug ? this.getSlugSearchValues(slug) : this.getSearchValues();
    const { from, to, isForward } = searchValues;
    const { current: footerBannerWrap } = this.footerBannerWrap;
    const { routesDate } = this.state;
    return (
      <>
        <Form
          searchValues={searchValues}
          isFormVisible={isFormVisible}
          tablet={tablet}
          mobile={mobile}
          toggleForm={() => this.setState({ isFormVisible: !isFormVisible })}
          onSubmit={this.handleFormSubmit}
          onRemoveTickets={(type) => onRemoveTicket(type)}
          submitting={submitting}
        />

        <div className="search-results">
          <Sidebar
            searchValues={searchValues}
            onSubmit={this.handleFormSubmit}
            submitting={submitting}
          />

          <div
            aria-live="polite"
            ref={this.searchResults}
            className="search-results-list-wrap"
            style={{
              paddingBottom: (footerBannerWrap && footerBannerSticky)
                ? footerBannerWrap.offsetHeight
                : '',
            }}
          >
            {headerBanner && (
              <div className="search-results-header-banner">
                <a
                  href={headerBanner.link}
                  target={headerBanner.linkTarget}
                  aria-label={headerBanner.title}
                >
                  <img src={mobile ? headerBanner.imageMobile : headerBanner.imageDesktop} alt="" />
                </a>
              </div>
            )}

            <Loyalty />

            <div className="search-results-list__title">
              {!isForward || isForward === 'false' ? `${from.name} - ${to.name}` : t('common_title')}
            </div>

            <h1 className="search-results-list__route title-primary">
              {submittingSearchBySlug && ' '}

              {!submittingSearchBySlug && (
                !isForward || isForward === 'false' ? t('common_subtitle') : `${from.name} - ${to.name}`
              )}
            </h1>

            <>
              {(!isForward || isForward === 'false') && (
                <div className="search-results-list-tickets">
                  <RoundTripTicket
                    type="forward"
                    ticket={ticketForward}
                    removeTicket={() => onRemoveTicket('forward')}
                  />

                  <RoundTripTicket
                    type="back"
                    ticket={ticketBack}
                    removeTicket={() => onRemoveTicket('backward')}
                  />
                </div>
              )}
              {(isForward || isForward === 'true' || !ticketForward) && (
                <DateSlider
                  endDate={10}
                  date={routesDate || searchValues.departureDate}
                  tablet={tablet}
                  mobile={mobile}
                  onChangeSelectedDate={(newDate) => (
                    this.handleFormSubmit({
                      ...searchValues,
                      departureDate: formatDate(newDate),
                      departureDateObj: newDate,
                      departureTime: '00:00',
                    })
                  )}
                />
              )}
              {(ticketForward && !ticketBack) && (
                <DateSlider
                  endDate={10}
                  date={routesDate || searchValues.returnDate}
                  tablet={tablet}
                  mobile={mobile}
                  onChangeSelectedDate={(newDate) => (
                    this.handleFormSubmit({
                      ...searchValues,
                      returnDate: formatDate(newDate),
                      returnDateObj: newDate,
                      returnTime: '00:00',
                    })
                  )}
                />
              )}

              {((!isForward || isForward === 'false') && ticketForward && ticketBack) ? (
                <div className="search-results-list__action">
                  <Button
                    variant="brand"
                    big
                    onClick={this.initiateRoundTripOrder}
                  >
                    {t('common_button_buy')}
                  </Button>
                </div>
              ) : (
                <SearchResultsList
                  loading={submitting}
                  routes={Array.from(routes)}
                  searchValues={searchValues}
                  showDates={showDates}
                />
              )}

              {footerBanner && !submitting && !footerBannerStickyDisabled && (
                <div
                  ref={this.footerBannerWrap}
                  className={`search-results-footer-banner ${footerBannerSticky ? 'is-sticky' : ''}`}
                >
                  <a
                    href={footerBanner.link}
                    target={footerBanner.linkTarget}
                    aria-label={footerBanner.title}
                  >
                    {footerBannerSticky && (
                      <ButtonClose
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          this.disableFooterBanner();
                        }}
                        className="search-results-footer-banner__btn"
                      />
                    )}

                    <img src={mobile ? footerBanner.imageMobile : footerBanner.imageDesktop} alt="" />
                  </a>
                </div>
              )}
            </>

            {(!routes || !routes.size) && !submitting && (
              <>
                <div className="divider" />

                <div className="search-results-empty-wrap">
                  <Icon icon="sad-bus" />

                  <span>{t('ticket_no_routes')}</span>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

SearchResults.propTypes = {
  t: PropTypes.func.isRequired,
  onInitiateSearch: PropTypes.func.isRequired,
  onInitiateSearchBySlug: PropTypes.func.isRequired,
  location: PropTypes.shape().isRequired,
  onSetSearchValues: PropTypes.func.isRequired,
  onClearSearchValues: PropTypes.func.isRequired,
  onGetHeaderBanner: PropTypes.func.isRequired,
  onGetFooterBanner: PropTypes.func.isRequired,
  onGetSidebarBanner: PropTypes.func.isRequired,
  searchValues: PropTypes.shape(),
  slugs: PropTypes.instanceOf(Map),
  submittingSearch: PropTypes.bool,
  submittingSearchBySlug: PropTypes.bool,
  showDates: PropTypes.bool,
  routes: PropTypes.shape(),
  headerBanner: PropTypes.shape(),
  footerBanner: PropTypes.shape(),
  ticketForward: PropTypes.shape(),
  ticketBack: PropTypes.shape(),
  onRemoveTicket: PropTypes.func.isRequired,
  onInitializeOrder: PropTypes.func.isRequired,
  match: PropTypes.shape().isRequired,
  setDocumentTitle: PropTypes.func.isRequired,
  setDocumentDescription: PropTypes.func.isRequired,
};

SearchResults.defaultProps = {
  searchValues: {},
  slugs: Map({}),
  submittingSearch: false,
  submittingSearchBySlug: false,
  showDates: false,
  routes: null,
  headerBanner: null,
  footerBanner: null,
  ticketForward: null,
  ticketBack: null,
};

const mapStateToProps = (state) => ({
  searchValues: state.data.search.get('values'),
  showDates: state.data.search.get('showDates'),
  slugs: state.data.search.get('slugs'),
  submittingSearch: state.request.getIn([endpoints.INITIATE_SEARCH.name, 'loading']),
  submittingSearchBySlug: state.request.getIn([endpoints.INITIATE_SEARCH_BY_SLUG.name, 'loading']),
  routes: state.data.collections.routes,
  stops: state.data.collections.stops,
  headerBanner: state.data.collections.banners.get('list_header'),
  footerBanner: state.data.collections.banners.get('list_footer'),
  ticketForward: state.roundTripTickets.get('forward'),
  ticketBack: state.roundTripTickets.get('backward'),
});

const mapDispatchToProps = (dispatch) => ({
  onInitiateSearch: (formData) => dispatch(submitSearch(formData)),
  onInitiateSearchBySlug: (slug) => dispatch(submitSearchBySlug(slug)),
  onSetSearchValues: (formData) => dispatch(setSearchValues(formData)),
  onClearSearchValues: () => dispatch(clearSearchValues()),
  onGetHeaderBanner: (searchValues) => dispatch(getBannerByZone('list_header', searchValues)),
  onGetFooterBanner: (searchValues) => dispatch(getBannerByZone('list_footer', searchValues)),
  onGetSidebarBanner: (searchValues) => dispatch(getBannerByZone('list_sidebar', searchValues)),
  onRemoveTicket: (type) => dispatch(removeRoundTripTicket(type)),
  onInitializeOrder: (orderInfo) => dispatch(initializeOrder(orderInfo)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(withDocumentHead(withTracker(SearchResults))));
