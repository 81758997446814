import {
  all,
  call,
  takeEvery,
  put, select,
} from 'redux-saga/effects';
import { constants } from '../constants';
import { startRequest } from '../Request/RequestSaga';
import { endpoints } from '../../config/endpoints';
import { getObjectNestedData } from '../../utilities/object';
import { setEurolinesTopRoute, setEurolinesTopRoutes } from './EurolinesTopRoutesActions';

function* getRoutes() {
  const { response } = yield call(
    startRequest,
    endpoints.GET_EUROLINES_ROUTES.name,
    endpoints.GET_EUROLINES_ROUTES.url.path(),
  );

  if (response) {
    const data = getObjectNestedData(response, 'data.data');

    yield put(setEurolinesTopRoutes(data));
  }
}

function* getRoute(action) {
  const { slug } = action.payload;
  const eurolinesTopRouteFromState = yield select(
    (state) => state.data.collections.eurolinesTopRoutes.get(slug),
  );

  if (eurolinesTopRouteFromState) return;

  const { response } = yield call(
    startRequest,
    endpoints.GET_EUROLINES_ROUTE.name,
    endpoints.GET_EUROLINES_ROUTE.url.path(slug),
  );

  if (response) {
    const data = getObjectNestedData(response, 'data.data');

    yield put(setEurolinesTopRoute(slug, data));
  }
}

export default function* EurolinesTopRoutesSaga() {
  yield all([
    takeEvery(constants.eurolinesRoutes.GET_ROUTES, getRoutes),
    takeEvery(constants.eurolinesRoutes.GET_ROUTE, getRoute),
  ]);
}
