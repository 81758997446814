import React from 'react';
import { NavLink, Link, matchPath } from 'react-router-dom';
import PropTypes from 'prop-types';
import Preloader from '../Preloader/Preloader';
import './Sidebar.scss';
import Icon from '../Icon/Icon';

const Sidebar = ({ menu, children }) => {
  const scrollPlaceholderRef = React.useRef(null);
  const [menuIsOpen, setMenuState] = React.useState(false);
  const [bannerIsSticky, setBannerSticky] = React.useState(false);
  const toggleSidebarMenu = () => setMenuState(!menuIsOpen);
  const closeSidebarMenu = () => menuIsOpen && toggleSidebarMenu();
  const displayBanner = menu && menu.banner && menu.banner.imageDesktop;

  const handleScroll = (e) => {
    const appContainer = e.target;

    if (displayBanner && scrollPlaceholderRef) {
      const { current: scrollPlaceholderEl } = scrollPlaceholderRef;

      if (!scrollPlaceholderEl) return;

      const originalScrollPosition = scrollPlaceholderEl.offsetTop;
      const currentScroll = appContainer.scrollTop;

      if (currentScroll >= originalScrollPosition) {
        if (!bannerIsSticky) setBannerSticky(true);
        return;
      }

      if (currentScroll < originalScrollPosition) {
        if (bannerIsSticky) setBannerSticky(false);
      }
    }
  };
  React.useEffect(() => {
    const appContainer = document.querySelector('.app-container');
    appContainer.addEventListener('scroll', handleScroll);

    return function cleanup() {
      appContainer.removeEventListener('scroll', handleScroll);
    };
  }, [menu, bannerIsSticky]);

  if (!menu) return null;

  return (
    <div className="sidebar">
      <div className="sidebar-wrapped">
        <div className="sidebar-arrow" />

        {(menu.loading || menu.icon || menu.title) && (
          <div className="sidebar-header">
            {menu.loading
              ? <Preloader fullHeight fullWidth />
              : menu.icon && (
                <div className="sidebar-header-icon sidebar-header--spacing">
                  <Icon icon={menu.icon} />
                </div>
              )}

            {menu.title && (menu.link
              ? <Link className="sidebar-header__title sidebar-header--spacing" to={menu.link}>{menu.title}</Link>
              : <div className="sidebar-header__title sidebar-header--spacing">{menu.title}</div>
            )}
          </div>
        )}

        {menu.items && (
          <>
            <div className={`sidebar-menu ${menuIsOpen ? 'is-open' : ''}`}>
              {menu.items.map((item) => {
                const {
                  id,
                  props,
                  title,
                  onClick,
                  icon,
                  slug,
                  to,
                  disabled,
                  activeRoutes,
                } = item;

                if (props) return <div {...props} />;

                if (!title) return null;

                if (onClick) {
                  return (
                    <div
                      key={id}
                      className="sidebar-link"
                      onClick={onClick}
                      role="button"
                      tabIndex={0}
                    >
                      {icon && <span className="sidebar-left-icon"><Icon icon={icon} /></span>}

                      <span className="sidebar-item-title">{title}</span>

                      <Icon icon="long-arrow-right" className="active-icon" />
                    </div>
                  );
                }

                if (slug || to) {
                  const LinkContainer = disabled ? 'div' : NavLink;
                  const linkProps = disabled
                    ? {}
                    : {
                      to: to || `/${slug}`,
                      exact: true,
                      activeClassName: 'is-active',
                      onClick: closeSidebarMenu,
                      ...activeRoutes
                        ? {
                          isActive: (match, location) => (
                            matchPath(location.pathname, {
                              path: activeRoutes,
                              exact: true,
                            })
                          ),
                        }
                        : {},
                    };

                  return (
                    <LinkContainer
                      key={id}
                      {...linkProps}
                      className={`sidebar-link ${disabled ? 'is-disabled' : ''}`}
                    >
                      {icon && <span className="sidebar-left-icon"><Icon icon={icon} /></span>}

                      <span className="sidebar-item-title">{title}</span>

                      <Icon icon="long-arrow-right" className="active-icon" />
                    </LinkContainer>
                  );
                }

                return null;
              })}
            </div>
          </>
        )}

        <div className={`sidebar-menu-children ${menuIsOpen ? 'is-open' : ''}`}>
          {children}
        </div>

        {menu.items && (
          <button className="sidebar-menu-switch" onClick={toggleSidebarMenu}>
            <Icon icon={`chevron-${menuIsOpen ? 'up' : 'down'}`} />
          </button>
        )}

        {displayBanner && (
          <>
            <div ref={scrollPlaceholderRef} />

            <div className={`sidebar-banner ${bannerIsSticky ? 'is-sticky' : ''}`}>
              <a href={menu.banner.link} target={menu.banner.linkTarget}>
                <img src={menu.banner.imageDesktop} alt="Search results banner" />
              </a>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  menu: PropTypes.shape(),
  children: PropTypes.node,
};

Sidebar.defaultProps = {
  menu: null,
  children: null,
};

export default Sidebar;
