import React from 'react';
import PropTypes from 'prop-types';
import preloader from '../../../../assets/images/preloader.gif';
import './Preloader.scss';

const PreloaderAb = ({ fullWidth, fullHeight, className }) => {
  const combinedClasses = `preloader ${fullWidth ? 'is-full-width' : ''} ${fullHeight ? 'is-full-height' : ''} ${className}`;

  return (
    <div className={`${combinedClasses} is-dissability`} aria-live="polite">
      <img className="preloader__spinner" src={preloader} alt="Loading content" />
    </div>
  );
};

PreloaderAb.propTypes = {
  fullWidth: PropTypes.bool,
  fullHeight: PropTypes.bool,
  className: PropTypes.string,
};

PreloaderAb.defaultProps = {
  fullWidth: false,
  fullHeight: false,
  className: '',
};

export default PreloaderAb;
