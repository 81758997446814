import React from 'react';
import PropTypes from 'prop-types';
import './Dropdown.scss';

const Dropdown = ({
  onShow, onHide, trigger, content, showOnHover, ...rest
}) => {
  const { className: triggerClassName, ...triggerProps } = trigger;
  const { className: contentClassName, ...contentProps } = content;
  const fragmentRef = React.useRef(null);
  const dropdownRef = React.useRef(null);
  const [active, setActive] = React.useState(false);
  
  const hide = () => {
    setActive(false);
    if (onHide) onHide();
  };
  
  const show = () => {
    setActive(true);
    if (onShow) onShow();
  };

  const toggle = () => (active ? hide() : show());

  const hoverProps = showOnHover
    ? {
      onMouseEnter: show,
      onMouseLeave: hide,
    }
    : {};

  const onWindowClick = (event) => {
    const dropdownElement = fragmentRef.current;

    if (event.target !== dropdownElement && !dropdownElement.contains(event.target) && active) {
      hide();
    }
  };

  const onTriggerKeyDown = (event) => {
    if (event.key === 'Enter') {
      toggle();
    }
  };

  React.useEffect(() => {
    window.addEventListener('click', onWindowClick);
    window.addEventListener('touchstart', onWindowClick);

    return function cleanup() {
      window.removeEventListener('click', onWindowClick);
      window.removeEventListener('touchstart', onWindowClick);
    };
  }, [active, fragmentRef, onShow, onHide]);

  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    if (active) {
      const focusableElements = dropdownRef.current.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
      );
      const firstFocusableElement = focusableElements[0];
      const lastFocusableElement = focusableElements[focusableElements.length - 1];
  
      const handleKeyDown = (event) => {
        const checkFocusableElement = () => {
          if (document.activeElement === firstFocusableElement) {
            event.preventDefault();
            lastFocusableElement.focus();
          } 
          
          if (document.activeElement === lastFocusableElement) {
            event.preventDefault();
            firstFocusableElement.focus();
          }
        };
        
        if (event.key === 'Tab' && event.shiftKey) {
          checkFocusableElement();
        } 
        
        if (event.key === 'Escape') {
          hide();
          const triggerElement = fragmentRef.current.querySelector('.dropdown-trigger');
          triggerElement.focus();
        }
      };
  
      dropdownRef.current.addEventListener('keydown', handleKeyDown);
  
      const firstChildElement = dropdownRef.current.firstChild;
      if (firstChildElement) {
        firstChildElement.focus();
      }

      const trapFocus = (event) => {
        const dropdownElement = dropdownRef.current;
        if (event.target !== dropdownElement && !dropdownElement.contains(event.target) && active) {
          firstFocusableElement.focus();
        }
      };
  
      window.addEventListener('focusin', trapFocus);
  
      return function cleanup() {
        dropdownRef.current.removeEventListener('keydown', handleKeyDown);
        window.removeEventListener('focusin', trapFocus);
        const triggerElement = fragmentRef.current.querySelector('.dropdown-trigger');
        triggerElement.focus();
      };
    }
  }, [active]);

  return (
    <div
      {...rest}
      className={`${rest.className || ''} dropdown ${active ? 'is-active' : ''}`}
      ref={fragmentRef}
      {...hoverProps}
    >
      {trigger && (
        <div
          {...triggerProps}
          className={`dropdown-trigger ${showOnHover ? 'hoverable' : ''} ${triggerClassName || ''}`}
          onClick={toggle}
          onKeyDown={onTriggerKeyDown}
          role="button"
          tabIndex={0}
          aria-expanded={active}
        />
      )}      

      {content && (
        <div
          {...contentProps}
          className={`dropdown-content ${contentClassName || ''}`}
          ref={dropdownRef}
        />
      )}
    </div>
  );
};

Dropdown.propTypes = {
  showOnHover: PropTypes.bool,
  onShow: PropTypes.func,
  onHide: PropTypes.func,
  trigger: PropTypes.shape({
    children: PropTypes.node,
    className: PropTypes.string,
  }),
  content: PropTypes.shape({
    children: PropTypes.node,
    className: PropTypes.string,
  }),
};

Dropdown.defaultProps = {
  showOnHover: false,
  onShow: null,
  onHide: null,
  trigger: null,
  content: null,
};

export default Dropdown;
