import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Sidebar from '../../../../../components/presentational/Sidebar/Sidebar';
import { routes } from '../../../../../core/config/routes';
import Alert from '../../../../../components/presentational/Alert/Alert';
import './ETicketSidebar.scss';
import { formatMoney } from '../../../../../core/utilities/money';
import { formatDate, formatTime, getMonth } from '../../../../../core/utilities/date';
import { eTicketLogout } from '../../../../../core/modules/ETicket/ETicketActions';
import { isExpired } from '../../../../../core/utilities/eTicket';
import Icon from '../../../../../components/presentational/Icon/Icon';
import IconContentContainer from '../../../../../components/presentational/Eticket/IconContentContainer';

const ETicketSidebar = (props) => {
  const {
    t, dispatchEticketLogout, type, cardNr, discount,
    lastOperationDate, validTill, amount,
  } = props;

  const isFirstType = type === 1;
  const lastOperationDateValidation = isFirstType ? true : (lastOperationDate !== '');
  const menu = {
    icon: 'ticket',
    title: t('eTicket'),
    items: [
      (!isExpired(validTill) && {
        title: t('eTicket_available_services'), to: t(routes.eticket.index), icon: 'add', id: 'index',
      }),
      {
        title: t('eTicket_current_services'), to: t(routes.eticket.topUpHistory), icon: 'wallet', id: 'topUpHistory',
      },
      (isFirstType && {
        title: t('eTicket_services_history'), to: t(routes.eticket.walletHistory), icon: 'wallet', id: 'walletHistory',
      }),
      (isFirstType && {
        title: t('eTicket_frequently_asked_question'), to: t(routes.eticket.faq), icon: 'circle-question', id: 'faq',
      }),
      {
        title: t('eTicket_logout'), onClick: dispatchEticketLogout, icon: 'exit', id: 'logout',
      },
    ],
  };
  const lastOperationMonth = getMonth(lastOperationDate);

  return (
    <Sidebar menu={menu}>
      <div className={`eticket-sidebar-card-info ${isFirstType ? 'is-tikis' : 'is-druskininkai'}`}>
        <div>{t('eTicket-sidebar-card-info-card-number')}: {cardNr}</div>
        <div>
          {validTill && (
            <>
              {t('eTicket_valid_till')}
              {': '}
              <span className={isExpired(validTill) ? 'expired-date' : ''}>
                {formatDate(validTill)}
              </span>
            </>
          )}
          {discount
          && (
            <>
              {t('eTicket-sidebar-card-info-card-discount')}
              {' '}
              {discount}
            </>
          )}
        </div>
      </div>

      {lastOperationDateValidation && (
        <Alert
          className={`eticket-sidebar-alert ${isExpired(validTill) ? 'is-expired' : ''}`}
          variant="warning"
          border={false}
          arrow="top"
        >
          {isExpired(validTill) ? (
            <>
              <Icon icon="sad-bus" />

              <div
                className="eticket-sidebar-alert-title"
              >{t('eTicket_sidebar_card-info_expired')}
              </div>

              <div className="eticket-sidebar-alert-subtitle">
                {t('eTicket_sidebar_card_info_purchase')}
              </div>
            </>
          ) : (
            <>
              <div className="eticket-sidebar-alert-title">
                {t(isFirstType ? 'eTicket_warning_message' : 'eTicket_warning_message_last_update')}
              </div>

              {!isFirstType && (
                <div className="eticket-sidebar-alert__month">
                  {t(`eticket.month.${lastOperationMonth}`)}
                </div>
              )}

              <div className="eticket-sidebar-alert-cash">
                {formatMoney(amount)}
              </div>

              <div className="eticket-sidebar-alert-wrapper">
                {t(isFirstType ? 'eTicket_warning_message_date' : 'eTicket_replenishment_data')}{': '}

                <IconContentContainer icon="calendar">
                  {formatDate(lastOperationDate)}
                </IconContentContainer>

                <IconContentContainer icon="clock">
                  {formatTime(lastOperationDate)}
                </IconContentContainer>
              </div>
            </>
          )}
        </Alert>
      )}
    </Sidebar>
  );
};

ETicketSidebar.propTypes = {
  dispatchEticketLogout: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  type: PropTypes.number,
  cardNr: PropTypes.number,
  discount: PropTypes.string,
  lastOperationDate: PropTypes.string,
  validTill: PropTypes.string,
  amount: PropTypes.number,
};

ETicketSidebar.defaultProps = {
  type: 1,
  cardNr: 0,
  discount: '',
  lastOperationDate: '',
  validTill: '',
  amount: 0,
};

const mapDispatchToProps = (dispatch) => ({
  dispatchEticketLogout: () => dispatch(eTicketLogout()),
});

export default connect(null, mapDispatchToProps)(withTranslation()(ETicketSidebar));
