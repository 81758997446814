import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import './UserLogin.scss';
import LoginForm from '../../../../modals/Login/Partials/LoginForm/LoginForm';
import ResetPasswordRequestForm
from '../../../../modals/Login/Partials/ResetPasswordRequestForm/ResetPasswordRequestForm';
import withDocumentHead from '../../../../common/DocumentHead/withDocumentHead';
import variantConst from '../../../../../core/config/constants/variantConst/variantConst';

const UserLogin = ({ t, setDocumentTitle, setDocumentDescription }) => {
  const [resetPassword, setResetPassword] = useState(false);

  const toggleLoginModalContent = () => setResetPassword(!resetPassword);

  React.useEffect(() => {
    setDocumentTitle(`${t(variantConst.documentTitle)} ${t(variantConst.documentTitleUserLogin)}`);
    setDocumentDescription(t(variantConst.documentDescriptionUserLogin));
  }, []);

  return (
    <div className="user-login">
      <div className="user-login-container">
        <div className="user-login-form-header">{t('login_connect')}</div>
        <div className="user-login-form-subheader">{t('user_login_form_header_wrapper')}</div>

        <div className="user-login-form">

          {resetPassword
            ? <ResetPasswordRequestForm toggleLoginModalContent={toggleLoginModalContent} />
            : <LoginForm toggleLoginModalContent={toggleLoginModalContent} />}
        </div>
      </div>
    </div>
  );
};

UserLogin.propTypes = {
  t: PropTypes.func.isRequired,
  setDocumentTitle: PropTypes.func.isRequired,
  setDocumentDescription: PropTypes.func.isRequired,
};

export default withTranslation()(withDocumentHead(UserLogin));
