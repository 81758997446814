import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TimeField from 'react-simple-timefield';
import './Timepicker.scss';
import { withTranslation } from 'react-i18next';
import Icon from '../Icon/Icon';

const TimepickerView = (props) => {
  const {
    id,
    t,
    onChange,
    value,
    name,
    outerRef,
  } = props;

  const [displayValue, setDisplayValue] = useState(value);

  useEffect(() => {
    const [hours, minutes] = value.split(':');
    const timeString = `${hours} ${t('search_time_hours_and')} ${minutes} ${t('search_time_minutes')}`;
    setDisplayValue(timeString);
  }, [value]);

  const onChangeWrapper = (e, nextValue) => {
    onChange(nextValue);
    const [hours, minutes] = nextValue.split(':');
    const timeString = `${hours} ${t('search_time_hours_and')} ${minutes} ${t('search_time_minutes')}`;
    setDisplayValue(timeString);
    const liveRegion = document.getElementById(`timepicker-live-${id || name}`);
    if (liveRegion) {
      liveRegion.textContent = `${t('time_updated_to')} ${timeString}`;
    }
  };
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <div htmlFor={id} ref={outerRef} className="timepicker-wrapper">
      <Icon icon="clock" />

      <TimeField
        input={(
          <input
            id={id || name}
            type="text"
            name={name}
            className="timepicker"
            aria-label={t('is_search_time_label')}
            aria-live="polite"
            aria-atomic="true"
            aria-describedby={`timepicker-live-${id || name}`}
          />
        )}
        onChange={onChangeWrapper}
        value={value}
      />

      <div
        id={`timepicker-live-${id || name}`}
        role="status"
        aria-live="polite"
        aria-atomic="true"
        style={{ position: 'absolute', left: '-9999px' }}
      >
        {t('time_updated_to')} {displayValue}
      </div>
    </div>
  );
};

TimepickerView.propTypes = {
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  outerRef: PropTypes.shape(),
  t: PropTypes.func.isRequired,
};

TimepickerView.defaultProps = {
  id: '',
  value: '00:00',
  outerRef: null,
};

export default withTranslation()(TimepickerView);
