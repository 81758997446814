import {
  put,
  call,
  all,
  takeLatest,
  delay,
} from 'redux-saga/effects';
import { setStopsList } from './StopsActions';
import { constants } from '../constants';
import { startRequest } from '../Request/RequestSaga';
import { endpoints } from '../../config/endpoints';
import { getObjectNestedData } from '../../utilities/object';
import { requestStarted } from '../Request/RequestActions';

function* getStopsSaga(action) {
  yield delay(50);

  const { value, exludedValue } = action.payload;
  const params = { name: value };

  yield put(requestStarted(endpoints.GET_STOPS_LIST.name));

  if (exludedValue) params.origin = exludedValue;

  const { response } = yield call(
    startRequest,
    endpoints.GET_STOPS_LIST.name,
    endpoints.GET_STOPS_LIST.url.path(),
    null,
    { ...params },
  );
  const data = getObjectNestedData(response, 'data.data');

  if (data) yield put(setStopsList(data));
}

export default function* StopsSaga() {
  yield all([
    takeLatest(constants.data.collections.stops.GET_STOPS_LIST, getStopsSaga),
  ]);
}
