import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import {
  getEventsCategories,
  getEvents,
  removeEvent,
} from '../../../core/modules/Events/EventsActions';
import { getBannerByZone } from '../../../core/modules/Data/Collections/Banners/BannersActions';
import { breakpointSet } from '../../../core/utilities/responsive';
import { getLink, routes } from '../../../core/config/routes';
import './Events.scss';
import { withTracker } from '../../common/Tracker/withTracker';
import { getMonth, getDay } from '../../../core/utilities/date';
import withDocumentHead from '../../common/DocumentHead/withDocumentHead';

class Events extends Component {
  constructor() {
    super();

    this.state = {
      tablet: breakpointSet('xs', 'sm', 'md'),
      mobile: breakpointSet('xs', 'sm'),
    };
  }

  componentDidMount() {
    const {
      onGetEvents,
      onGetEventsBanner,
      onGetEventsCategories,
      t,
      setDocumentTitle,
    } = this.props;

    onGetEvents();
    onGetEventsCategories();
    onGetEventsBanner();

    if (t) {
      setDocumentTitle(t('document_title', { title: t('events_events') }));
    }

    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    const { onRemoveEvent } = this.props;

    onRemoveEvent();
  }

  handleResize = () => {
    const { mobile, tablet } = this.state;
    const newMobileValues = breakpointSet('xs', 'sm');
    const newTabletValues = breakpointSet('xs', 'sm', 'md');

    if (mobile !== newMobileValues) {
      this.setState({
        mobile: newMobileValues,
      });
    }

    if (tablet !== newTabletValues) {
      this.setState({
        tablet: newTabletValues,
      });
    }
  };

  render() {
    const {
      t, eventBanner, eventsCategories, eventsList, match,
    } = this.props;
    const { category } = match.params;
    const {
      mobile, tablet,
    } = this.state;
    const bannerImage = eventBanner && ((mobile && eventBanner.imageMobile)
      || (tablet && eventBanner.imageTablet)
      || eventBanner.imageDesktop);
    const events = category && eventsList.length !== undefined
      ? eventsList.filter((event) => event.category.slug === category)
      : eventsList;
    events.sort((a, b) => b.position - a.position);

    return (
      <div className="events">
        {eventBanner && (
          <div
            className="events-header-banner"
            style={{ backgroundImage: `url('${bannerImage}')` }}
          />
        )}

        <div className="events-navigation">
          <div className="events-navigation-item-wrap">
            <NavLink
              exact
              to={t(routes.events.index)}
              className="events-navigation-item"
              activeClassName="is-active"
            >
              {t('stop_type_0')}
            </NavLink>
          </div>

          {eventsCategories && eventsCategories.map(({ id, title, slug }) => (
            <div key={title + id} className="events-navigation-item-wrap">
              <NavLink
                to={getLink(t(routes.events.eventCategory), { category: slug })}
                className="events-navigation-item"
                activeClassName="is-active"
              >
                {title}
              </NavLink>
            </div>
          ))}
        </div>

        <div className="events-list-wrap">
          <div className="events-list">
            {events && Array.isArray(events) && events.map((event) => (
              <div key={event.id} className="events-list-item-wrap">
                <Link
                  className="events-list-item"
                  to={getLink(
                    t(routes.events.event),
                    { category: event.category.slug, event: event.slug },
                  )}
                >
                  <div
                    className="events-list-item__image"
                    style={{ backgroundImage: `url(${event.image})` }}
                  />

                  <div className="events-list-item-text">
                    <div className="events-list-item__title">{event.title}</div>

                    <div className="events-list-item__subtitle">{event.place}</div>

                    {event.date && (
                      <div className="events-list-item__time">
                        <span className="events-list-item__capital">
                          {t(`of_month_${getMonth(event.date)}`)}
                        </span>
                        {' '}
                        {getDay(event.date)}
                        {' '}
                        {t('day_short')}
                        {event.time && (
                          <>
                            {' '}
                            {event.time}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

Events.propTypes = {
  t: PropTypes.func.isRequired,
  setDocumentTitle: PropTypes.func.isRequired,
  onGetEvents: PropTypes.func.isRequired,
  onGetEventsBanner: PropTypes.func.isRequired,
  onGetEventsCategories: PropTypes.func.isRequired,
  eventBanner: PropTypes.shape(),
  match: PropTypes.shape().isRequired,
  eventsCategories: PropTypes.arrayOf(PropTypes.shape()),
  eventsList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape()),
    PropTypes.shape(),
  ]),
  onRemoveEvent: PropTypes.func.isRequired,
};

Events.defaultProps = {
  eventBanner: null,
  eventsCategories: null,
  eventsList: [],
};

const mapStateToProps = (state) => ({
  eventBanner: state.data.collections.banners.get('event'),
  eventsList: state.data.collections.events.get('list'),
  eventsCategories: state.data.collections.events.get('categories'),
});

const mapDispatchToProps = (dispatch) => ({
  onGetEvents: () => dispatch(getEvents()),
  onRemoveEvent: () => dispatch(removeEvent()),
  onGetEventsCategories: () => dispatch(getEventsCategories()),
  onGetEventsBanner: () => dispatch(getBannerByZone('event')),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(withTracker(withDocumentHead(Events))));
