import {
  all,
  takeEvery,
  call,
  put,
} from 'redux-saga/effects';
import { constants } from '../constants';
import { startRequest } from '../Request/RequestSaga';
import { endpoints } from '../../config/endpoints';
import { getObjectNestedData } from '../../utilities/object';
import { setLocales } from './LocalesActions';

export function* getLocales() {
  const { response } = yield call(
    startRequest,
    endpoints.GET_LOCALES.name,
    endpoints.GET_LOCALES.url.path(),
  );

  if (response) {
    const languages = getObjectNestedData(response, 'data.data') || [];

    yield put(setLocales(languages));
  }
}

export default function* LocalesSaga() {
  yield all([
    takeEvery(constants.locales.GET_LOCALES, getLocales),
  ]);
}
