import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import './Checkout.scss';
import Input from '../../../../../components/presentational/FormElements/Input/Input';
import {
  initializeEticketOrder,
  getEticketOrder,
  submitEticketOrder,
  destroyEticketOrder,
} from '../../../../../core/modules/ETicket/ETicketActions';
import NotFound from '../../../NotFound/NotFound';
import { getErrors } from '../../../../../core/utilities/formValidator';
import { validations } from '../../../../../core/config/form/validations';
import Alert from '../../../../../components/presentational/Alert/Alert';
import { routes } from '../../../../../core/config/routes';
import { eTicketEndpoints } from '../../../../../core/config/endpoints/eTicket';
import Payments from './Partials/Payments/Payments';
import { formatMoney } from '../../../../../core/utilities/money';
import Preloader from '../../../../../components/presentational/Preloader/Preloader';
import Icon from '../../../../../components/presentational/Icon/Icon';
import withDocumentHead from '../../../../common/DocumentHead/withDocumentHead';
import variantConst from '../../../../../core/config/constants/variantConst/variantConst';

const Checkout = ({
  location,
  match,
  t,
  loading,
  dispatchInitializeEticketOrder,
  eTicketOrder,
  eTicketCheckoutErrors,
  dispatchGetEticketOrder,
  dispatchSubmitEticketOrder,
  dispatchDestroyEticketOrder,
  setDocumentTitle,
  setDocumentDescription,
}) => {
  const { state: locationState } = location;
  const { initialOrder } = locationState || {};
  const { params, path } = match;
  const { orderHash: urlOrderHash } = params;
  const isEticketErrorRoute = path === t(routes.eticket.checkout.error);
  const eTicket = eTicketOrder && eTicketOrder.items[0];
  const formRef = React.useRef(null);
  const eTicketServiceStringsByDurationType = {
    1: {
      textKey: 'checkout_eticket_order_chosen_service_head_2',
      nameKey: 'durationTitle',
    },
    2: {
      textKey: 'checkout_eticket_order_7_day_service',
      nameKey: 'durationTitle',
    },
    3: {
      textKey: '',
      nameKey: 'name',
    },
  };

  React.useEffect(() => {
    if (initialOrder) {
      dispatchInitializeEticketOrder(initialOrder);
    } else if (urlOrderHash) {
      dispatchGetEticketOrder(urlOrderHash);
    }
  }, [initialOrder, urlOrderHash]);

  React.useEffect(() => function cleanup() {
    dispatchDestroyEticketOrder();
  }, []);

  React.useEffect(() => {
    if (t) {
      setDocumentTitle(`${t(variantConst.documentTitle)} ${t('document_title_eticket_order_checkout')}`);

      setDocumentDescription(t('document_description_eticket_order_checkout'));
    }
  }, [t]);

  if (!initialOrder && !urlOrderHash) return <NotFound />;

  if (!eTicketOrder && loading) return <Preloader fullWidth fullHeight />;

  const onSubmitOrder = (data) => {
    if (eTicketOrder) dispatchSubmitEticketOrder(eTicketOrder.hash, data);
  };

  return (
    <div className="eTicket-checkout">
      {isEticketErrorRoute && (
        <Alert
          variant="danger"
          heading={t('common_error_exclamation')}
        >
          {t('checkout_payment_cancelled')}
        </Alert>
      )}

      {eTicketCheckoutErrors.map((error) => (
        <Alert
          key={error}
          variant="danger"
          heading={t('common_error_exclamation')}
        >
          {error}
        </Alert>
      ))}

      <h1 className="eTicket-checkout-title">{t('eTicket_available_services_title')}</h1>

      <Formik
        enableReinitialize
        validateOnChange={false}
        onSubmit={onSubmitOrder}
        validate={(values) => getErrors(values,
          { email: values.email ? [validations.email] : [] }, formRef)}
        initialValues={{ email: '' }}
        render={(formProps) => {
          const {
            values, handleSubmit, setFieldValue, errors,
          } = formProps;
          const { email } = values;
          return (
            <form
              ref={formRef}
              onSubmit={handleSubmit}
              className="eTicket-submit"
            >
              <div className="eTicket-chosen-service">
                <div className="eTicket-chosen-service-info">
                  <div className="eTicket-chosen-service-info-head">
                    <span>{t('checkout_eticket_order_chosen_service')}</span>

                    <Link to={t(routes.eticket.index)}>{t`order_change`}<Icon icon="arrow-right" /></Link>
                  </div>

                  {eTicket
                    && (
                      <div className="eTicket-chosen-service-info-prices">
                        <div>
                          <span>{t('checkout_eticket_order_chosen_service_head_1')}</span>

                          <strong>
                            {eTicket[
                              eTicketServiceStringsByDurationType[eTicket.durationType].nameKey
                            ]}
                          </strong>
                        </div>

                        <div>
                          <strong>{formatMoney(eTicketOrder.total)}</strong>
                        </div>
                      </div>
                    )}

                  <div className="arrow-right" />
                </div>

                <div className="eTicket-chosen-service-email">
                  <span>{t('checkout_eticket_order_chosen_service_email_explained')}</span>

                  <Input
                    label={t('form_enter_email_address')}
                    name="email"
                    type="text"
                    value={email}
                    onChange={(e) => setFieldValue('email', e.target.value)}
                    disabled={loading}
                    error={t(errors.email)}
                  />
                </div>
              </div>

              { eTicketOrder && <Payments /> }

            </form>
          );
        }}
      />
    </div>
  );
};

Checkout.propTypes = {
  eTicketCheckoutErrors: PropTypes.arrayOf(PropTypes.string),
  t: PropTypes.func.isRequired,
  location: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
  dispatchInitializeEticketOrder: PropTypes.func.isRequired,
  dispatchGetEticketOrder: PropTypes.func.isRequired,
  eTicketOrder: PropTypes.shape(),
  dispatchSubmitEticketOrder: PropTypes.func.isRequired,
  dispatchDestroyEticketOrder: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  setDocumentTitle: PropTypes.func.isRequired,
  setDocumentDescription: PropTypes.func.isRequired,
};

Checkout.defaultProps = {
  eTicketOrder: null,
  loading: false,
  eTicketCheckoutErrors: [],
};

const mapStateToProps = (state) => ({
  eTicketOrder: state.eTicket.get('order'),
  loading: state.eTicket.getIn(['status', 'loading']),
  eTicketCheckoutErrors: Object
    .entries(eTicketEndpoints)
    .reduce((accumulator, [endpointName]) => (
      accumulator.concat(state.request.getIn([endpointName, 'error', 'global']))
    ), [])
    .filter(Boolean),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchDestroyEticketOrder: () => dispatch(destroyEticketOrder()),
  dispatchGetEticketOrder: (orderHash) => dispatch(getEticketOrder(orderHash)),
  dispatchSubmitEticketOrder: (orderHash, orderData) => (
    dispatch(submitEticketOrder(orderHash, orderData))
  ),
  dispatchInitializeEticketOrder: (order) => dispatch(initializeEticketOrder(order)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(withDocumentHead(Checkout)),
);
