import React from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import logo from '../../../../../../../assets/logo/logo-VARIANT.png';
import './OverlayContent-VARIANT.scss';
import Icon from '../../../../../../components/presentational/Icon/Icon';
import { redirect } from '../../../../../../core/utilities/routing';
import { history } from '../../../../../../core/historyAndStore';
import { closeMenuOverlay } from '../../../../../../core/modules/Components/MenuOverlay/MenuOverlayActions';
import { routes } from '../../../../../../core/config/routes';

const OverlayContent = ({
  locales,
  dispatchCloseMenuOverlay,
  menuItems,
  activeLocale,
  defaultLocale,
  i18n,
  t,
}) => (
  <div className="overlay-container">
    <div className="overlay-container-header">
      <div className="overlay-container-header-logo-container">
        <Link to={t(routes.homepage)} className="header-logo-link" aria-label={t('homepage_link')}>
          <img src={logo} alt="Logo" className="header-logo" />
        </Link>

        <Icon
          icon="cancel"
          onClick={dispatchCloseMenuOverlay} 
          role="button"
          aria-label="Close"
          tabIndex={0} 
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              dispatchCloseMenuOverlay();
            }
          }}
        />
      </div>

      {menuItems.map((menuItem, i) => {
        const ButtonComponent = menuItem.to ? NavLink : 'div';
        const hasIcon = menuItem.icon;

        if (!menuItem.children) return null;

        if (menuItem.id === 'promotions' && !(t('special_offers_dropdown'))) return null;

        if (menuItem.id === 'buses' && !(t('buses_dropdown'))) return null;

        const handleClick = () => {
          if (!(menuItem.children instanceof Object)) {
            menuItem.onClick();
            dispatchCloseMenuOverlay();
          }

          return null;
        };

        return (
          <div key={i} className={`overlay-menu-item ${hasIcon ? 'has-background' : ''}`}>
            <ButtonComponent
              className={menuItem.to ? '' : 'has-inline-flex'}
              onClick={handleClick}
              to={menuItem.to}
            >
              {hasIcon && <Icon icon={menuItem.icon} />}

              <span className={`overlay-button-text ${hasIcon ? 'font-weight-normal' : ''}`}>
                {menuItem.children}
              </span>
            </ButtonComponent>
          </div>
        );
      })}
    </div>

    <div className="overlay-menu-locales-list">
      {locales.map((locale, i) => (
        <div
          key={i}
          tabIndex={0}
          role="button"
          onClick={() => {
            redirect(history, `${defaultLocale === locale ? '' : `/${locale}`}`);
            i18n.changeLanguage(locale);
            dispatchCloseMenuOverlay();
          }}
          className={`locales-list-item ${activeLocale === locale ? 'is-active' : ''}`}
        >
          {locale}
        </div>
      ))}
    </div>
  </div>
);

OverlayContent.propTypes = {
  dispatchCloseMenuOverlay: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape().isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.shape()),
  locales: PropTypes.arrayOf(PropTypes.string),
  activeLocale: PropTypes.string.isRequired,
  defaultLocale: PropTypes.string.isRequired,
};

OverlayContent.defaultProps = {
  menuItems: [],
  locales: [],
};

const mapStateToProps = (state) => ({
  locales: state.locales.get('list'),
  defaultLocale: state.locales.get('default'),
  activeLocale: state.locale,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchCloseMenuOverlay: () => dispatch(closeMenuOverlay()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OverlayContent));
