export const cmsEndpoints = {
  GET_CUSTOM_FOOTER: {
    name: 'GET_CUSTOM_FOOTER',
    url: {
      method: 'GET',
      path: (endpointFooter) => `/cms/folder/${endpointFooter}`,
    },
  },
  GET_CMS_PAGE: {
    name: 'GET_CMS_PAGE',
    url: {
      method: 'GET',
      path: (pageId) => `/cms/${pageId}`,
    },
  },
  GET_CMS_MENU: {
    name: 'GET_CMS_MENU',
    url: {
      method: 'GET',
      path: (menuFolder) => `/cmsMenu/${menuFolder}`,
    },
  },
};
