import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { getUserOrder } from '../../../../../core/modules/User/UserActions';
import Ticket from '../../../Tickets/Partials/Ticket/Ticket';
import { routes } from '../../../../../core/config/routes';
import Button from '../../../../../components/presentational/Button/Button';
import Preloader from '../../../../../components/presentational/Preloader/Preloader';
import './UserTicket.scss';
import NotFound from '../../../NotFound/NotFound';
import { endpoints } from '../../../../../core/config/endpoints';
import Icon from '../../../../../components/presentational/Icon/Icon';

const UserTicket = (props) => {
  const {
    match, dispatchGetUserOrder, userTickets, hash, t, loading, error,
  } = props;

  useEffect(() => {
    dispatchGetUserOrder(match.params.id);
  }, []);

  if (loading) return <Preloader fullHeight fullWidth className="user-ticket-preloader" />;

  if (error) return <NotFound />;

  return (
    <>
      <div className="user-ticket-header">{t('user_purchase_history')}</div>
      <Button variant="inline-link" className="user-ticket-link" route={t(routes.user.index)}>
        <span>
          <Icon icon="arrow-left" />
          {t('user_ticket_return_to_list')}
        </span>
      </Button>
      {userTickets.map((ticket) => (
        <Ticket
          key={ticket.ticketId}
          orderHash={hash}
          ticket={ticket}
        />
      ))}
    </>
  );
};

UserTicket.propTypes = {
  match: PropTypes.shape(),
  t: PropTypes.func.isRequired,
  dispatchGetUserOrder: PropTypes.func.isRequired,
  userTickets: PropTypes.arrayOf(PropTypes.shape()),
  hash: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.shape(),
};

UserTicket.defaultProps = {
  match: {},
  userTickets: [],
  hash: '',
  loading: false,
  error: null,
};

const mapStateToProps = (state) => ({
  userTickets: state.order.getIn(['data', 'tickets']),
  hash: state.order.getIn(['data', 'hash']),
  loading: state.order.getIn(['status', 'loading']),
  error: state.request.getIn([endpoints.GET_USER_ORDER.name, 'error']),

});

const mapDispatchToProps = (dispatch) => ({
  dispatchGetUserOrder: (id) => dispatch(getUserOrder(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserTicket));
