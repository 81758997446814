import { Map } from 'immutable';
import { constants } from '../../constants';

const initialState = Map({});

const NewsletterReducer = (state = initialState, action) => {
  switch (action.type) {
  case constants.components.newsletter.SET_STATUS:
    return state.set('state', action.payload.state);

  default:
    return state;
  }
};

export default NewsletterReducer;
